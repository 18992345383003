import React, { useEffect, useState } from 'react'
import DashBoardheader from '../../../components/DashboardHeader'
import SideNavBar from '../../../components/Sidebar'
import './target-customer.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TargetCustomerTable from '../../../components/TargetCustomerTable';
import { useNavigate } from 'react-router-dom';
import { BaseUrl, dsUrl } from '../../Api/api';
import axios from 'axios';


const TargetCustomer = () => {

    const [customerList, setCustomerList] = useState([])
    const [searchList, setSearchList] = useState([])

    const navigate = useNavigate()
    const handleClickAddSingleCustomer = () => {
        navigate('/target-add-single-customer')
    }

    const handleClickAddMultipleCustomer = () => {
        navigate('/target-add-multiple-customer')
    }

    useEffect(() => {
        const userToken = localStorage.getItem("token")
        axios
            .get(`${BaseUrl}AdminTargetCustomer/Get-Target-Customer-List`,
                { headers: { Authorization: `Bearer ${userToken}` } }
            )
            .then((response) => {
                console.log("customerList ==>", response.data)
                setCustomerList(response.data.data)
                setSearchList(response.data.data)
            })
    }, [])

    const handleSearch = (e) => {
        const filtered = customerList.filter(l => {
            return l.customer_name && l.customer_name.toLowerCase().includes(e.target.value)
                || l.company_name && l.company_name.toLowerCase().includes(e.target.value)
                || l.customer_email && l.customer_email.toLowerCase().includes(e.target.value)

        })
        setSearchList(filtered)
    }

    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className="main-body-container">
                <div className="container">
                    <div className='mt-3'>
                        <div className="">
                            <h1 className="customer-top-name">Customer</h1>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <div className="customer-top-search d-flex">
                                <div>
                                    <input type="text"
                                        placeholder='search'
                                        style={{
                                            border: "1px solid #B5B7BC",
                                            padding: '5px',
                                            borderRadius: "8px",
                                            marginRight: "20px"
                                        }}
                                        onChange={handleSearch}
                                    />
                                </div>
                                <div>
                                    <button
                                        class="target-top-add-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        Add Customer
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><a class="dropdown-item" onClick={handleClickAddSingleCustomer} >Add Single Customer</a></li>
                                        <li><a class="dropdown-item" onClick={handleClickAddMultipleCustomer}>Add Multiple Customer</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* {JSON.stringify(customerList)} */}
                <div className="container mt-3">
                    <TargetCustomerTable customerList={searchList} />
                </div>

            </div>
        </>
    )
}

export default TargetCustomer