import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../pages/Api/api";
import FilterComponent from "../DashboardHome/Vendor/VendorFilter/index";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import ColumnsIcon from "../../images/columns.png";

const Table = () => {
  const [apiData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowTable, showTableUpdate] = useState(true);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});
  const [isColumns, isSetColumns] = useState({});
  const [checkBox, showCheckBox] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedHeaders, setSelectedHeaders] = useState([
    {
      id: "name",
      name: "Person Name",
      selector: (result) => result.name,
      sortable: true,
    },
    {
      id: "company_name",
      name: "Vendor",
      selector: (result) => result.company_name,
      sortable: true,
    },
    {
      id: "user_name",
      name: "Email id",
      selector: (result) => result.user_name,
      sortable: true,
    },
    {
      id: "phone_no",
      name: <div>Phone Number</div>,
      selector: (result) => result.phone_no,
      sortable: true,
    },
    {
      id: "department",
      name: <div>Department</div>,
      selector: (result) => result.department,
      sortable: true,
    },
    {
      id: "positon",
      name: <div>Positon</div>,
      selector: (result) => result.position,
      sortable: true,
    },
    {
      id: "country",
      name: "Country",
      selector: (result) => result.country,
      sortable: true,
    },
    // {
    //   id: "solutions",
    //   name: "Category",
    //   selector: (result) => result.solutions,
    //   sortable: true,
    // },
  ]);
  const userToken = localStorage.getItem("token");
  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setLoading(true);
    fetch(`${BaseUrl}AdminVendorContact/Get-Contacts-By-Vendor`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //  console.log(result.data, "apidata of vendor contact ");
        setLoading(false);
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const tableData = apiData;
  
  const result = tableData && tableData.map((e) => {
    return {
      
      country: e.country,
      company_name: e.vendor_name,
      user_name: e.person_email,
      position: e.position,
      department: e.department,
      phone_no: e.contact_no,
      name:
        e.person_name

          ? e.person_name
          : "Not Available",
    };
  });

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#0900bc",
        color: "white",
        fontSize: "14px",
        width: "100%",
      },
    },
    cells: {
      style: {
        fontSize: "13.5px",
        //   padding: '0px 8px 0px 20px',
        //  width:'150px'
        // if we want show the full detail of topic need un comment the above line
      },
    },
    rows: {
      style: {
          cursor:"pointer",
          fontSize: "13.5px",
          // textTransform:"capitalize",
          
      },
  },
  };

  const onRowClicked = (row) => {
    // showTableUpdate(false);
    // setSelectedVendorDetails(row);
    // console.log("onRow", row);
    // console.log(selectedVendorDetails, "selectedVendorDetails");
  };
  const onClickCol = () => {
    showCheckBox(!checkBox);
  };
  const allColumns = [
    {
      id: "name",
      name: "Person Name",
      selector: (result) => result.name,
      sortable: true,
    },
    {
      id: "company_name",
      name: "Vendor",
      selector: (result) => result.company_name,
      sortable: true,
    },
    {
      id: "user_name",
      name: "Email id",
      selector: (result) => result.user_name,
      sortable: true,
    },
    {
      id: "phone_no",
      name: <div>Phone Number</div>,
      selector: (result) => result.phone_no,
      sortable: true,
    },
    {
      id: "department",
      name: <div>Department</div>,
      selector: (result) => result.department,
      sortable: true,
    },
    {
      id: "positon",
      name: <div>Positon</div>,
      selector: (result) => result.position,
      sortable: true,
    },
    {
      id: "country",
      name: "Country",
      selector: (result) => result.country,
      sortable: true,
    },
  ];

  const col = allColumns.filter((value) => {
    // return console.log(value, "allColumns");
    // isSetColumns(value)
  });

  //   console.log(col, "filtered columns");
  //   console.log(isColumns, "state columns");

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const results =
    result &&
    result.filter(
      (item) =>
        item.company_name &&
        item.company_name.toLowerCase().includes(filterText.toLowerCase())
        || item.name.toLowerCase().includes(filterText.toLowerCase())
        || item.user_name.toLowerCase().includes(filterText.toLowerCase())
        ||item.phone_no.toLowerCase().includes(filterText.toLowerCase())
        || item.country.toLowerCase().includes(filterText.toLowerCase())
        || item.position.toLowerCase().includes(filterText.toLowerCase())
        || item.department.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="table-filter">
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeholder={"Search"}
      />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const onClickApply = () => {
    let select = allColumns.filter((value) => {
      return isSelected.includes(value.id);
    });
    setSelectedHeaders(select);
  };

  return (
    <div className="pt-3">
      {isShowTable && (
        <>
          <div className="custom-columns">
            {/* <img
            className="columns-icon"
            onClick={onClickCol}
            src={ColumnsIcon}
            alt=""
          />{" "} */}
            &nbsp;
            {checkBox && (
              <>
                <div className="dropdown-multi">
                  <DropdownMultiselect
                    handleOnChange={(value) => {
                      console.log(value, "handleOnChange");
                      setIsSelected(value);
                    }}
                    options={[
                      "created_at",
                      "updated_at",
                      "name",
                      // "first_name",
                      // "last_name",
                      "company_name",
                      "user_name",
                      "country",
                      "currency",
                      "currency_code",
                      "symbol",
                      "address",
                      "city",
                      "zipcode",
                      "solutions",
                      "phone_no",
                      "activation_status",
                      "status",
                      "available_point",
                    ]}
                    selected={[
                      "company_name",
                      "activation_status",
                      "name",
                      "phone_no",
                      "user_name",
                      "solutions",
                    ]}
                    placeholder="select"
                  />
                </div>
                <button className="apply-button" onClick={onClickApply}>
                  apply
                </button>
              </>
            )}
          </div>
        </>
      )}
      {loading && (
        <center>
          <div
            className="spinner-border text-primary loader"
            role="status"
          ></div>
        </center>
      )}
      {isShowTable && (
        <>
          {/* <h1 className="vendor-follow-header">Vendor</h1> */}
          <DataTable
            columns={selectedHeaders}
            data={results}
            // expandableRows
            customStyles={customStyles}
            //  expandableRowsComponent={ExpandedComponent}
            //  expandableRows
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onRowClicked={onRowClicked}
            pagination
          />
        </>
      )}
     
    </div>
  );
};

export default Table;
