import React from 'react'
// import './breadcrumbs.css'
import { useLocation, useNavigate } from 'react-router-dom'

const   VendorBreadCrumbs = () => {

    const { pathname } = useLocation();
    const navigate = useNavigate();


    const onClickVendor = () => {
        navigate('/vendor-dashboard')
    }

    const onClickAddcontact = () => {
        navigate('/add-new-contacts')
    }

   

    return (
        <>

            <nav>
                <div className='d-flex' style={{ listStyle: "none" }}>
                    <li>
                        <p className={`breadcrumbs-name ${pathname === '/vendor-dashboard' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickVendor}
                            style={{ cursor: "pointer" }}
                        >
                            Vendor <span className='px-1'>{'>'}</span>
                        </p>
                    </li>
                    <li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/add-new-contacts' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickAddcontact}
                            style={{ cursor: "pointer" }}
                        >
                            Add New Contact <span className='px-1'></span>
                        </p>
                    </li>
                </div>

            </nav>
        </>
    )
}

export default VendorBreadCrumbs