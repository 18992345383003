import React, { useState } from "react";

const Productinputs = ({ register, selectedOption, errors }) => {
  const [value, setValue] = useState("");
  const [count, setCount] = useState(0);

  const handleInputChange = (event) => {
    event.preventDefault();
    console.log("handleInputChange");
    const newValue = event.target.value;
    setValue(newValue);
    setCount(newValue.length);
  };

  return (
    <div>
      <input
        type="text"
        className="anp-input"
        //  value={value}
        maxLength="50"
        {...register("new_brand_name", {
          onChange: handleInputChange,
          required: selectedOption === "input",
          minLength: 3,
          maxLength: 50,
        })}
      />
      {errors.new_brand_name && (
        <span className="error-msg-ap">
          Please enter the product brand name
        </span>
      )}
      <p className="anp-input-count">{count}/50</p>
    </div>
  );
};

export default Productinputs;
