import React, { useState, useEffect } from "react";
import Table from "./table.jsx";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import { useNavigate } from "react-router-dom";

const CustomerContacts = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("all");
  const [enableFilter, setEnableFilter] = useState(false);
  
  const [apiData, setData] = useState([]);
  
  
  const handleTypeSelect = (selectedKey) => setType(selectedKey);

  const toggleFilter = () => {
    setEnableFilter(!enableFilter);
  };
  const onClickAccept = () => {
    navigate("/Vendor");
  };
 
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
    
      <div className="vendor-follow">
        <div className="vendor-follow-main-div">
          <h1 className="vendor-follow-header">Customer Contact </h1>
          <div className="containerr">
            <div className="row">
              <div className="col-12">
                <div className="card">
                
                 <Table/>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerContacts;
