import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const user = localStorage.getItem("token");
  // console.log(user,"protected token");
  if (user) {
    return true;
  } else {
    return false;
  }
  
};
const ProtectedRoutes = () => {
  

  const auth = useAuth();
  // console.log(auth,"this after logout button clicked")
  return auth ?   <Outlet /> : <Navigate to="/"  /> ;
};

export default ProtectedRoutes;
