import React from 'react'
import './subscriberList.css'
import DashBoardheader from '../../../components/DashboardHeader'
import SideNavBar from '../../../components/Sidebar';
import FilterIcon from '../../../images/FilterIcon.svg'
import SubscriberListTable from '../../../components/SubscriberListTable';

const SubscriberList = () => {
    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className="main-body-container">
                <div className="container mt-4">
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h5 className='subscriber-list-top-header'>Subscriber List</h5>
                        </div>
                        <div>
                            <p className='add-sub-btn px-3'>Add New Subscriber</p>
                        </div>
                    </div>

                    <div className='d-flex justify-content-end align-items-center'>
                        <span>
                            <input type="text" placeholder='Search' style={{
                                background: "#F1F1F1",
                                border: "none",
                                borderRadius: "3px",
                                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"
                            }} />
                        </span>
                        <img src={FilterIcon} alt="" />
                    </div>

                    <div className='subscriber-table-bgm mt-5'>
                        <SubscriberListTable />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriberList