import React from 'react'
import DashBoardheader from '../../../components/DashboardHeader'
import SideNavBar from '../../../components/Sidebar'

const EmailCampaign = () => {
    return (
        <>  <DashBoardheader />
            <SideNavBar />
        </>
    )
}

export default EmailCampaign