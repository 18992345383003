import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../pages/Api/api";
import FilterComponent from "../../pages/DashboardHome/Vendor/VendorFilter";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import ColumnsIcon from "../../images/columns.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCallExpertTableData,
  updateSelectedCallExpertTableCheckbox,
  UpdateSelectedCallExpertId,
  updateSelectedSingleExpertData,
} from "../../Store/Reducer/CallExpertReducer";
import { transformCallExpertData } from "../../utils/util";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import ReactModal from "react-modal";
import ActionPopup from "../../pages/Target/CallExpert/PopUp/ActionPopup.jsx";
import "./CallExpert.css";

const TargetCallExpertTable = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [apiData, setData] = useState([]);
  const {
    callExpertList,
    loading,
    selectedCallExpertTableCheckbox,
    selectedCallExpertId,
  } = useSelector((state) => state.callExpertData);
  const [isShowTable, showTableUpdate] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});
  const [isColumns, isSetColumns] = useState({});
  const [checkBox, showCheckBox] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [contactView, setContactView] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState([
    {
      id: "Date",
      name: "Date",
      selector: (result) => result.created_at,
      sortable: true,
      width: "11rem",
    },
    {
      id: "Client Name",
      name: "Client Name",
      selector: (result) => result.name,
      sortable: true,
      width: "10rem",
    },
    {
      id: "Company Email",
      name: "Company Email",
      selector: (result) => result.company_email,
      sortable: true,
      width: "16rem",
    },
    {
      id: "Phone Number",
      name: "Phone Number",
      selector: (result) => result.phone,
      sortable: true,
      width: "10rem",
    },
    {
      id: "Status",
      name: "Status",
      selector: (result) =>  <button
      className={`${
        result.status === "Completed"
          ? "call-expert-status0"
          : result.status === "New Inquiry"
          ? "call-expert-status1"
          : result.status === "In Progress"
          ? "call-expert-status2"
          : "call-expert-status"
      }`}
    >
      {result.status}
    </button>,
      sortable: true,
      width: "10rem",
    },
    {
      id: "Solution",
      name: "Solution",
      selector: (result) => result.solution,
      sortable: true,
      width: "10rem",
    },
    {
      id: "Industry",
      name: "Industry",
      selector: (result) => result.company,
      sortable: true,
      width: "13rem",
    },
    {
      id: "Company Size",
      name: "Company Size",
      selector: (result) => result.company_size,
      sortable: true,
      width: "9rem",
    },
    {
      id: "Designation",
      name: "Designation",
      selector: (result) => result.designation,
      sortable: true,
      width: "12rem",
    },
    {
      id: "Client Query",
      name: "Client Query",
      selector: (result) => parse(result.query),
      sortable: true,
      width: "16rem",
    },
    {
      id: "Feedback",
      name: "Feedback",
      selector: (result) => result.remark,
      sortable: true,  
    },
    {
      id: "Action",
      name: "Action",
      width: "7rem",
      cell: (result) => (
        <div className="call-expert-action">
          <button
            onClick={() => {
              handleActionBtn(result.id);
            }}
            className="call-expert-action-btn"
          >
            {" "}
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0.5C5.45455 0.5 1.57273 3.19533 0 7C1.57273 10.8047 5.45455 13.5 10 13.5C14.5455 13.5 18.4273 10.8047 20 7C18.4273 3.19533 14.5455 0.5 10 0.5ZM10 11.3333C7.49091 11.3333 5.45455 9.392 5.45455 7C5.45455 4.608 7.49091 2.66667 10 2.66667C12.5091 2.66667 14.5455 4.608 14.5455 7C14.5455 9.392 12.5091 11.3333 10 11.3333ZM10 4.4C8.49091 4.4 7.27273 5.56133 7.27273 7C7.27273 8.43867 8.49091 9.6 10 9.6C11.5091 9.6 12.7273 8.43867 12.7273 7C12.7273 5.56133 11.5091 4.4 10 4.4Z"
                fill="#0F2B95"
              />
            </svg>
          </button>
        </div>
      ),
    },
  ]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const userToken = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getCallExpertTableData());
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  }, []);

  const tableData = callExpertList;
  const result = transformCallExpertData(tableData);
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#0900bc",
        color: "white",
        fontSize: "14px",
        width: "100%",
        // gap:"50px"
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "13.5px",
        color: "black",
        fontWeight: "500",
        letterSpacing: "0.5px",
        whiteSpace: "nowrap",

        // background :  index % 2? { background : "#fdffe0" }:{ background : "white" }
        // backgroundColor: result.index %2 ? 'pink' : 'green'
      },
    },
    cells: {
      style: {
        fontSize: "13.5px",
      },
    },
  };

  const handleActionBtn = (id) => {
    setIsOpen(true);
    dispatch(UpdateSelectedCallExpertId(id));
    dispatch(
      updateSelectedSingleExpertData(
        callExpertList.filter((item) => item.id == id)
      )
    );
  };
  const onRowClicked = (row) => {
    setIsOpen(true);
    dispatch(
      updateSelectedSingleExpertData(
        callExpertList.filter((item) => item.id == row.id)
      )
    );
  };

  const onClickApply = () => {
    let select = allColumns.filter((value) => {
      return isSelected.includes(value.id);
    });
    setSelectedHeaders(select);
    dispatch(updateSelectedCallExpertTableCheckbox(isSelected));
  };

  //   const onRowClicked = (row) => {
  //     navigate("/");
  //     showTableUpdate(false);
  //     dispatch(updateCallExpertId(row.id));
  //   };

  const onClickCol = () => {
    showCheckBox(!checkBox);
  };

  const onRequestClose = (close) => {
    setIsOpen(close);
  };

  useEffect(() => {
    let select = allColumns.filter((value) => {
      return (
        selectedCallExpertTableCheckbox &&
        selectedCallExpertTableCheckbox.includes(value.id)
      );
    });
    setSelectedHeaders(select);
  }, []);

  const allColumns = [
    {
      id: "Date",
      name: "Date",
      selector: (result) => result.created_at,
      sortable: true,
      width: "11rem",
    },
    {
      id: "Client Name",
      name: "Client Name",
      selector: (result) => result.name,
      sortable: true,
      width: "10rem",
    },
    {
      id: "Company Email",
      name: "Company Email",
      selector: (result) => result.company_email,
      sortable: true,
      width: "16rem",
    },
    {
      id: "Phone Number",
      name: "Phone Number",
      selector: (result) => result.phone,
      sortable: true,
      width: "10rem",
    },
    {
      id: "Status",
      name: "Status",
      selector: (result) => (
        <button
          className={`${
            result.status === "Completed"
              ? "call-expert-status0"
              : result.status === "New Inquiry"
              ? "call-expert-status1"
              : result.status === "In Progress"
              ? "call-expert-status2"
              : "call-expert-status"
          }`}
        >
          {result.status}
        </button>
      ),
      sortable: true,
      width: "10rem",
    },
    {
      id: "Solution",
      name: "Solution",
      selector: (result) => result.solution,
      sortable: true,
      width: "10rem",
    },
    {
      id: "Industry",
      name: "Industry",
      selector: (result) => result.company,
      sortable: true,
      width: "13rem",
    },
    {
      id: "Company Size",
      name: "Company Size",
      selector: (result) => result.company_size,
      sortable: true,
      width: "9rem",
    },
    {
      id: "Designation",
      name: "Designation",
      selector: (result) => result.designation,
      sortable: true,
      width: "12rem",
    },
    {
      id: "Client Query",
      name: "Client Query",
      selector: (result) => parse(result.query),
      sortable: true,
      width: "16rem",
    },
    {
      id: "Feedback",
      name: "Feedback",
      selector: (result) => result.remark,
      sortable: true,
      
    },
    {
      id: "Action",
      name: "Action",
      width: "7rem",
      cell: (result) => (
        <div className="call-expert-action">
          <button
            onClick={() => {
              handleActionBtn(result.id);
            }}
            className="call-expert-action-btn"
          >
            {" "}
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0.5C5.45455 0.5 1.57273 3.19533 0 7C1.57273 10.8047 5.45455 13.5 10 13.5C14.5455 13.5 18.4273 10.8047 20 7C18.4273 3.19533 14.5455 0.5 10 0.5ZM10 11.3333C7.49091 11.3333 5.45455 9.392 5.45455 7C5.45455 4.608 7.49091 2.66667 10 2.66667C12.5091 2.66667 14.5455 4.608 14.5455 7C14.5455 9.392 12.5091 11.3333 10 11.3333ZM10 4.4C8.49091 4.4 7.27273 5.56133 7.27273 7C7.27273 8.43867 8.49091 9.6 10 9.6C11.5091 9.6 12.7273 8.43867 12.7273 7C12.7273 5.56133 11.5091 4.4 10 4.4Z"
                fill="#0F2B95"
              />
            </svg>
          </button>
        </div>
      ),
    },
  ];

  const results =
    result &&
    result.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())) ||
        item.company_email.toLowerCase().includes(filterText.toLowerCase()) ||
        item.phone.toLowerCase().includes(filterText.toLowerCase()) ||
        item.solution.toLowerCase().includes(filterText.toLowerCase()) ||
        item.designation.toLowerCase().includes(filterText.toLowerCase()) ||
        item.company_size.toLowerCase().includes(filterText.toLowerCase()) ||
        item.company.toLowerCase().includes(filterText.toLowerCase()) ||
        item.query.toLowerCase().includes(filterText.toLowerCase()) ||
        item.status.toLowerCase().includes(filterText.toLowerCase()) ||
        item.remark.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className="table-filter">
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            placeholder={"Search"}
          />
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="pt-3">
      {isShowTable && (
        <>
          <div
            className={checkBox ? "custom-columns" : "custom-columns-before"}
          >
            <img
              className="columns-icon"
              onClick={onClickCol}
              src={ColumnsIcon}
              alt=""
            />{" "}
            &nbsp;
            {checkBox && (
              <>
                <div className="dropdown-multi">
                  <DropdownMultiselect
                    handleOnChange={(value) => {
                      setIsSelected(value);
                    }}
                    options={[
                      "Date",
                      "Client Name",
                      "Company Email",
                      "Phone Number",
                      "Status",
                      "Solution",
                      "Industry",
                      "Company Size",
                      "Designation",
                      "Client Query",
                      "Action",
                      "Feedback"
                    ]}
                    selected={selectedCallExpertTableCheckbox}
                    placeholder="select"
                  />
                </div>
                <button className="apply-button" onClick={onClickApply}>
                  Apply
                </button>
              </>
            )}
          </div>
        </>
      )}
      {loading && (
        <center>
          <div
            className="spinner-border text-primary loader"
            role="status"
          ></div>
        </center>
      )}
      {isShowTable && (
        <>
          <ReactModal
            isOpen={isOpen}
            contentLabel="Example Modal"
            onRequestClose={() => setIsOpen(false)}
          >
            <ActionPopup close={onRequestClose} />
          </ReactModal>
          <DataTable
            columns={selectedHeaders}
            data={results}
            customStyles={customStyles}
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onRowClicked={onRowClicked}
            pagination
          />
        </>
      )}
    </div>
  );
};

export default TargetCallExpertTable;
