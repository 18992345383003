import React from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,  Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Dashboard',
      },
     
    },
    scales: {
      x: { grid: { display: false }, },
      y: { 
        grid: { display: false },
          
         
      
  },
    },
   
  };


  const Chart=({data})=> {
 
    return (
        <div style={{width:"600px",height:"400px"}}>
        <Bar options={options} data={data}   />
  </div>
    )
   
}

export default Chart