import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BreadCrumbs from '../../../../components/BreadCrumbs'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import './campaignreview.css'
import { BaseUrl, dsUrl } from "../../../../pages/Api/api";
import BackArrow from "../../../../images/BackArrow.svg";
import { useNavigate } from "react-router-dom";
import { clearCampaign } from "../../../../Store/Reducer/CampaignReducer";
const HtmlToReactParser = require("html-to-react").Parser;
const parse = require("html-react-parser");

const CampaignReview = () => {
  const { campaign, campaignFile } = useSelector((state) => state?.campaign);
  console.log(campaignFile, "file from use selector in campaign review");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickRecipients = () => {
    navigate("/campaign-recipients");
  };

  const createCampaign = async () => {
    const userToken = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const formdata = new FormData();
    console.log("Campaign Data: ", campaign);

    let campaign_category = [];
    let total_count = 0;
    let campaign_recipients = [];

    const data = { ...campaign };
    data.recipients?.forEach((reciept, index) => {
      formdata.append[`recipients[${index}]`] = data.recipients[index];
      let campaignSplit = reciept.split("- ");
      campaign_category.push(campaignSplit[0]);
      campaign_recipients.push(reciept);
      total_count += Number(campaignSplit[1]);
    });
    console.log(campaign_category, campaign_recipients, total_count);

    formdata.append("campaign_recipients", campaign_recipients);
    formdata.append("total_count", total_count);
    formdata.append("campaign_category", campaign_category);
    formdata.append("campaign_type", data.campaign_type);
    formdata.append("campaign_name", data.campaign_name);
    formdata.append("campaign_description", data.campaign_description);
    formdata.append("campaign_content", data.campaign_content);
    formdata.append("signature_name", data.signature_name);
    formdata.append("signature_email", data.signature_email);
    formdata.append("signature_contact_no", data.signature_contact_no);
    formdata.append("signature_position", data.signature_position);
    console.log(formdata, "form data of campaign");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formdata,
      // body: JSON.stringify(data)
    };

    return fetch(`${BaseUrl}AdminCampaign/Add-Campaign`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("Result: ", result);
        dispatch(clearCampaign());
        navigate("/campaign-main-page");
        return result.data;
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container">
        <div className="container py-2 mt-2">
          <BreadCrumbs />
        </div>
        <div className="container py-4 d-flex justify-content-between align-items-center">
          <h5 className="recipient-top-name">Review</h5>
          <img
            onClick={handleClickRecipients}
            src={BackArrow}
            alt=""
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="container">
          <div className="recipient-top-card px-3 py-4 my-4">
            <div
              className="p-3"
              style={{
                background: "White",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div className="py-4">
                {/* {JSON.stringify(campaign)} */}
                <div className="d-flex">
                  <div className="">
                    <p className="recipient-sub-header">Campaign Name </p>
                  </div>
                  <div className="px-4">-</div>
                  <div className="">
                    <p className="recipient-sub-header">
                      {campaign?.campaign_name}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h6 className="recipient-sub-header">Campaign Description</h6>
                <div className="recipient-content">
                  {campaign?.campaign_description}
                </div>
              </div>
              <div>
                <hr />
              </div>
              <div className="py-3">
                <h5 className="recipient-sub-header">Content</h5>
                <div className="recipient-content">
                  {parse(campaign?.campaign_content || "")}
                </div>
              </div>
              <div>
                <hr />
              </div>
              {/* <div>
                                <div className='recipient-samll-header'>{campaign?.signature_name}</div>
                                <div className='recipient-content'>{campaign?.signature_email}</div>
                                <div className='recipient-content'>{campaign?.signature_contact_no}</div>
                            </div> */}
              <div className="py-3">
                <div className="d-flex ">
                  <p className="recipient-unsubscriber">Unsubscriber</p>
                  <p className="recipient-update-performance">
                    Update your Performance
                  </p>
                </div>
              </div>
            </div>

            <div
              className="container my-4 p-2"
              style={{ background: "white", borderRadius: "5px" }}
            >
              <p className="recipient-samll-header">
                Recipient -{" "}
                <span className="p-2 m-1">
                  {campaign?.recipients?.map((e) => (
                    <button className="rec-btn mx-1">{e}</button>
                  ))}
                </span>
              </p>
            </div>
            <div className="done-btn-recipients pt-4">
              <button
                className="done-btn-recipient px-5 py-2"
                onClick={createCampaign}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignReview