import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getMainProductListTableData = createAsyncThunk("mainProductList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}product/Admin-Product-List`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
    
        return result.data
    })
    .catch((error) => console.log("error", error));
});



export const myReducer = createSlice({
  name: "mainProductList",
  initialState: {
    loading: false,
    selectedMainProductId: "",
    mainproductList: [],
    selectedSingleProductData:[],
  },
  reducers: {
    UpdateSelectedMainProductId: (state, action) => {
      state.selectedMainProductId = action.payload;
    },
    updateSelectedSingleProductData : (state, action) => {
       state.selectedSingleProductData = action.payload;
    },
    
  },
  extraReducers: {
    [getMainProductListTableData.pending]: (state) => {
      state.loading = true;
    },
    [getMainProductListTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mainproductList = payload;

      // console.log(payload,"payload from reducer") 
    },
   

  },


})

export const {
  UpdateSelectedMainProductId,
  updateSelectedSingleProductData,
} = myReducer.actions;

export default myReducer.reducer;
