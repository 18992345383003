import React, { useState, useEffect } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
// import "./popupDep.css";
import './editApproved.css'
import { useForm } from "react-hook-form";
// import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import FeaturedIcon from '../../../images/Featuredicon.svg';
import { BaseUrl } from '../../../pages/Api/api';

const EditApproved = ({ open, handleClose, handleApporveSuccess }) => {

        const dispatch = useDispatch();
        const navigate = useNavigate();

        const [close, setClose] = useState(handleClose);


        return (
                <>
                        <Modal open={open} handleClose={handleClose} size="xs" style={{ marginTop: "50px" }}>
                                <Modal.Body >
                                        <img src={FeaturedIcon} alt="" />
                                        <div className='d-flex flex-column justify-content-center'>
                                                <h4 className='app-top-con py-4'>Approve this product</h4>
                                                <p className='app-des'>Do you want to approve this product?</p>

                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-4 pt-4'>
                                                <button className='cencel-dep-btn' onClick={handleClose}>Cancel</button>
                                                <button className='app-con-btn' onClick={handleApporveSuccess}>Confirm</button>

                                        </div>
                                </Modal.Body>
                        </Modal>
                </>
        )
}

export default EditApproved