import React, { useEffect, useState } from 'react'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import './particularuserdetails.css'
import { useNavigate } from 'react-router-dom'
import ArrowDropDownIcon from '../../../../images/downArrow.svg'


const ParticularUseDetails = () => {
    const [user, setUser] = useState({})
    const [showEdit, setShowEdit] = useState(false)

    const toggleAction = () => {
        setShowEdit(!showEdit)
    }

    useEffect(() => {
        const localUser = localStorage.getItem('selectedUser')
        if (localUser) {
            setUser(JSON.parse(localUser))
        }
        console.log('user', user)
    }, [])

    const navigate = useNavigate()

    const handleClickEditPud = () => {
        navigate('/edit-particular-user-details')
    }
    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className='main-body-content'>
                <div className="container">
                    <div className='d-flex justify-content-between mt-3'>
                        <h2 className='pud-top-name'>Intern</h2>
                        <button className='pud-action-button px-3 mx-1 py-1' onClick={toggleAction}>Action <span><img src={ArrowDropDownIcon} style={{
                            width: "12px",
                            marginLeft: "5px"
                        }} alt="" /></span> </button>
                    </div>
                    {/* {JSON.stringify(user)} */}


                    {showEdit && (
                        <div>
                            <div className='pud-edit-button pt-3 mt-3'>
                                <button className='pud-edit-button-pro px-3 py-1' style={{ width: "80px" }} onClick={handleClickEditPud}>Edit</button>
                            </div>
                            <div className='pud-edit-button pt-3 mt-3'>
                                <button className='pud-edit-button-pro px-3 py-1' >Delete</button>
                            </div>
                        </div>

                    )}
                    <div>
                        <hr />
                    </div>

                    <div className=''>
                        {Object.keys(user).map(key => {
                            return (

                                <div className='pud-data'>
                                    <div className='pud-data-key py-2 px-3 mx-5'>
                                        {key}
                                    </div>
                                    <div>
                                        <p>-</p>
                                    </div>
                                    <div className='pud-daya-key-values px-3 py-2 mx-5'>
                                        {user[key]}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParticularUseDetails