import React, { useState, useEffect } from "react";
import FileUploaderComponent from "./FileUploaderComponent/FileUploaderComponent";
import "../AddProducts.css";
import MultipleFileUpload from "./FileUploaderComponent/MultipleFileUpload";
import { getBrandListData } from "../../../../../Store/Reducer/BrandList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";


const ProductSection = ({ formValues, setFormValues, handleChange, formErrors }) => {
  const [categoryType, setCategoryType] = useState("0");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brandList, loading, selectedBrandId, selectedSingleBrandData } =
    useSelector((state) => state.brandList);

  const userToken = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getBrandListData());
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  }, []);

  const brandHandleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      ["brand_logo_url"]: e.target.selectedOptions[0].dataset.logo_url,
    }));
  };
  console.log("productSection", formValues);
  return (
    <>
      <div className="addnew-product-section1">
        <div className="addnew-product-items">
          <label htmlFor="product_name" className="addnew-product-Input-label">
            Product Name{" "}
            <svg
              data-tooltip-id="productName-tooltip"
              data-tooltip-content="Product Name should be max 50 characters"
              id="tooltip"
              style={{ margin: "0 0 0.4rem 0.4rem" }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.0587 5.99967C6.21543 5.55412 6.5248 5.17841 6.932 4.9391C7.3392 4.69978 7.81796 4.6123 8.28348 4.69215C8.749 4.772 9.17124 5.01402 9.47542 5.37536C9.77959 5.7367 9.94607 6.19402 9.94536 6.66634C9.94536 7.99967 7.94536 8.66634 7.94536 8.66634M7.9987 11.333H8.00536M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z"
                stroke="#667085"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Tooltip
              id="productName-tooltip"
              place="right"
            />
          </label>
          <input
            id="product_name"
            className="addnew-product-Input"
            placeholder="Input text"
            name="product_name"
            onChange={handleChange}
            type="text"
            style={
              formValues.product_name.length > 50
                ? {
                    color: "red",
                    border: "1px solid red",
                    focus: {
                      border: "1px solid red",
                    },
                  }
                : {}
            }
          />
          <p
            style={formValues.product_name.length > 50 ? { color: "red" } : 
            (formErrors.product_name? {margin:"0"} : {})
          }
            className="input-dynamiclength-addproduct"
          >
            {formValues.product_name.length}/50{" "}
          </p>
          <p style={{ margin:"0 0 1rem 0"}} className="err-message">{formErrors.product_name}</p>
        </div>
       
        <div className="addnew-product-items">
          <label className="addnew-product-Input-label">Product Brand</label>
          <div className="addproduct-checkbox-div">
            <div className="add-product-checkbox1">
              <label
                style={{ marginRight: "1rem" }}
                className="add-product-radio-btn"
              >
                <input
                  className="input-btn-color-green"
                  type="radio"
                  name="product_brand"
                  value="1"
                  onClick={(e) => {
                    setCategoryType(e.target.value);
                    setFormValues((prevState) => ({
                      ...prevState,
                      ["brand_logo_url"]: "",
                    }));
                  }}
                />
                Add New Brand
              </label>
            </div>

            <div className="add-product-checkbox2">
              <label className="add-product-radio-btn">
                <input
                  className="input-btn-color-green"
                  type="radio"
                  name="product_brand"
                  value="2"
                  onClick={(e) => {
                    setCategoryType(e.target.value);
                  }}
                />
                Select existing
              </label>
            </div>
          </div>
          {categoryType === "1" ? (
            <input
              id="name"
              type="text"
              className="addnew-product-Input"
              placeholder="Input text"
              name="brand_name"
              style={
                formValues.brand_name.length > 50
                  ? {
                      color: "red",
                      border: "1px solid red",
                      focus: {
                        border: "1px solid red",
                      },
                    }
                  : {}
              }
              onChange={handleChange}
            />
          ) : categoryType === "2" ? (
            <select
              onChange={brandHandleChange}
              name="brand_name"
              className="addnew-product-Input"
            >
              <option hidden>Select</option>
              {brandList.map((item) => {
                return (
                  <option
                    value={item.brand_name}
                    data-logo_url={item.brand_logo_url}
                  >
                    {item.brand_name}
                  </option>
                );
              })}
            </select>
          ) : (
            <></>
          )}
          <p
            style={formValues.brand_name.length > 50 ? { color: "red" } : (formErrors.brand_name? {margin:"0"} : {})}
            className="input-dynamiclength-addproduct"
          >
            {formValues.brand_name.length}/50
          </p>
          <p style={{ margin:"0 0 1rem 0"}} className="err-message">{formErrors.brand_name}</p>
        </div>

        <div className="addnew-product-items">
          <label className="add-product-checkbox-btn">
            <input type="checkbox" id="AddNewProductCheckbox" name="verifiedByConqt" />
            <p className="addnewProduct-Input-label">Verified by ConQt</p>
          </label>
          <label htmlFor="" className="verified-caption-text">
            Check when product is verified by ConQt
          </label>
        </div>
        
        <div className="addnew-product-items">
          <label htmlFor="description" className="addnew-product-Input-label">
            Short Description{" "}
            <svg
              data-tooltip-id="shortDescription-tooltip"
              data-tooltip-content="Short description should be max 150 characters"
              style={{ margin: "0 0 0.4rem 0.4rem" }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.0587 5.99967C6.21543 5.55412 6.5248 5.17841 6.932 4.9391C7.3392 4.69978 7.81796 4.6123 8.28348 4.69215C8.749 4.772 9.17124 5.01402 9.47542 5.37536C9.77959 5.7367 9.94607 6.19402 9.94536 6.66634C9.94536 7.99967 7.94536 8.66634 7.94536 8.66634M7.9987 11.333H8.00536M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z"
                stroke="#667085"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Tooltip
              id="shortDescription-tooltip"
              place="right"
            />
          </label>
          <textarea
            id="product_description"
            type="text"
            className="AddNewProductInputs"
            placeholder="Input text"
            name="product_description"
            style={
              formValues.product_description.length > 150
                ? {
                    color: "red",
                    border: "1px solid red",
                    focus: {
                      border: "1px solid red",
                    },
                  }
                : {}
            }
            onChange={handleChange}
          />
          <p
            style={
              formValues.product_description.length > 150
                ? { color: "red" }
                : (formErrors.product_description? {margin:"0"} : {})
            }
            className="input-dynamiclength-addproduct"
          >
            {formValues.product_description.length}/150
          </p>
          <p style={{ margin:"0 0 1rem 0"}} className="err-message">{formErrors.product_description}</p>
        </div>
      </div>
      <div className="addnew-product-section1">
        <div className="addnew-product-items1">
          <h1 className="addnew-product-Input-label">
            Brand Logo
            <svg
              data-tooltip-id="brandLogo-tooltip"
              data-tooltip-content="Your Brand Logo(Max - 1 Image)."
              style={{ margin: "0 0 0.4rem 0.4rem" }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.0587 5.99967C6.21543 5.55412 6.5248 5.17841 6.932 4.9391C7.3392 4.69978 7.81796 4.6123 8.28348 4.69215C8.749 4.772 9.17124 5.01402 9.47542 5.37536C9.77959 5.7367 9.94607 6.19402 9.94536 6.66634C9.94536 7.99967 7.94536 8.66634 7.94536 8.66634M7.9987 11.333H8.00536M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z"
                stroke="#667085"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Tooltip
              id="brandLogo-tooltip"
              place="right"
            />
          </h1>
          <div className="addnew-product-logo-section">
            <FileUploaderComponent
              formValues={formValues}
              setFormValues={setFormValues}
              handleChange={handleChange}
              formErrors = {formErrors}
            />
          </div>
        </div>
        <div className="addnew-product-items1">
          <div className="addnew-product-image-section">
            <MultipleFileUpload
              formValues={formValues}
              setFormValues={setFormValues}
              handleChange={handleChange}
              formErrors = {formErrors}
            />
          </div>
          <div className="addnew-product-selected-images"></div>
        </div>
      </div>
    </>
  );
};

export default ProductSection;
