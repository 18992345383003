import React from 'react'
import BreadCrumbsCC from '../../../components/BreadcrumbsCC'
import DashBoardheader from '../../../components/DashboardHeader'
import SideNavBar from '../../../components/Sidebar'
import './other.css'
import AvtarIcon from '../../../images/AvtarIcon.svg'

const OtherForCC = () => {
    return (
        <>
            <DashBoardheader />
            <SideNavBar />

            <div className="main-body-container">

                <div className="container mt-5 d-flex align-items-center">
                    <img src={AvtarIcon} alt="" /> <span className="create-contact-top-name px-2">Amitabh</span>
                </div>
                <div className=" container breadcrumbs-cc">
                    <BreadCrumbsCC />
                </div>
                <div className='container '>
                    <div className="cc-overview px-3 py-5">
                        <div className="d-flex col-md-12">
                            <div className="col-md-2">
                                <p className="input-names-cc-overview">
                                    Date Created
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p className="label-input-cc-overview">
                                    11/11/2022
                                </p>
                            </div>
                            <div className="col-md-2 px-3">
                                <p className="input-names-cc-overview">
                                    Date Modify
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p className="label-input-cc-overview">
                                    08/12/2022
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default OtherForCC