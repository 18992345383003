import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getTableData = createAsyncThunk("vendorList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}admin/Get-Admin-Vendor-List`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result.data, "store api data");
      return result.data;
    })
    .catch((error) => console.log("error", error));
});
export const getSingleVendorDetails = createAsyncThunk(
  "vendorDetails",
  (id) => {
    const userToken = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    // console.log(id, "vendor id  in reducer");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `${BaseUrl}admin/Vendor-Details-By-Id?vendor_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //  console.log(result.data, "store single vendor api  data");
        return result.data;
      })
      .catch((error) => console.log("error", error));
  }
);

export const myReducer = createSlice({
  name: "conqtAdmin",
  initialState: {
    loading: false,
    selectedVendorId: "",
    vendorList: [],
    vendorDetails: {},
    selectedVendorName: "",
    selectedVendorTableCheckbox: [
      "Vendor",
      "Activation Status",
      "Person Name",
      "Phone No",
      "Email",
      "Add Contact"
    ],
  },
  reducers: {
    updateSelectVendorId: (state, action) => {
      state.selectedVendorId = action.payload;
    },
    updateSelectVendorName: (state, action) => {
      state.selectedVendorName = action.payload;
    },
    updateSelectVendorTableCheckbox: (state, action) => {
      state.selectedVendorTableCheckbox = action.payload;
    },
  },
  extraReducers: {
    [getTableData.pending]: (state) => {
      state.loading = true;
    },
    [getTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vendorList = payload;

      // console.log(payload,"payload from reducer")
    },
    [getSingleVendorDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSingleVendorDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vendorDetails = payload;
      // console.log(payload,"payload from vendor details reducer")
    },
  },
});

export const {
  updateSelectVendorId,
  updateSelectVendorName,
  updateSelectVendorTableCheckbox,
} = myReducer.actions;

export default myReducer.reducer;
