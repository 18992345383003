import React, { useState, useEffect } from 'react'
import './targetcustomertable.css'
import DataTable from 'react-data-table-component'
import EditTableIcon from '../../images/TargetCustomeTableIcon.svg';
import { useNavigate } from 'react-router-dom';
// import FilterComponent from './AddsaingleCustomerFilter/FilterComponent';
import { setCurrentSingleCustomer, updateSingleCustomerID, updateAddSingleCustomer } from '../../Store/Reducer/AddSingleCustomerReducer';
import { useDispatch } from 'react-redux';


const customStyles = {
    headCells: {
        style: {
            background: ' #0900BC',
            color: 'white',
            fontSize: '16px',
            fontWeight: 400
        },
    },
};

const TargetCustomerTable = ({ customerList }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleClicAddCustomer = () => {
        navigate('/target-add-single-customer')
    }

    // const [filterText, setFilterText] = useState('');
    // const [resetPaginationToggle, setResetPaginationToggle] = useState(false)


    console.log("========>", customerList)
    const [columns, setColumns] = useState([
        {
            name: "Customer",
            selector: row => row.customer_name,
            sortable: true,
        },
        {
            name: "Organization",
            selector: row => row.company_name,
            sortable: true,
        },
        {
            name: "Email ID",
            selector: row => row.customer_email,
            sortable: true,
        },
        {
            name: "Phone Number",
            selector: row => row.customer_phone_no,
            sortable: true,
        },
        {
            cell: (row) => (
                <div className="add-contact-icon-table">
                    <img src={EditTableIcon} onClick={handleClicAddCustomer} style={{ width: "25px" }} alt=""></img>
                </div>
            ),
        },
    ])
    useEffect(() => {

    }, [customerList])



    const onRowClicked = (row) => {
        console.log("rowcustomerdata", row)
        dispatch(updateSingleCustomerID(row.id))
        dispatch(updateAddSingleCustomer(row))
        navigate('/target-customer-over-view')
    }


    return (
        <>
            {/* {JSON.stringify(customerList)} */}
            <DataTable

                columns={columns}
                data={customerList}
                customStyles={customStyles}
                onRowClicked={onRowClicked}
                // paginationResetDefaultPage={resetPaginationToggle}
                // subHeaderComponent={subHeaderComponentMemo}
                pagination
            />
        </>
    )
}

export default TargetCustomerTable