import React, { useState, useEffect } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import StatCard from "../../components/Dashboard/StatCard";
import ChairIcon from "@mui/icons-material/Chair";
import BarChartIcon from "@mui/icons-material/BarChart";
import StoreIcon from "@mui/icons-material/Store";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../Api/api";
import Chart from "../../components/DashboardChart";

const MainDashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onClickVendor = () => {
    navigate("/vendor-dashboard");
  };
  const onClickCustomer = () => {
    navigate("/customer-dashboard");
  };
  
  const  onClickProposals= () => {
    navigate("/proposals");
  };
  const  onClickProjects= () => {
    navigate("/projects");
  };
  useEffect(() => {
    const userToken = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",
    };
    setLoading(true);
    fetch(`${BaseUrl}admin/Get-Main-Dashboard-Data`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result.data[0], "main dashboard api response");
        setLoading(false);
        setData(result.data[0]);
      })
      .catch((error) => console.log("error", error));
  }, []);


  const labels = ['Registered Vendors Today', 'Vendors', 'Customers', 'Proposals', 'Live Projects'];

   const dataa = {
    labels,
    datasets: [
      {
         label: 'Dashboard',
        data: [data.todays_total_vendor,data.total_vendors,data.total_customers,data.total_proposals,data.total_projects],
         backgroundColor: ["#FFC0C7","#D9EEE1", "#E7E9EB", "#96D4D4", "#96D4D4",],
        barPercentage: 0.5,
        borderColor:"black",
        borderRadius:"10",
      
      },
    
    ],
  };




  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-dashboard">
        <div className="vendor-follow">
          {/* <div className="vendor-follow-main-div"> */}
            
           
          <div className="main-body-container">

              <div className="container">
            <h1 className="main-dashboard-header">Dashboard</h1>
            {loading && (
              <center>
                <div
                  className="spinner-border text-primary loader"
                  role="status"
                ></div>
              </center>
            )}
            <div className="container ">
              <div className="main-dashboard-cards-main-div">
                <div className="">
                  <StatCard
                    title={"Registered Vendors Today"}
                    count={data.todays_total_vendor}
                    description={
                      <div className="registered-vendors-view-more" onClick={onClickVendor}>
                        View more
                      </div>
                    }
                    bgColor={"#FFC0C7"}
                    // image={<ChairIcon />}
                  />
                </div>
                <div className="">
                  <StatCard
                    title={"Vendors"}
                    count={data.total_vendors}
                    description={<div onClick={onClickVendor}>View more</div>}
                    bgColor={"#D9EEE1"}
                    // image={<ChairIcon />}
                  />
                </div>
                <div className="">
                  <StatCard
                    title={`Customers`}
                    count={data.total_customers}
                    description={
                      <div
                        className="customer-view-more"
                        onClick={onClickCustomer}
                      >
                        View more
                      </div>
                    }
                    bgColor={"#E7E9EB"}

                    // image={<BarChartIcon />}
                  />
                </div>
                <div className="">
                  <StatCard
                    title={`Proposals`}
                    count={data.total_proposals}
                    description={
                      <div className="proposals-view-more"    onClick={onClickProposals}>View more</div>
                    }
                    bgColor={"#F3ECEA"}
                    // image={<BarChartIcon />}
                  />
                </div>
                <div className="">
                  <StatCard
                    title={`Live Projects`}
                    count={data.total_projects}
                    description={
                      <div className="live-projects-view-more" onClick={onClickProjects}>View more</div>
                    }
                    bgColor={"#96D4D4"}
                    // image={<BarChartIcon />}
                  />
                </div>
                {/* <div className="">
                  <StatCard
                    title={`Subscriptions`}
                    count={data.total_subscriptions}
                    // description={
                    //   <div className="customer-view-more">View more</div>
                    // }
                    bgColor={"#D9EEE1"}
                    // image={<BarChartIcon />}
                  />
                </div> */}
                <div className="col-lg-3 col-md-6 py-3">
                  {/* <StatCard
                    title={`Revenue`}
                    count={data.total_revenue}
                  
                  /> */}
                </div>
                {/* <div className="col-lg-3 col-md-6 py-3">
                        <StatCard
                            title={'Revenue'}
                            count={'34k'}
                            image={<StoreIcon />}
                            percent={1}
                            description={' than yestrday'} />
                    </div>
                    <div className="col-lg-3 col-md-6 py-3">
                        <StatCard
                            title={'Followers'}
                            count={'+91'}
                            image={<PersonAddIcon />}

                            description={' Just Updated'} />
                    </div> */}
              </div>
            </div>
          </div>
          <Chart data={dataa}/>
          </div>
        </div>
       

      </div>\
    </>
  );
};

export default MainDashboard;
