import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../Api/api";
import FilterComponent from "../VendorFilter";
import Expander from "../../../../components/Expander";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import ColumnsIcon from "../../../../images/columns.png";
import Arrow from "../../../../images/arrow-left-circle.svg";
import ContactArrow from "../../../../images/contact-drop-down.png";
import { useNavigate } from "react-router-dom";
import Proposals from "../../../Proposals";
import { useDispatch, useSelector } from "react-redux";
import { updatedData } from "../../../../Store/Reducer/Reducer";
import {
  getTableData,
  updateSelectVendorId,
  updateSelectVendorName,
  updateSelectVendorTableCheckbox,
} from "../../../../Store/Reducer/Reducer";
import { transformTableDataResponse } from "../../../../utils/util";
import AddContact from "../../../../images/add-contact.png";
import moment from "moment";

const Table = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [apiData, setData] = useState([]);
  const {
    vendorList,
    loading,
    selectedVendorName,
    selectedVendorTableCheckbox,
  } = useSelector((state) => state.conqtAdmin);
  // console.log(selectedVendorTableCheckbox, "selectedVendorTableCheckbox");
  console.log(vendorList, "data from use Selector");
  // console.log(selectedVendorName,"selectedVendorName")
  //const [loading, setLoading] = useState(false);
  const [isShowTable, showTableUpdate] = useState(true);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});
  const [isColumns, isSetColumns] = useState({});
  const [checkBox, showCheckBox] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [contactView, setContactView] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState([
     {
      id: "created_at",
      name: "Joining Date",
      // selector: (result) => moment(result.created_at).format("DD-MM-YYYY"),
      selector:(result) =>result.created_at.split(" ")[0]
    },
    {
      id: "company_name",
      name: "Vendor",
      selector: (result) => result.company_name,
      sortable: true,
    },
    {
      id: "Person Name",
      name: "Person Name",
      selector: (result) => result.name,
      sortable: true,
    },
    {
      id: "Email",
      name: "Email id",
      selector: (result) => result.user_name,
      sortable: true,
    },
    {
      id: "Phone No",
      name: <div>Phone Number</div>,
      selector: (result) => result.phone_no,
      sortable: true,
    },

    {
      id: "Activation Status",
      name: "Status",
      selector: (result) => result.activation_status,
      sortable: true,
      cell: (result) => (
        <div
          className={
            result.activation_status === "Subscribe"
              ? "table-status-Delivered"
              : "table-status-Delayed"
          }
        >
          {result.activation_status}
        </div>
      ),
    },
    {
      id: "Add Contact",
      name: "Add Contact",
      cell: (result) => (
        <div className="add-contact-icon-table">
          <img
            src={AddContact}
            onClick={() => {
              onClickAddIcon(result.company_name);
            }}
            alt=""
          ></img>
        </div>
      ),
    },
  ]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const userToken = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getTableData());
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  }, []);

  const tableData = vendorList;
  const result = transformTableDataResponse(tableData);
  console.log("Result", result)


  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#0900bc",
        color: "white",
        fontSize: "14px",
        width: "100%",
        // gap:"50px"
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "13.5px",
        color: "black",
        fontWeight: "500",
        letterSpacing: "0.5px",
        whiteSpace: "nowrap",

        // background :  index % 2? { background : "#fdffe0" }:{ background : "white" }
        // backgroundColor: result.index %2 ? 'pink' : 'green'
      },
    },
    cells: {
      style: {
        fontSize: "13.5px",
      },
    },
  };
  // const conditionalRowStyles = [

  //   {
  //      when: row => row.index%2==0,
  //     style: row => ({ backgroundColor: row.index % 2 ==0? '#ebebeb' : 'inerit' }),
  //   },
  // ]
  const onClickContact = () => {
    setContactView(!contactView);
  };
  const onClickAddIcon = (row) => {
    navigate("/add-new-contacts");
    showTableUpdate(false);
    dispatch(updateSelectVendorName(row));
    // console.log(row,"row list")
  };

  const onClickView = () => {
    navigate("/vendor-view-contacts");
  };
  const onClickCreate = () => {
    navigate("/create-contacts");
  };
  const onClickApply = () => {
    let select = allColumns.filter((value) => {
      return isSelected.includes(value.id);
    });
    setSelectedHeaders(select);
    dispatch(updateSelectVendorTableCheckbox(isSelected));
    // console.log(select, "updateSelectVendorTableCheckbox");
  };

  const onRowClicked = (row) => {
    navigate("/vendor-details");
    showTableUpdate(false);
    dispatch(updateSelectVendorId(row.id));
  };

  const onClickCol = () => {
    showCheckBox(!checkBox);
  };
  useEffect(() => {
    let select = allColumns.filter((value) => {
      return (
        selectedVendorTableCheckbox &&
        selectedVendorTableCheckbox.includes(value.id)
      );
    });
    setSelectedHeaders(select);
  }, []);

  const allColumns = [
    {
      id: "Joining Date",
      name: "Created Date",
      selector:(result) =>result.created_at.split(" ")[0],
    },
    {
      id: "Updated At",
      name: "Updated Date",
      selector: (result) => result.updated_at,
    },
    {
      id: "Vendor",
      name: "Vendor",
      selector: (result) => result.company_name,
      sortable: true,
    },
    {
      id: "Person Name",
      name: "Person Name",
      selector: (result) => result.name,
      sortable: true,
    },
    {
      id: "Email",
      name: "Email id",
      selector: (result) => result.user_name,
      sortable: true,
    },
    {
      id: "Phone No",
      name: <div>Phone Number</div>,
      selector: (result) => result.phone_no,
      sortable: true,
    },
    {
      id: "Subscription",
      name: <div>Subscription</div>,
      selector: (result) => result.subcription,
      sortable: true,
    },
    {
      id: "Category",
      name: <div>Category</div>,
      selector: (result) => result.solutions,
      sortable: true,
    },
    {
      id: "Country",
      name: "Country",
      selector: (result) => result.country,
      sortable: true,
    },
    {
      id: "City",
      name: "City",
      selector: (result) => result.city,
      sortable: true,
    },
    {
      id: "Zipcode",
      name: "Zipcode",
      selector: (result) => result.zipcode,
      sortable: true,
    },
    {
      id: "Address",
      name: "Address",
      selector: (result) => result.address,
      sortable: true,
    },
    {
      id: "Available Points",
      name: "Available Point",
      selector: (result) => result.available_point,
      sortable: true,
    },
    {
      id: "Currency Code",
      name: "Currency Code",
      selector: (result) => result.currency_code,
      sortable: true,
    },
    {
      id: "Currency",
      name: "Currency",
      selector: (result) => result.currency,
      sortable: true,
    },
    {
      id: "Activation Status",
      name: "Status",
      selector: (result) => result.activation_status,
      sortable: true,
      cell: (result) => (
        <div
          className={
            result.activation_status === "Subscribe"
              ? "table-status-Delivered"
              : "table-status-Delayed"
          }
        >
          {result.activation_status}
        </div>
      ),
    },
    {
      id: "Add Contact",
      name: "Add Contact",
      cell: (result) => (
        <div className="add-contact-icon-table">
          <img
            src={AddContact}
            onClick={() => {
              onClickAddIcon(result.company_name);
            }}
            alt=""
          ></img>
        </div>
      ),
    },
  ];


  const results =
    result &&
    result.filter(
      (item) =>
        (item.company_name &&
          item.company_name.toLowerCase().includes(filterText.toLowerCase())) ||
          item.country && item.country.toLowerCase().includes(filterText.toLowerCase()) ||
          item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.user_name && item.user_name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.phone_no &&  item.phone_no.toLowerCase().includes(filterText.toLowerCase()) ||
          item.city &&   item.city.toLowerCase().includes(filterText.toLowerCase()) ||
          item.address && item.address.toLowerCase().includes(filterText.toLowerCase()) ||
          item.solutions && item.solutions.toLowerCase().includes(filterText.toLowerCase()) ||
          item.zipcode && item.zipcode.toLowerCase().includes(filterText.toLowerCase()) ||
          item.created_at && item.created_at.toLowerCase().includes(filterText.toLowerCase()) ||
          item.activation_status && item.activation_status.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className="table-filter">
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            placeholder={"Search"}
          />
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="pt-3">
      {isShowTable && (
        <>
          <div
            className={checkBox ? "custom-columns" : "custom-columns-before"}
          >
            <img
              className="columns-icon"
              onClick={onClickCol}
              src={ColumnsIcon}
              alt=""
            />{" "}
            &nbsp;
            {checkBox && (
              <>
                <div className="dropdown-multi">
                  <DropdownMultiselect
                    handleOnChange={(value) => {
                      // console.log(value, "handleOnChange");
                      setIsSelected(value);
                    }}
                    options={[
                      "Activation Status",
                      "Add Contact",
                      "Address",
                      "Available Points",
                      "Category",
                      "City",
                      "Country",
                      "Currency",
                      "Currency Code",
                      "Email",
                      "Joining Date",
                      "Person Name",
                      "Phone No",
                      "Subscription",
                      // "Updated Date",
                      "Vendor",
                      "Zipcode",
                    
                    ]}
                    selected={selectedVendorTableCheckbox}
                    placeholder="select"
                  />
                </div>
                <button className="apply-button" onClick={onClickApply}>
                  Apply
                </button>
              </>
            )}
          </div>
        </>
      )}
      {loading && (
        <center>
          <div
            className="spinner-border text-primary loader"
            role="status"
          ></div>
        </center>
      )}
      {isShowTable && (
        <>
          <DataTable
            columns={selectedHeaders}
            data={results}
            customStyles={customStyles}
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onRowClicked={onRowClicked}
            pagination
          />
        </>
      )}
      {/* {!isShowTable && (
        <>
          <div
            className="vendor-arrow"
            onClick={() => {
              showTableUpdate(true);
              props.isShowHeader(true);
            }}
          >
            <img src={Arrow} alt="" />
          </div>
          <h1 className="vendor-single-page-title">
            {selectedVendorDetails.company_name}
          </h1>
          <div className="single-page-contact">Overview</div>

          <div className="overview-table">
            <div className="single-page-two-div">
              <div className="single-page-club-div-left">
                <div className="single-page-title"> Name </div>
                <div className="single-page-value">
                  {selectedVendorDetails.company_name}
                </div>
              </div>

              <div className="single-page-club-div">
                <div className="single-page-title">Email </div>
                <div className="single-page-value">
                  {selectedVendorDetails.user_name}
                </div>
              </div>
            </div>

            <hr className="line"></hr>
            <div className="single-page-two-div">
              <div className="single-page-club-div-left">
                <div className="single-page-title"> Phone No. </div>
                <div className="single-page-value">
                  {selectedVendorDetails.phone_no}
                </div>
              </div>

              <div className="single-page-club-div">
                <div className="single-page-title">Category</div>
                <div className="single-page-value">
                  {selectedVendorDetails.solutions}
                </div>
              </div>
            </div>

            <hr className="line"></hr>
            <div className="single-page-two-div">
              <div className="single-page-club-div-left">
                <div className="single-page-title"> Status </div>
                <div className="single-page-value">
                  {selectedVendorDetails.status}
                </div>
              </div>

              <div className="single-page-club-div">
                <div className="single-page-title">Company Address</div>
                <div className="single-page-value">
                  {selectedVendorDetails.address}
                </div>
              </div>
            </div>

            <hr className="line"></hr>
            <div className="single-page-two-div">
              <div className="single-page-club-div-left">
                <div className="single-page-title"> Country </div>
                <div className="single-page-value">
                  {selectedVendorDetails.country}
                </div>
              </div>

              <div className="single-page-club-div">
                <div className="single-page-title">City </div>
                <div className="single-page-value">
                  {selectedVendorDetails.city}
                </div>
              </div>
            </div>

            <hr className="line"></hr>
            <div className="single-page-two-div">
              <div className="single-page-club-div-left">
                <div className="single-page-title">State </div>
                <div className="single-page-value">
                  {selectedVendorDetails.state}
                </div>
              </div>

              <div className="single-page-club-div">
                <div className="single-page-title">Zip </div>
                <div className="single-page-value">
                  {selectedVendorDetails.zipcode}
                </div>
              </div>
            </div>
          </div>

          <Expander
            header={"Other Information"}
            title1={"No. of Employees"}
            value1={selectedVendorDetails.nam}
            title2={"Website"}
            value2={selectedVendorDetails.FacebookPage}
            title3={"Facebook Page"}
            value3={selectedVendorDetails.FacebookPage}
            title4={"Twitter Page"}
            value4={selectedVendorDetails.TwitterPage}
            title5={"Linkedin ID"}
            value5={selectedVendorDetails.departmt}
            title6={"Instagram"}
            value6={selectedVendorDetails.pos}
          />

          <Expander
            header={
              <div>
                <h5>
                  Contact{" "}
                  <img onClick={onClickContact} src={ContactArrow} alt="" />{" "}
                </h5>
                {contactView && (
                  <div className="create-view">
                    <h5 onClick={onClickCreate}>Create</h5>
                    <hr className="line"></hr>
                    <h5 onClick={onClickView}>View</h5>
                  </div>
                )}
              </div>
            }
            title1={"Name"}
            value1={selectedVendorDetails.name}
            title2={"Email"}
            value2={selectedVendorDetails.user_name}
            title3={"Phone No."}
            value3={selectedVendorDetails.phone_no}
            title4={"Department"}
            value4={selectedVendorDetails.department}
            title5={"Position"}
            value5={selectedVendorDetails.positon}
          />

          <Expander
            header={"Bank Account Details"}
            title1={"Bank Name"}
            value1={selectedVendorDetails.BankName}
            title2={"Contact Person"}
            value2={selectedVendorDetails.available_pont}
            title3={"Account Number"}
            value3={selectedVendorDetails.last}
            title4={"Account Type"}
            value4={selectedVendorDetails.Bank}
            title5={"IFSC code"}
            value5={selectedVendorDetails.Bankbranch}
            title6={"Bank Address"}
            value6={selectedVendorDetails.acc}
            title7={"Currency Code"}
            value7={selectedVendorDetails.currency_code}
            title8={"Currency"}
            value8={selectedVendorDetails.currency}
            title9={"Bank Branch"}
            value9={selectedVendorDetails.ifsc}
          />
          <Expander
            header={"Proposal"}
            title1={"Project Name"}
            value1={selectedVendorDetails.project_name}
            title2={"Customer"}
            value2={selectedVendorDetails.customer}
            title3={"Category"}
            value3={selectedVendorDetails.solutions}
            title4={"Price"}
            value4={selectedVendorDetails.price}
            title5={"Attachment"}
            value5={selectedVendorDetails.attachment}
          />
          <Expander
            header={"Project"}
            title1={"Project"}
            value1={selectedVendorDetails.project}
            title2={"Category"}
            value2={selectedVendorDetails.solutions}
            title3={"Milestones"}
            value3={selectedVendorDetails.mile}
            title4={"Attachment"}
            value4={selectedVendorDetails.attachment}
          />
          <Expander
            header={"Subscription"}
            title1={"Date"}
            value1={selectedVendorDetails.created_at}
            title2={"Package"}
            value2={selectedVendorDetails.package}
            title3={"Amount"}
            value3={selectedVendorDetails.amount}
            title4={"Status"}
            value4={selectedVendorDetails.status}
            title5={"Current Balance"}
            value5={selectedVendorDetails.current}
          />
          <Expander
            header={"Invoice"}
            title1={"Invoice Number"}
            value1={selectedVendorDetails.invoice}
            title2={"Project"}
            value2={selectedVendorDetails.description}
            title3={"Description"}
            value3={selectedVendorDetails.last}
            title4={"Amount"}
            value4={selectedVendorDetails.Bank}
            title5={"Invoice Date"}
            value5={selectedVendorDetails.Bankbranch}
            title6={"Attachment"}
            value6={selectedVendorDetails.acc}
            title7={"Due Date"}
            value7={selectedVendorDetails.due}
          />
          <Expander
            header={"Receipt"}
            title1={"Receipt Number"}
            value1={selectedVendorDetails.invoice}
            title2={"Project"}
            value2={selectedVendorDetails.description}
            title3={"Description"}
            value3={selectedVendorDetails.last}
            title4={"Amount"}
            value4={selectedVendorDetails.Bank}
            title5={"Receipt Date"}
            value5={selectedVendorDetails.Bankbranch}
            title6={"Attachment"}
            value6={selectedVendorDetails.acc}
          />
        </>
      )} */}
    </div>
  );
};

export default Table;
