import React, { useEffect, useState } from "react";
import SideNavBar from "../../../../components/Sidebar";
import DashBoardheader from "../../../../components/DashboardHeader";
import { Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import TargetVendorBreadCrumbs from "../TargetVendorBreadCrumbs";
import {
  updateAddSingleTargetVendor
} from "../../../../Store/Reducer/AddSingleTargetVendor";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountryList } from "../../../../Store/Reducer/CountryListReducer";
import { BaseUrl } from "../../../Api/api";
import { toast } from "react-toastify";

const AddNewVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addSingleVendor } =
    useSelector((state) => state.addTargetVendor);

  const {
    countryList, selectedCountryId } = useSelector(
      (state) => state.allCountryList
    );
  console.log("countryList", countryList);
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});
  const [solutions, setSolutions] = useState([]);
  useEffect(() => {

    dispatch(getAllCountryList(selectedCountryId));

  }, [selectedCountryId]);
  useEffect(() => {
    for (const key in addSingleVendor) {
      if (Object.hasOwnProperty.call(addSingleVendor, key)) {
        const element = addSingleVendor[key];
        setValue(key, element);
      }
    }
  }, [addSingleVendor]);

  const onSubmit = (data) => {
    console.log('onSubmit', data);
    dispatch(updateAddSingleTargetVendor(data));
    console.log('dispact data', dispatch(updateAddSingleTargetVendor(data)));
    navigate("/target-overview-vendor")
  };


  // mobile validation regex

  const validateMobileNumber = (value) => {
    const mobileNumberRegex = /^[0-9]{10}$/;
    const maxLength = 10;
    if (!value) {
      return 'Mobile number is required';
    }
    if (!mobileNumberRegex.test(value)) {
      return 'Invalid mobile number';
    }
    if (value.length > maxLength) {
      return `Mobile number must be ${maxLength} digits or less`;
    }
    return true;
  };


  console.log("Addsinglecustomer", addSingleVendor);
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}vendor/Get-Solutions`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("Get Solutions", JSON.parse(result).data);
        setSolutions(JSON.parse(result).data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const handleChange = (e) => {
    if (e.target.value == "Single") {
      navigate("/target-add-single-vendor");
    } else if (e.target.value == "Multiple") {
      navigate("/target-add-multiple-vendor");
    } else {
      return 0;
    }
  };
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container mb-5">
        <div className="container py-2 mt-2">
          <TargetVendorBreadCrumbs />
        </div>
        <div className="container py-4 d-flex justify-content-between align-items-center">
          <h2
            className="vendor-campaign-details-top-name"
            style={{ width: "100%" }}
          >
            Add Single Vendor
          </h2>
          {/* <div className="container py-4 d-flex  align-items-center">
            <select className="vendor-list-dropdown" onChange={handleChange}>
              <option value="select">Select</option>
              <option value="Single">Add Single Vendor</option>
              <option value="Multiple">Add Multiple Vendor</option>
            </select>
          </div> */}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container ">
            <div className="cc-overview-vendor-feature px-4 py-5">
              <div className="d-flex col-md-12 pb-3">
                <div className="col-md-2">
                  <h1 className="vendor-campaign-details-labels-name ">
                    Overview
                  </h1>
                </div>
              </div>
              <div className="d-flex col-md-12 pb-3">
                <div className="col-2">
                  <p className="label-add-vendor">Name </p>
                </div>
                <div className="col-4">
                  <input
                    className="label-input-add-new"
                    type="text"
                    placeholder="Enter Vendor Name"
                    {...register("person_name", {
                      required: "Required Field",
                    })}
                  ></input>
                  <p className="required-field">
                    {errors.person_name?.message}
                  </p>
                </div>

                <div className="col-2">
                  <div className="label-right-add-contact">Company Name</div>
                </div>
                <div className="col-4">
                  <input
                    className="label-input-add-new"
                    type="text"
                    placeholder="Enter Company Name"
                    {...register("company_name", {
                      required: "Required Field",
                    })}
                  ></input>
                  <p className="required-field">
                    {errors.company_name?.message}
                  </p>
                </div>
              </div>

              <div className="d-flex col-md-12">
                <div className="col-2">
                  <p className="label-add-vendor">Phone No. </p>
                </div>
                <div className="col-4">
                  <input
                    className="label-input-add-new"
                    type="number"
                    placeholder="Enter Vendor Phone Number"
                    // ref={register({ required: "Required Field", validate: validateMobileNumber }) }
                    {...register("company_phone_no", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      validate: validateMobileNumber,
                    })}
                  ></input>
                  {/* <p className="required-field"> */}
                  {errors.company_phone_no?.type === 'required' && <p className="required-field"> Required Field</p>}
                  {errors.company_phone_no?.type === 'minLength' && <p className="required-field">Mobile number must be at least 10 digits</p>}
                  {errors.company_phone_no?.type === 'maxLength' && <p className="required-field">Mobile number must be no more than 10 digits</p>}
                  {/* </p> */}
                </div>
                <div className="col-2 ">
                  <div className="label-right-add-contact">Email</div>
                </div>
                <div className="col-4">
                  <input
                    className="label-input-add-new"
                    type="email"
                    placeholder="Enter Vendor Email"
                    {...register("company_email",

                      { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })
                    }
                  ></input>
                  {errors.company_email?.type === 'required' && <p className="required-field">Required Field</p>}
                  {errors.company_email?.type === 'pattern' && <p className="required-field">Invalid email format (must be a valid Gmail address)</p>}
                </div>
              </div>

              <div className="d-flex col-md-12 py-3">
                <div className="col-2">
                  <p className="label-add-vendor">Company Address</p>
                </div>
                <div className="col-4">
                  <input
                    className="label-input-add-new"
                    type="text"
                    placeholder="Enter Company Address"
                    {...register("company_address", {
                      required: "Required Field",
                    })}
                  ></input>
                  <div>
                    <p className="required-field">
                      {errors.company_address?.message}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="label-right-add-contact ">Category</div>
                </div>
                <div className="col-4">
                  <select
                    className="label-input-add-new"
                    type="text"
                    placeholder="Select Category"
                    {...register("category", {
                      required: "Required Field",
                    })}
                  >
                    {solutions.map((solutions) => (
                      <option
                        key={solutions.id}
                        value={solutions.solutions_name}
                      >
                        {solutions.solutions_name}
                      </option>
                    ))}
                  </select>
                  <p className="required-field">{errors.category?.message}</p>
                </div>
              </div>

              <div className="d-flex col-md-12 pb-3">
                <div className="col-2">
                  <p className="label-add-vendor">City </p>
                </div>
                <div className="col-4">
                  <input
                    className="label-input-add-new"
                    type="link"
                    placeholder="Enter City"
                    {...register("company_city", {
                      required: "Required Field",
                    })}
                  ></input>
                  <div>
                    <p className="required-field">
                      {errors.company_city?.message}
                    </p>
                  </div>
                </div>

                <div className="col-2 ">
                  <div className="label-right-add-contact">Zip</div>
                </div>
                <div className="col-4">
                  <input
                    className="label-input-add-new"
                    type="number"
                    placeholder="Enter Zip Code"
                    {...register("company_pincode", {
                      required: "Required Field",
                    })}
                  ></input>
                  <div>
                    <p className="required-field">
                      {errors.company_pincode?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div className="d-flex col-md-12 pb-3">
                <div className="col-2">
                  <p className="label-add-vendor">State </p>
                </div>
                <div className="col-4">
                  <input
                    className="label-input-add-new"
                    type="link"
                    placeholder="Enter State"
                    {...register("company_state", {
                      required: "Required Field",
                    })}
                  ></input>
                  <div>
                    <p className="required-field">
                      {errors.company_state?.message}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <p className="label-right-add-contact ">Country</p>
                </div>
                <div className="col-4">
                  <select
                    className="label-input-add-new"
                    type="text"
                    placeholder="Select Country"
                    {...register("company_country", {
                      required: "Required Field",
                    })}
                  >
                    <option className="dropdown-country" value="">
                      {" "}
                      Select Country
                    </option>
                    {countryList &&
                      countryList.map((country, index) => {
                        return (
                          <option key={index} value={country.value}>
                            {country.country}
                          </option>
                        );
                      })}
                  </select>
                  <div>
                    <p className="required-field">
                      {errors.company_country?.message}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex col-md-12 pb-3">
                <div className="col-md-2">
                  <h1
                    className="vendor-campaign-details-labels-name "
                    style={{ marginTop: "2.5rem" }}
                  >
                    Other Information
                  </h1>
                </div>
              </div>
              <div className="d-flex col-md-12 pb-3">
                <div className="col-md-2">
                  <p className="label-add-vendor">
                    Employees{" "}
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1065_63493)">
                        <path
                          d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                          fill="#103A5D"
                        />
                        <path
                          d="M8.92995 6.588L6.63995 6.875L6.55795 7.255L7.00795 7.338C7.30195 7.408 7.35995 7.514 7.29595 7.807L6.55795 11.275C6.36395 12.172 6.66295 12.594 7.36595 12.594C7.91095 12.594 8.54395 12.342 8.83095 11.996L8.91895 11.58C8.71895 11.756 8.42695 11.826 8.23295 11.826C7.95795 11.826 7.85795 11.633 7.92895 11.293L8.92995 6.588ZM8.99995 4.5C8.99995 4.76522 8.8946 5.01957 8.70706 5.20711C8.51952 5.39464 8.26517 5.5 7.99995 5.5C7.73474 5.5 7.48038 5.39464 7.29285 5.20711C7.10531 5.01957 6.99995 4.76522 6.99995 4.5C6.99995 4.23478 7.10531 3.98043 7.29285 3.79289C7.48038 3.60536 7.73474 3.5 7.99995 3.5C8.26517 3.5 8.51952 3.60536 8.70706 3.79289C8.8946 3.98043 8.99995 4.23478 8.99995 4.5Z"
                          fill="#103A5D"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1065_63493">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </p>
                </div>
                <div className="col-md-3">
                  <input
                    className="label-input-add-new"
                    type="number"
                    placeholder="Enter Company Total Employees"
                    {...register("vendor_employees", {
                      maxLength: 30,
                    })}
                  ></input>
                  <div>
                    <p className="required-field">
                      {errors.vendor_employees?.message}
                    </p>
                  </div>
                </div>

                <div className="col-md-2 px-5">
                  <div className="label-right-add-contact">Website</div>
                </div>
                <div className="col-md-4">
                  <input
                    className="label-input-add-new"
                    type="link"
                    placeholder="Enter Company Website Link"
                    {...register("vendor_website", {
                      
                    })}
                  ></input>
                  <div>
                    <p className="required-field">
                      {errors.vendor_website?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex col-md-12 pb-3">
                <div className="col-md-2">
                  <p className="label-add-vendor">Facebook Page </p>
                </div>
                <div className="col-md-3">
                  <input
                    className="label-input-add-new"
                    type="link"
                    placeholder="Enter Facebook Link"
                    {...register("vendor_facebook", {
                     
                      maxLength: 30,
                    })}
                  ></input>
                  
                </div>

                <div className="col-md-2 px-5">
                  <div className="label-right-add-contact" style={{"width":"100%"}}>Twitter Page</div>
                </div>
                <div className="col-md-4">
                  <input
                    className="label-input-add-new"
                    type="link"
                    placeholder="Enter Twitter Link"
                    {...register("vendor_twitter", {
                      
                    })}
                  ></input>
                </div>
              </div>
              <div className="d-flex col-md-12 pb-3">
                <div className="col-md-2">
                  <p className="label-add-vendor">Linkedin ID </p>
                </div>
                <div className="col-md-3">
                  <input
                    className="label-input-add-new"
                    type="link"
                    placeholder="Enter LinkedIn Link"
                    {...register("vendor_linkedin", {
                     
                      
                    })}
                  ></input>
                  <div>
                    <p className="required-field">
                      {errors.vendor_linkedin?.message}
                    </p>
                  </div>
                </div>

                <div className="col-md-2 px-5">
                  <div className="label-right-add-contact">Instagram</div>
                </div>
                <div className="col-md-4">
                  <input
                    className="label-input-add-new"
                    type="link"
                    placeholder="Enter Instagram Link"
                    {...register("vendor_instagram", {
                      
                    })}
                  ></input>
                 
                </div>
              </div> */}

              <div className="d-flex justify-content-end mt-5">
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="add-btn-add-contact px-5 py-2"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewVendor;
