import React, { useEffect, useState } from 'react';
import './singleReview.css'
import DashBoardheader from '../../../components/DashboardHeader';
import SideNavBar from '../../../components/Sidebar';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BaseUrl } from '../../Api/api';
import StarRatings from 'react-star-ratings';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { calculatePercentage } from '../../../utils/util';
import moment from "moment";

const SingleReview = () => {

        const { singleReview } = useSelector(state => state.adminReview)

        const [review, setReview] = useState({})
        const id = singleReview?.id
        console.log("id", id)

        useEffect(() => {
                const token = localStorage.getItem("token")

                axios.get(`${BaseUrl}admin/Get-Product-Review-By-Id?review_id=${id}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                )
                        .then((response) => {
                                console.log("review response", response)
                                if (response.data.status === 200) {
                                        console.log("singleReview", response.data.data)
                                        setReview(response.data.data)
                                } else if (response.data.status === 401) {
                                        console.log("error")
                                }
                        }).catch((err) => {
                                console.log("error", err)
                        }
                        )
        }, [])

        const Feature_rate = calculatePercentage(review?.features_rate, 5)
        const money_rate = calculatePercentage(review?.money_rate, 5)
        const use_rate = calculatePercentage(review?.use_rate, 5)
        const support_rate = calculatePercentage(review?.support_rate, 5)

        const handleBack = () => {
                window.history.back()
        }
        return (
                <>
                        <DashBoardheader />
                        <SideNavBar />
                        <div className="main-body-container mb-5">
                                <div className="container">
                                        <div className='mt-3'>
                                                <div className="my-4 px-3">
                                                        <h3 className="back" onClick={handleBack} style={{ cursor: "pointer" }}>{"< back"}</h3>
                                                </div>

                                        </div>
                                        {/* {JSON.stringify(review)} */}
                                        <div className='' style={{ paddingLeft: "20px" }}>
                                                <div className='container mb-4'>
                                                        <h3 className='review-sub-header my-3'>Product Details</h3>
                                                        <div className="d-flex align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label'>Product Name</p>
                                                                </div>
                                                                <div className="col-8">
                                                                        <p className='review-label-ans'>{review?.product_name}</p>
                                                                </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center my-2">
                                                                <div className="col-4">
                                                                        <p className='review-label'>Brand</p>
                                                                </div>
                                                                <div className="col-8">
                                                                        <p className='review-label-ans'>{review?.brand_name}</p>
                                                                </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label'>Category</p>
                                                                </div>
                                                                <div className="col-8">
                                                                        <p className='review-label-ans'>{review?.department_name}</p>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className='container mb-4'>
                                                        <h3 className='review-sub-header my-3'>Customer Details</h3>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label'>Customer Name</p>
                                                                </div>
                                                                <div className="col-8">
                                                                        <p className='review-label-ans'>{review?.name}</p>
                                                                </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label my-2'>Company</p>
                                                                </div>
                                                                <div className="col-8">
                                                                        <p className='review-label-ans'>{review?.company_name}</p>
                                                                </div>
                                                        </div>
                                                </div>


                                                <div className='container mb-4'>
                                                        <h3 className='review-sub-header my-3'>Customer Review</h3>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label'>Date</p>
                                                                </div>
                                                                <div className="col-8">
                                                                        <p className='review-label-ans'>
                                                                                {moment(review?.created_at).format("DD-MM-YYYY")}
                                                                        </p>
                                                                </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label my-2'>Review</p>
                                                                </div>
                                                                <div className="col-8">
                                                                        <p className='review-label-ans'>{review?.feedback}</p>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className='container mb-4'>
                                                        <h3 className='review-sub-header my-3'>Rating</h3>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label'>Overall</p>
                                                                </div>
                                                                <div className="d-flex align-items-center col-8">
                                                                        <StarRatings
                                                                                rating={review?.overall_rating}
                                                                                starRatedColor="#EAC52D"
                                                                                numberOfStars={5}
                                                                                starDimension="16px"
                                                                                starSpacing="5px"
                                                                        />
                                                                        <div className='review-label-ans px-2 pt-2'>|<strong> {review?.overall_rating}/5</strong></div>
                                                                </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label my-2'>Features</p>
                                                                </div>
                                                                <div className="d-flex  align-items-center col-8">
                                                                        <ProgressBar variant="success" now={Feature_rate} style={{ width: "300px", height: "5px" }} />
                                                                        <span className='review-label-ans px-2'>|  {review?.features_rate}/5</span>
                                                                </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label'>Value of Money</p>
                                                                </div>
                                                                <div className="d-flex  align-items-center col-8">
                                                                        <ProgressBar variant="success" now={money_rate} style={{ width: "300px", height: "5px" }} />
                                                                        <span className='review-label-ans px-2'>|  {review?.money_rate}/5</span>
                                                                </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label my-2'>Ease of USe</p>
                                                                </div>
                                                                <div className="d-flex  align-items-center col-8">
                                                                        <ProgressBar variant="success" now={use_rate} style={{ width: "300px", height: "5px" }} />
                                                                        <span className='review-label-ans px-2'> |  {review?.use_rate}/5</span>
                                                                </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                                <div className="col-4">
                                                                        <p className='review-label'>Customer Support</p>
                                                                </div>
                                                                <div className="d-flex  align-items-center col-8">
                                                                        <ProgressBar variant="success" now={support_rate} style={{ width: "300px", height: "5px" }} />
                                                                        <span className='review-label-ans px-2'> |  {review?.support_rate}/5</span>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                </div>
                        </div>
                </>
        )
}

export default SingleReview