import React, { useState } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import cross from "../../../../images/cross.svg";
// import "./popupDep.css"
import { useForm } from "react-hook-form";
import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import { toast } from "react-toastify";
import AddIndustry from './AddInd';
// import DelDep from './DelDep';
// import AddDepartment from './AddDept';

const AddNewIndustry = ({ open, handleClose }) => {

        const navigate = useNavigate();

        const [close, setClose] = useState(handleClose);
        const [show, setShow] = useState(false);
        const [data, setData] = useState([]);

        const handleAddClose = () => {
                setShow(false)
        }
        const { register, handleSubmit, getValues, setValue, setError, formState: { errors } } = useForm();

        const onSubmit = (data) => {
                console.log("current dep data", data);
                setData(data)
                setShow(true)
        }

        return (
                <>
                        <AddIndustry open={show} handleAddClose={handleAddClose} name={"Industry"}
                                data={data} handleClose={handleClose}
                        />
                        <Modal open={open} handleClose={handleClose}>
                                <div className='d-flex align-items-center justify-content-end'>
                                        <img src={cross} alt="" onClick={handleClose} style={{ cursor: "pointer" }} />
                                </div>
                                <Modal.Body >
                                        <h3 className='top-header-dep'>New Industry</h3>

                                        <form onSubmit={handleSubmit(onSubmit)}>

                                                <div className='d-flex flex-column'>
                                                        <div className='d-flex flex-column py-3'>
                                                                <label htmlFor="" className='dep-label py-1'>Name</label>
                                                                <input type="text" className='dep-input'
                                                                        {...register("industry_name", { required: true, maxLength: 20 })}
                                                                />
                                                                {errors.industry_name && <p className='dep-error'>This field is required</p>}
                                                        </div>
                                                        <label htmlFor="" className='dep-label py-1'>Description</label>
                                                        <input type="text" className='dep-input'
                                                                {...register("description", { required: true, maxLength: 250 })}
                                                        />
                                                        {errors.description && <p className='dep-error'>This field is required</p>}
                                                </div>
                                                <button className='dep-add-btn mt-5'>
                                                        <p className='dep-add-btn-name'>Add</p>
                                                </button>

                                        </form>
                                </Modal.Body>
                        </Modal>
                </>
        )
}

export default AddNewIndustry