import React, { useState, useRef,useEffect } from "react";
import "./FileUploader.css";

const FileUploaderComponent = ({formValues,setFormValues,handleChange, formErrors}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (formValues.brand_logo_url.length !== 0) {
      setSelectedFile(null);
      setShowPreview(true);
      console.log("see")
    } else {
      setShowPreview(false);
      setFormValues((prevState) => ({
        ...prevState,
        ["brand_image"]:selectedFile
      }));
    }

  },[selectedFile]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedFile(event.dataTransfer.files[0]);
  };

  const handlePaste = (event) => {
    setSelectedFile(event.clipboardData.files[0]);
  };

  const handleBrowse = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  const handleDeleteURL = () => {
    setFormValues((prevState)=>({
      ...prevState,
      ["brand_logo_url"]:""

    }))
  } 
  return (
    <>
      <div className="FileUploader-Main-Container">
        {showPreview || formValues.brand_logo_url.length!==0 ? (
          <div className="fileupload-logo-preview">
            <img
              src={formValues.brand_logo_url.length!==0?formValues.brand_logo_url:selectedFile}
              alt="preview"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                marginLeft: "5%",
              }}
            />
            <div className="logo-preview-details">
              <div className="logo-preview-cancel-btn">
                <button
                  onClick={handleDeleteURL}
                  className="logo-close-preview"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0013 1.66699C14.6096 1.66699 18.3346 5.39199 18.3346 10.0003C18.3346 14.6087 14.6096 18.3337 10.0013 18.3337C5.39297 18.3337 1.66797 14.6087 1.66797 10.0003C1.66797 5.39199 5.39297 1.66699 10.0013 1.66699ZM12.993 5.83366L10.0013 8.82533L7.00963 5.83366L5.83464 7.00866L8.8263 10.0003L5.83464 12.992L7.00963 14.167L10.0013 11.1753L12.993 14.167L14.168 12.992L11.1763 10.0003L14.168 7.00866L12.993 5.83366Z"
                      fill="#FF0000"
                    />
                  </svg>
                </button>
              </div>
              <div className="logo-preview-name-div">
                {" "}
                <p className="logo-preview-name">
                  {formValues.brand_name.substring(0, 4)}...
                  {
                    formValues.brand_name[
                      formValues.brand_name.lastIndexOf(".") - 1
                    ]
                  }
                  .{formValues.brand_name.split(".").pop()}
                </p>
                <p className="logo-preview-size">
                  {(752483 / (1024 * 1024)).toFixed(2)} MB
                </p>
              </div>
            </div>
          </div>
        ) : (
          formValues.brand_logo_url.length===0?
          <>
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onPaste={handlePaste}
              onClick={handleBrowse}
              className="FilterUploader"
            >
              <div className="fileupload-icon-div">
                <svg
                  width="29"
                  height="31"
                  viewBox="0 0 29 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29 21.3535V3.35352C29 2.55787 28.6945 1.7948 28.1506 1.2322C27.6068 0.669586 26.8691 0.353516 26.1 0.353516H8.7C7.93087 0.353516 7.19325 0.669586 6.64939 1.2322C6.10554 1.7948 5.8 2.55787 5.8 3.35352V21.3535C5.8 22.1492 6.10554 22.9122 6.64939 23.4748C7.19325 24.0374 7.93087 24.3535 8.7 24.3535H26.1C26.8691 24.3535 27.6068 24.0374 28.1506 23.4748C28.6945 22.9122 29 22.1492 29 21.3535ZM13.05 15.3535L15.9935 19.4185L20.3 13.8535L26.1 21.3535H8.7M0 6.35352V27.3535C0 28.1492 0.305535 28.9122 0.84939 29.4748C1.39325 30.0374 2.13087 30.3535 2.9 30.3535H23.2V27.3535H2.9V6.35352"
                    fill="#7D7D7D"
                  />
                </svg>
              </div>
              {selectedFile ? (
                <button className="fileupload-add-logo-button">
                  {" "}
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33203 0.871419C8.33203 0.411182 7.95894 0.0380859 7.4987 0.0380859C7.03846 0.0380859 6.66536 0.411182 6.66536 0.871419V5.87142H1.66536C1.20513 5.87142 0.832031 6.24452 0.832031 6.70475C0.832031 7.16499 1.20513 7.53809 1.66536 7.53809H6.66536V12.5381C6.66536 12.9983 7.03846 13.3714 7.4987 13.3714C7.95893 13.3714 8.33203 12.9983 8.33203 12.5381V7.53809H13.332C13.7923 7.53809 14.1654 7.16499 14.1654 6.70475C14.1654 6.24452 13.7923 5.87142 13.332 5.87142H8.33203V0.871419Z"
                      fill="white"
                    />
                  </svg>
                </button>
              ) : (
                <button className="fileupload-add-logo-button">
                  {" "}
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33203 0.871419C8.33203 0.411182 7.95894 0.0380859 7.4987 0.0380859C7.03846 0.0380859 6.66536 0.411182 6.66536 0.871419V5.87142H1.66536C1.20513 5.87142 0.832031 6.24452 0.832031 6.70475C0.832031 7.16499 1.20513 7.53809 1.66536 7.53809H6.66536V12.5381C6.66536 12.9983 7.03846 13.3714 7.4987 13.3714C7.95893 13.3714 8.33203 12.9983 8.33203 12.5381V7.53809H13.332C13.7923 7.53809 14.1654 7.16499 14.1654 6.70475C14.1654 6.24452 13.7923 5.87142 13.332 5.87142H8.33203V0.871419Z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileSelect}
                style={{ display: "none" }}
              />
            </div>
            {selectedFile ? (
              <div className="fileupload-logo-preview">
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="preview"
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    marginLeft: "5%",
                  }}
                />
                <div className="logo-preview-details">
                  <div className="logo-preview-cancel-btn">
                    <button
                      onClick={handleDeleteFile}
                      className="logo-close-preview"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0013 1.66699C14.6096 1.66699 18.3346 5.39199 18.3346 10.0003C18.3346 14.6087 14.6096 18.3337 10.0013 18.3337C5.39297 18.3337 1.66797 14.6087 1.66797 10.0003C1.66797 5.39199 5.39297 1.66699 10.0013 1.66699ZM12.993 5.83366L10.0013 8.82533L7.00963 5.83366L5.83464 7.00866L8.8263 10.0003L5.83464 12.992L7.00963 14.167L10.0013 11.1753L12.993 14.167L14.168 12.992L11.1763 10.0003L14.168 7.00866L12.993 5.83366Z"
                          fill="#FF0000"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="logo-preview-name-div">
                    {" "}
                    <p className="logo-preview-name">
                      {selectedFile.name.substring(0, 4)}...
                      {
                        selectedFile.name[
                          selectedFile.name.lastIndexOf(".") - 1
                        ]
                      }
                      .{selectedFile.name.split(".").pop()}
                    </p>
                    <p className="logo-preview-size">
                      {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}{" "}
          </>:<></>
        )}
      </div>
      <p onClick={handleBrowse} className="verified-hint-text">
        drop file here, paste or{" "}
        <b style={{ color: "#0900BC", fontWeight: "400px" }}>browse</b>
        <p style={{ margin:"0 0 1rem 0"}} className="err-message">{formErrors.brand_image}</p>
      </p>
    </>
  );
};

export default FileUploaderComponent;
