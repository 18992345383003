import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../Api/api";
import FilterComponent from "../../DashboardHome/Vendor/VendorFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
        getDepartmentListTableData,
        UpdateSelectedDepartmentId,
        updateSelectedSingleDepartmentData,
} from "../../../Store/Reducer/DepartmentList";
import { transformDepartmentData } from "../../../utils/util";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import ReactModal from "react-modal";
import "./DepartmentList.css";
import AddNewDepartment from "./PopUp/AddNewDepartment";
import style from "../TableMainHeader.module.css"
import AddNewDep from "./PopUp/AddNewDepartment";
import EditDep from "./PopUp/EditDep";
import DelDep from "./PopUp/DelDep";

const DepartmentListTable = (props) => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const {
                departmentList,
                loading,
                selectedDepartmentId,
        } = useSelector((state) => state.departmentList);
        const [isShowTable, showTableUpdate] = useState(true);
        const [isOpen, setIsOpen] = useState(false);
        const [filterText, setFilterText] = useState("");
        const [selectedHeaders, setSelectedHeaders] = useState([
                {
                        id: "Department Name",
                        name: "Department Name",
                        selector: (result) => result.department_name,
                        sortable: true,
                        width: "auto",
                },
                {
                        id: "Description",
                        name: (
                                <div>
                                        Description
                                        <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                        >
                                                <g clip-path="url(#clip0_2425_80169)">
                                                        <path
                                                                d="M6.06065 6.00016C6.21739 5.55461 6.52675 5.1789 6.93395 4.93958C7.34116 4.70027 7.81991 4.61279 8.28544 4.69264C8.75096 4.77249 9.1732 5.01451 9.47737 5.37585C9.78154 5.73718 9.94802 6.19451 9.94732 6.66683C9.94732 8.00016 7.94732 8.66683 7.94732 8.66683M8.00065 11.3335H8.00732M14.6673 8.00016C14.6673 11.6821 11.6826 14.6668 8.00065 14.6668C4.31875 14.6668 1.33398 11.6821 1.33398 8.00016C1.33398 4.31826 4.31875 1.3335 8.00065 1.3335C11.6826 1.3335 14.6673 4.31826 14.6673 8.00016Z"
                                                                stroke="#98A2B3"
                                                                stroke-width="1.33333"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                        />
                                                </g>
                                                <defs>
                                                        <clipPath id="clip0_2425_80169">
                                                                <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                </defs>
                                        </svg>
                                </div>
                        ),
                        selector: (result) => result.description,
                        sortable: true,
                        wrap: true,
                },
                // {
                //   id: "Parent Department",
                //   name: (
                //     <div>
                //       Parent Department
                //       <svg
                //         width="16"
                //         height="16"
                //         viewBox="0 0 16 16"
                //         fill="none"
                //         xmlns="http://www.w3.org/2000/svg"
                //       >
                //         <g clip-path="url(#clip0_2425_80169)">
                //           <path
                //             d="M6.06065 6.00016C6.21739 5.55461 6.52675 5.1789 6.93395 4.93958C7.34116 4.70027 7.81991 4.61279 8.28544 4.69264C8.75096 4.77249 9.1732 5.01451 9.47737 5.37585C9.78154 5.73718 9.94802 6.19451 9.94732 6.66683C9.94732 8.00016 7.94732 8.66683 7.94732 8.66683M8.00065 11.3335H8.00732M14.6673 8.00016C14.6673 11.6821 11.6826 14.6668 8.00065 14.6668C4.31875 14.6668 1.33398 11.6821 1.33398 8.00016C1.33398 4.31826 4.31875 1.3335 8.00065 1.3335C11.6826 1.3335 14.6673 4.31826 14.6673 8.00016Z"
                //             stroke="#98A2B3"
                //             stroke-width="1.33333"
                //             stroke-linecap="round"
                //             stroke-linejoin="round"
                //           />
                //         </g>
                //         <defs>
                //           <clipPath id="clip0_2425_80169">
                //             <rect width="16" height="16" fill="white" />
                //           </clipPath>
                //         </defs>
                //       </svg>
                //     </div>
                //   ),
                //   selector: (result) => result.parent_department,
                //   sortable: true,
                //   width: "auto",
                // },
                {
                        id: "Added on",
                        name: "Added on",
                        selector: (result) => result.added_on,
                        sortable: true,
                        width: "auto",
                },

                {
                        id: "Action",
                        name: "",
                        width: "10%",
                        cell: (result) => (
                                <div className="call-expert-action">
                                        <button
                                                // onClick={() => {
                                                //   handleActionBtn(result.id);
                                                // }}
                                                onClick={() => {
                                                        onRowClicked(result);

                                                }
                                                }
                                                className="call-expert-action-btn"
                                        >
                                                {" "}
                                                <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                >
                                                        <g clip-path="url(#clip0_2376_83673)">
                                                                <path
                                                                        d="M14.166 2.49993C14.3849 2.28106 14.6447 2.10744 14.9307 1.98899C15.2167 1.87054 15.5232 1.80957 15.8327 1.80957C16.1422 1.80957 16.4487 1.87054 16.7347 1.98899C17.0206 2.10744 17.2805 2.28106 17.4993 2.49993C17.7182 2.7188 17.8918 2.97863 18.0103 3.2646C18.1287 3.55057 18.1897 3.85706 18.1897 4.16659C18.1897 4.47612 18.1287 4.78262 18.0103 5.06859C17.8918 5.35455 17.7182 5.61439 17.4993 5.83326L6.24935 17.0833L1.66602 18.3333L2.91602 13.7499L14.166 2.49993Z"
                                                                        stroke="black"
                                                                        stroke-width="1.66667"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                />
                                                        </g>
                                                        <defs>
                                                                <clipPath id="clip0_2376_83673">
                                                                        <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                        </defs>
                                                </svg>
                                        </button>
                                        <button
                                                onClick={() => {
                                                        // handleActionBtn(result.id);
                                                        onRowClicked1(result);
                                                }}
                                                className="call-expert-action-btn"
                                        >
                                                {" "}
                                                <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                >
                                                        <path
                                                                d="M2.5 4.99984H4.16667M4.16667 4.99984H17.5M4.16667 4.99984V16.6665C4.16667 17.1085 4.34226 17.5325 4.65482 17.845C4.96738 18.1576 5.39131 18.3332 5.83333 18.3332H14.1667C14.6087 18.3332 15.0326 18.1576 15.3452 17.845C15.6577 17.5325 15.8333 17.1085 15.8333 16.6665V4.99984H4.16667ZM6.66667 4.99984V3.33317C6.66667 2.89114 6.84226 2.46722 7.15482 2.15466C7.46738 1.8421 7.89131 1.6665 8.33333 1.6665H11.6667C12.1087 1.6665 12.5326 1.8421 12.8452 2.15466C13.1577 2.46722 13.3333 2.89114 13.3333 3.33317V4.99984M8.33333 9.1665V14.1665M11.6667 9.1665V14.1665"
                                                                stroke="black"
                                                                stroke-width="1.66667"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                        />
                                                </svg>
                                        </button>
                                </div>
                        ),
                },
        ]);

        // create new department
        const [show, setShow] = useState(false);
        const [editShow, setEditShow] = useState(false);
        const [delShow, setDelShow] = useState(false);


        const handleClose = () => {
                setShow(false);
        }

        const handleEditClose = () => {
                setEditShow(false);
        }

        const handleDelClose = () => {
                setDelShow(false);
        }

        const handleOpen = () => {
                setShow(true);
        }

        const [resetPaginationToggle, setResetPaginationToggle] =
                React.useState(false);
        const userToken = localStorage.getItem("token");
        
        useEffect(() => {
                dispatch(getDepartmentListTableData());
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${userToken}`);

                const requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        redirect: "follow",
                };
        }, []);

        const tableData = departmentList;
        console.log(tableData)
        const result = transformDepartmentData(tableData);
        console.log(result)
        const customStyles = {
                headCells: {
                        style: {
                                backgroundColor: "#0900bc",
                                color: "white",
                                fontSize: "14px",
                                width: "100%",
                                // gap:"50px"
                        },
                },
                rows: {
                        style: {
                                cursor: "pointer",
                                textTransform: "capitalize",
                                fontSize: "13.5px",
                                color: "black",
                                fontWeight: "500",
                                letterSpacing: "0.5px",
                                whiteSpace: "nowrap",

                                // background :  index % 2? { background : "#fdffe0" }:{ background : "white" }
                                // backgroundColor: result.index %2 ? 'pink' : 'green'
                        },
                },
                cells: {
                        style: {
                                fontSize: "13.5px",
                        },
                },
        };

        const handleActionBtn = (id) => {
                setIsOpen(true);
                dispatch(UpdateSelectedDepartmentId(id));
                dispatch(
                        updateSelectedSingleDepartmentData(
                                departmentList.filter((item) => item.id == id)
                        )
                );
        };
        const onAddClicked = () => {
                setIsOpen(true);
        };

        const onRequestClose = (close) => {
                setIsOpen(close);
        };

        const onRowClicked1 = (result) => {
                dispatch(UpdateSelectedDepartmentId(result.id));
                console.log("result delete", dispatch(UpdateSelectedDepartmentId(result.id)))
                dispatch(updateSelectedSingleDepartmentData(result));
                console.log(" delete ==>>", dispatch(updateSelectedSingleDepartmentData(result)))
                setDelShow(true);
        };


        const onRowClicked = (result) => {

                dispatch(UpdateSelectedDepartmentId(result.id));
                console.log("result ", dispatch(UpdateSelectedDepartmentId(result.id)))
                dispatch(updateSelectedSingleDepartmentData(result));
                console.log("==>>", dispatch(updateSelectedSingleDepartmentData(result)))
                console.log("result of current row", result);
                setEditShow(true);

        };
        const results =
                result &&
                result.filter(
                        (item) =>
                        (item.department_name &&
                                item.department_name.toLowerCase().includes(filterText.toLowerCase())));

        const subHeaderComponentMemo = React.useMemo(() => {
                const handleClear = () => {
                        if (filterText) {
                                setResetPaginationToggle(!resetPaginationToggle);
                                setFilterText("");
                        }
                };

                return (
                  <>
                    <div className="table-header-mainProduct">
                      <div className="product-listcount">
                        <p className="product-list-table-head">Departments</p>
                        {/* <p className="table-badge-mainproduct">100 Product</p> */}
                      </div>
                      <div className="main-product-table-filter">
                        <input
                          id="search"
                          type="text"
                          placeholder={"      Search Category"}
                          aria-label="Search Input"
                          value={filterText}
                          onChange={(e) => setFilterText(e.target.value)}
                          onClear={handleClear}
                          className={
                            filterText.length != 0
                              ? style["filter-vendor-name-without-bg"]
                              : style["filter-vendor-name"]
                          }
                        />
                        <button
                          onClick={handleOpen}
                          className="add-btn-product-list"
                        >
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0013 4.6665V16.3332M4.16797 10.4998H15.8346"
                              stroke="white"
                              stroke-width="1.67"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          New Department
                        </button>
                      </div>
                    </div>
                  </>
                );
        }, [filterText, resetPaginationToggle]);

        return (
                <div className="pt-3">
                        {loading && (
                                <center>
                                        <div
                                                className="spinner-border text-primary loader"
                                                role="status"
                                        ></div>
                                </center>
                        )}
                        {isShowTable && (
                                <>

                                        <AddNewDep open={show} handleClose={handleClose} />
                                        <EditDep open={editShow} handleClose={handleEditClose} />
                                        <DelDep open={delShow} handleClose={handleDelClose} name={"Department"} />

                                        <DataTable
                                                columns={selectedHeaders}
                                                data={results}
                                                customStyles={customStyles}
                                                // onRowClicked={onRowClicked}
                                                paginationResetDefaultPage={resetPaginationToggle}
                                                subHeader
                                                subHeaderComponent={subHeaderComponentMemo}
                                                pagination
                                        />
                                </>
                        )}
                </div>
        );
};

export default DepartmentListTable;
