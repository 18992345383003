import React, { useState, useEffect } from "react";
import DashBoardheader from "../../../../components/DashboardHeader";
import SideNavBar from "../../../../components/Sidebar";
import "./AddProducts.css";
import PricingSection from "./Components/PricingSection";
import ProductSection from "./Components/ProductSection";
import DescriptionSection from "./Components/DescriptionSection";
import SpecificationSection from "./Components/SpecificationSection";
import FeatureSection from "./Components/FeatureSection";
import AddFilterBySection from "./Components/AddFilterBySection";
import { BaseUrl } from "../../../Api/api"
import { toast } from "react-toastify";
import axios from "axios";

const AddProduct = () => {
        const initialValues = {
                product_name: "",
                category_id: "",
                parent_category_id: "",
                product_description: "",
                product_information: "",
                features: "",
                specifications: "",
                compatibility: "",
                video_link: "",
                video_description: "",
                category_name: "",
                department_id: [],
                department_name: [],
                industry_id: [],
                industry_name: [],
                brand_name: "",
                is_price: "Price",
                is_tax_include: "",
                initial_price: "",
                gst_percentage: "",
                discount: "",
                price: "",
                price_without_discount: "",
                brand_logo_url: "",
                gallery: [],
                image: null,
                brand_image: null
        };
        const excludedKeys = [
                "compatibility",
                "video_link",
                "video_description",
                "is_tax_include",
                "initial_price",
                "gst_percentage",
                "discount",
                "price",
                "price_without_discount",
                "brand_logo_url"
        ]
        const [formValues, setFormValues] = useState(initialValues);
        const [formErrors, setFormErrors] = useState({});
        const [isSubmit, setIsSubmit] = useState(false);
        const [brandAdded, setBrandAdded] = useState(false)
        const handleChange = (e) => {
                setFormValues((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value,
                }));
        };
        console.log(formValues)
        useEffect(() => {

                if (Object.keys(formErrors).length === 0 && isSubmit) {
                }
        }, [formErrors]);

        const validate = (values, excludedKeys) => {
                const errors = {};

                Object.entries(values).forEach(([key, value]) => {
                        if (!excludedKeys.includes(key) && !String(value).trim() || value == null) {
                                const keyname = key;
                                const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);
                                errors[key] = `This Field is required`;
                        }
                });
                if (values.gallery.length < 2) {
                        errors["gallery"] = `Select 3 or more images`;
                }

                console.log(errors);
                return errors;
        };

        const handleSubmit = async (e) => {
                const userToken = localStorage.getItem("token");
                setFormValues((prevState) => ({
                        ...prevState,
                        ["price"]: ((Number(formValues.initial_price) + Number(Number(formValues.initial_price) * (Number(formValues.gst_percentage) / 100))) - Number(Number(formValues.initial_price) * (Number(formValues.discount) / 100))).toFixed(2),
                        ["is_tax_include"]: (Number(formValues.initial_price) + Number(Number(formValues.initial_price) * (Number(formValues.gst_percentage) / 100))).toFixed(2),
                        ["price_without_discount"]: (Number(formValues.initial_price) + Number(Number(formValues.initial_price) * (Number(formValues.gst_percentage) / 100))).toFixed(2),
                }))

                console.log(formValues)

                try {
                        const userToken = localStorage.getItem("token");

                        const formdata = new FormData();
                        formdata.append("brand_image", formValues.brand_image);
                        formdata.append("brand_name", formValues.brand_name);
                        const response = await axios.post(`${BaseUrl}ProductMaster/Add-Brand-By-Admin`, formdata, {
                                headers: {
                                        Authorization: `Bearer ${userToken}`
                                }
                        });

                        const result = response.data;
                        console.log(result);
                        if (result.status === 200) {
                                setBrandAdded(true)
                                toast("Brand Added Successfully");
                        } else {
                                toast(result.message);
                        }
                } catch (error) {
                        console.log("error", error);
                }

                e.preventDefault();
                setFormErrors(validate(formValues, excludedKeys));

                try {
                        const myHeaders = {
                                Authorization: `Bearer ${userToken}`,
                        };

                        const formdata = new FormData();
                        formdata.append("brand_image", formValues.brand_image);
                        formdata.append("image", formValues.image);
                        formdata.append("product_name", formValues.product_name);
                        formdata.append("category_id", formValues.category_id);
                        formdata.append("product_description", formValues.product_description);
                        formdata.append("product_information", formValues.product_information);
                        formdata.append("features", formValues.features);
                        formdata.append("specifications", formValues.specifications);
                        formdata.append("category_name", formValues.category_name);
                        for (let i = 0; i < formValues.industry_name.length; i++) {
                                formdata.append(`industry_id[${i}]`, formValues.industry_id[i]);
                                formdata.append(`industry_name[${i}]`, formValues.industry_name[i]);
                        }
                        formdata.append("brand_name", formValues.brand_name);
                        for (let i = 0; i < formValues.department_name.length; i++) {
                                formdata.append(`department_id[${i}]`, formValues.department_id[i]);
                                formdata.append(`department_name[${i}]`, formValues.department_name[i]);
                        }
                        formdata.append("is_price", formValues.is_price);
                        formdata.append("is_tax_include", formValues.is_tax_include);
                        formdata.append("initial_price", formValues.initial_price);
                        formdata.append("gst_percentage", formValues.gst_percentage);
                        formdata.append("discount", formValues.discount);
                        formdata.append("price", formValues.price);
                        formdata.append("video_link", formValues.video_link);
                        formdata.append("price_without_discount", formValues.price_without_discount);
                        formValues.gallery.forEach((element) => {
                                formdata.append("gallery", element);
                        });
                        for (const pair of formdata.entries()) {
                                console.log(pair[0] + ", " + pair[1]);
                        }

                        const config = {
                                method: "post",
                                url: `${BaseUrl}product/Add-Product-By-Admin`,
                                headers: myHeaders,
                                data: formdata,
                        };

                        axios(config)
                                .then((response) => {
                                        const result = response.data;
                                        console.log(result);
                                        if (result.status === 200) {
                                                setFormValues("");
                                                //alert(result.message);
                                                toast("Product Publish Successfully");
                                                // window.location = "/main-product";
                                        } else {
                                                console.log(brandAdded)
                                                if (brandAdded == true) {
                                                        // window.location.reload();
                                                }
                                                else {
                                                        toast(result.message);
                                                }
                                        }
                                })
                                .catch((error) => console.log("error", error));
                } catch (err) {
                        console.log(err);
                }

        };

        const handleback = () => {
                window.location = "/main-product";
        }
        return (
                <>
                        <DashBoardheader />
                        <SideNavBar />
                        <div className="vendor-follow">
                                <div className="vendor-follow-main-div">
                                        <div className="main-product">
                                                <div className="main-product-title">
                                                        <h1 className="new-product-header">New Product</h1>
                                                </div>
                                                <div className="main-product-filter"></div>
                                                {/* <img className="addicon" onClick={onClickAddIcon} src={PersonIcon} alt=""></img> */}
                                        </div>
                                        <div className="addnew-containerr">
                                                <div className="addnew-product-section-div">
                                                        <div className="addnew-text-underline">
                                                                <h1 className="addnew-product-section-title">Product</h1>
                                                        </div>
                                                </div>
                                                <div className="addnew-product-section">
                                                        <ProductSection formValues={formValues} setFormValues={setFormValues} handleChange={handleChange} formErrors={formErrors} />
                                                </div>
                                                <div className="addnew-description-section">
                                                        <AddFilterBySection formValues={formValues} setFormValues={setFormValues} handleChange={handleChange} formErrors={formErrors} />
                                                </div>
                                                <div className="addnew-description-section">
                                                        <DescriptionSection formValues={formValues} setFormValues={setFormValues} handleChange={handleChange} formErrors={formErrors} />
                                                </div>
                                                <div className="addnew-pricing-section">
                                                        <PricingSection formValues={formValues} setFormValues={setFormValues} handleChange={handleChange} formErrors={formErrors} />
                                                </div>
                                                <div className="addnew-specification-section">
                                                        <SpecificationSection formValues={formValues} setFormValues={setFormValues} handleChange={handleChange} formErrors={formErrors} />
                                                        <FeatureSection formValues={formValues} setFormValues={setFormValues} handleChange={handleChange} formErrors={formErrors} />
                                                </div>

                                                <div className="addnew-save-back-section">
                                                        <button onClick={handleback} className="addnew-Product-Submit-Button">Back</button>
                                                        <button onClick={handleSubmit} className="addnew-Product-Submit-Button">Save</button>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        );
};

export default AddProduct;
