import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const myReducer = createSlice({
    name: "conqtAdmin",
    initialState: {
        loading: false,
        targetCustomerContactId: "",
        targetCustomerContact: {},
        currentCustomer: {},
    },
    reducers: {
        setCurrentCustomer: (state, action) => {
            state.currentCustomer = action.payload;
        },
        updateTargetCustomerContactId: (state, action) => {
            state.targetCustomerContactId = { ...state.targetCustomerContactId, ...action.payload }
        },
        updateTargetCustomerContact: (state, action) => {
            state.targetCustomerContact = { ...state.targetCustomerContact, ...action.payload };
        },
        clearTargetCustomerContact: (state, action) => {
            state.targetCustomerContact = {};
        },
    },
    extraReducers: {
    },
})

export const {
    updateTargetCustomerContact,
    clearTargetCustomerContact,
    updateTargetCustomerContactId,
    setCurrentCustomer,
} = myReducer.actions;

export default myReducer.reducer;