import React, { useEffect, useState } from "react";
import DashBoardheader from "../../../components/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import SideNavBar from "../../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import "./ProductList.css"
import ProductListTable from "./ProductListTable"

const ProductList = () => {

  const [showHeader, setShowHeader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
 

  const handleClickByCategories = () => {
    navigate('category-list')
}

const handleClickByIndustry = () => {
  navigate('industry-list')
}

const handleClickByDepartment = () => {
  navigate('department-list')
}

  return (
    <>
      <DashBoardheader />
      <SideNavBar />

      <div className="vendor-follow">
        <div className="vendor-follow-main-div">
          {showHeader && (
            <div className="main-product">
              <div className="main-product-title">
                <h1 className="vendor-follow-header">Product</h1>
              </div>
              <div className="main-product-filter">
                <button
                  class="main-product-filterbtn target-top-add-btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Filter by{" "}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li onClick={handleClickByCategories}>
                    <a class="dropdown-item">Categories</a>
                  </li>
                  <li onClick={handleClickByIndustry}>
                    <a class="dropdown-item">Industries</a>
                  </li>
                  <li onClick={handleClickByDepartment}>
                    <a class="dropdown-item">Department</a>
                  </li>
                </ul>
              </div>
              {/* <img className="addicon" onClick={onClickAddIcon} src={PersonIcon} alt=""></img> */}
            </div>
          )}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <ProductListTable
                    DelayClicked={() => {}}
                    isShowHeader={(val) => {
                      setShowHeader(val);
                    }}
                  />
                </div>
                <div style={{ margin: "0 0 2rem 0" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;
