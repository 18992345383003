import React, {useState} from "react";
import "../AddProducts.css";
import { useDispatch, useSelector } from "react-redux";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const FeatureSection = ({formValues,setFormValues,handleChange, formErrors}) => {

    const [error, setError] = useState(false); 
    const dispatch = useDispatch();
  
      const handleChangeText = (e) => {
        console.log("handleChangeText", e);
        setFormValues((prevState) => ({
          ...prevState,
          ["features"]: e,
        }));
      };
  return (
    <>
    <div className="addnew-containerr">
         <div className="addnew-product-section-div">
           <div className="addnew-text-underline">
             <h1 className="addnew-product-section-title">Features</h1>
           </div>
         </div>
         <div className="addnew-product-textEditor"> <CKEditor
                type="texteditor textarea"
                name="perposal_description"
                placeholder="Enter Answer Here"
                editor={Editor}
                data="<p>Enter Answer Here</p>"
                onReady={(Editor) => {
                  // You can store the "editor" and use when it is needed.
                  
                }}
                onChange={(event, Editor) => {
                  const text = Editor.getData();
                  handleChangeText(text);
                }}
                // value={
                //   console.log("Blur.", ClassicEditor)
                // }
                onFocus={(event, Editor) => {
                 
                }}
              /></div>
              
            <p style={{ margin: "0 0 1rem 0" }} className="err-message">{formErrors.features}</p>

         <div className="addnew-product-section-div">
           <div className="addnew-text-underline">
             <h1 className="addnew-product-section-title">{"Upload Video (Optional)"}</h1>
           </div>
         </div>
         <input
        id="video_link"
        className="addnew-product-Input"
        placeholder="Enter URL"
        name="video_link"
        type="text"
        onChange={handleChange}
        style = {{marginTop: "1rem"}}
        />
       </div>
 </>
  )
}

export default FeatureSection