import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getTargetVendorContactData = createAsyncThunk("targetvendorContactList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}AdminTargetVendor/Get-Target-Vendor-List`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result.data, "store api data");
      return result.data
    })
    .catch((error) => console.log("error", error));
});


export const myReducer = createSlice({
  name: "conqtAdmin",
  initialState: {
    loading: false,
    targetVendorContactList: [],
    formDetails:{},
  },
  reducers: {
   
    clearFormDetails: (state, action) => {
      state.formDetails = {};
  },
  updateFormDetails: (state, action) => {
    state.formDetails = { ...state.formDetails, ...action.payload };
},
      },
  extraReducers: {
    [getTargetVendorContactData.pending]: (state) => {
      state.loading = true;
    },
    [getTargetVendorContactData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.targetVendorContactList = payload;

      // console.log(payload,"payload from reducer") 
    },
   
  },
})

export const {
    clearFormDetails,
    updateFormDetails
} = myReducer.actions;

export default myReducer.reducer;
