import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getDepartmentListTableData = createAsyncThunk("departmentList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}ProductMaster/Get-Departments`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
        return result.data
    })
    .catch((error) => console.log("error", error));
});



export const myReducer = createSlice({
  name: "departmentList",
  initialState: {
    loading: false,
    selectedDepartmentId: "",
    departmentList: [],
    selectedSingleDepartmentData:[],
  },
  reducers: {
    UpdateSelectedDepartmentId: (state, action) => {
      state.selectedDepartmentId = action.payload;
    },
    updateSelectedSingleDepartmentData : (state, action) => {
       state.selectedSingleDepartmentData = action.payload;
    },
    
  },
  extraReducers: {
    [getDepartmentListTableData.pending]: (state) => {
      state.loading = true;
    },
    [getDepartmentListTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.departmentList = payload;
      // console.log(payload,"payload from reducer") 
    },
   

  },


})

export const {
  UpdateSelectedDepartmentId,
  updateSelectedSingleDepartmentData,
} = myReducer.actions;

export default myReducer.reducer;
