import React from "react";

import "./statcard.css";

const StatCard = ({ title, image, count, percent, description,bgColor }) => {
  return (
    <div className="statcard" >
      {/* <div className="icon-card"> */}
      {/* <img className="icon" src="https://cdn-icons-png.flaticon.com/512/2271/2271403.png" alt="chair" />
       */}
      {/* {image}
            </div> */}
      <div className="main-card-body" style={{backgroundColor:`${bgColor}`}}>
        <div className="cardname">
        <div className="card-count-text">
         
         <h1 className="card-count">{count}</h1>
        
       
       </div>
          <div className="card-title-text">
            <p className="card-title">{title}</p>

          
          </div>
          {/* <div className="three-dots">...</div> */}
         
          <div className="view-more">{description}</div>
        </div>

        {/* <hr />
                <div>
                    <p><span className='spannumber'>+{percent}%</span>{description}</p>
                </div> */}
      </div>
    </div>
  );
};

export default StatCard;
