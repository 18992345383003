import React, { useState, useEffect } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import cross from "../../../../images/cross.svg";
// import "./popupDep.css"
import { useForm } from "react-hook-form";
import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const EditIndustry = ({ open, handleClose }) => {

        const navigate = useNavigate();

        const [close, setClose] = useState(handleClose);
        const { register, handleSubmit, getValues, setValue, setError, formState: { errors } } = useForm();

        const selectedSingleIndustryData = useSelector((state) => state.industryList.selectedSingleIndustryData);
        console.log("selectedSingleIndustryData==> edit", selectedSingleIndustryData);
        const industry_id = selectedSingleIndustryData?.id;



        useEffect(() => {
                Object.keys(selectedSingleIndustryData)?.map(key => {
                        setValue(key, selectedSingleIndustryData[key])
                })
        }, [selectedSingleIndustryData])


        const onSubmit = () => {
                const data = getValues();
                data["industry_id"] = industry_id;
                axios.post(`${BaseUrl}ProductMaster/Update-Industry`, data,
                        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
                )
                        .then(res => {
                                if (res.status === 200) {
                                        console.log("res", res);
                                        setClose(handleClose(false))
                                        // toast.success("Department Updated Successfully")
                                        window.location.reload();
                                        window.scrollTo(0, 0);
                                }
                        })
                        .catch(err => {
                                console.log("err", err);
                                toast.error("Something went wrong")
                        })
        }

        return (
                <>
                        <Modal open={open} handleClose={handleClose}>
                                <div className='d-flex align-items-center justify-content-end'>
                                        <img src={cross} alt="" onClick={handleClose} style={{ cursor: "pointer" }} />
                                </div>
                                <Modal.Body >
                                        <h3 className='top-header-dep'>Edit Industry</h3>
                                        {/* {JSON.stringify(selectedSingleDepartmentData)}  */}

                                        <form onSubmit={handleSubmit(onSubmit)}>

                                                <div className='d-flex flex-column'>
                                                        <div className='d-flex flex-column py-3'>
                                                                <label htmlFor="" className='dep-label py-1'>Name</label>
                                                                <input type="text" className='dep-input'
                                                                        {...register("industry_name", { required: true, maxLength: 20 })}
                                                                />
                                                                {errors.industry_name && <p className='dep-error'>This field is required</p>}
                                                        </div>
                                                        <label htmlFor="" className='dep-label py-1'>Description</label>
                                                        <input type="text" className='dep-input'
                                                                {...register("description", { required: true, maxLength: 250 })}
                                                        />
                                                        {errors.description && <p className='dep-error'>This field is required</p>}
                                                </div>
                                                <button className='dep-add-btn mt-5'>
                                                        <p className='dep-add-btn-name'>Add</p>
                                                </button>

                                        </form>
                                </Modal.Body>
                        </Modal>
                </>
        )
}

export default EditIndustry