import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getBrandListData = createAsyncThunk("brandList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}ProductMaster/Get-Brands-for-Admin`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
        console.log(result.data)
        return result.data
    })
    .catch((error) => console.log("error", error));
});



export const myReducer = createSlice({
  name: "brandList",
  initialState: {
    loading: false,
    selectedBrandId: "",
    brandList: [],
    selectedSingleBrandData:[],
  },
  reducers: {
    UpdateSelectedBrandId: (state, action) => {
      state.selectedBrandId = action.payload;
    },
    updateSelectedSingleBrandData : (state, action) => {
       state.selectedSingleBrandData = action.payload;
    },
    
  },
  extraReducers: {
    [getBrandListData.pending]: (state) => {
      state.loading = true;
    },
    [getBrandListData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.brandList = payload;
      // console.log(payload,"payload from reducer") 
    },
   

  },


})

export const {
  UpdateSelectedBrandId,
  updateSelectedSingleBrandData,
} = myReducer.actions;

export default myReducer.reducer;
