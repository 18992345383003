import React from 'react'
import './filterinputcomp.css'

const FilterInputComponent = ({ filterText, onFilter, onClear }) => {
    return (
        <>
            <input
                id="search"
                type="text"
                placeholder="Filter By Email Category"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
                style={{
                    border: "1px solid black",
                    paddingX: 3,
                    height: "40px",
                    borderRadius: "5px"

                }}

            />
            <div type="button" className='close-but-style mx-3' onClick={onClear}>
                X
            </div>
        </>
    )
}

export default FilterInputComponent