import React from 'react'
import './copypaste.css'
import CopyIcon from '../../images/Copy.svg'

const CopyandPaste = () => {
    return (
        <>
            <div className='copyandpaste-contact px-3 py-3'>
                <div className='copy-paste-top py-3'>
                    <span ><img style={{ width: "27px" }} src={CopyIcon} alt="" /></span>
                    <h5 className='copy-paste-top-name px-2 pt-1'>Copy and paste Your Contact</h5>
                </div>
                <div className='contact-data mx-5 px-3'>
                    <div className='col-3'>
                        <p className='copy-paste-contact-header py-1'>Email</p>
                        <p className='copy-paste-contact-data'>abc123@gmail.com</p>
                    </div>
                    <div className='col-3'>
                        <p className='copy-paste-contact-header py-1'>FirstName</p>
                        <p className='copy-paste-contact-data'>Intern1</p>
                    </div>
                    <div className='col-3'>
                        <p className='copy-paste-contact-header py-1'>LastName</p>
                        <p className='copy-paste-contact-data'>Techcurve</p>
                    </div>
                    <div className='col-3'>
                        <p className='copy-paste-contact-header py-1'>Phone No.</p>
                        <p className='copy-paste-contact-data'>7789645632</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CopyandPaste