import React from 'react'

const FilterComponent = ({ filterText, onFilter, onClear ,placeholder}) => (
    <>
    <input
        id="search"
        type="text"
        placeholder={placeholder}
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="filter-vendor-name"
    />
      {/* <div  className='filter-close-button' type="button" onClick={onClear}>
          x
      </div> */}
      </>
    );
export default FilterComponent 
