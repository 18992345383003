import React, { useState, useEffect } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
// import "./popupDep.css";
import './reject.css'
import { useForm } from "react-hook-form";
// import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import FeaturedIcon from '../../../images/Featuredicon.svg';
import { BaseUrl } from '../../../pages/Api/api';
import RejIcon from '../../../images/rejicon.svg';

const RejectProduct = ({ open, handleClose, name }) => {

        const dispatch = useDispatch();
        const navigate = useNavigate();

        const [close, setClose] = useState(handleClose);

        const { selectedSingleProductData } = useSelector(
                (state) => state.mainProductList
        );
        console.log("selectedSingleProductData", selectedSingleProductData);

        const product_id = selectedSingleProductData?.id;

        console.log("000", product_id)

        const handleChangeStatus = async () => {
                const data = {
                        product_id: product_id,
                        "product_status": "Reject"
                }

                axios.post(`${BaseUrl}product/Product-Status-Change`, data,
                        {
                                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                        })
                        .then((res) => {
                                if (res.data.status === 200) {
                                        console.log("app respone", res.data)
                                        setClose(handleClose(false));
                                        window.location.reload();
                                        // navigate("/main-product");
                                        window.scrollTo(0, 0);

                                } else if (res.data.status === 401) {
                                        console.log("app fail")
                                        setClose(handleClose(false));
                                        toast.error(res.data.message);
                                }

                        }).catch((err) => {
                                console.log("error", err)
                        })
        }




        return (
                <>
                        <Modal open={open} handleClose={handleClose} size="xs" style={{ marginTop: "50px" }}>
                                <Modal.Body >
                                        <img src={RejIcon} alt="" />
                                        <div className='d-flex flex-column justify-content-center'>
                                                <h4 className='app-top-con py-4'>Reject Product</h4>
                                                <p className='app-des'>Are you sure you want to Reject this product? This action cannot be undone.</p>

                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-4 pt-4'>
                                                <button className='cencel-dep-btn' onClick={handleClose}>Cancel</button>
                                                <button className='rej-con-btn' onClick={handleChangeStatus}>Confirm</button>

                                        </div>
                                </Modal.Body>
                        </Modal>
                </>
        )
}

export default RejectProduct