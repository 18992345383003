import { padding } from '@mui/system'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import './editpud.css'
import { useNavigate } from 'react-router-dom'

const EditParticularUseDetails = () => {
    const navigate = useNavigate()
    const { register, setValue, getValues, handleSubmit } = useForm({})

    const [editUser, setEditUser] = useState({})
    const [text, setText] = useState('')

    useEffect(() => {
        const localEditUser = localStorage.getItem('selectedUser')
        if (localEditUser) {
            const parsedUser = JSON.parse(localEditUser)
            Object.keys(parsedUser)?.map(key => {
                setValue(key, parsedUser[key])
            })
            setEditUser(JSON.parse(localEditUser))
        }
    }, [])

    const editUsers = Object.keys(editUser)

    const handleClickGoBack = () => {
        navigate('/particular-user-details')
    }

    const onSubmit = (data) => {
        console.log('Form Data: ', data)
    }
    return (

        <>
            <DashBoardheader />
            <SideNavBar />
            <div className='main-body-content mt-5'>
                {/* <div className="container">
                    <h5>Edit Particular User Details</h5>
                </div> */}

                <div className='container edit-pud-top-body'>

                    <div className='d-flex align-items-center justify-content-center mt-3'>
                        <h5 className='edit-pud-top-name pt-2'>Edit Details</h5>
                    </div>
                    <div>
                        <hr />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className='py-3'>
                        {editUsers.map(key => {
                            return (
                                <div className='edit-pud-body'>
                                    <div className='edit-pud-labels my-1'>
                                        <label htmlFor="">{key}</label>
                                    </div>
                                    <div className='mx-3'>
                                        <p>-</p>
                                    </div>
                                    <div className='edit-pud-inputs my-1'>
                                        <input {...register(key)} style={{
                                            border: "none",
                                            background: 'rgba(217, 217, 217, 0.5)',
                                            padding: "5px"

                                        }} />
                                    </div>
                                </div>

                            )
                        })}


                        <div className='d-flex align-items-center justify-content-center pt-5' >
                            <button value={'submit'} className="pud-edit-btns mx-2">
                                <p className="pud-edit-save-btn px-2 ">Save</p>
                            </button>
                            <button className="pud-edit-btns mx-2" onClick={handleClickGoBack}>
                                <p className='pud-edit-cancel-btn px-2'>Cancel</p>
                            </button>
                        </div>
                    </form>

                </div>

            </div>
        </>

    )
}

export default EditParticularUseDetails