import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getIndustryListTableData = createAsyncThunk("industryList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}ProductMaster/Get-Industries`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
    
        return result.data
    })
    .catch((error) => console.log("error", error));
});



export const myReducer = createSlice({
  name: "industryList",
  initialState: {
    loading: false,
    selectedIndustryId: "",
    industryList: [],
    selectedSingleIndustryData:[],
  },
  reducers: {
    UpdateSelectedIndustryId: (state, action) => {
      state.selectedIndustryId = action.payload;
    },
    updateSelectedSingleIndustryData : (state, action) => {
       state.selectedSingleIndustryData = action.payload;
    },
    
  },
  extraReducers: {
    [getIndustryListTableData.pending]: (state) => {
      state.loading = true;
    },
    [getIndustryListTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.industryList = payload;
      // console.log(payload,"payload from reducer") 
    },
   

  },


})

export const {
  UpdateSelectedIndustryId,
  updateSelectedSingleIndustryData,
} = myReducer.actions;

export default myReducer.reducer;
