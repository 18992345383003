import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./FileUploader.css"
import "../../AddProducts.css"

const MultipleFileUpload = ({formValues,setFormValues,handleChange, formErrors}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handlePaste = (event) => {
    const files = Array.from(event.clipboardData.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleBrowse = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  useEffect(() =>{
    console.log(selectedFiles,selectedFiles.length)
         if (selectedFiles.length>1){
          setFormValues((prevState) => ({
            ...prevState,
            ["image"]:selectedFiles.shift(),
            ["gallery"]:selectedFiles
          }));
         }

  },[selectedFiles])

  return (
    <>
      <div className="product-ImageUpload-Main-Container">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onPaste={handlePaste}
          onClick={handleBrowse}
          className="MultipleProductImageUploader"
        >
          <div className="MultipleProductImageUploader-title">
            <h1
              style={{ margin: "1rem 0 0.5rem 1rem" }}
              className="addnew-product-Input-label"
            >
              Product Image
              <svg
                data-tooltip-id="productImage-tooltip"
                data-tooltip-content="500*500px Image only. (Min - 2 Images)"
                style={{ margin: "0 0 0.4rem 0.4rem" }}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.0587 5.99967C6.21543 5.55412 6.5248 5.17841 6.932 4.9391C7.3392 4.69978 7.81796 4.6123 8.28348 4.69215C8.749 4.772 9.17124 5.01402 9.47542 5.37536C9.77959 5.7367 9.94607 6.19402 9.94536 6.66634C9.94536 7.99967 7.94536 8.66634 7.94536 8.66634M7.9987 11.333H8.00536M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z"
                  stroke="#667085"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Tooltip
              id="productImage-tooltip"
              place="right"
            />
            </h1>
          </div>
          <div className="product-upload-icon-div">
            <svg
              width="29"
              height="31"
              viewBox="0 0 29 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29 21.3535V3.35352C29 2.55787 28.6945 1.7948 28.1506 1.2322C27.6068 0.669586 26.8691 0.353516 26.1 0.353516H8.7C7.93087 0.353516 7.19325 0.669586 6.64939 1.2322C6.10554 1.7948 5.8 2.55787 5.8 3.35352V21.3535C5.8 22.1492 6.10554 22.9122 6.64939 23.4748C7.19325 24.0374 7.93087 24.3535 8.7 24.3535H26.1C26.8691 24.3535 27.6068 24.0374 28.1506 23.4748C28.6945 22.9122 29 22.1492 29 21.3535ZM13.05 15.3535L15.9935 19.4185L20.3 13.8535L26.1 21.3535H8.7M0 6.35352V27.3535C0 28.1492 0.305535 28.9122 0.84939 29.4748C1.39325 30.0374 2.13087 30.3535 2.9 30.3535H23.2V27.3535H2.9V6.35352"
                fill="#7D7D7D"
              />
            </svg>
            <p className="product-image-upload-caption-text">
              Browse and chose the files you want to upload from your computer
            </p>
            <button className="fileupload-addproductImage-btn">
              {" "}
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.832 4.94466C10.832 4.48442 10.4589 4.11133 9.9987 4.11133C9.53846 4.11133 9.16536 4.48442 9.16536 4.94466V9.94466H4.16536C3.70513 9.94466 3.33203 10.3178 3.33203 10.778C3.33203 11.2382 3.70513 11.6113 4.16536 11.6113H9.16536V16.6113C9.16536 17.0716 9.53846 17.4447 9.9987 17.4447C10.4589 17.4447 10.832 17.0716 10.832 16.6113V11.6113H15.832C16.2923 11.6113 16.6654 11.2382 16.6654 10.778C16.6654 10.3178 16.2923 9.94466 15.832 9.94466H10.832V4.94466Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>

          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleFileSelect}
            style={{ display: "none" }}
            multiple
          />
        </div>
        {selectedFiles.length > 0 ? (
          <div className="fileuploads-product-preview">
            <div style={{borderBottom: "1px dashed #A2A2A2", width: "90%",height:"20%"}} className="MultipleProductImageUploader-title">
              <h1
                style={{ margin: "1rem 0 0.5rem 0rem" }}
                className="addnew-product-Input-label"
              >
                Selected Images{" "}
              </h1>
            </div>
            <div className="selected-productImages-Container">
            {selectedFiles.map((item, index) => (
              <div className="productImage-card-preview">
                <div className="delete-product-image">
                    <button onClick={()=>handleDeleteFile(index)} className="delete-productImage-btn"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.66927 0.445312C13.2776 0.445312 17.0026 4.17031 17.0026 8.77865C17.0026 13.387 13.2776 17.112 8.66927 17.112C4.06094 17.112 0.335938 13.387 0.335938 8.77865C0.335938 4.17031 4.06094 0.445312 8.66927 0.445312ZM11.6609 4.61198L8.66927 7.60365L5.6776 4.61198L4.5026 5.78698L7.49427 8.77865L4.5026 11.7703L5.6776 12.9453L8.66927 9.95365L11.6609 12.9453L12.8359 11.7703L9.84427 8.77865L12.8359 5.78698L11.6609 4.61198Z" fill="#FF0000"/>
</svg>
</button></div>
                <img
                  src={URL.createObjectURL(item)}
                  alt="preview"
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    marginLeft: "5%",
                    
                  }}
                />
                <p className="logo-preview-name">
            {item.name.substring(0, 4)}...{item.name[item.name.lastIndexOf(".")-1]}.{item.name.split(".").pop()}</p>
            <p className="logo-preview-size">{(item.size / (1024 * 1024)).toFixed(2)} MB</p>
              </div>
            ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <p onClick={handleBrowse} className="verified-hint-text">
        drop file here, paste or{" "}
        <b style={{ color: "#0900BC", fontWeight: "400px" }}>browse</b>
        <p style={{ margin:"0 0 1rem 0"}} className="err-message">{formErrors.gallery}</p>
      </p>
      
    </>
  );
}

export default MultipleFileUpload;
