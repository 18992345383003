import React, { useState, useEffect } from "react";
import VendorTable from "./VendorTable/index";
import TuneIcon from "@mui/icons-material/Tune";
import "./vendorFollow.css";
import SideNavBar from "../../../components/Sidebar";
import DashBoardheader from "../../../components/DashboardHeader";
import { useNavigate } from "react-router-dom";
import StatCard from "../../../components/Dashboard/StatCard/index.jsx";
import PersonIcon from "../../../images/material-symbols_person-add.png"

const Vendor = (props) => {
  const navigate = useNavigate();
  const [type, setType] = useState("all");
  const [enableFilter, setEnableFilter] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [apiData, setData] = useState([]);


  const toggleFilter = () => {
    setEnableFilter(!enableFilter);
  };
  const onClickAccept = () => {
    navigate("/Vendor");
  };

  const onClickAddIcon = () => {
    navigate("/add-new-contacts");
  }
  
  return (
    <>
      <DashBoardheader />
      <SideNavBar />

      <div className="vendor-follow">
        <div className="vendor-follow-main-div">
          {showHeader && (
            <div className="vendor-addicon">
              <h1 className="vendor-follow-header">Vendor</h1>
              {/* <img className="addicon" onClick={onClickAddIcon} src={PersonIcon} alt=""></img> */}
            </div>
          )}
          <div className="containerr">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <VendorTable
                    DelayClicked={() => {}}
                    isShowHeader={(val) => {
                      setShowHeader(val);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vendor;
