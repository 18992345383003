import React from 'react'
import BreadCrumbsCC from '../../../components/BreadcrumbsCC'
import DashBoardheader from '../../../components/DashboardHeader'
import SideNavBar from '../../../components/Sidebar'
import './actionsForCc.css'
import AvtarIcon from '../../../images/AvtarIcon.svg'


const ActionsForCC = () => {
    return (
        <>
            <DashBoardheader />
            <SideNavBar />

            <div className="main-body-container">

                <div className="container mt-5 d-flex align-items-center">
                    <img src={AvtarIcon} alt="" /> <span className="create-contact-top-name px-2">Amitabh</span>
                </div>
                <div className="container breadcrumbs-cc">
                    <BreadCrumbsCC />
                </div>
            </div>
        </>
    )
}

export default ActionsForCC