import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../pages/Api/api";
import FilterComponent from "../DashboardHome/Vendor/VendorFilter/index";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import ColumnsIcon from "../../images/columns.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectTableData,
  updateSelectProjectId,
  updateSelectProjectTableCheckbox,
} from "../../Store/Reducer/ProjectList";
import moment from "moment";
import { transformProjectTableDataResponse } from "../../utils/util";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const Table = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectList, loading, selectedProjectTableCheckbox } = useSelector(
    (state) => state.project
  );
  //const [loading, setLoading] = useState(false);

  console.log(projectList, "project lsit");
  const [isShowTable, showTableUpdate] = useState(true);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});
  const [isColumns, isSetColumns] = useState({});
  const [checkBox, showCheckBox] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedHeaders, setSelectedHeaders] = useState([
    {
      id: "Date",
      name: "Date",
      selector: (result) => moment(result.created_at).format("DD-MM-YYYY"),
      // sortable: true,
    },
    {
      id: "project_name",
      name: "Project Name",
      selector: (result) => result.project_name,
      sortable: true,
    },
    {
      id: "company_name",
      name: "Account Name",
      selector: (result) => result.company_name,
      sortable: true,
    },
    // {
    //   id: "project_description",
    //   width:"12rem",
    //   name: "Project Description",
    //   selector: (result) => result.project_description.slice(0,10),
    //   sortable: true,
    // },
    {
      id: "solution_name",
      name: "Project Category",
      selector: (result) => result.solution_name,
      sortable: true,
    },
    {
      id: "project_expiry_datetime",
      name: "Project Due Date",
      selector: (result) => result.project_expiry_datetime,
      sortable: true,
    },
    {
      id: "country",
      name: "Location",
      selector: (result) => result.country,
      sortable: true,
    },
    {
      id: "attachment_name",
      name: "Attachment",
      selector: (result) =>
        result.attachment_name ? (
          result.attachment_name
        ) : (
          <svg
            width="40"
            height="23"
            viewBox="0 0 80 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M69 48.3757L63 42.1476C65.6667 41.411 67.8333 39.9873 69.5 37.8764C71.1667 35.7682 72 33.3078 72 30.4951C72 27.1467 70.8333 24.3005 68.5 21.9566C66.1667 19.6127 63.3333 18.4408 60 18.4408H44V10.4046H60C65.5333 10.4046 70.2507 12.3627 74.152 16.2791C78.0507 20.1981 80 24.9367 80 30.4951C80 34.3123 79.0173 37.8282 77.052 41.0427C75.084 44.2572 72.4 46.7015 69 48.3757ZM55.4 34.5132L47.4 26.477H56V34.5132H55.4ZM60.2956 62.1827L4.0729 5.62536L9.6729 0L65.8956 56.5573L60.2956 62.1827ZM36 50.5857H20C14.4667 50.5857 9.75067 48.6262 5.852 44.7072C1.95067 40.7909 0 36.0535 0 30.4951C0 25.8743 1.4 21.7557 4.2 18.1394C7 14.5231 10.6 12.1458 15 11.0073L22.4 18.4408H20C16.6667 18.4408 13.8333 19.6127 11.5 21.9566C9.16667 24.3005 8 27.1467 8 30.4951C8 33.8436 9.16667 36.6897 11.5 39.0336C13.8333 41.3775 16.6667 42.5495 20 42.5495H36V50.5857ZM24 34.5132V26.477H30.5L38.4 34.5132H24Z"
              fill="#0900BC"
            />
          </svg>
        ),
      sortable: true,
    },
    {
      id: "price",
      name: "Project Price",
      selector: (result) => result.price,
      sortable: true,
    },
    // {
    //   id: "solutions",
    //   name: "Category",
    //   selector: (result) => result.solutions,
    //   sortable: true,
    // },
  ]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const userToken = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getProjectTableData());
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  }, []);

  const projectTableData = projectList;
  const result = transformProjectTableDataResponse(projectTableData);
  console.log(projectTableData, "Project Table Data @@");
  console.log(result, "transformProjectTableDataResponse@@");
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#0900bc",
        color: "white",
        fontSize: "14px",
        width: "100%",
      },
    },
    cells: {
      style: {
        fontSize: "13.5px",
        height: "auto",
        //   padding: '0px 8px 0px 20px',
        //  width:'150px'
        // if we want show the full detail of topic need un comment the above line
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        // textTransform:"capitalize",
      },
    },
  };

  const onClickApply = () => {
    let select = allColumns.filter((value) => {
      return isSelected.includes(value.id);
    });
    setSelectedHeaders(select);
    dispatch(updateSelectProjectTableCheckbox(isSelected));
  };

  const onRowClicked = (row) => {
    navigate("/projects-details");
    showTableUpdate(false);

    dispatch(updateSelectProjectId(row.project_id));
  };
  const onClickCol = () => {
    showCheckBox(!checkBox);
  };
  useEffect(() => {
    let select = allColumns.filter((value) => {
      return (
        selectedProjectTableCheckbox &&
        selectedProjectTableCheckbox.includes(value.id)
      );
    });
    setSelectedHeaders(select);
  }, []);
  const allColumns = [
    {
      id: "Date",
      name: "Date",
      selector: (result) => moment(result.created_at).format("DD-MM-YYYY"),
      // sortable: true,
    },
    {
      id: "Project Name",
      name: "Project Name",
      selector: (result) => result.project_name,
      sortable: true,
    },
    {
      id: "Account Name",
      name: "Account Name",
      selector: (result) => result.company_name,
      sortable: true,
    },
    // {
    //   id: "Project Description",
    //   width:"12rem",
    //   name: "Project Description",
    //   selector: (result) => (parse(result.project_description.slice(0,20))),
    //   sortable: true,
    // },
    {
      id: "Project Category",
      name: "Project Category",
      selector: (result) => result.solution_name,
      sortable: true,
    },
    {
      id: "Project Due Date",
      name: "Project Due Date",
      selector: (result) => result.project_expiry_datetime,
      sortable: true,
    },
    {
      id: "Location",
      name: "Location",
      selector: (result) => result.country,
      sortable: true,
    },
    {
      id: "Attachment",
      name: "Attachment",
      selector: (result) =>
        result.attachment_name ? (
          result.attachment_name
        ) : (
          <svg
            width="30"
            height="13"
            viewBox="0 0 80 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M69 48.3757L63 42.1476C65.6667 41.411 67.8333 39.9873 69.5 37.8764C71.1667 35.7682 72 33.3078 72 30.4951C72 27.1467 70.8333 24.3005 68.5 21.9566C66.1667 19.6127 63.3333 18.4408 60 18.4408H44V10.4046H60C65.5333 10.4046 70.2507 12.3627 74.152 16.2791C78.0507 20.1981 80 24.9367 80 30.4951C80 34.3123 79.0173 37.8282 77.052 41.0427C75.084 44.2572 72.4 46.7015 69 48.3757ZM55.4 34.5132L47.4 26.477H56V34.5132H55.4ZM60.2956 62.1827L4.0729 5.62536L9.6729 0L65.8956 56.5573L60.2956 62.1827ZM36 50.5857H20C14.4667 50.5857 9.75067 48.6262 5.852 44.7072C1.95067 40.7909 0 36.0535 0 30.4951C0 25.8743 1.4 21.7557 4.2 18.1394C7 14.5231 10.6 12.1458 15 11.0073L22.4 18.4408H20C16.6667 18.4408 13.8333 19.6127 11.5 21.9566C9.16667 24.3005 8 27.1467 8 30.4951C8 33.8436 9.16667 36.6897 11.5 39.0336C13.8333 41.3775 16.6667 42.5495 20 42.5495H36V50.5857ZM24 34.5132V26.477H30.5L38.4 34.5132H24Z"
              fill="#0900BC"
            />
          </svg>
        ),
      sortable: true,
    },
    {
      id: "Project Price",
      name: "Project Price",
      selector: (result) => result.price,
      sortable: true,
    },
  ];
  const results =
    result &&
    result.filter(
      (item) =>
        (item.project_name &&
          item.project_name.toLowerCase().includes(filterText.toLowerCase())) ||
        item.solution_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.project_expiry_datetime
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        item.country.toLowerCase().includes(filterText.toLowerCase()) ||
        item.project_description
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        item.price.toLowerCase().includes(filterText.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.company_name.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    // const col = allColumns.filter((value) => {
    // return console.log(value, "allColumns");
    // isSetColumns(value)
    //   });

    //   console.log(col, "filtered columns");
    //   console.log(isColumns, "state columns");

    return (
      <div className="table-filter">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder={"Search"}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="pt-3">
      {isShowTable && (
        <>
          <div
            className={checkBox ? "custom-columns" : "custom-columns-before"}
          >
            <img
              className="columns-icon"
              onClick={onClickCol}
              src={ColumnsIcon}
              alt=""
            />{" "}
            &nbsp;
            {checkBox && (
              <>
                <div className="dropdown-multi">
                  <DropdownMultiselect
                    handleOnChange={(value) => {
                      setIsSelected(value);
                    }}
                    options={[
                      "Customer Name",
                      "Account Name",
                      "Project Name",
                      "Project Description",
                      "Project Category",
                      "Project Due Date",
                      "Location",
                      "Attachment",
                      "Project Price",
                      "Date",
                    ]}
                    selected={selectedProjectTableCheckbox}
                    placeholder="select"
                  />
                </div>
                <button className="apply-button" onClick={onClickApply}>
                  Apply
                </button>
              </>
            )}
          </div>
        </>
      )}
      {loading && (
        <center>
          <div
            className="spinner-border text-primary loader"
            role="status"
          ></div>
        </center>
      )}
      {isShowTable && (
        <>
          {/* <h1 className="vendor-follow-header">Vendor</h1> */}
          <DataTable
            columns={selectedHeaders}
            data={results}
            // expandableRows
            customStyles={customStyles}
            //  expandableRowsComponent={ExpandedComponent}
            //  expandableRows
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onRowClicked={onRowClicked}
            pagination
          />
        </>
      )}
    </div>
  );
};

export default Table;
