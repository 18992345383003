import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const myReducer = createSlice({
    name: "conqtAdmin",
    initialState: {
        loading: false,
        uploadfile: {}
    },
    reducers: {
        updateUploadedFile: (state, action) => {
            state.uploadfile = action.payload ;
            
        },
        clearUplaodedFile: (state, action) => {
            state.uploadfile = {};
            console.log("running")
        }
    },
    extraReducers: {
    },
})

export const {
    updateUploadedFile,
    clearUplaodedFile
} = myReducer.actions;

export default myReducer.reducer;