import React, { useEffect, useState } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  // getSingleProposalDetails,
  // getProposalTableData,
  getSingleMilestoneDetails,
  //   updateSelectCustomerId,
} from "../../Store/Reducer/ProposalReducer";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { CheckingValue } from "../../utils/util";

const MilestoneSingleDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    // getValues,
    // handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});
  const {
    // proposalList,
    loading,
    proposalDetails,
    // milestoneList,
    milestoneDetails,
    // selectedProposalId,
    // selectedMilestoneId,
    milestoneSinglePageId,
  } = useSelector((state) => state.proposal);
  useEffect(() => {
    dispatch(getSingleMilestoneDetails(milestoneSinglePageId));
    // dispatch(getSingleProposalDetails(selectedMilestoneId));
  }, [milestoneSinglePageId]);
  useEffect(() => {
    if (milestoneDetails) {
      Object.entries(milestoneDetails).forEach(([name, value]) => {
        setValue(name, value);
      });
    }
  }, []);
  const onClickMilestoneReceipt = () => {
    navigate("/milestone-receipt-details");
  };
  return (
    <div>
      <DashBoardheader />
      <SideNavBar />
      <div className="vendor-follow">
        <div className="vendor-follow-main-div">
          <h1 className="vendor-follow-header pb-3">
            Milestone No.{milestoneDetails && milestoneDetails.count}
          </h1>
          <h1 className="vendor-follow-header pb-3">
            {milestoneDetails && milestoneDetails.milestone_description}
          </h1>
          {loading && (
            <center>
              <div
                className="spinner-border text-primary loader"
                role="status"
              ></div>
            </center>
          )}
          {/* <h1 className="proposal-description">
            {milestoneDetails &&
              milestoneDetails.milestone_description &&
              parse(milestoneDetails.milestone_description)}
          </h1> */}
          <div className="proposal-input-label-main-div">
            <div className="d-flex col-md-12 pb-3">
              <div className="col-md-2">
                <p className="proposal-label">Vendor</p>
              </div>
              <div className="col-md-3">
                <div className="proposal-input">
                  {milestoneDetails &&
                    CheckingValue(milestoneDetails.assignee_name)}
                </div>
                {/* <input
                  disabled
                  className="proposal-input"
                  type="text"
                  {...register("assignee_name", {
                    required: true,
                    maxLength: 30,
                  })}
                ></input> */}
                {errors.person_name && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>

              <div className="col-md-3 px-5">
                <div className="proposal-right-label">Milestone Status</div>
              </div>
              <div className="col-md-4">
                {/* <input
                  disabled
                  className="proposal-right-input"
                  {...register("city", {
                    required: true,
                  })}
                ></input> */}
                <div className="proposal-right-input">
                  {milestoneDetails && proposalDetails.proposal_status === 1
                    ? milestoneDetails.milestone_start_close_status === 0
                      ? "Not Started"
                      : milestoneDetails.milestone_start_close_status === 1
                      ? "In Progress"
                      : "Completed"
                    : proposalDetails.proposal_status === 0
                    ? "Not Started"
                    : proposalDetails.proposal_status === 2
                    ? "Rejected"
                    : "Not Available"}
                </div>
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="col-md-2">
                <p className="proposal-label">Milestone Amount</p>
              </div>
              <div className="col-md-3">
                {/* <div className="proposal-input">
                  {milestoneDetails &&  CheckingValue(milestoneDetails.assignee_name)}
                </div> */}
                <div className="proposal-input">
                  {milestoneDetails && milestoneDetails.milestone_amount
                    ? milestoneDetails.milestone_amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " " +
                      milestoneDetails.vendor_currency
                    : "Not Available"}
                </div>
                {errors.contact_no && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
              <div className="col-md-3  px-5">
                <div className="proposal-right-label ">
                  Milestone Completed
                </div>
              </div>
              <div className="col-md-4">
                <div className="proposal-right-input">
                  {milestoneDetails &&
                  milestoneDetails.vendor_status_update_time
                    ? milestoneDetails.vendor_status_update_time.split("T")[0]
                    : "Not Available"}
                </div>
              </div>
            </div>

            <div className="d-flex col-md-12 py-3">
              <div className="col-md-2">
                <p className="proposal-label">Payment Status</p>
              </div>
              <div className="col-md-3">
                <div className="proposal-input">
                  {milestoneDetails &&
                  milestoneDetails.cont_to_v_transaction_status === 2
                    ? "Payment In Progress"
                    : milestoneDetails.cont_to_v_transaction_status === 1
                    ? "Success"
                    : "Not Started"}
                  {/* <a
                    href={proposalDetails && proposalDetails.attachment_url}
                    target="_blank"
                  >
                    {proposalDetails && proposalDetails.attachment_url
                      ? "Click Here"
                      : "Not Available"}
                  </a> */}
                </div>
              </div>
              {/* <div className="col-md-2">
                <p className="proposal-right-label px-5">Milestone Amount</p>
              </div>
              <div className="col-md-4">
                <div className="proposal-right-input">
                  {milestoneDetails && milestoneDetails.milestone_amount
                    ? milestoneDetails.milestone_amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " " +
                      milestoneDetails.vendor_currency
                    : "Not Available"}
                </div>
              </div> */}
            </div>
            {/* <div className="d-flex col-md-12 pb-3">
              <div className="col-md-2">
                <p className="proposal-label">Payment Status</p>
              </div>
              <div className="col-md-3">
                <input
                  className="proposal-input"
                  type="text"
                  disabled
                  {...register("price", {
                    required: true,
                  })}
                ></input>
                {errors.position && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
            </div> */}
            <div className="proposal-long-input-div pb-3">
              <div className="col-md-2">
                <p className="proposal-label">Vendor Message</p>
              </div>
              <div className="">
                <div className="proposal-long-input">
                  {" "}
                  <h5 className="proposal-input-text">
                    {milestoneDetails && milestoneDetails.vendor_remark
                      ? parse(milestoneDetails.vendor_remark)
                      : "Not Available"}
                  </h5>
                </div>
              </div>
            </div>
            <div className="proposal-long-input-div pb-2">
              <div className="col-md-2">
                <p className="proposal-label">Customer Feedback</p>
              </div>
              <div className="">
                <div className="proposal-long-input">
                  {" "}
                  <h5 className="proposal-input-text">
                    {milestoneDetails && milestoneDetails.customer_remark
                      ? parse(milestoneDetails.customer_remark)
                      : "Not Available"}
                  </h5>
                </div>
              </div>
            </div>
            <h1 className="partner-header">Milestone Payment Status</h1>
            <div className="d-flex col-md-12 pb-3">
              <div className="col-md-2">
                <p className="proposal-label">Invoice Date</p>
              </div>

              <div className="invoice-input-button">
                <div className="proposal-input">
                  {milestoneDetails.vendor_status_update_time
                    ? new Date(
                        milestoneDetails.vendor_status_update_time
                      ).toLocaleDateString("en-GB")
                    : "Not Available"}
                </div>
                {/* <input
                  disabled
                  className="proposal-input"
                  type="text"
                  {...register("project_expiry_datetime", {
                    required: true,
                  })}
                ></input> */}
                <a
                  className={
                    milestoneDetails.invoice_url
                      ? "milestone-receipt-view"
                      : " "
                  }
                  href={milestoneDetails && milestoneDetails.invoice_url}
                  target="_blank"
                >
                  {milestoneDetails && milestoneDetails.invoice_url
                    ? "View"
                    : ""}
                </a>
              </div>
            </div>

            <div className="d-flex  pb-2">
              <div className="col-md-2">
                <p className="proposal-label">Receipt Date</p>
              </div>
              <div className="invoice-input-button">
                <div className="proposal-input">
                  {milestoneDetails.conqt_to_v_receipt_date
                    ? milestoneDetails.conqt_to_v_receipt_date.split(" ")[0]
                    : "Not Available"}
                </div>
                <div
                  className="milestone-receipt-view"
                  onClick={onClickMilestoneReceipt}
                >
                  {" "}
                  View
                </div>
              </div>
            </div>
            <div className="d-flex  pb-5">
              <div className="col-md-2">
                <p className="proposal-label">Payment ID</p>
              </div>
              <div className="invoice-input-button">
                <div className="proposal-input">
                  {milestoneDetails.cont_to_v_transactionn_id
                    ? milestoneDetails.cont_to_v_transactionn_id
                    : "Not Available"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MilestoneSingleDetails;
