import React, { useState } from 'react'
import './breadcrumbscc.css'
import { useLocation, useNavigate } from 'react-router-dom'
import ActionsDropDown from '../../images/actionsDrop.svg'
import BreadCrumbs from '../BreadCrumbs'
import EditIcon from '../../images/editIcon.svg'
import DeleteIcon from '../../images/delIcon.svg'
import DuplicateIcon from '../../images/DuplicateIcon.svg'
import MSIcon from '../../images/MSIcon.svg'
import PrintIcon from '../../images/printIcon.svg'
import SDIcon from '../../images/SDIcon.svg'

const BreadCrumbsCC = () => {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [showOptions, setShowOptions] = useState(false)

    const handleClickCreateContact = () => {
        navigate('/create-contacts')
    }

    const handleClickMoreInformation = () => {
        navigate('/more-information')
    }

    const handleClickOther = () => {
        navigate('/other-create-contact')
    }

    // const handleClickActions = () => {
    //     navigate('/action-create-contact')
    // }

    const toggleOptions = () => {
        setShowOptions(!showOptions)
    }
    return (
        <>

            <nav>
                <div className='d-flex' style={{ listStyle: "none" }}>
                    <li>
                        <p className={`breadcrumbscc-name  ${pathname === '/create-contacts' ? 'breadcrumbscc-lin-active' : ''}`}
                            onClick={handleClickCreateContact}
                            style={{ cursor: "pointer" }}
                        >
                            Overview
                        </p>
                    </li>
                    {/* <li className='px-2'>
                        <p className={`breadcrumbscc-name px-3 ${pathname === '/more-information' ? 'breadcrumbscc-lin-active' : ''}`}
                            onClick={handleClickMoreInformation}
                            style={{ cursor: "pointer" }}
                        >
                            More Information
                        </p>
                    </li> */}
                    <li className='px-2'>
                        <p className={`breadcrumbscc-name ${pathname === '/other-create-contact' ? 'breadcrumbscc-lin-active' : ''}`}
                            onClick={handleClickOther}
                            style={{ cursor: "pointer" }}
                        >
                            Other
                        </p>
                    </li>
                    <li className=''>
                        <p className={`breadcrumbscc-name px-3 ${pathname === '/action-create-contact' ? 'breadcrumbscc-lin-active' : ''}`}
                            // onClick={handleClickActions}
                            onClick={toggleOptions}
                            style={{ cursor: "pointer" }}
                        >
                            {/* <select class="form-select" style={{ border: "none" }} className={`breadcrumbscc-name px-3${pathname === '/action-create-contact' ? 'breadcrumbscc-lin-active' : ''}`}>
                            <option selected>Actions</option>
                            <option value="1" >
                                Edit
                            </option>
                            <option value="2">Duplicate</option>
                            <option value="3">Delete</option>
                            <option value="4">Find Duplicate</option>
                            <option value="5">Manage Subscription</option>
                            <option value="6">Print as PDF</option>
                            <option value="7">View Channel Log</option>
                        </select> */}
                            Action <span><img src={ActionsDropDown} alt="" /></span>
                        </p>
                        {showOptions && (
                            <div className='createCC-overview px-3 py-3'>
                                <div className='d-flex'> <span><img src={EditIcon} alt="" /></span> <p className="action-options px-2 ">Edit</p></div>
                                <div className='d-flex py-2'> <span><img src={DuplicateIcon} alt="" /></span><p className="action-options px-2">Duplicate</p></div>
                                <div className='d-flex'> <span><img src={DeleteIcon} alt="" /></span> <p className="action-options px-2">Delete</p></div>
                                <div className='d-flex py-2'> <span><img src={SDIcon} alt="" /></span><p className="action-options px-2">Find Duplicate</p></div>
                                <div className='d-flex'> <span><img src={MSIcon} alt="" /></span><p className="action-options px-2">Manage Subscription</p></div>
                                <div className='d-flex py-2'> <span><img src={PrintIcon} alt="" /></span><p className="action-options px-2">Print as PDF</p></div>
                                <div>  <p className="action-options px-4">View Channel Log</p></div>

                            </div>
                        )}
                    </li>

                </div>


            </nav>
        </>
    )
}

export default BreadCrumbsCC