import React from 'react'
import './tccbreadcrumbs.css';
import { useLocation, useNavigate } from 'react-router-dom'


const TargetCustomerContactBreadCrumbs = () => {

    const { pathname } = useLocation()
    return (
        <nav>
            <div className='d-flex' style={{ listStyle: "none" }}>
                <li>
                    <p className={`breadcrumbs-name ${pathname === '/target-add-customer-contact' ? 'breadcrumbs-lin-active' : ''}`}
                        style={{ cursor: "pointer" }}
                    >
                        Add New Customer <span className='px-1'>{'>'}</span>
                    </p>
                </li>
                <li className='px-2'>
                    <p className={`breadcrumbs-name ${pathname === '/target-customer-contact-overview' ? 'breadcrumbs-lin-active' : ''}`}
                        style={{ cursor: "pointer" }}
                    >
                        Overview <span className='px-1'>{'>'}</span>
                    </p>
                </li>
            </div>

        </nav>
    )
}

export default TargetCustomerContactBreadCrumbs