import React, { useState, useEffect } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import { useNavigate } from "react-router-dom";
import Conqt from "../../images/Conqt_logo.png";
import { CheckingValue } from "../../utils/util";
import {
  getSingleProposalDetails,
  getProposalTableData,
  getSingleMilestoneDetails,
  //   updateSelectCustomerId,
} from "../../Store/Reducer/ProposalReducer";
import { useDispatch, useSelector } from "react-redux";
import Pdf from "react-to-pdf";

const MilestoneReceipt = () => {
  const { milestoneDetails } = useSelector((state) => state.proposal);
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [4, 2],
  };
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
     
      <div className="vendor-follow">
        <div className="vendor-follow-main-div">
          <div className="receipt-container" ref={ref}>
            <div className="cs-invoice cs-style1">
              <div className="cs-invoice_in" id="download_section">
                <div className="cs-invoice_head cs-type1 cs-mb25">
                  <div className="cs-invoice_left">
                    <div className="cs-logo cs-mb5">
                      <img src={Conqt} alt="Logo" style={{ width: 150 }} />
                    </div>
                    <p className="receipt-text cs-mb0">
                      67 T1 01 SARAFIPURA TIWARIPURA <br />
                      Mahoba, 210427 - India
                    </p>
                    <p className="receipt-text cs-mb0">
                      Phone: (+91) 941-565-0637
                    </p>
                    <p className="receipt-text cs-mb0">
                      LUT NO: AD090422023238A
                    </p>
                    <p className="receipt-text cs-mb0">IEC CODE: AAECF8340F</p>
                  </div>
                  <div className="cs-invoice_right cs-text_right">
                    <h2 style={{ color: "#9932cc", fontWeight: 500 }}>
                      Payment Successful
                    </h2>

                    <p className="cs-invoice_date cs-primary_color cs-m0">
                      <b className="cs-primary_color">
                        Invoice #:
                        {milestoneDetails && milestoneDetails.milestone_id}
                      </b>
                    </p>
                  </div>
                </div>

                <div className="cs-mb50">
                  <h2 style={{ color: "grey", lineHeight: "55px" }}>
                    Receipt ID:
                    {milestoneDetails &&
                      CheckingValue(milestoneDetails.conqt_to_v_receipt_no)}
                  </h2>
                  <b
                    className="cs-mb0"
                    style={{ color: "rgba(5, 35, 140, 0.81)" }}
                  >
                    This is a receipt for your transaction on ConQt.com
                  </b>
                </div>

                <div className="cs-mb30">
                  <tr>
                    <th style={{ width: "150px", textAlign: "left" }}>
                      <h3
                        style={{
                          color: "grey",
                          fontWeight: 700,
                          fontSize: "1.30em",
                        }}
                        className="cs-primary_color b"
                      >
                        Amount Paid
                      </h3>
                    </th>
                    <th
                      style={{
                        fontSize: "25px",
                        color: "black",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {milestoneDetails &&
                      milestoneDetails.cont_to_v_txn_amount?
                        CheckingValue(milestoneDetails.cont_to_v_txn_amount+
                          " " +
                          milestoneDetails.vendor_currency):"Not Available"}
                    </th>
                  </tr>
                  {/* <tr>
                    <td></td>
                    <td style={{ fontWeight: "70px", marginLeft: "150px" }}>
                      {numberToEnglish(1000).toUpperCase()}{" "}
                      {user.user_info.currency.toUpperCase()}
                    </td>
                  </tr> */}
                </div>
                <ul className="cs-grid_row cs-col_3 cs-mb10">
                  <li className="cs-mb30">
                    <h3
                      style={{
                        color: "grey",
                        fontWeight: 700,
                        fontSize: "1.30em",
                      }}
                      className="cs-primary_color b"
                    >
                      Issued To
                    </h3>
                    <h4 style={{ color: "grey" }}>
                      {milestoneDetails && milestoneDetails.assignee_name}
                    </h4>
                  </li>
                  <li className="cs-mb30">
                    <h3
                      style={{
                        color: "grey",
                        fontWeight: 700,
                        fontSize: "1.30em",
                      }}
                      className="cs-primary_color b"
                    >
                      Paid On
                    </h3>
                    <h4 style={{ color: "grey" }}>
                      {milestoneDetails &&
                        CheckingValue(milestoneDetails.conqt_to_v_receipt_date)}
                    </h4>
                  </li>
                </ul>

                <ul className="cs-grid_row cs-col_3 cs-mb10">
                  <li className="cs-mb30">
                    <h3
                      style={{ color: "grey" }}
                      className="cs-primary_color b"
                    >
                      Transaction ID
                    </h3>
                    <h4 style={{ color: "grey" }}>
                      {milestoneDetails &&
                        CheckingValue(
                          milestoneDetails.conq_to_v_transaction_id
                        )}
                    </h4>
                  </li>
                </ul>

                <div className="container invoicetable">
                  <table className="rwd-table">
                    <tbody>
                      <tr>
                        <th>Sr.No.</th>
                        <th>Project Name</th>
                        <th>Milestone</th>
                        <th>Amount</th>
                        {/* <th>Discount applied</th> */}
                      </tr>

                      <tr>
                        <td data-th="Sr.No.">1</td>
                        <td data-th="Milestone Description">
                          {milestoneDetails &&
                            milestoneDetails.milestone_description}
                        </td>
                        <td data-th="Milestone" className="">
                          {milestoneDetails && milestoneDetails.count}
                        </td>
                        <td data-th="Amount" className="">
                          {milestoneDetails &&
                          milestoneDetails.cont_to_v_txn_amount
                            ? milestoneDetails.cont_to_v_txn_amount +
                              " " +
                              milestoneDetails.vendor_currency
                            : "Not Available"}
                        </td>
                        {/* <td data-th="Discount applied" className="text-center">
                          <span> test</span>
                        </td> */}
                      </tr>

                      <tr>
                        <td>Sub Total</td>
                        {/* <td></td>
                        <td></td>
                        <td data-th="Days" className="text-center">
                          test
                        </td>
                        <td data-th="Project Status" className="text-center">
                          <span> test</span>
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="cs-table cs-style2">
                  <div className="cs-table_responsive">
                    <tr>
                      <td>
                        <tr className="cs-table_baseline">
                          <td className="">
                            <br />
                            <div
                              style={{
                                color: "rgba(5, 35, 140, 0.81)",
                                fontWeight: 500,
                              }}
                            >
                              If you have any questions concerning this receipt,
                              contact ConQt Support Team at support@conqt.com{" "}
                            </div>
                            <br />
                            <b style={{ color: "rgba(5, 35, 140, 0.81)" }}>
                              Thank you for your business!
                            </b>
                          </td>
                          <div className="footeramt">
                            <li>
                              <td
                                style={{ color: "#09eea7", fontWeight: "bold" }}
                                className="cs-width_5 cs-primary_color cs-bold cs-f16 cs-text_right"
                              >
                                Amount Paid
                              </td>
                              <td
                                style={{ color: "#9932cc", fontWeight: "bold" }}
                                className="cs-width_5 cs-text_left cs-primary_color cs-bold cs-primary_color cs-f16"
                              >
                                {milestoneDetails &&
                                milestoneDetails.cont_to_v_txn_amount
                                  ? milestoneDetails.cont_to_v_txn_amount +
                                    " " +
                                    milestoneDetails.vendor_currency
                                  : "Not Available"}
                              </td>
                            </li>
                          </div>
                        </tr>
                      </td>
                    </tr>
                  </div>
                </div>
              </div>
              <div className="cs-invoice_btns cs-hide_print">
                {/* <a
                  // href="javascript:window.print()"
                  className="cs-invoice_btn cs-color1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24"
                      fill="none"
                      stroke="currentColor"
                      stroke-linejoin="round"
                      stroke-width="32"
                    />
                    <rect
                      x="128"
                      y="240"
                      width="256"
                      height="208"
                      rx="24.32"
                      ry="24.32"
                      fill="none"
                      stroke="currentColor"
                      stroke-linejoin="round"
                      stroke-width="32"
                    />
                    <path
                      d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24"
                      fill="none"
                      stroke="currentColor"
                      stroke-linejoin="round"
                      stroke-width="32"
                    />
                    <circle cx="392" cy="184" r="24" />
                  </svg>
                  <span>Print</span>
                </a> */}
                {/* <Link to={`/ViewCustomerMilestone/${p_id}`}>
                  <button
                    id="download_btn"
                    className="cs-invoice_btn cs-color2"
                  >
                    Back
                  </button>
                </Link> */}
                {/* <button
                    id="download_btn"
                    className="receipt-back-button cs-invoice_btn cs-color2"
                  >
                    Back
                  </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pdf targetRef={ref} filename="ConQt Invoice" x={1.5} y={1} scale={0.9}>
        {({ toPdf }) => (
          <button className="invoice-print-btn" onClick={toPdf}>
             <a
                  // href="javascript:window.print()"
                  className="cs-invoice_btn cs-color1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24"
                      fill="none"
                      stroke="currentColor"
                      stroke-linejoin="round"
                      stroke-width="32"
                    />
                    <rect
                      x="128"
                      y="240"
                      width="256"
                      height="208"
                      rx="24.32"
                      ry="24.32"
                      fill="none"
                      stroke="currentColor"
                      stroke-linejoin="round"
                      stroke-width="32"
                    />
                    <path
                      d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24"
                      fill="none"
                      stroke="currentColor"
                      stroke-linejoin="round"
                      stroke-width="32"
                    />
                    <circle cx="392" cy="184" r="24" />
                  </svg>
                 
                </a>
           Print
          </button>
        )}
      </Pdf>
   
    </>
  );
};

export default MilestoneReceipt;
