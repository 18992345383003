import React, { useEffect, useState } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import cross from "../../../../images/cross.svg";
// import "./popupDep.css"
import { useForm } from "react-hook-form";
import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from "react-drag-drop-files";
import { getCategoryListTableData } from '../../../../Store/Reducer/CategoryList';
import { dialogActionsClasses } from '@mui/material';


const AddCat = ({ open, handleClose }) => {

        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { register, handleSubmit, getValues, setValue, setError, formState: { errors } } = useForm();


        const categoryList = useSelector((state) => state.categoryList);
        console.log("categoryList", categoryList)



        const [close, setClose] = useState(handleClose);
        const [show, setShow] = useState(false);
        const [data, setData] = useState([]);
        const [parentCategory, setParentCategory] = useState(false);
        const [selectCategory, setSelectCategory] = useState("")
        const [isChecked, setIsChecked] = useState(false);
        const [selectedFile, setSelectedFile] = useState(null);
        const [file, setFile] = useState(null);
        const [formData, setFormData] = useState(null);

        const handleAddClose = () => {
                setShow(false)
        }

        const handleChangeCheckbox = (e) => {
                setIsChecked(e.target.checked)
                console.log("isChecked", isChecked)
                // setParentCategory(e.target.checked)

        };

        const handleChange = (e) => {
                const cat = categoryList.categoryList.find((item) => item.category_id == e.target.value)
                console.log("cat", cat)
                setSelectCategory(cat.category_id)
        }



        const handleFileInputChange = (event) => {
                setSelectedFile(event.target.files[0]);
                console.log("selectedFile ==>", (event.target.files[0]))
        };
        console.log("selectedFile", selectedFile);

        const fileTypes = ["JPG", "PNG", "GIF"];

        const handleFileChange = (file) => {
                setFile(file);
        };
        console.log("cat image", file);
        console.log("cat image", file?.name);

        // const onSubmit = (data) => {
        //         console.log("current dep data", data);
        //         setData(data)
        //         setShow(true)
        // }

        const category_type = isChecked ? "2" : "1";
        console.log("category_type", category_type);

        useEffect(() => {
          dispatch(getCategoryListTableData());
        }, []);

        const onSubmit = async (data) => {
          setData(data);

          const formData = new FormData();

          formData.append("category_name", data.category_name);
          formData.append("category_type", category_type);
          formData.append("parent_id", selectCategory);
          formData.append("image", file);
          formData.append("description", data.description);

          console.log("formData", formData);

          axios
            .post(`${BaseUrl}product/Add-Category`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((res) => {
              console.log("cat res ==>", res);
              if (res.data.status === 200) {
                // dispatch(getCategoryListTableData())
                setClose(handleClose(false));
                // navigate("/main-product")
                // window.location.reload();
              } else if (res.data.status === 401) {
                toast.error(res.data.message);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        };

        return (
          <>
            {/* <AddDepartment open={show} handleAddClose={handleAddClose} name={"Department"}
                                data={data} handleClose={handleClose}
                        /> */}
            <Modal open={open} handleClose={handleClose}>
              <div className="d-flex align-items-center justify-content-end">
                <img
                  src={cross}
                  alt=""
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <Modal.Body>
                <h3 className="top-header-dep">New Category</h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      style={{ width: "15px", height: "15px" }}
                      onChange={handleChangeCheckbox}
                      checked={isChecked}
                      required
                    />
                    <label htmlFor="" className="dep-label py-3 px-2">
                      {" "}
                      Category have parent Category?
                      <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                  </div>
                  {!isChecked && (
                    <p className="dep-error">This field is Required</p>
                  )}
                  {isChecked && (
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="dep-label py-1">
                        Parent Category
                      </label>
                      <select
                        name=""
                        id=""
                        onChange={handleChange}
                        className="NewCategoryInputs"
                      >
                        <option value="" hidden>
                          Select Parent Category
                        </option>
                        {categoryList?.categoryList.map((item) => {
                          return (
                            <option value={item.category_id}>
                              {item.category_name}
                            </option>
                          );
                        })}
                      </select>
                      <p className="add-newCategory-hint-Text">
                        This is a hint text to help users.
                      </p>
                    </div>
                  )}
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column py-3">
                      <label htmlFor="" className="dep-label py-1">
                        Name
                      </label>
                      <input
                        type="text"
                        className="dep-input"
                        {...register("category_name", {
                          required: true,
                          maxLength: 20,
                        })}
                      />
                      {errors.category_name && (
                        <p className="dep-error">This field is required</p>
                      )}
                    </div>
                    <label htmlFor="" className="dep-label py-1">
                      Description
                    </label>
                    <input
                      type="text"
                      className="dep-input"
                      {...register("description", {
                        required: true,
                        maxLength: 250,
                      })}
                    />
                    {errors.description && (
                      <p className="dep-error">This field is required</p>
                    )}
                  </div>
                  <div className="d-flex flex-column py-3">
                    <label htmlFor="" className="dep-label py-1">
                      Upload Image
                    </label>
                    {/* <input type="file" className='dep-i'
                                                                onChange={handleFileInputChange}
                                                                {...register("image", { required: true })}
                                                        /> */}
                    <FileUploader
                      handleChange={handleFileChange}
                      name="file"
                      types={fileTypes}
                      children={
                        <div className="file-upload-btn">
                          <button className="upload-cat-image">
                            Select Image
                          </button>
                        </div>
                      }
                    />
                     <p className='upload-image-name'>{file ? `File name: ${ file && file?.name}` : "no files uploaded yet"}</p>
                  </div>
                       
                   
                    <button className="dep-add-btn mt-5">
                      <p className="dep-add-btn-name">Add</p>
                    </button>
                  
                </form>
              </Modal.Body>
            </Modal>
          </>
        );
}

export default AddCat