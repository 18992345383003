import React, { useState, useEffect } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import { useNavigate } from "react-router-dom";
import './createcontact.css'
import BreadCrumbsCC from "../../components/BreadcrumbsCC";
import AvtarIcon from '../../images/AvtarIcon.svg'

const CreateContact = () => {


  return (
    <>
      <DashBoardheader />
      <SideNavBar />

      <div className="main-body-container">

        <div className="container mt-5 d-flex align-items-center">
          <img src={AvtarIcon} alt="" /> <span className="create-contact-top-name px-2">Amitabh</span>
        </div>
        <div className="container breadcrumbs-cc">
          <BreadCrumbsCC />
        </div>
        <div className="container ">
          <div className="cc-overview px-4 py-5">
            <div className="d-flex col-md-12 pb-3">
              <div className="col-md-2">
                <p className="input-names-cc-overview">First Name </p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">Amitabh</p>
              </div>
              <div className="col-md-2 px-5">
                <div className="input-names-cc-overview">LastName </div>
              </div>
              <div className="col-md-4">
                <p className="label-input-cc-overview">Mishra</p>
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Account Name </p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">Amitabh</p>
              </div>
              <div className="col-md-2">
                <div className="input-names-cc-overview px-5">Position </div>
              </div>
              <div className="col-md-4">
                <p className="label-input-cc-overview">Sr.Admin</p>
              </div>
            </div>

            <div className="d-flex col-md-12 py-3">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Department</p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">Developer</p>
              </div>
              <div className="col-md-2">
                <p className="input-names-cc-overview px-5">Email</p>
              </div>
              <div className="col-md-4">
                <p className="label-input-cc-overview">amitabh@gmail.com</p>
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Mobile</p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">+91 987654321</p>
              </div>
              <div className="col-md-2">
                <div className="input-names-cc-overview px-5">OfficePhone</div>
              </div>
              <div className="col-md-4">
                <p className="label-input-cc-overview">+66 0987667890</p>
              </div>
            </div>

            <div className="d-flex col-md-12 py-3">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Street Address</p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">ABC Mumbai</p>
              </div>
              <div className="col-md-2">
                <p className="input-names-cc-overview px-5">City</p>
              </div>
              <div className="col-md-4">
                <p className="label-input-cc-overview"> Mumbai</p>
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="col-md-2">
                <p className="input-names-cc-overview">State/Province</p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">Maharashtra</p>
              </div>
              <div className="col-md-2">
                <div className="input-names-cc-overview px-5">Country</div>
              </div>
              <div className="col-md-4">
                <p className="label-input-cc-overview">India</p>
              </div>
            </div>
            <div className="d-flex col-md-12 py-3">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Description</p>
              </div>
              <div className="col-md-9">

                <div className="label-input-cc-overview"> Lorem Ipsum this is the way we can get the things . This is the opportunity. Be the one u want to be.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-5" style={{ marginTop: "100px" }}>
          <p className="more-information my-2">More Information</p>
          <div className="cc-overview px-4 py-5">
            <div className="d-flex col-md-12 pb-3">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Type</p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">Vendor</p>
              </div>
              <div className="col-md-2 px-5">
                <div className="input-names-cc-overview">Category </div>
              </div>
              <div className="col-md-4">
                <p className="label-input-cc-overview">CRM</p>
              </div>
            </div>
            <div className="d-flex col-md-12 pb-3">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Annual Revenue </p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">20,00,000.00 THB</p>
              </div>
              <div className="col-md-2 px-5">
                <div className="input-names-cc-overview">Employees </div>
              </div>
              <div className="col-md-4">
                <p className="label-input-cc-overview">Mishra</p>
              </div>
            </div>
            <div className="d-flex col-md-12 pb-3">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Member Of </p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">Fyoocher</p>
              </div>
            </div>
            <div className="d-flex col-md-12 pb-3">
              <div className="col-md-2">
                <p className="input-names-cc-overview">Campaign </p>
              </div>
              <div className="col-md-3">
                <p className="label-input-cc-overview">ERP</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default CreateContact;
