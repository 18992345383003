import React, { useState, useEffect } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import { useNavigate } from "react-router-dom";
import Table from "./table.jsx";


const Proposals= () => {
  const navigate = useNavigate();

  

  return (
    <>
      <DashBoardheader />
      <SideNavBar />
    
      <div className="vendor-follow">
        <div className="vendor-follow-main-div">
          <h1 className="vendor-follow-header"> Proposals</h1>
          <div className="containerr">
            <div className="row">
              <div className="col-12">
                <div className="card">
                
                <Table/>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Proposals;
