import React, { useEffect, useState } from "react";
import DashBoardheader from "../../../components/DashboardHeader";
import SideNavBar from "../../../components/Sidebar";
import "./DepartmentList.css"
import DepartmentListTable from "./DepartmentListTable";

const DepartmentList = () => {
  const [showHeader, setShowHeader] = useState(true);
  

  return (
    <>
      <DashBoardheader />
      <SideNavBar />

      <div className="vendor-follow">
        <div className="vendor-follow-main-div">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <DepartmentListTable
                    DelayClicked={() => {}}
                    isShowHeader={(val) => {
                      setShowHeader(val);
                    }}
                  />
                </div>

                <div style={{ margin: "0 0 2rem 0" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentList;
