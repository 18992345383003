import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getCustomerTableData = createAsyncThunk("customerList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}admin/Get-Admin-Customer-List`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result.data, "store api data");
      return result.data;
    })
    .catch((error) => console.log("error", error));
});
export const getSingleCustomerDetails = createAsyncThunk(
  "customerDetails",
  (id) => {
    const userToken = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    // console.log(id, "vendor id  in reducer")
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `${BaseUrl}admin/User-Details-By-Id?user_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        return result.data;
      })
      .catch((error) => console.log("error", error));
  }
);

export const myReducer = createSlice({
  name: "conqtAdmin",
  initialState: {
    loading: false,
    selectedCustomerId: "",
    customerList: [],
    customerDetails: {},
    selectedCustomerName: "",
    selectedCustomerTableCheckbox: [
      "Organization",
      "Customer",
      "Phone No",
      "Email",
      "Add Contact",
    ],
  },
  reducers: {
    updateSelectCustomerId: (state, action) => {
      // console.log("*****in the store", action);

      state.selectedCustomerId = action.payload;
    },
    updateSelectCustomerName: (state, action) => {
      state.selectedCustomerName = action.payload;
    },
    updateSelectCustomerTableCheckbox: (state, action) => {
      state.selectedCustomerTableCheckbox = action.payload;
    },
  },
  extraReducers: {
    [getCustomerTableData.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerList = payload;

      // console.log(payload,"payload from reducer")
    },
    [getSingleCustomerDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSingleCustomerDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerDetails = payload;

      // console.log(payload,"payload from vendor details reducer")
    },
  },
});

export const {
  updateSelectCustomerId,
  updateSelectCustomerName,
  updateSelectCustomerTableCheckbox,
} = myReducer.actions;

export default myReducer.reducer;
