import { Button, TextField, Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
// import { Link } from 'react-router-dom'
import "../components/Login/Login.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "./Api/api.js";
import axios from "axios";
import { toast } from "react-toastify";
import { AiFillEyeInvisible,AiFillEye } from 'react-icons/ai'

const Login = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [errorMessage, setErrorMessage] = useState("");
  const passwordIcon=watch("password")
    const [isVisible, setIsVisible] = useState(false)
    const [rememberMe, setRememberMe] = useState(false);



  const navigate = useNavigate();

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log("welcome Data", data);
    axios
      .post(
        // `https://dev-api.conqt.com/api/admin/Admin-Login`,
        `${BaseUrl}admin/Admin-Login`,
        data
      )
      .then((response) => {
        
        // console.log("Response: ", response);
        if (response.data.status === 200) {
          // console.log("success");
          toast("Welcome to Admin Panel");
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("user_info",JSON.stringify(response.data.data.user_info))

         //Rember me functionality
         if(rememberMe){
          // localStorage.setItem("user_name",data.user_name)
          // localStorage.setItem("password",data.password)
          document.cookie = `user_name=${data.user_name}; path=/`
          document.cookie = `password=${data.password}; path=/`

         }else {
         
          document.cookie = `user_name=; path=/`
          document.cookie = `password=; path=/`
        }
        
        setValue("user_name","");
        setValue("password","");
        setRememberMe(false);

          navigate("/admin-dashboard");
        } else if (response.data.status === 401) {
          // alert("Enter Valid Credentials")
          console.log("error");
          // toast("Network Error Occured");
          setErrorMessage({ message: errorMessage });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  // for getting cookie values
  // updating input fields for rember me functionality
  useEffect(() => {

    function getCookieValue(cookieName) {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(cookieName + '=')) {
          return cookie.substring(cookieName.length + 1);
        }
      }
      return null; // Cookie not found
    }
      const storedUsername = getCookieValue('user_name');
      const  storedPassword = getCookieValue('password');
      // const storedUsername= localStorage.getItem('user_name');
      //  const storedPassword = localStorage.getItem('password');
  
    if (storedUsername && storedPassword) {
    
      setValue("user_name",storedUsername);
      setValue("password",storedPassword);
      setRememberMe(true);
    }
  }, []);


 const token= localStorage.getItem("token")

  useEffect(()=>{
    if(token){
      navigate("/admin-dashboard")
    }
    else{
      navigate("/")
    }
  },[])

  const passwordRegex = /^(?=.*\d)(?=.*[a-z]).*[A-Z].{5,}$/;
  

  return (
    <div className="d-flex col-12">
      <div className="col-12 col-md-8 d-none d-sm-block imgCard">
        <img src="/Images/logo (2).svg" alt="" className="logo" />
        <Typography
          variant="h4"
          fontSize="12"
          sx={{
            paddingY: 2,
            fontWeight: "bold",
          }}
        >
          Hi, Welcome ConQt
        </Typography>
        <img src="/Images/image.png" alt="" className="image" />
      </div>
      <div className="col-12 col-md-4 col-sm-12 login">
        <Typography
          variant="h5"
          fontSize="15"
          sx={{
            paddingY: 2,
            fontWeight: "bold",
          }}
        >
          Sign in to ConQt 
        </Typography>

        <div className="conqt-sigin-form">
          <form
            className="d-flex flex-column"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              {...register("user_name", { required: true })}
              id="outlined-basic"
              label="Email Address"
              variant="outlined"
              type="email"
              sx={{ marginY: 3 }}
            />
                    {/* {errors.user_name && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )} */}
               
                

            <TextField  
              {...register("password", { required: true ,
              //    pattern: {
              //      value: passwordRegex,
              //      message:
              //          'password must be at least 5 characters long and  contain at least one uppercase letter,one lowercase letter, and one digit',
              //  },
              
              })}
              // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$"
              id="outlined-basic"
              label="Password"
              type={isVisible?'text': "password"}
              variant="outlined"
              sx={{ marginBottom: 2}}
            />
            {/* { errors.password && <Typography
                variant="p" style={{ color: "red" }}>{errors.password.message}</Typography> }
              */}
           
 
           <div class="form-check" style={{textAlign:'left',position:"relative"}}>
           {passwordIcon && <div className="passwordIcon" onClick={(p)=>setIsVisible((p)=>!p)}>{isVisible?  <AiFillEyeInvisible />:<AiFillEye/> } </div>}

                 <input id="Remember-me" type="checkbox" class="form-check-input" checked={rememberMe} onChange={(e)=>setRememberMe(e.target.checked)} />
                  <label for="Remember-me" class="form-check-label" >Remember me</label>
            </div>
  
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#263238",
                paddingY: 1.5,
                marginY: 2,
                paddingX: 12.5,
              }}
              type="submit"
            >
              <Typography
                variant="p"
                fontSize={13}
                sx={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "white",
                }}
              >
                Login
              </Typography>
            </Button>
            {errorMessage && (
              <Typography variant="p" color="red">
                Invalid Credentials
              </Typography>
            )}
          </form>
        </div>
      </div>
     
    </div>

  );
};

export default Login;
