import React from 'react'
import './breadcrumbs.css'
import { useLocation, useNavigate } from 'react-router-dom'

const BreadCrumbs = () => {

    const { pathname } = useLocation();
    const navigate = useNavigate();


    const handleClickCampaignDetails = () => {
        navigate('/campaign-details')
    }

    const handleClickCampaignContent = () => {
        navigate('/campaign-content')
    }

    const handleClickCampaignRecipients = () => {
        navigate('/campaign-recipients')
    }

    const handleClickCampaignReview = () => {
        navigate('/campaign-review')
    }

    return (
        <>

            <nav>
                <div className='d-flex' style={{ listStyle: "none" }}>
                    <li>
                        <p className={`breadcrumbs-name ${pathname === '/campaign-details' ? 'breadcrumbs-lin-active' : ''}`}
                            // onClick={handleClickCampaignDetails}
                            style={{ cursor: "pointer" }}
                        >
                            Details <span className='px-3'>{'>'}</span>
                        </p>
                    </li>
                    <li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/campaign-content' ? 'breadcrumbs-lin-active' : ''}`}
                            // onClick={handleClickCampaignContent}
                            style={{ cursor: "pointer" }}
                        >
                            Content <span className='px-3'>{'>'}</span>
                        </p>
                    </li>
                    <li>
                        <p className={`breadcrumbs-name ${pathname === '/campaign-recipients' ? 'breadcrumbs-lin-active' : ''}`}
                            // onClick={handleClickCampaignRecipients}
                            style={{ cursor: "pointer" }}
                        >
                            Recipients <span className='px-3'>{'>'}</span>
                        </p>
                    </li>
                    <li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/campaign-review' ? 'breadcrumbs-lin-active' : ''}`}
                            // onClick={handleClickCampaignReview}
                            style={{ cursor: "pointer" }}
                        >
                            Review
                        </p>
                    </li>
                </div>

            </nav>
        </>
    )
}

export default BreadCrumbs