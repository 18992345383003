import React, { useState } from "react";
import "./PackageField.css";
import Switch from "react-switch";
const PackageField = ({packages,setPackages}) => {
  const initialChecked = {
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false
  };
  const [checked, setChecked] = useState(initialChecked);

  const handleChanged = (checked, event, id) => {
    console.log(id);
    setChecked((prevState) => ({
        ...prevState,
        [id]: checked,
      }));
  };
  
  const [shortDescription, setShortDescription] = useState("");
  const handleChange = (e) => {
    setShortDescription(e.target.value);
  };
  
  const handleChangeCheckbox = (e) => {
    const newPackages = [...packages];
    newPackages[e.target.value].value = e.target.checked;
    setPackages(newPackages);
  };
console.log(packages)
  return (<>
  {packages.map((item, index) => (
        <div className="PackageMainContainer">
          <div className="PackageColumn1">
            <input type="checkbox" value={index} name={`PackageCheckbox${index}`} onChange={handleChangeCheckbox} id="AddNewPackageCheckbox" />
          </div>
          {/* <!----------------------------Package Column 2 Starts Here-------------------------------------------!> */}
          <div className="PackageColumn2">
            {/* <!----------------------------Package Row 1 Starts Here-------------------------------------------!> */}
            <div className="PackageRow1">
              <div className="Package-product-Name">
                <label
                  htmlFor="product_name"
                  className="AddNew-Package-Input-label"
                >
                  Product Name
                </label>
                <input
                  id="product_name"
                  className="AddNew-Package-Input"
                  placeholder="Input text"
                  name={`product_name[${index}]`}
                  type="text"
                />
                <p className="input-dynamiclength-addproduct">
                  The name is how it appears on your site
                </p>
              </div>
              <div className="Package-Product-short-Description">
                <label
                  htmlFor="product_name"
                  className="AddNew-Package-Input-label"
                >
                  Short Description{" "}
                </label>
                <input
                  id="product_name"
                  className="AddNew-Package-Input"
                  placeholder="Input text"
                  name="product_name"
                  onChange={handleChange}
                  type="text"
                  style={
                    shortDescription.length > 50
                      ? {
                          color: "red",
                          border: "1px solid red",
                          focus: {
                            border: "1px solid red",
                          },
                        }
                      : {}
                  }
                />
                <p
                  style={shortDescription.length > 50 ? { color: "red" } : {}}
                  className="input-dynamiclength-addproduct"
                >
                  {shortDescription.length}/50{" "}
                </p>
              </div>
            </div>
            {/* <!----------------------------Package Row 1 Ends Here-------------------------------------------!> */}
    
            {/* <!----------------------------Package Row 2 Starts Here-------------------------------------------!> */}
            <div className="PackageRow1">
              <div className="Package-Row2-inputs">
                <label
                  htmlFor="product_name"
                  className="AddNew-Package-Input-label"
                >
                  Price
                </label>
                <input
                  id="product_name"
                  className="AddNew-Package-Input"
                  placeholder="Input text"
                  name="product_name"
                  type="text"
                />
                <p className="input-dynamiclength-addproduct">
                  The name is how it appears on your site
                </p>
              </div>
    
              <div className="Package-Row2-inputs">
                <label
                  htmlFor="product_name"
                  className="AddNew-Package-Input-label"
                >
                  Currency
                </label>
                <input
                  id="product_name"
                  className="AddNew-Package-Input"
                  placeholder="Input text"
                  name="product_name"
                  type="text"
                />
              </div>
    
              <div className="Package-Row2-inputs">
                <label
                  htmlFor="product_name"
                  className="AddNew-Package-Input-label"
                >
                  Select Package
                </label>
                <select name="pricing_model" className="AddNew-Package-Input" id="">
                  <option value={0} hidden>
                    Select
                  </option>
                  <option value={1}>Monthly</option>
                  <option value={1}>Quarterly</option>
                  <option value={1}>Half Yearly</option>
                  <option value={1}>Yearly</option>
                </select>
                <p className="input-dynamiclength-addproduct">Select Package</p>
              </div>
            </div>
            {/* <!----------------------------Package Row 2 Ends Here-------------------------------------------!> */}
    
            <div className="Package-Row3-upper-line"></div>
            {/* <!----------------------------Package Row 3 Starts Here-------------------------------------------!> */}
            <div className="Package-Row3-Title">
              <label htmlFor="product_name" className="AddNew-Package-Input-label">
                Package Points
              </label>
            </div>
            <div className="Package-Row3-Packages-Points">
            {Array.from({ length: 2 }, (_, i) => (
             <div className="Packages-Points-Columns"> 
              {Array.from({ length: 3 }, (_, j) => (
            <div className="Package-PointsToggle-btn-with-label">
            <Switch
              onChange={handleChanged}
              id={`checked${index}${i}${j}`}
              checked={checked[`checked${index}${i}${j}`]}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={"#0900BC"}
              height={20}
              width={36}
              borderRadius={12}
              handleDiameter={14}
            />
            <input
              id="product_name"
              disabled={!checked[`checked${index}${i}${j}`]}
              className="AddNew-Package-Input"
              placeholder="Input text"
              name="product_name"
              type="text"
            />
          </div>
          ))}
             
           </div>
          ))}
             
            </div>
          </div>
          {/* <!----------------------------Package Column 2 Ends Here-------------------------------------------!> */}
        </div>
       ))}</>);
};

export default PackageField;
