import React from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import TargetAddSingleCustomerBreadcrumbs from '../../../../components/TASCBreadCrumbs';
import BackArrowIcon from '../../../../images/BackArrow.svg'
import BiCircleIcon from '../../../../images/bi_info-circle.svg'
import { clearAddSingleCustomer } from '../../../../Store/Reducer/AddSingleCustomerReducer';
import { BaseUrl, dsUrl } from "../../../Api/api";
import EditIcon from "../../../../images/TargetCustomeTableIcon.svg";

const CustomerOverView = () => {
  const { addSingleCustomer } = useSelector(
    (state) => state?.addSingleCustomer
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickAddSingleCustomer = () => {
    navigate("/target-add-single-customer");
  };
  const handleClickEditCustomer = () => {
    navigate("/target-edit-customer");
  };

  const addSingleCustomerFunction = async () => {
    const userToken = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const formdata = new FormData();
    console.log("AddSingleCustomerData: ", addSingleCustomer);

    const data = { ...addSingleCustomer };

    formdata.append("customer_name", data.customer_name);
    formdata.append("customer_email", data.customer_email);
    formdata.append("customer_phone_no", data.customer_phone_no);
    formdata.append("customer_country", data.customer_country);
    formdata.append("customer_state", data.customer_state);
    formdata.append("customer_city", data.customer_city);
    formdata.append("customer_pincode", data.customer_pincode);
    formdata.append("customer_address", data.customer_address);
    formdata.append("customer_category", data.customer_category);

    console.log(formdata, "formdata singlecustomer");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formdata,
    };

    axios
      .post(
        `${BaseUrl}AdminTargetCustomer/Create-Admin-Target-Customer`,
        addSingleCustomer,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((result) => {
        console.log("Result: ", result);
        dispatch(clearAddSingleCustomer());
        navigate("/target-customer-main-page");
        return result.data;
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container" style={{ marginBottom: "100px" }}>
        <div className="container py-1">
          <TargetAddSingleCustomerBreadcrumbs />
        </div>
        <div className="container py-2 d-flex justify-content-between align-items-center">
          {/* {JSON.stringify(addSingleCustomer)} */}
          <div>
            <h5 className="target-add-customer-top-name">
              Sukhumvit Solutions
            </h5>
          </div>
          <div>
            <img
              onClick={handleClickAddSingleCustomer}
              src={BackArrowIcon}
              alt=""
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div
          className=" container d-flex justify-content-end"
          style={{ paddingBottom: "20px" }}
        >
          <img onClick={handleClickEditCustomer} src={EditIcon} alt="" />
        </div>
        <div className="container">
          <div className="target-add-single-customer px-3 py-4">
            <div style={{ paddingBottom: "200px" }}>
              <div>
                <h5 className="sub-h py-3">Overview</h5>
              </div>
              <div className="d-flex">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Name</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_name}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">
                    Company Name
                  </p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.company_name}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
              </div>
              <div className="d-flex my-3">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Phone No.</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_phone_no}
                    style={{
                      border: "1px solid #91959C",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Email</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_email}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">
                    Company Address
                  </p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_address}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Country</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_country}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
              </div>
              <div className="d-flex my-3">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">City</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_city}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Zip</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_pincode}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">State</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_state}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Category</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    value={addSingleCustomer?.customer_category}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
              </div>
            </div>
            {/* <div className='' style={{ marginBottom: "100px" }}>
                            <div>
                                <h5 className='sub-h py-4'>Other Information</h5>
                            </div>
                            <div className='d-flex'>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>
                                        Employees <span><img src={BiCircleIcon} alt="" /></span>
                                    </p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        value={addSingleCustomer?.employess}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                </div>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Website</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        value={addSingleCustomer?.website}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='d-flex my-3'>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Facebook Page</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        value={addSingleCustomer?.facebook}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                </div>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Twitter Page</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        value={addSingleCustomer?.twitterpage}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Linkedin ID</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        value={addSingleCustomer?.linkedinIcon}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                </div>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Instagram</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        value={addSingleCustomer?.instagram}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                </div>
                            </div>

                        </div> */}
          </div>
        </div>
        <div className="d-flex justify-content-end container mt-5">
          <button
            className="target-add-customer-bottom-save-btn px-4 py-1"
            onClick={addSingleCustomerFunction}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomerOverView