import React, { useEffect, useState } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import "../Proposals/proposal.css";
import { useForm } from "react-hook-form";
import { BaseUrl } from "../Api/api";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { getSingleProjectDetails } from "../../Store/Reducer/ProjectList";
import {
  getProposalTableData,
  updateSelectProposalId,
  updateSelectMilestoneId,
  updateSelectProposalTableCheckbox,
} from "../../Store/Reducer/ProposalReducer";
import { useDispatch, useSelector } from "react-redux";
import FilterComponent from "../DashboardHome/Vendor/VendorFilter/index";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { SingleProjectTableDataResponse } from "../../utils/util";
import ColumnsIcon from "../../images/columns.png";
import AttachmentIcon from "../../images/Attachment_icon.png";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
const ProjectSingleDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userToken = localStorage.getItem("token");
  const [filterText, setFilterText] = useState("");
  const [isShowTable, showTableUpdate] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [checkBox, showCheckBox] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState([
    {
      id: "Project Name",
      name: "Project Name",
      selector: (result) => result.project_name,
      sortable: true,
    },
    {
      id: "Vendor",
      name: "Vendor",
      selector: (result) => result.vendor_name,
      sortable: true,
    },
    {
      id: "Project Category",
      name: "Project Category",
      selector: (result) => result.product_category,
      sortable: true,
    },
    {
      id: "Project Price",
      name: "Project Price",
      selector: (result) => result.price,
      sortable: true,
    },
  ]);
  const { projectList, loading, projectDetails, selectedProjectId } =
    useSelector((state) => state.project);

  useEffect(() => {
    dispatch(getSingleProjectDetails(selectedProjectId));
    // dispatch(getSingleProposalDetails(selectedMilestoneId));
  }, [selectedProjectId]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        {/* <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder={""}
        /> */}
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const SingleProjectTableData = projectDetails;
  const result = SingleProjectTableDataResponse(SingleProjectTableData);
  const list = projectList.filter((item) => item.id == selectedProjectId);
  const onRowClicked = (row) => {
    showTableUpdate(false);
    navigate("/proposals-details");
    dispatch(updateSelectProposalId(row.id));
    dispatch(updateSelectMilestoneId(row.project_id));
  };
  const results =
    result &&
    result.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#0900bc",
        color: "white",
        fontSize: "14px",
        width: "100%",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "14px",
        color: "#103a5d",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };
  const onClickApply = () => {
    let select = allColumns.filter((value) => {
      return isSelected.includes(value.id);
    });
    setSelectedHeaders(select);
  };
  const onClickCol = () => {
    showCheckBox(!checkBox);
  };

  const allColumns = [
    {
      id: "Project Name",
      name: "Project Name",
      selector: (result) => result.project_name,
      sortable: true,
    },
    {
      id: "Vendor",
      name: "Vendor",
      selector: (result) => result.vendor_name,
      sortable: true,
    },
    {
      id: "Project Category",
      name: "Project Category",
      selector: (result) => result.product_category,
      sortable: true,
    },
    {
      id: "Project Price",
      name: "Project Price",
      selector: (result) => result.price,
      sortable: true,
    },
  ];

  return (
    <div>
      <DashBoardheader />
      <SideNavBar />
      <div className=" container vendor-follow">
        <div className="vendor-follow-main-div">
          <h1 className="vendor-follow-header">{list[0]["project_name"]}</h1>
          {loading && (
            <center>
              <div
                className="spinner-border text-primary loader"
                role="status"
              ></div>
            </center>
          )}
          <h1 className="proposal-description"></h1>
          <div className="proposal-input-label-main-div">
            <div className="d-flex col-12 pb-3">
              <div className="col-2">
                <p className="proposal-label">Project Name</p>
              </div>
              <div className="col-4">
                <div className="proposal-input">{list[0]["project_name"]}</div>
              </div>

              <div className="col-2 mx-3">
                <div className="proposal-right-label">
                  <p className="proposal-label">Project Category</p>
                </div>
              </div>
              <div className="col-4 ">
                <div className="proposal-input">{list[0]["solution_name"]}</div>
              </div>
            </div>
            <div className="d-flex col-12 pb-3">
              <div className="col-2">
                <p className="proposal-label">Project Due Date</p>
              </div>
              <div className="col-4">
                <div className="proposal-input">
                  {new Date(
                    list[0]["project_expiry_datetime"]
                  ).toLocaleDateString("en-GB")}
                </div>
              </div>

              <div className="col-2 mx-3">
                <div className="proposal-right-label">
                  <p className="proposal-label">Location</p>
                </div>
              </div>
              <div className="col-4 ">
                <div className="proposal-input">{list[0]["country"]}</div>
              </div>
            </div>

            <div className="d-flex col-12 pb-3">
              <div className="col-2">
                <p className="proposal-label">Project Price</p>
              </div>
              <div className="col-4">
                <div className="proposal-input">
                  {list[0]["price"] &&
                    list[0]["price"]?.toLocaleString("en-US")}{" "}
                  {list[0]["proposal_currecny"]}
                </div>
              </div>

              <div className="col-2 mx-3">
                <div className="proposal-right-label">
                  <p className="proposal-label">Product Budget</p>
                </div>
              </div>
              <div className="col-4 ">
                <div className="proposal-input">
                  {list[0]["project_budget"]
                    ? list[0]["project_budget"].toLocaleString("en-US") +
                      " " +
                      list[0]["proposal_currecny"]
                    : "Not Available"}
                </div>
              </div>
            </div>
            <div className="d-flex col-12 pb-3">
              <div className="col-2">
                <p className="proposal-label">Price in USD</p>
              </div>
              <div className="col-4">
                <div className="proposal-input">
                  {list[0]["price_in_usd"]
                    ? list[0]["price_in_usd"].toLocaleString("en-US")
                    : "Not Available"}{" "}
                  {"USD"}
                </div>
              </div>

              <div className="col-2 mx-3">
                <div className="proposal-right-label">
                  <p className="proposal-label">Payment Type</p>
                </div>
              </div>
              <div className="col-4 ">
                <div className="proposal-input">{list[0]["payment_type"]}</div>
              </div>
            </div>

            <div className="d-flex col-12 pb-3">
              <div className="col-2">
                <p className="proposal-label">
                  Project Expire
                  <br />
                  Datetime
                </p>
              </div>
              <div className="col-4">
                <div className="proposal-input">
                  {list[0]["project_expiry_datetime"]}
                </div>
              </div>

              <div className="col-2 mx-3">
                <div className="proposal-right-label">
                  <p className="proposal-label">Attachment</p>
                </div>
              </div>
              <div className="col-4 ">
                <div className="proposal-input">
                  {list[0]["attachment_name"] ? (
                    <>
                      <a href={`${list[0]["attachment_url"]}`} target="_blank">
                        <svg
                          width="55"
                          height="15"
                          viewBox="0 0 80 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M36 40H20C14.4667 40 9.75067 38.0493 5.852 34.148C1.95067 30.2493 0 25.5333 0 20C0 14.4667 1.95067 9.74933 5.852 5.848C9.75067 1.94933 14.4667 0 20 0H36V8H20C16.6667 8 13.8333 9.16667 11.5 11.5C9.16667 13.8333 8 16.6667 8 20C8 23.3333 9.16667 26.1667 11.5 28.5C13.8333 30.8333 16.6667 32 20 32H36V40ZM24 24V16H40H56V24H24ZM44 40V32H60C63.3333 32 66.1667 30.8333 68.5 28.5C70.8333 26.1667 72 23.3333 72 20C72 16.6667 70.8333 13.8333 68.5 11.5C66.1667 9.16667 63.3333 8 60 8H44V0H60C65.5333 0 70.2507 1.94933 74.152 5.848C78.0507 9.74933 80 14.4667 80 20C80 25.5333 78.0507 30.2493 74.152 34.148C70.2507 38.0493 65.5333 40 60 40H44Z"
                            fill="#0900BC"
                          />
                        </svg>{" "}
                        {list[0]["attachment_name"]}
                      </a>
                    </>
                  ) : (
                    <svg
                      width="25"
                      height="20"
                      viewBox="0 0 25 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.5625 15.1174L19.6875 13.1711C20.5208 12.9409 21.1979 12.496 21.7188 11.8364C22.2396 11.1776 22.5 10.4087 22.5 9.52973C22.5 8.48335 22.1354 7.59392 21.4062 6.86145C20.6771 6.12898 19.7917 5.76275 18.75 5.76275H13.75V3.25143H18.75C20.4792 3.25143 21.9533 3.86335 23.1725 5.0872C24.3908 6.31189 25 7.79273 25 9.52973C25 10.7226 24.6929 11.8213 24.0788 12.8258C23.4638 13.8304 22.625 14.5942 21.5625 15.1174ZM17.3125 10.7854L14.8125 8.27407H17.5V10.7854H17.3125ZM18.8424 19.4321L1.27278 1.75792L3.02278 0L20.5924 17.6742L18.8424 19.4321ZM11.25 15.808H6.25C4.52083 15.808 3.04708 15.1957 1.82875 13.971C0.609584 12.7471 0 11.2667 0 9.52973C0 8.08572 0.4375 6.79867 1.3125 5.66857C2.1875 4.53848 3.3125 3.79555 4.6875 3.43978L7 5.76275H6.25C5.20833 5.76275 4.32292 6.12898 3.59375 6.86145C2.86458 7.59392 2.5 8.48335 2.5 9.52973C2.5 10.5761 2.86458 11.4655 3.59375 12.198C4.32292 12.9305 5.20833 13.2967 6.25 13.2967H11.25V15.808ZM7.5 10.7854V8.27407H9.53125L12 10.7854H7.5Z"
                        fill="#0900BC"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>

            <div className="proposal-long-input-div">
              <div className="col-md-2">
                <p className="proposal-label">Proposal Description</p>
              </div>
              <div className="">
                <div className="proposal-long-input">
                  {" "}
                  <h5
                    className="proposal-input-text"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    {parse(list[0]["project_description"])}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="vendor-follow-main-div">
        <div className="milestone-and-columns-icon">
          <h1 className="milestone-header">Proposal</h1>
          {isShowTable && (
            <>
              <div
                className={
                  checkBox ? "milestone-columns" : "milestone-columns-before"
                }
              >
                <img
                  className="columns-icon"
                  onClick={onClickCol}
                  src={ColumnsIcon}
                  alt=""
                />{" "}
                &nbsp;
                {checkBox && (
                  <>
                    <div className="dropdown-multi">
                      <DropdownMultiselect
                        handleOnChange={(value) => {
                          setIsSelected(value);
                        }}
                        options={[
                          "Project Name",
                          "Vendor",
                          "Project Category",
                          "Project Price",
                        ]}
                        selected={[
                          "Project Name",
                          "Vendor",
                          "Project Category",
                          "Project Price",
                        ]}
                        placeholder="select"
                      />
                    </div>
                    <button className="apply-button" onClick={onClickApply}>
                      Apply
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <DataTable
          columns={selectedHeaders}
          data={result}
          // expandableRows
          customStyles={customStyles}
          //  expandableRowsComponent={ExpandedComponent}
          //  expandableRows
          paginationResetDefaultPage={resetPaginationToggle}
          // subHeader
          // subHeaderComponent={subHeaderComponentMemo}
          pagination
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
};

export default ProjectSingleDetails;
