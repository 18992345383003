import { Dashboard, Home } from "@mui/icons-material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Person2Icon from "@mui/icons-material/Person2";
import ReviewsIcon from '@mui/icons-material/Reviews';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Logo from '../../assets/images/Frame1196.svg'
import "./sidebar.css";
import logo from "../../images/logo-white.svg";
import Conqt from "../../images/Conqt_logo.png";
import Icon from "../../images/side-icon.png";
import ActiveIcon from "../../images/side-bar-icon.png";
import ArrowIcon from "../../images/downArrow.svg";
import CustomerIcon from "../../images/customer.svg";
// import CampaignIcon from '../../images/campaign.svg'
import ProjectsIcon from "../../images/projects.svg";
import ProposalsIcon from "../../images/proposals.svg";
import LogoutIcon from "../../images/logout.svg";
// import CleanHandsIcon from '@mui/icons-material/CleanHands';
// import Groups3Icon from '@mui/icons-material/Groups3';
// import SummarizeIcon from '@mui/icons-material/Summarize';
import CampaignIcon from "@mui/icons-material/Campaign";
import ActiveCustomerIcon from "../../images/activeCustomer.svg";
import ActiveProjects from "../../images/activeProjects.svg";
import ActiveProposals from "../../images/activeProposal.svg";
import ActiveLogout from "../../images/activeLogout.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TargetIcon from "../../images/targetIcon.svg";
import ActiveTargetIcon from "../../images/activeTargetIcon.svg";
import vendorContactIcon from "../../images/vendorContactIcon.svg";
import contactActiveIcon from "../../images/contactActiveIcon.svg";

const SideNavBar = () => {
  const [showEmailCampaign, setEmailShowCampaign] = useState(false);
  const [showTarget, setShowTarget] = useState(false);
  // const [showCampaign, setShowCampaign] = useState(false)

  const toggleShowCampaign = () => {
    setEmailShowCampaign(!showEmailCampaign);
  };

  const toggleTarget = () => {
    setShowTarget(!showTarget);
  };

  // const toggleCampaign = () => {
  //   setShowCampaign(!showCampaign)
  // }
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClickCustomer = () => {
    navigate("/customer-dashboard");
  };

  const onClickAdmin = () => {
    navigate("/vendor-dashboard");
  };
  const onClickMain = () => {
    navigate("/admin-dashboard");
  };
  const onClickEmail = () => {
    navigate("/email-dashboard");
  };
  const onClickProjects = () => {
    navigate("/projects");
  };
  const onClickProposals = () => {
    navigate("/proposals");
  };
  
  const onClickMainProduct = () => {
    navigate("/main-product")
  }
  const onClickLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };
  const handleClickAllContacts = () => {
    navigate("/all-contacts");
  };
  const handleClickEmailSubscriberList = () => {
    navigate("/email-subscriber-list");
  };
  const handleClickEmailCampaign = () => {
    navigate("/email-campaign");
  };

  const handleClikCampaign = () => {
    navigate("/campaign-main-page");
    // setEmailShowCampaign(!showEmailCampaign)
  };
  const handleClickTargetCustomer = () => {
    navigate("/target-customer-main-page");
  };
  const handleClickTargetVendor = () => {
    navigate("/target-vendor-main-page");
  };

  const handleClickTargetCustomerContact = () => {
    navigate("/target-customer-contact-main-page");
  };

  const handleClickTargetVendorContact = () => {
    navigate("/target-vendor-contact-main-page");
  };

  const handleClickCallExpert = () => {
    navigate("/target-call-expert");
  };

  const onClickReviews = () => {
    navigate("/admin-reviews");
  };

  return (
    <>
      <div
        className="d-flex flex-column flex-shrink-0 py-3 text-white sidebar"
        style={{ width: "250px" }}
      >
        <div className="dashboard-top d-flex align-items-center">
          <img src={Conqt} alt="" />
        </div>
        <div
          onClick={onClickMain}
          className={`d-flex align-items-center justify-content-center sid ${
            pathname === "/admin-dashboard" ? "sid-link-active" : ""
          }`}
          style={{ width: "190px" }}
        >
          <Home />
          <p
            className=""
            style={{
              paddingLeft: "6px",
              paddingTop: "3px",
            }}
          >
            Main Dashboard
          </p>
        </div>

        <div
          onClick={onClickAdmin}
          className={`d-flex align-items-center sid  my-2 ${
            pathname === "/vendor-dashboard" ||
            pathname === "/vendor-details" ||
            pathname === "/add-new-contacts" ||
            pathname === "/vendor-view-contacts"
              ? "sid-link-active"
              : ""
          }
          }`}
          style={{ width: "190px" }}
        >
          <Person2Icon />
          <p
            style={{
              paddingLeft: "10px",
              paddingTop: "3px",
            }}
          >
            Vendor
          </p>
        </div>

        <div
          onClick={onClickReviews}
          className={`d-flex align-items-center sid   ${pathname === "/admin-reviews" ||
            pathname === "/single-review" 
            ? "sid-link-active"
            : ""
            }
          }`}
          style={{ width: "190px" }}
        >
          < ReviewsIcon />
          <p
            style={{
              paddingLeft: "10px",
              paddingTop: "3px",
            }}
          >
            Reviews
          </p>
        </div>

        <div
          onClick={onClickCustomer}
          className={`d-flex align-items-center sid  my-2 ${
            pathname === "/customer-dashboard" ||
            pathname === "/customer-details" ||
            pathname === "/customer-contacts" ||
            pathname === "/add-customer-new-contact"
              ? "sid-link-active"
              : ""
          }
          }`}
          style={{ width: "190px" }}
        >
          <img
            className="img-figma"
            src={
              pathname === "/customer-dashboard" ||
              pathname === "/customer-details" ||
              pathname === "/customer-contacts" ||
              pathname === "/add-customer-new-contact"
                ? ActiveCustomerIcon
                : CustomerIcon
            }
            alt=""
          />
          <p
            style={{
              paddingLeft: "10px",
              paddingTop: "3px",
            }}
          >
            Customer
          </p>
        </div>

        <div
          onClick={onClickProjects}
          className={`d-flex align-items-center sid  my-1 ${
            pathname === "/projects" || pathname === "/projects-details"
              ? "sid-link-active"
              : ""
          }`}
          style={{ width: "190px" }}
        >
          <img
            src={
              pathname === "/projects" || pathname === "/projects-details"
                ? ActiveProjects
                : ProjectsIcon
            }
            alt=""
          />
          <p
            style={{
              paddingLeft: "10px",
              paddingTop: "3px",
            }}
          >
            Projects
          </p>
        </div>

        <div
          onClick={onClickMainProduct}
          className={`d-flex align-items-center sid  my-2 ${
            pathname === "/main-product" ||
            pathname === "/main-product/category-list" ||
            pathname === "/main-product/industry-list" ||
            pathname === "/main-product/department-list" ||
            pathname === "/add-product" ||
            pathname === "/edit-product"
              ? "sid-link-active"
              : ""
          }`}
          style={{ width: "190px" }}
        >
          {pathname === "/main-product" ||
          pathname === "/main-product/category-list" ||
          pathname === "/main-product/industry-list" ||
          pathname === "/main-product/department-list" ||
          pathname === "/add-product" ||
          pathname === "/edit-product" ? (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2376_86797)">
                <path
                  d="M16.4062 0L25 4.29688V13.8794L23.4375 13.0981V6.04248L17.1875 9.16748V12.3169L15.625 13.0981V9.16748L9.375 6.04248V8.81348L7.8125 8.03223V4.29688L16.4062 0ZM16.4062 7.8125L18.5669 6.72607L12.915 3.49121L10.3394 4.78516L16.4062 7.8125ZM20.2515 5.896L22.4731 4.78516L16.4062 1.74561L14.5874 2.66113L20.2515 5.896ZM14.0625 13.8794L12.5 14.6606V14.6484L7.8125 16.9922V22.5464L12.5 20.1904V21.9482L7.03125 24.6826L0 21.1548V12.9028L7.03125 9.38721L14.0625 12.9028V13.8794ZM6.25 22.5464V16.9922L1.5625 14.6484V20.1904L6.25 22.5464ZM7.03125 15.6372L11.5356 13.3911L7.03125 11.1328L2.52686 13.3911L7.03125 15.6372ZM14.0625 15.625L19.5312 12.8906L25 15.625V22.0581L19.5312 24.7925L14.0625 22.0581V15.625ZM18.75 22.6562V18.9331L15.625 17.3706V21.0938L18.75 22.6562ZM23.4375 21.0938V17.3706L20.3125 18.9331V22.6562L23.4375 21.0938ZM19.5312 17.5781L22.4731 16.1011L19.5312 14.6362L16.5894 16.1011L19.5312 17.5781Z"
                  fill="#0900BC"
                />
              </g>
              <defs>
                <clipPath id="clip0_2376_86797">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2400_80146)">
                <path
                  d="M16.4062 0L25 4.29688V13.8794L23.4375 13.0981V6.04248L17.1875 9.16748V12.3169L15.625 13.0981V9.16748L9.375 6.04248V8.81348L7.8125 8.03223V4.29688L16.4062 0ZM16.4062 7.8125L18.5669 6.72607L12.915 3.49121L10.3394 4.78516L16.4062 7.8125ZM20.2515 5.896L22.4731 4.78516L16.4062 1.74561L14.5874 2.66113L20.2515 5.896ZM14.0625 13.8794L12.5 14.6606V14.6484L7.8125 16.9922V22.5464L12.5 20.1904V21.9482L7.03125 24.6826L0 21.1548V12.9028L7.03125 9.38721L14.0625 12.9028V13.8794ZM6.25 22.5464V16.9922L1.5625 14.6484V20.1904L6.25 22.5464ZM7.03125 15.6372L11.5356 13.3911L7.03125 11.1328L2.52686 13.3911L7.03125 15.6372ZM14.0625 15.625L19.5312 12.8906L25 15.625V22.0581L19.5312 24.7925L14.0625 22.0581V15.625ZM18.75 22.6562V18.9331L15.625 17.3706V21.0938L18.75 22.6562ZM23.4375 21.0938V17.3706L20.3125 18.9331V22.6562L23.4375 21.0938ZM19.5312 17.5781L22.4731 16.1011L19.5312 14.6362L16.5894 16.1011L19.5312 17.5781Z"
                  fill="#9290AB"
                />
              </g>
              <defs>
                <clipPath id="clip0_2400_80146">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}

          <p
            style={{
              paddingLeft: "15px",
              paddingTop: "3px",
            }}
          >
            Main Product
          </p>
        </div>

        <div
          onClick={onClickProposals}
          className={`d-flex align-items-center sid  ${
            pathname === "/proposals" ||
            pathname === "/proposals-details" ||
            pathname === "/milestone-details" ||
            pathname === "/milestone-receipt-details"
              ? "sid-link-active"
              : ""
          }`}
          style={{ width: "190px" }}
        >
          <img
            src={
              pathname === "/proposals" ||
              pathname === "/proposals-details" ||
              pathname === "/milestone-details" ||
              pathname === "/milestone-receipt-details"
                ? ActiveProposals
                : ProposalsIcon
            }
            alt=""
          />
          <p
            style={{
              paddingLeft: "15px",
              paddingTop: "3px",
            }}
          >
            Proposals
          </p>
        </div>

        <div
          onClick={handleClikCampaign}
          className={`d-flex align-items-center sid  my-2 ${
            pathname === "/campaign-main-page" ||
            pathname === "/campaign-details" ||
            pathname === "/campaign-content" ||
            pathname === "/campaign-recipients" ||
            pathname === "/campaign-review"
              ? "sid-link-active"
              : ""
          }`}
          style={{ width: "190px" }}
        >
          <CampaignIcon />
          <p
            style={{
              paddingLeft: "10px",
              paddingTop: "3px",
            }}
          >
            Campaign
          </p>
        </div>

        <div
          onClick={toggleTarget}
          className={`d-flex align-items-center sid  ${
            pathname === "/target-customer-main-page" ||
            pathname === "/target-add-single-customer" ||
            pathname === "/target-customer-over-view" ||
            pathname === "/target-add-multiple-customer" ||
            pathname === "/target-add-customer-contact" ||
            pathname === "/target-customer-contact-main-page" ||
            pathname === "/target-customer-contact-overview" ||
            pathname === "/target-edit-customer-contact" ||
            pathname === "/target-edit-customer" ||
            pathname === "/target-vendor-main-page"||
            pathname === "/target-vendor-contact-main-page"||
            pathname === "/target-add-single-vendor"
              ? "sid-link-active"
              : ""
          }`}
          style={{ width: "190px" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img
                src={
                  pathname === "/target-customer-main-page" ||
                  pathname === "/target-add-single-customer" ||
                  pathname === "/target-customer-over-view" ||
                  pathname === "/target-add-multiple-customer" ||
                  pathname === "/target-add-customer-contact" ||
                  pathname === "/target-customer-contact-main-page" ||
                  pathname === "/target-customer-contact-overview" ||
                  pathname === "/target-edit-customer-contact" ||
                  pathname === "/target-edit-customer"||
                   pathname === "/target-vendor-main-page"||
                    pathname === "/target-vendor-contact-main-page"||
                  pathname === "/target-add-single-vendor"
                    ? ActiveTargetIcon
                    : TargetIcon
                }
                alt=""
              />
              <p
                style={{
                  paddingLeft: "10px",
                  paddingTop: "3px",
                }}
              >
                Target
              </p>
            </div>
            <div style={{ paddingLeft: "50px" }}>
              <ArrowDropDownIcon />
            </div>
          </div>
        </div>
        {showTarget && (
          <div style={{ paddingLeft: "30px" }}>
            <div
              onClick={handleClickTargetVendor}
              className={`d-flex align-items-center sid my-2`}
            >
              <Person2Icon /> &nbsp;
              <p>Vendor</p>
            </div>
            <div
              onClick={handleClickTargetVendorContact}
              className={`d-flex align-items-center sid`}
            >
              <img src={vendorContactIcon} alt="" /> &nbsp;
              <p>Vendor Contact</p>
            </div>

            <div
              onClick={handleClickCallExpert}
              className={`d-flex align-items-center sid my-1 ${
                pathname === "/target-call-expert" ? "sid-link-active" : ""
              }`}
            >
              {pathname === "/target-call-expert" ? (
                <svg
                  width="34"
                  height="35"
                  viewBox="0 0 34 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2200_25954)">
                    <path
                      d="M24.6 0.823242H5.4V3.07324H24.6V0.823242ZM5.4 27.8232H24.6V25.5732H5.4V27.8232ZM24.6 5.32324H5.4C4.08 5.32324 3 6.33574 3 7.57324V21.0732C3 22.3107 4.08 23.3232 5.4 23.3232H24.6C25.92 23.3232 27 22.3107 27 21.0732V7.57324C27 6.33574 25.92 5.32324 24.6 5.32324ZM15 8.41699C16.488 8.41699 17.7 9.55324 17.7 10.9482C17.7 12.3432 16.488 13.4795 15 13.4795C13.512 13.4795 12.3 12.3432 12.3 10.9482C12.3 9.55324 13.512 8.41699 15 8.41699ZM21 19.9482H9V18.2607C9 16.382 12.996 15.4482 15 15.4482C17.004 15.4482 21 16.382 21 18.2607V19.9482Z"
                      fill="#0900BC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2200_25954">
                      <rect
                        width="34"
                        height="34"
                        fill="white"
                        transform="translate(0 0.823242)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <img src={vendorContactIcon} alt="" />
              )}
              <p
                style={{
                  paddingLeft: "10px",
                  paddingTop: "3px",
                }}
              >
                Call Experts
              </p>
            </div>
            <div
              onClick={handleClickTargetCustomer}
              className={`d-flex align-items-center sid my-1 ${
                pathname === "/target-customer-main-page" ||
                pathname === "/target-add-single-customer" ||
                pathname === "/target-customer-over-view" ||
                pathname === "/target-edit-customer"
                  ? "sid-link-active"
                  : ""
              }`}
            >
              <Person2Icon />
              <p
                style={{
                  paddingLeft: "10px",
                  paddingTop: "3px",
                }}
              >
                Customer
              </p>
            </div>
            <div
              onClick={handleClickTargetCustomerContact}
              className={`d-flex align-items-center sid ${
                pathname === "/target-add-customer-contact" ||
                pathname === "/target-customer-contact-main-page" ||
                pathname === "/target-customer-contact-overview" ||
                pathname === "/target-edit-customer-contact"
                  ? "sid-link-active"
                  : ""
              }`}
            >
              <img
                src={
                  pathname === "/target-add-customer-contact" ||
                  pathname === "/target-customer-contact-main-page" ||
                  pathname === "/target-customer-contact-overview" ||
                  pathname === "/target-edit-customer-contact"
                    ? ActiveCustomerIcon
                    : CustomerIcon
                }
                alt=""
              />
              <p>CustomerContact</p>
            </div>
          </div>
        )}

        <div
          onClick={onClickLogout}
          className={`d-flex align-items-center sid  my-2 ${""}`}
          style={{ width: "190px" }}
        >
          <ExitToAppIcon />

          <p
            style={{
              paddingLeft: "10px",
              paddingTop: "3px",
            }}
          >
            Logout
          </p>
        </div>

        {/* <li className="">
          <div
            onClick={onClickMain}
            className={`sidenavheader d-flex align-items-center ${pathname === "/admin-dashboard" ? "link-active" : ""
              }`}
          >
            <Home /> <span className="px-3"> Main Dashboard</span>
          </div>
        </li> */}
        {/* <ul
          className="nav nav-pills flex-column mb-auto"
          style={{ borderBottom: "none" }}
        >
          <li className="nav-s my-1 px-3">
            <div
              onClick={onClickAdmin}
              className={`sidenavheader d-flex align-items-center my-2 ${(pathname === "/vendor-dashboard")
                || (pathname === "/vendor-details")
                || (pathname === "/add-new-contacts")
                || (pathname === "/vendor-view-contacts")
                ? "link-active" : ""
                }`}
            >
              <Person2Icon /> <span className="px-3"> Vendor</span>
            </div>
          </li>

          <li className="nav-itesm px-3">
            <div
              onClick={onClickCustomer}
              className={`sidenavheader d-flex align-items-center ${(pathname === "/customer-dashboard") || (pathname === "/customer-details") ? "link-active" : ""
                }`}
            >
              <img
                className={`sidenavheader ${pathname === "/customer-dashboard" ? "link-active" : ""
                  }`}
                src={pathname === "/customer-dashboard" ? ActiveCustomerIcon : CustomerIcon}
                alt=""
              /> <span className="px-3"> Customer</span>

            </div>
          </li>
          <li className="nav-itsem px-3">
            <div
              onClick={onClickProjects}
              className={`sidenavheader d-flex align-items-center my-3 ${pathname === "/projects" ? "link-active" : ""
                }`}
            >
              <img
                className={`sidenavheader ${pathname === "/customer-dashboard" ? "link-active" : ""
                  }`}
                src={pathname === "/projects" ? ActiveProjects : ProjectsIcon}
                alt=""
              /><span className="px-3"> Projects</span>

            </div>
          </li>
          <li className="nav-itsem my-0 px-3">
            <div
              onClick={onClickProposals}
              className={`sidenavheader d-flex  align-items-center ${pathname === "/proposals" ? "link-active" : ""
                }`}
            >
              <img
                className={`sidenavheader ${pathname === "/proposals" ? "link-active" : ""
                  }`}
                src={pathname === "/proposals" ? ActiveProposals : ProposalsIcon}
                alt=""
              /><span className="px-4"> Proposals</span>


            </div>

          </li>
          <li className="nav-itsem  px-3">
            <div className="d-flex justify-content-between align-items-center" onClick={toggleShowCampaign}>
              <div

                className={`sidenavheader d-flex align-items-center my-3 ${(pathname === "/campaign-main-page")

                  || (pathname === "/campaign-details") || (pathname === "/campaign-content") || (pathname === "/campaign-recipients")
                  || (pathname === "/campaign-review")

                  ? "link-active" : ""
                  }`}
              >

                <div className="d-flex align-items-center">


                  <CampaignIcon /><span className="px-3"> Campaign</span> <span><ArrowDropDownIcon /></span>
                </div>
              </div>

            </div>

          </li>
          {showEmailCampaign && (
            <>
              <div className="sidenavsubtitles" style={{ marginTop: "-15px" }}>
                <p className={`sidenavheader ${pathname === '/all-contacts' ? 'link-active' : ''}`}
                  onClick={handleClickAllContacts}
                  style={{ paddingLeft: "40px" }}
                >
                  Email Campaign
                </p>
                <p className={`sidenavheader pt-1 ${pathname === '/campaign-main-page' ? 'link-active' : ''}`}
                  onClick={handleClikCampaign}
                  style={{ paddingLeft: "40px" }}
                >
                  Campaign List
                </p>
              </div>

            </>

          )}

          <li className="nav-itsem  px-3" style={{ marginTop: "-10px" }}>
            <div className="d-flex justify-content-between align-items-center" onClick={toggleTarget}>
              <div

                className={`sidenavheader d-flex align-items-center my-2 ${(pathname === "/target-customer-main-page")
                  || (pathname === "/target-add-single-customer") || (pathname === "/target-customer-over-view")
                  || (pathname === "/target-add-multiple-customer")
                  ? "link-active" : ""
                  }`}
              >
                <img
                  style={{ width: "25px", paddingTop: "10px" }}
                  className={`sidenavheader ${(pathname === "/target-customer-main-page")
                    || (pathname === "/target-add-single-customer")
                    || (pathname === "/target-customer-over-view")
                    || (pathname === "/target-add-multiple-customer")
                    ? "link-active" : ""
                    }`}
                  src={(pathname === "/target-customer-main-page")
                    || (pathname === "/target-add-single-customer")
                    || (pathname === "/target-customer-over-view")
                    || (pathname === "/target-add-multiple-customer")
                    ? ActiveTargetIcon : TargetIcon}
                  alt=""
                /><span className="px-3"> Target</span><span className="px-4"><ArrowDropDownIcon /></span>
              </div>

            </div>

          </li>
          {showTarget && (
            <>
              <div className="sidenavsubtitles" style={{ marginTop: "-10px" }}>
                <div onClick={handleClickTargetVendor} >
                  <p className="sidenavheader" style={{ paddingLeft: "10px" }}><Person2Icon /> <span className=""> Vendor</span></p>
                  <p className="sidenavheader my-1" style={{ paddingLeft: "10px" }}>
                    <img src={vendorContactIcon} alt=""
                      style={{ width: "20px" }}
                    /><span className="px-2">Vendor Contact</span>
                  </p>
                </div>
                <div
                  onClick={handleClickTargetCustomer}
                  style={{ paddingLeft: "10px" }} className={`sidenavheader d-flex align-items-center my-2 ${pathname === "/target-customer-main-page" ? "link-active" : ""
                    }`}
                >
                  <img className={`sidenavheader ${pathname === "/target-customer-main-page" ? "link-active" : ""
                    }`}
                    src={pathname === "/target-customer-main-page" ? contactActiveIcon : vendorContactIcon} alt=""
                    style={{ width: "20px" }}
                  />
                  <span className="px-2">Customer</span>
                </div>


                <p className="sidenavheader my-1" style={{ paddingLeft: "10px" }}>
                  <img src={CustomerIcon} alt=""
                    style={{ width: "20px" }}
                  /><span className="px-2">Customer Contact</span>
                </p>
              </div>
              <div className="px-3">
                <hr />
              </div>
            </>

          )}

          <li className="px-3">
            <div
              onClick={onClickLogout}
              className={`sidenavheader d-flex align-items-center ${pathname === "/logout" ? "link-active" : ""
                }`}
            >

              <ExitToAppIcon /> <span className="px-3"> Logout</span>
            </div>
          </li>
        </ul> */}
      </div>
    </>
  );
};

export default SideNavBar;
