import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const myReducer = createSlice({
        name: "conqtAdmin",
        initialState: {
                loading: false,
                singleReview: {}
        },
        reducers: {
                updateSingleReview: (state, action) => {
                        state.singleReview = { ...state.singleReview, ...action.payload };
                },

        },
        extraReducers: {
        },
})

export const {
        updateSingleReview,
} = myReducer.actions;

export default myReducer.reducer;