import React, { useState, useEffect } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import "./popupDep.css";
import Add from "../../../../images/Add.svg"
import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getDepartmentListTableData } from '../../../../Store/Reducer/DepartmentList';
import { useDispatch } from 'react-redux';

const AddDepartment = ({ open, handleAddClose, handleClose, name, data }) => {

        const navigate = useNavigate();
        const dispatch = useDispatch();

        console.log("data of the match", data)
        const [addClose, setAddClose] = useState(handleAddClose);
        const [close, setClose] = useState(handleAddClose);

        useEffect(() => {
                dispatch(getDepartmentListTableData())
        }, [])

        const addDepartment = () => {
                axios.post(`${BaseUrl}ProductMaster/Add-Department`, data,
                        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
                )
                        .then(res => {
                                if (res.data.status === 200) {
                                        console.log("res", res);
                                        setAddClose(handleAddClose(false))
                                        setClose(handleClose(false))
                                        // toast.success("Department Added Successfully")
                                        window.location.reload();
                                        window.scrollTo(0, 0);
                                        // navigate("/main-product/department-list")
                                }
                                else if (res.data.status === 401) {
                                        console.log("error res", res);
                                        setAddClose(handleAddClose(false))
                                        setClose(handleClose(false))
                                        toast.error(res.data.message)
                                }
                        })
                        .catch(err => {
                                console.log("err", err);
                                toast.error("Something went wrong")
                        })
        }
        return (
                <>
                        <Modal open={open} handleClose={handleAddClose} size="xs">
                                <Modal.Body >
                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <img src={Add} alt="" />
                                                <h4 className='are-you-sure py-4'>Are you sure?</h4>
                                                <p className='sub-del-header'>You want to Add this {name}</p>

                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-4 pt-4'>
                                                <button className='add-dep-btn' onClick={addDepartment}>Add</button>
                                                <button className='cencel-dep-btn' onClick={handleAddClose}>Cancel</button>
                                        </div>
                                </Modal.Body>


                        </Modal>
                </>
        )
}

export default AddDepartment