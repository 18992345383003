import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddNewProducts.css";
import redcross from "../../images/redcross.png";
import { BaseUrl } from "../Api/api";

const AddGallery = ({ images, onDelete, oldImages }) => {
  // console.log(images);
  // console.log(oldImages, "old images");
  const productOldImages = oldImages && oldImages;
  const productImages = productOldImages ? productOldImages : images;

  const onDeleteImage = (e) => {
    console.log(e, "onDeleteImage");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}product/Delete-Product-Image?image_id=${e.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200) {
          console.log("api response: ", result);
          toast.success("Product image deleted successfully");
          window.location.reload()
        } else {
          console.log("error from api", result);
          toast.error("Something went wrong Please try again");
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Something went wrong Please try again");
      });
  };
  return (
    <div className="anp-gallery">
      <p className="anp-label anp-selected-text">Selected Images</p>
      <div className="anp-selected-products-image">
        {productImages.map(
          (image, index) => (
            // console.log(image, "productImage in gallery"),
            (
              <div key={index} className="anp-product-image">
                <button
                  type="button"
                  className="anp-delete-product-image"
                  onClick={
                    !productOldImages
                      ? () => onDelete(index)
                      : () => onDeleteImage(image)
                  }
                >
                  <img src={redcross} alt="" className="anp-red-cross" />{" "}
                </button>
                <img
                  src={
                    !productOldImages
                      ? URL.createObjectURL(image)
                      : image.img_url
                  }
                  style={{ width: "80px", height: "90px" }}
                />
                <p className="anp-image-name">{image.name}</p>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default AddGallery;
