import React, { useState, useEffect } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import Expander from "../../components/Expander/index";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ContactArrow from "../../images/contact-drop-down.png";
import { parsePath, useNavigate } from "react-router-dom";
import { getSingleCustomerDetails ,updateSelectCustomerName } from "../../Store/Reducer/CustomerReducer";
import GlobalExpander from "../../components/GlobalExpander/index";
import { toast } from "react-toastify";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import "react-toastify/dist/ReactToastify.css";
import { BaseUrl } from "../Api/api";
import { getAllCountryList ,updateSelectcountryList} from "../../Store/Reducer/CountryListReducer";
import pencil from "../../images/pencil-square.svg";
import save from "../../images/save.png";
import CustomerContact from "./customerContact";
const CustomerDashboardDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [contactView, setContactView] = useState(false);
  const [isEditPage, setIsEditPage] = useState(true);
  const [data, setData] = useState({});
  const [otherInformationData, setOtherInformationData] = useState({});
  const [isEditOtherInformationPage, setIsEditOtherInformationPage] =
  useState(true);
  const { customerList, loading, selectedCustomerId, customerDetails,selectedCustomerName } =
    useSelector((state) => state.customer);
  // console.log(customerDetails.projectProposalList,"project and proposal list")
  const { countryList, selectedCountryId } = useSelector(
    (state) => state.allCountryList
  );
  const onSubmit = (data) => {
    // dispatch(updatedDataSecond(data));
    // navigate("/c");

    const userToken = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      company_name: data.company_name,
      // first_name: data.name,
      // last_name: "a",
      phone_number: data.phone_number,
      solutions: data.solutions,
      name: data.name,
      contact_mobile: data.contact_mobile,
      position: data.position,
      department: data.department,
      country: data.country,
      state: data.state,
      address: data.address,
      city: data.city,
      zipcode: data.zipcode,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}admin/User-Basic-Detail-Update?user_id=` + selectedCustomerId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          setData(result);
          setIsEditPage(true);
          // console.log(result, "api success");
          toast("Data updated successfully");
        } else {
          toast("Network error occurred");
        }
      })
      .catch((error) => console.log("error", error));

    var raw = JSON.stringify({
      agency_user_id: selectedCustomerId,
      no_of_employee: data.no_of_employee,
      agency_website: data.agency_website,
      linkedin_url: data.linkedin_url,
      twitter_url: data.twitter_url,
      instagram_url: data.instagram_url,
      facebook_url: data.facebook_url,
    });
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${BaseUrl}admin/User-Other-Information-Update`, requestOptions)
      .then((response) => response.json())
      .then((result) =>{
        // setOtherInformationData(result),
        setIsEditOtherInformationPage(true);
        // console.log(result, "otherInformation api data")
       } )
      .catch((error) => console.log("error", error));

    // console.log("*****submit******", data);
  };
  console.log(errors, "error");

  // const stateData = useSelector((state) => state.conqtAdmin.apidata);
  // console.log(stateData, "data from vendor details redux store");

  // console.log(customerList, "data for details screen");
  const onClickView = () => {
    navigate("/customer-contacts");
  };
  const onClickCreate = () => {
    navigate("/add-customer-new-contact");
    dispatch(updateSelectCustomerName(customerDetails.basicDetails.company_name))
  };
  const onClickContact = (e) => {
    e.stopPropagation();
    setContactView(!contactView);
  };
  const onClickEditButton = () => {
    setIsEditPage(false);
  };
  const onClickEditOtherInfromationButton = () => {
    setIsEditOtherInformationPage(false);
  };

  useEffect(() => {
    if (selectedCustomerId) {
    dispatch(getSingleCustomerDetails(selectedCustomerId));
    }
  }, []);

  useEffect(() => {
    if (customerDetails && customerDetails.basicDetails) {
      Object.entries(customerDetails.basicDetails).forEach(([name, value]) => {
        setValue(name, value);
      });
      Object.entries(
        customerDetails.otherInformation
          ? customerDetails.otherInformation
          : customerDetails
      ).forEach(([name, value]) => {
        setValue(name, value);
      });
    }
    // console.log("data changed from store");
  }, [customerDetails]);

  useEffect(() => {
    dispatch(updateSelectcountryList(selectedCountryId));
  }, [selectedCountryId]);

  return (
    <>
    <DashBoardheader />
        <SideNavBar />
    <div className="vendor-details-main-div">
    <form onSubmit={handleSubmit(onSubmit)}>
        {loading && (
          <center>
            <div
              className="spinner-border text-primary loader"
              role="status"
            ></div>
          </center>
        )}
        <div className="title-and-edit-save">
          <h1 className="vendor-single-page-title">
            {customerDetails &&
              customerDetails.basicDetails &&
              customerDetails.basicDetails.company_name}
          </h1>
       
        </div>
        <div className="single-page-overview">
          <h5 className="header-plus">Overview</h5>
          {isEditPage && (
            <img
              className="overview-pencil"
              onClick={onClickEditButton}
              src={pencil}
              alt=""
            ></img>
          )}
          {!isEditPage && (
            <button
              className="overview-save-button"
              disabled={isEditPage}
              type="submit"
            >
              <img className="overview-pencil" src={save} alt=""></img>
            </button>
          )}
        </div>


        <div className="overview-table">
          {/* //div start */}
          <div className="single-page-two-div">
            <div className="single-page-left-container">
              <div className="input-title">
                <div className="single-page-title"> Name </div>
                <input
                  disabled={isEditPage}
                  className={
                    isEditPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("company_name", {
                    required: true,
                  })}
                >
                  {/* {stateData.company_name} */}
                </input>
              </div>

              {errors.company_name && (
                <span className="left-error-msg">Please enter the value</span>
              )}
            </div>

            <div className="single-page-email-div">
              <div className="single-page-title">Email </div>
              <input
                disabled
                className="single-page-value"
                {...register("user_name", {})}
              >
                {/* {stateData.user_name}  */}
              </input>
            </div>
          </div>
          {/* div end */}
          <hr className="line"></hr>
          {/* //div start */}
          <div className="single-page-two-div">
            <div className="single-page-left-container">
              <div className="input-and-title">
                <div className="single-page-title">Phone No.</div>
                <input
                  disabled={isEditPage}
                  className={
                    isEditPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("phone_number", {
                    required: true,
                  })}
                ></input>
              </div>
              {errors.phone_no && (
                <span className="left-error-msg">Please enter the value</span>
              )}
            </div>
            {/* {errors.phone_no && (
              <span className="error-msg">Please enter the value</span>
            )} */}
            <div className="single-page-left-container">
              <div className="address-and-input">
                <div className="single-page-title">Address</div>
                <input
                  disabled={isEditPage}
                  className={
                    isEditPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("address", {
                    required: true,
                  })}
                ></input>
              </div>
              {errors.address && (
                <span className="error-msg">Please enter the value</span>
              )}
            </div>
          </div>
          <hr className="line"></hr>

          <div className="single-page-two-div">
            <div className="single-page-left-container">
              <div className="country-and-input">
                <div className="single-page-title">Country</div>
                <select
                  disabled={isEditPage}
                  className={
                    isEditPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("country", {
                    required: true,
                  })}
                >
                  <option className="dropdown-country" value="">
                    {" "}
                    select country
                  </option>
                  {countryList &&
                    countryList.map((country, index) => {
                      return (
                        <option key={index} value={country.value}>
                          {country.country}
                        </option>
                      );
                    })}
                </select>
              </div>
              {errors.country && (
                <span className="left-error-msg">Please enter the value</span>
              )}
            </div>
            <div className="single-page-left-container">
              <div className="city-and-input">
                <div className="single-page-title">City</div>
                <input
                  disabled={isEditPage}
                  className={
                    isEditPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("city", {
                    required: true,
                  })}
                ></input>
              </div>
              {errors.city && (
                <span className="error-msg">Please enter the value</span>
              )}
            </div>
          </div>
          <hr className="line"></hr>
        
          <div className="single-page-two-div">
            <div className="single-page-left-container">
              <div className="state-and-input">
                <div className="single-page-title">State/Province</div>
                <input
                  disabled={isEditPage}
                  className={
                    isEditPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("state", {
                    required: true,
                  })}
                ></input>
              </div>
              {errors.state && (
                <span className="left-error-msg">Please enter the value</span>
              )}
            </div>
            {/* {errors.phone_no && (
              <span className="error-msg">Please enter the value</span>
            )} */}
            <div className="single-page-left-container">
              <div className="zip-and-input">
                <div className="single-page-title">Zip</div>
                <input
                  disabled={isEditPage}
                  type="Number"
                  className={
                    isEditPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("zipcode", {
                    required: true,
                  })}
                ></input>
              </div>
              {errors.zipcode && (
                <span className="error-msg">Please enter the value</span>
              )}
            </div>
          </div>
          {/* div end */}
        </div>
        <GlobalExpander header={<div>
              {" "}
              Other Information{" "}
              {isEditOtherInformationPage && (
               
                <img
                  className="overview-pencil"
                  onClick={onClickEditOtherInfromationButton}
                  src={pencil}
                  alt=""
                ></img>
              )}
              {!isEditOtherInformationPage && (
                <button
                  className="other-information-save-icon"
                  disabled={isEditOtherInformationPage}
                  type="submit"
                >
                  <img className="overview-pencil" src={save} alt=""></img>
                </button>
              )}{" "}
            </div>}>
          <div className="overview-table">
            {/* //div start */}
            <div className="single-page-two-div">
              <div className="single-page-club-div-left">
                <div className="single-page-title"> Employees </div>
                <input
                  type="number"
                  disabled={isEditOtherInformationPage}
                  className={
                    isEditOtherInformationPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("no_of_employee", {})}
                >
                  {/* {stateData.company_name} */}
                </input>
              </div>

              <div className="single-page-club-div">
                <div className="single-page-title">Website </div>
                <input
                  type="link"
                  disabled={isEditOtherInformationPage}
                  className={
                    isEditOtherInformationPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("agency_website", {})}
                >
                  {/* {stateData.user_name}  */}
                </input>
              </div>
            </div>
            {/* div end */}
            <hr className="line"></hr>
            {/* //div start */}
            <div className="single-page-two-div">
              <div className="single-page-club-div-left">
                <div className="single-page-title"> Facebook Page </div>
                <input
                  type="link"
                  disabled={isEditOtherInformationPage}
                  className={
                    isEditOtherInformationPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("facebook_url", {})}
                >
                  {/* {stateData.company_name} */}
                </input>
              </div>

              <div className="single-page-club-div">
                <div className="single-page-title">Twitter Page </div>
                <input
                  type="link"
                  disabled={isEditOtherInformationPage}
                  className={
                    isEditOtherInformationPage? "single-page-value" : "after-click-edit"
                  }
                  {...register("twitter_url", {})}
                >
                  {/* {stateData.user_name}  */}
                </input>
              </div>
            </div>
            {/* div end */}
            <hr className="line"></hr>
            {/* //div start */}
            <div className="single-page-two-div">
              <div className="single-page-club-div-left">
                <div className="single-page-title"> Linkedin ID </div>
                <input
                  type="link"
                  disabled={isEditOtherInformationPage}
                  className={
                    isEditOtherInformationPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("linkedin_url", {})}
                >
                  {/* {stateData.company_name} */}
                </input>
              </div>

              <div className="single-page-club-div">
                <div className="single-page-title">Instagram </div>
                <input
                  type="link"
                  disabled={isEditOtherInformationPage}
                  className={
                    isEditOtherInformationPage ? "single-page-value" : "after-click-edit"
                  }
                  {...register("instagram_url", {})}
                >
                  {/* {stateData.user_name}  */}
                </input>
              </div>
            </div>
            {/* div end */}
            <hr className="line"></hr>
          </div>
        </GlobalExpander>
        </form>
        <GlobalExpander
          header={
            <div>
              {/* <h5 className="header-plus"> */}
              Contact{" "}
              <img
                className="contact-arrow"
                onClick={onClickContact}
                src={ContactArrow}
                alt=""
              />{" "}
              {/* </h5> */}
              {contactView && (
                <div className="create-view">
                  <h5 onClick={onClickCreate} className="contact-create">
                    Create
                  </h5>
                  <hr className="create-view-line"></hr>
                  <p className="contact-view" onClick={onClickView}>
                    View
                  </p>
                </div>
              )}
            </div>
          }
        >
          <div className="expander-body-proposal-details">
            <>
              <div className="proposal-titles">
                <div className="details-expander-title center"> Name </div>
                <div className="details-expander-title center ">Email </div>
                <div className="details-expander-title center">Phone No </div>
                <div className="details-expander-title center">Department</div>
                <div className="details-expander-title center">Position </div>
              </div>
                <CustomerContact/>
            
              <div className="proposal-titles">
                <div className="details-expander-title center">
                  {" "}
                  {errors.contact_name && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}{" "}
                </div>
                <div className="details-expander-title center ">
                  {errors.contact_email && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
                <div className="details-expander-title center">
                  {errors.contact_mobile && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}{" "}
                </div>
                <div className="details-expander-title center">
                  {errors.department && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
                <div className="details-expander-title center">
                  {errors.position && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
              </div>
            </>
          </div>
        </GlobalExpander>

        <Expander
          header={"Bank Account Details"}
          title1={"Bank Name"}
          value1={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.bankDetails.bank_name ?? "Not available")
          }
          title2={"Contact Person"}
          value2={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.bankDetails.account_name ?? "Not available")
          }
          title3={"Account Number"}
          value3={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.bankDetails.bank_account_no ?? "Not available")
          }
          title4={"Account Type"}
          value4={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.bankDetails.account_type ?? "Not available")
          }
          title5={"IFSC code"}
          value5={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.bankDetails.ifsc_code ?? "Not available")
          }
          title6={"Bank Address"}
          value6={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.bankDetails.bank_address ?? "Not available")
          }
          title7={"Currency Code"}
          value7={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.basicDetails.currency_code ?? "Not available")
          }
          title8={"Currency"}
          value8={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.basicDetails.currency ?? "Not available")
          }
          title9={"Bank Branch"}
          value9={
            customerDetails &&
            customerDetails.bankDetails &&
            (customerDetails.bankDetails.ifsc ?? "Not available")
              ? customerDetails.bankDetails.ifsc ?? "Not available"
              : "Not available"
          }
        />

        <GlobalExpander header={"Proposal"}>
          {customerDetails && customerDetails.proposaltList ? (
            <div className="expander-body-proposal-details">
              {customerDetails.proposaltList &&
              customerDetails.proposaltList[0] ? (
                <>
                  <div className="proposal-titles">
                    <div className="details-proposal-expander-title">
                      Project Name{" "}
                    </div>
                    <div className="details-proposal-customer-title">
                      Customer{" "}
                    </div>
                    <div className="details-proposal-customer-title">
                      Category{" "}
                    </div>
                    <div className="details-proposal-price-title">Price </div>
                  </div>

                  {customerDetails &&
                    customerDetails.proposaltList &&
                    customerDetails.proposaltList
                      .slice(0, 5)
                      .map((proposalDetails, index) => {
                        return (
                          <div className={index % 2 === 0
                            ? "proposal-values"
                            : "proposal-even-values"}>
                            {(
                              <>
                                <div className="details-proposal-expander-value">
                                  {" "}
                                  {proposalDetails.project_name ??
                                    "Not available"}
                                </div>
                                <div className="details-proposal-customer-value">
                                  {proposalDetails.customer_name ??
                                    "Not available"}
                                </div>
                                <div className="details-proposal-customer-value">
                                  {proposalDetails.category ?? "Not available"}
                                </div>
                                <div className="details-proposal-price-value">
                                  {proposalDetails.price +
                                    " " +
                                    customerDetails.basicDetails.currency ??
                                    "Not available"}
                                </div>
                              </>
                            ) ?? "Not available"}
                          </div>
                        );
                      })}
                </>
              ) : (
                <center>
                  <h4>Data Not Available</h4>
                </center>
              )}
            </div>
          ) : (
            "Not available"
          )}
        </GlobalExpander>

        <GlobalExpander header={"Project"}>
          <div className="expander-body-proposal-details">
            {customerDetails &&
            customerDetails.projectList &&
            customerDetails.projectList[0] ? (
              <>
                <div className="proposal-titles">
                  <div className="details-proposal-expander-title">
                    Project{" "}
                  </div>
                  <div className="details-proposal-category-title">
                    Category{" "}
                  </div>
                  <div className="details-proposal-milestone-title">
                    Milestones{" "}
                  </div>
                  <div className="details-proposal-customer-title">
                    Attachment{" "}
                  </div>
                  <div className="details-proposal-customer-title">Budget</div>
                </div>

                {customerDetails &&
                  customerDetails.projectList &&
                  customerDetails.projectList
                    .slice(0, 5)
                    .map((projectDetails, index) => {
                      return (
                        <div className={index % 2 === 0
                          ? "proposal-values"
                          : "proposal-even-values"}>
                          {(
                            <>
                              <div className="details-proposal-expander-value">
                                {" "}
                                {projectDetails.project_name ?? "Not available"}
                              </div>
                              <div className="details-proposal-category-value">
                                {projectDetails.solution_name ??
                                  "Not available"}
                              </div>
                              <div className="details-proposal-milestone-value">
                                {projectDetails.total_milestones ??
                                  "Not available"}
                              </div>
                              <div className="details-proposal-customer-value">
                                {projectDetails.attachment_url ??
                                  "Not available"}
                              </div>
                              <div className="details-proposal-customer-value">
                                {projectDetails.project_budget ??
                                  "Not available"}
                              </div>
                            </>
                          ) ?? "Not available"}
                        </div>
                      );
                    })}
              </>
            ) : (
              <center>
                <h4>Data Not Available</h4>
              </center>
            )}
          </div>
        </GlobalExpander>
        <GlobalExpander header={"Invoice"}>
          <div className="expander-body-invoice-details">
            {customerDetails &&
            customerDetails.invoiceList &&
            customerDetails.invoiceList[0] ? (
              <>
                <div className="invoice-titles">
                  <div className="details-invoice-number-title">Invoice No. </div>
                  <div className="details-expander-invoice-title"> Project</div>
                  <div className="details-expander-invoice-title">
                    Description{" "}
                  </div>
                  <div className="details-amount-title">Amount </div>
                  <div className="details-invoice-date-title">Invoice Date</div>
                  <div className="details-attachment-title">Attachment</div>
                  <div className="details-expander-due-title">Due Date</div>
                </div>

                {customerDetails &&
                  customerDetails.invoiceList &&
                  customerDetails.invoiceList
                    .slice(0, 5)
                    .map((InvoiceDetails, index) => {
                      return (
                        <div className={
                          index % 2 === 0
                            ? "invoice-values"
                            : "invoice-even-values"
                        }>
                          <>
                            <div className="details-invoice-number-value">
                              {" "}
                              {InvoiceDetails.invoice_no ?? "Not Available"}
                            </div>
                            <div className="details-expander-invoice-value">
                              {InvoiceDetails.project_name.slice &&(InvoiceDetails.project_name.slice(0, 50) ??
                                "Not Available")}
                            </div>
                            <div className="details-expander-invoice-value">
                              {InvoiceDetails.project_description ??
                                "Not Available"}
                            </div>
                            <div className="details-amount-value">
                              {" $ " + InvoiceDetails.user_milestone_amount ??
                                "Not Available"}
                            </div>
                            <div className="details-invoice-date-value">
                              {InvoiceDetails.invoice_date ?? "Not Available"}
                            </div>
                            <div className="details-attachment-value">
                              <a
                                href={InvoiceDetails.invoice_url}
                                target="_blank"
                              >
                                {InvoiceDetails.invoice_url
                                  ? "Click Here"
                                  : "Not Available"}
                              </a>
                            </div>
                            <div className="details-expander-due-value">
                              {InvoiceDetails.due ?? "Not Available"}
                            </div>
                          </>
                        </div>
                      );
                    })}
              </>
            ) : (
              <center>
                <h4>Data Not Available</h4>
              </center>
            )}
          </div>
        </GlobalExpander>
        <GlobalExpander header={"Receipt"}>
          <div className="expander-body-invoice-details">
            {customerDetails &&
            customerDetails.receiptList &&
            customerDetails.receiptList[0] ? (
              <>
                <div className="invoice-titles">
                  <div className="details-invoice-title">Receipt </div>
                  <div className="details-expander-invoice-title"> Project</div>
                  <div className="details-expander-invoice-title">
                    Description{" "}
                  </div>
                  <div className="details-amount-title">Amount </div>
                  <div className="details-expander-title">Receipt Date</div>
                  <div className="details-expander-title">Attachment</div>
                </div>

                {customerDetails &&
                  customerDetails.receiptList &&
                  customerDetails.receiptList
                    .slice(0, 5)
                    .map((paymentDetails, index) => {
                      return (
                        <div className={
                          index % 2 === 0
                            ? "invoice-values"
                            : "invoice-even-values"
                        }>
                          <>
                            <div className="details-invoice-value">
                              {" "}
                              {paymentDetails.receipt_no ?? "Not Available"}
                            </div>
                            <div className="details-expander-invoice-value">
                              {paymentDetails.project_name ?? "Not Available"}
                            </div>
                            <div className="details-expander-invoice-value">
                              {parse(
                                paymentDetails.project_description.slice(0, 50)
                              ) ?? "Not Available"}
                            </div>
                            <div className="details-amount-value">
                              {" $ " + paymentDetails.amount ?? "Not Available"}
                            </div>
                            <div className="details-expander-value">
                              {paymentDetails.receipt_date ?? "Not Available"}
                            </div>
                            <div className="details-expander-value">
                              {paymentDetails.acc ?? "Not Available"}
                            </div>
                          </>
                        </div>
                      );
                    })}
              </>
            ) : (
              <center>
                <h4>Data Not Available</h4>
              </center>
            )}
          </div>
        </GlobalExpander>
        {/*   */}
      </div>
      </>
  );
};

export default CustomerDashboardDetails;
