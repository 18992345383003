import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import DateIcon from '../../images/dateIcon.svg'
import './campaignlisttable.css'
import { dsUrl } from '../../pages/Api/api'
import axios from 'axios'



const customStyles = {
    headCells: {
        style: {
            background: ' #0900BC',
            color: 'white',
            fontSize: '16px',
            fontWeight: 400
        },
    },
};


const CampaignListTable = ({ campaignList }) => {

    const getCampaignList = (recipients) => {
        if (typeof (recipients) === 'object') return null;
        const recipientArr = recipients?.substring(1, recipients.length - 1).split(',')
        let finalArr = []
        recipientArr.forEach(r => {
            finalArr.push(<span class="m-1">{r}</span>)
        })
        return finalArr
    }

    const [columns, setColumns] = useState([
        {
            name: <div className='d-flex align-items-center'>
                <span><img src={DateIcon} alt="" style={{ width: "18px", marginRight: "5px" }} /></span>
                Date
            </div>,
            // name: "Date",
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: "Campaign Name",
            selector: row => row.campaign_name,
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true,
            cell: (row) => (
                <div
                    className={
                        row.status === 1
                            ? "campaign-main-status-Delivered"
                            : "campaign-main-status-Delayed"
                    }
                >
                    {row.status === 1 ? "Delivered" : "Not Delivered"}
                </div>
            ),
        },
        {
            name: "Subscriber List",
            selector: row => row.campaign_recipients,
            sortable: true,
            cell: (row) => (
                <div>
                    {getCampaignList(row.campaign_recipients)}
                </div>
            )
        },

    ])

    useEffect(() => {

    }, [campaignList])

    const [data, setData] = useState([
        {
            id: 1,
            Date: "12-2-2222",
            CampaignName: "ERP Project",
            Status: <button className='campaign-delivered-button px-2 py-1'>Deliverd</button>,
            SubscriberList: "ERP Software-250 Members"

        },
        {
            id: 2,
            Date: "12-2-2222",
            CampaignName: "ERP Project",
            Status: <button className='campaign-delivered-button px-2 py-1'>Deliverd</button>,
            SubscriberList: "ERP Software-250 Members"

        },
        {
            id: 3,
            Date: "12-2-2222",
            CampaignName: "ERP Project",
            Status: <button className='campaign-delivered-button px-2 py-1'>Deliverd</button>,
            SubscriberList: "ERP Software-250 Members"

        },
        {
            id: 4,
            Date: "12-2-2222",
            CampaignName: "ERP Project",
            Status: <button className='campaign-delivered-button px-2 py-1'>Deliverd</button>,
            SubscriberList: "ERP Software-250 Members"

        },
        {
            id: 5,
            Date: "12-2-2222",
            CampaignName: "ERP Project",
            Status: <button className='campaign-delivered-button px-2 py-1'>Deliverd</button>,
            SubscriberList: "ERP Software-250 Members"

        },
        {
            id: 6,
            Date: "12-2-2222",
            CampaignName: "ERP Project",
            Status: <button className='campaign-delivered-button px-2 py-1'>Deliverd</button>,
            SubscriberList: "ERP Software-250 Members"

        },
        {
            id: 7,
            Date: "12-2-2222",
            CampaignName: "ERP Project",
            Status: <button className='campaign-delivered-button px-2 py-1'>Deliverd</button>,
            SubscriberList: "ERP Software-250 Members"

        },
        {
            id: 8,
            Date: "12-2-2222",
            CampaignName: "ERP Project",
            Status: <button className='campaign-delivered-button px-2 py-1'>Deliverd</button>,
            SubscriberList: "ERP Software-250 Members"

        },
    ])
    return (
        <>
            {/* {JSON.stringify(campaignList)} */}
            <DataTable
                columns={columns}
                data={campaignList}
                customStyles={customStyles}
                pagination
            />
        </>
    )
}

export default CampaignListTable