import React, { useState } from 'react'
import DataTable from 'react-data-table-component';




const customStyles = {
    headCells: {
        style: {
            background: ' #0900BC',
            color: 'white',
            fontSize: '16px',
            fontFamily: 'Public Sans',
            fontStyle: 'normal',
            fontWeight: 600
        },
    },
};


const SubscriberListTable = () => {


    const [columns, setColumns] = useState([
        {

            name: "Subscriber Name",
            selector: row => row.SubscriberName,
        },
        {
            name: "Subscribers Email",
            selector: row => row.SubscribersEmail,
        },
        {
            name: "Sent",
            selector: row => row.Sent,
        },
        {
            name: "Opens",
            selector: row => row.Opens,
        },
        {
            name: "Clicks",
            selector: row => row.Clicks,
        },
        {
            name: "Subscriber On",
            selector: row => row.SubscriberOn,
        },


    ])

    const [data, setData] = useState([
        {
            id: 1,
            SubscriberName: "Amitab Mishra",
            SubscribersEmail: "Amitab@gmail.com",
            Sent: "0",
            Opens: "0",
            Clicks: "0",
            SubscriberOn: "11.08.2022"

        },
        {
            id: 2,
            SubscriberName: "Amitab Mishra",
            SubscribersEmail: "Amitab@gmail.com",
            Sent: "0",
            Opens: "0",
            Clicks: "0",
            SubscriberOn: "11.08.2022"

        },
        {
            id: 3,
            SubscriberName: "Amitab Mishra",
            SubscribersEmail: "Amitab@gmail.com",
            Sent: "0",
            Opens: "0",
            Clicks: "0",
            SubscriberOn: "11.08.2022"

        },
        {
            id: 4,
            SubscriberName: "Amitab Mishra",
            SubscribersEmail: "Amitab@gmail.com",
            Sent: "0",
            Opens: "0",
            Clicks: "0",
            SubscriberOn: "11.08.2022"

        },
        {
            id: 5,
            SubscriberName: "Amitab Mishra",
            SubscribersEmail: "Amitab@gmail.com",
            Sent: "0",
            Opens: "0",
            Clicks: "0",
            SubscriberOn: "11.08.2022"

        },
        {
            id: 6,
            SubscriberName: "Amitab Mishra",
            SubscribersEmail: "Amitab@gmail.com",
            Sent: "0",
            Opens: "0",
            Clicks: "0",
            SubscriberOn: "11.08.2022"

        },
        {
            id: 7,
            SubscriberName: "Amitab Mishra",
            SubscribersEmail: "Amitab@gmail.com",
            Sent: "0",
            Opens: "0",
            Clicks: "0",
            SubscriberOn: "11.08.2022"

        },
        {
            id: 8,
            SubscriberName: "Amitab Mishra",
            SubscribersEmail: "Amitab@gmail.com",
            Sent: "0",
            Opens: "0",
            Clicks: "0",
            SubscriberOn: "11.08.2022"

        },
    ])
    return (
        <>

            <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
            />
        </>
    )
}

export default SubscriberListTable