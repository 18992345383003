import React from 'react'

const VendorFilter = ({ filterText, onFilter, onClear ,placeholder}) => (
    <>
    <input
        id="search"
        type="text"
        placeholder={placeholder}
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="filter-vendor-name"
    />
      </>
    );
export default VendorFilter 
