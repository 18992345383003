import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import DashboardHome from "./pages/dashboard/DashboardHome";
import VendorRegistration from "./pages/dashboard/VendorRegister";
import Praposals from "./pages/dashboard/Praposals";
import Login from "./pages/login";
// import DashBoardHomePage from "./pages/DashboardHome";
import Vendor from "./pages/DashboardHome/Vendor";
import CustomerDashboard from "./pages/CustomerDashboard";
import MainDashboard from "./pages/MainPage";
import Projects from "./pages/Projects";
import Proposals from "./pages/Proposals";
import AllContacts from "./pages/Campaigns/Audience/AllContacts";
import EmailCampaign from "./pages/Campaigns/EmailCampaign";
import EmailSubscriberList from "./pages/Campaigns/Audience/EmailSubscriber";
import ImportContact from "./pages/Campaigns/Audience/ImportContact";
import ParticularUseDetails from "./pages/Campaigns/Audience/ParticularUserDetails";
import EditParticularUseDetails from "./pages/Campaigns/Audience/EditPud";
import CampaignMainPage from "./pages/Campaigns";
import SubscriberList from "./pages/Campaigns/SubscriberList";
import CampaignDetails from "./pages/Campaigns/CampaignPages/CampaignDetails";
import CamapignContent from "./pages/Campaigns/CampaignPages/CampaignContent";
import CampaignRecipients from "./pages/Campaigns/CampaignPages/CampaignRecipients";
import CampaignReview from "./pages/Campaigns/CampaignPages/CampaignReview";

import VendorContacts from "./pages/ContactVendor";
import VendorDashboardDetails from "./pages/VendorDashboardDetails";
import CreateContact from "./pages/CreateContact";
import { ToastContainer } from "react-toastify";
import ProtectedRoutes from "./ProtectedRoutes";
import CustomerDashboardDetails from "./pages/CustomerDashboard/customerdashboarddetails";
import MoreInformationForCC from "./pages/CreateContact/MoreInformation";
import OtherForCC from "./pages/CreateContact/Other";
import ActionsForCC from "./pages/CreateContact/Actions";
import CreateVendorContact from "./pages/DashboardHome/Vendor/CreateVendorContact";
import AddCustomerContact from "./pages/CustomerDashboard/addCustomerContact";
import CustomerContacts from "./pages/CustomerContactTable";
import ProposalSingleDetails from "./pages/Proposals/proposalSingleDetails";
import TargetCustomer from "./pages/Target/Customer";
import AddSingleCustomer from "./pages/Target/Customer/AddSingleCustomer";
import CustomerOverView from "./pages/Target/Customer/CustomerOverview";
import TargetAddMultipleCustomer from "./pages/Target/Customer/AddMultipleCustomer";
import MilestoneSingleDetails from "./pages/Proposals/milestoneSingleDetails";
import MilestoneReceipt from "./pages/Proposals/milestoneReceipt";
import ProjectSingleDetails from "./pages/Projects/projectSingleDetails";
import VendorFeature from "./pages/Target/Vendor/VendorFeature";
import AddNewVendor from "./pages/Target/Vendor/AddSingleVendor/AddNewVendor"
import TargetOverviewSingle from "./pages/Target/Vendor/AddSingleVendor/Overview"
import TargetEditVendorPage from "./pages/Target/Vendor/AddSingleVendor/EditVendorPage"
import AddMultipleVendor from "./pages/Target/Vendor/AddMultipleVendor/AddMultipleVendor"
import TargetOverviewMultiple from "./pages/Target/Vendor/AddMultipleVendor/Overview"
import TargetAddCustomerContact from "./pages/Target/CustomerContact/AddCustomerContact";
import TargetCustomerConatactOverview from "./pages/Target/CustomerContact/CustomerContactOverview";
import EditCustomerContact from "./pages/Target/CustomerContact/EditCustomerContact";
import EditTargetCustomer from "./pages/Target/Customer/EditTargetCustomer";
import TargetCustomerContact from "./pages/Target/CustomerContact";
import TargetAddVendorContact from "./pages/Target/VendorContact/AddVendorContact";
import TargetVendorConatactOverview from "./pages/Target/VendorContact/VendorContactOverview";
import TargetVendorContact from "./pages/Target/VendorContact";
import TargetAddMultipleCustomerOverview from "./pages/Target/Customer/AddMultipleCustomer/oveview";
import TargetCallExpert from "./pages/Target/CallExpert/CallExpert"
// import VendorProfile from "./pages/DashboardHome/VendorProfile"
import ProductList from "./pages/MainProduct/Products/ProductList"
import CategoryList from "./pages/MainProduct/Categories/CategoryList";
import DepartmentList from "./pages/MainProduct/Departments/DepartmentList";
import IndustryList from "./pages/MainProduct/Industries/IndustryList";
import AddProduct from "./pages/MainProduct/Products/AddProducts/AddProduct";
import MainEditProduct from "./pages/MainProduct/Products/AddProducts/EditProduct";
import AddNewProducts from "./pages/AddNewProducts/AddNewProducts";
import VendorEditProduct from "./pages/EditProduct/VendorEditProduct";
import AdminReviews from "./pages/Reviews";
import SingleReview from "./pages/Reviews/singleReview";



const App = () => {
 
  
  return (

    <BrowserRouter>
      <ToastContainer />
      <Routes>
      <Route path="/" element={<Login />} />
        
      
        <Route path="/" element={<ProtectedRoutes />}>
          {/* <Route path="/dashboard" element={<DashboardHome />} /> */}
          {/* <Route path="/vendor-registration" element={<VendorRegistration />} />
        <Route path='/praposals' element={<Praposals />} /> */}
          {/* <Route path="/VendorProfile" element={<VendorProfile/>}/> */}
          <Route path="customer-details" element={<CustomerDashboardDetails />} />
          <Route path="vendor-dashboard" element={<Vendor />} />
          <Route path="/target-vendor-main-page" element={<VendorFeature />} />
          <Route path="/target-add-single-vendor" element={<AddNewVendor />} />
          <Route path="/target-overview-vendor" element={<TargetOverviewSingle />} />
          <Route path="/target-edit-vendor" element={<TargetEditVendorPage />} />
          <Route path="/target-add-multiple-vendor" element={<AddMultipleVendor />} />
          <Route path="/target-overview-multiple-vendor" element={<TargetOverviewMultiple />} />
          <Route path="customer-dashboard" element={<CustomerDashboard />} />
          <Route path="admin-dashboard" element={<MainDashboard />} />
          {/* <Route path="/Home" element={<DashBoardHomePage />} /> */}
          {/* <Route path="email-dashboard" element={<EmailDashboard />} /> */}
          <Route path="projects" element={<Projects />} />
          <Route path="projects-details" element={<ProjectSingleDetails />} />
          <Route path="proposals" element={<Proposals />} />
          <Route path="proposals-details" element={<ProposalSingleDetails />} />
          <Route path="milestone-details" element={<MilestoneSingleDetails />} />
          <Route path="milestone-receipt-details" element={<MilestoneReceipt />} />
          <Route path="vendor-view-contacts" element={<VendorContacts />} />
          <Route path="customer-contacts" element={<CustomerContacts />} />
          <Route path="add-new-contacts" element={<CreateVendorContact />} />
          <Route path="add-customer-new-contact" element={<AddCustomerContact />} />
          <Route path="vendor-details" element={<VendorDashboardDetails />} />
          <Route path="create-contacts" element={<CreateContact />} />
          <Route path="/all-contacts" element={<AllContacts />} />
          <Route path="/email-subscriber-list" element={<EmailSubscriberList />} />
          <Route path="/email-campaign" element={<EmailCampaign />} />
          <Route path="/import-contact" element={<ImportContact />} />
          <Route path="/particular-user-details" element={<ParticularUseDetails />} />
          <Route path="/edit-particular-user-details" element={<EditParticularUseDetails />} />
          <Route path="campaign-main-page" element={<CampaignMainPage />} />
          <Route path="/subscriber-list" element={<SubscriberList />} />
          <Route path="/campaign-details" element={<CampaignDetails />} />
          <Route path="/campaign-content" element={<CamapignContent />} />
          <Route path="/campaign-recipients" element={<CampaignRecipients />} />
          <Route path="/campaign-review" element={<CampaignReview />} />
          <Route path="/more-information" element={<MoreInformationForCC />} />
          <Route path="/other-create-contact" element={<OtherForCC />} />
          <Route path="/action-create-contact" element={<ActionsForCC />} />
          <Route path="/target-customer-main-page" element={<TargetCustomer />} />
          <Route path="/target-add-single-customer" element={<AddSingleCustomer />} />
          <Route path="/target-customer-over-view" element={<CustomerOverView />} />
          <Route path="/target-add-multiple-customer" element={<TargetAddMultipleCustomer />} />
          <Route path="/target-add-customer-contact" element={<TargetAddCustomerContact />} />
          <Route path="/target-customer-contact-overview" element={<TargetCustomerConatactOverview />} />
          <Route path="/target-customer-contact-main-page" element={<TargetCustomerContact />} />
          <Route path="/target-add-vendor-contact" element={<TargetAddVendorContact />} />
          <Route path="/target-vendor-contact-overview" element={<TargetVendorConatactOverview />} />
          <Route path="/target-vendor-contact-main-page" element={<TargetVendorContact />} />
          <Route path="/target-edit-customer-contact" element={<EditCustomerContact />} />
          <Route path="/target-edit-customer" element={<EditTargetCustomer />} />
          <Route path="/target-call-expert" element={<TargetCallExpert />} />
          <Route path="main-product" element={<ProductList />}></Route>
          <Route path="main-product/category-list" element={<CategoryList />}></Route>
          <Route path="main-product/department-list" element={<DepartmentList />}></Route>
          <Route path="main-product/industry-list" element={<IndustryList />}></Route>
          <Route path="main-product/add-product" element={<AddProduct />}></Route>
          <Route path="/target-add-multiple-customer-over-view" element={<TargetAddMultipleCustomerOverview />} />
          <Route path="/main-product/edit-product" element={<MainEditProduct />}></Route>
          <Route path="/add-product" element={<AddNewProducts/>}></Route>
          <Route path="/edit-product" element={<VendorEditProduct/>}></Route>
          <Route path="/admin-reviews" element={<AdminReviews />}></Route>
          <Route path="/single-review" element={<SingleReview />}></Route>

        </Route>
      </Routes>
    </BrowserRouter>


  );
};

export default App;
