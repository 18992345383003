import React from 'react'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import CopyandPaste from '../../../../components/CopyandPaste'
import ImportContactUploadFile from '../../../../components/UploadFileImport'
import './importcontact.css'

const ImportContact = () => {
    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className='main-body-container '>
                <h1 className='import-top-name container mt-3'>Import</h1>
                <div>
                    <hr />
                </div>
                <div className='container'>
                    <div className=''>
                        <CopyandPaste />
                    </div>
                    <div className='my-4'>
                        <ImportContactUploadFile />
                    </div>
                    <div className=''>
                        <button className='continue-btn-import px-2 py-1'>Continue</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ImportContact