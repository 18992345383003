import React, { useState, useEffect } from 'react'
import './campaignMainpage.css'
import DashBoardheader from '../../components/DashboardHeader'
import SideNavBar from '../../components/Sidebar'
import CampaignTopCards from '../../components/CampaignTopCards'
import HornIcon from '../../images/HornIcon.svg'
import EmailIcon from '../../images/emailSymbol.svg'
import CampaignListTable from '../../components/CampaignListTable'
import { useNavigate } from 'react-router-dom'
import { BaseUrl, dsUrl } from "../Api/api";
import axios from "axios";

const CampaignMainPage = () => {
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [countNumbers, setCountNumbers] = useState([]);

  const handleClicksubscriberList = () => {
    navigate("/subscriber-list");
  };

  const handleClickCreateList = () => {
    navigate("/campaign-details");
  };

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    axios
      .get(`${BaseUrl}AdminCampaign/Get-Campaign-List`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        console.log("responsedata ==>", response.data?.data.campaignList);
        console.log("cards==>", response.data?.data.countData);
        setCampaignList(response.data?.data.campaignList);
        setCountNumbers(response.data?.data.countData);
      });
  }, []);

  // const countEmailNumbers = Object.keys(countNumbers)
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container my-4">
        {/* {JSON.stringify(countNumbers)} */}

        <div className="container d-flex align-items-center justify-content-between mt-3 mb-5">
          <div className="col-lg-9 col-md-10 top-cards-camp-main-page py-3">
            {countNumbers.map((stat, index) => (
              <>
                <div
                  className=" col-lg-3 px-4"
                  style={{ borderRight: "1px solid gray" }}
                >
                  <p className="top-card-names ">Campaign</p>
                  <h1 className="top-cards-numbers" key={index}>
                    {stat.total_campaign}
                  </h1>
                </div>
                <div className="px-4" style={{ borderRight: "1px solid gray" }}>
                  <p className="top-card-names">Campaign Delivered</p>
                  <h1 className="top-cards-numbers">
                    {stat.total_delivered_count}
                  </h1>
                </div>
                <div
                  className="col-lg-3 px-4"
                  style={{ borderRight: "1px solid gray" }}
                >
                  <p className="top-card-names">Email Sent</p>
                  <h1 className="top-cards-numbers">{stat.total_count}</h1>
                </div>
                <div className="px-4">
                  <p className="top-card-names">Opened</p>
                  <h1 className="top-cards-numbers">{stat.email_sent_count}</h1>
                </div>
              </>
            ))}
          </div>
          <div className="col-lg-2 col-md-2 mx-2" style={{ width: "200px" }}>
            <p
              className="campaign-create-button px-3 py-2"
              onClick={handleClickCreateList}
              style={{ cursor: "pointer", marginTop: "100px" }}
            >
              Create Campaign
            </p>
          </div>
        </div>

        <div className="container">
          {/* {JSON.stringify(campaignList)} */}
          <div className="campaign-table-bgm">
            <CampaignListTable campaignList={campaignList} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignMainPage