import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pencil from "../../images/pencil-square.svg";
import save from "../../images/save.png";
import { getSingleVendorDetails } from "../../Store/Reducer/Reducer";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { BaseUrl } from "../Api/api";
import "react-toastify/dist/ReactToastify.css";
const VendorContact = () => {
  const { vendorList, loading, selectedVendorId, vendorDetails } = useSelector(
    (state) => state.conqtAdmin
  );
  const userToken = localStorage.getItem("token");
  const {
    register,
    handleSubmit,
    control,
    index,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [isEditContactPage, setIsEditContactPage] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-100);
  // console.log(vendorDetails.contactsList, "contactsList");
  const fieldArrayName = "test";
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: fieldArrayName,
    defaultValues: {
      [fieldArrayName]: [],
    },
  });
  // console.log(fields, "fields from hook form use field array");
  // console.log(fieldArrayName, "fields array from contact list");

  const onSubmitContact = (data) => {
    // console.log(data, "contact on submit data");
    data = data.test;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      vendor_id: selectedVendorId,
      vendor_contact_id: vendorDetails.contactsList[selectedIndex].id,
      person_name: data[selectedIndex].person_name,
      person_email: data[selectedIndex].person_email,
      contact_no: data[selectedIndex].contact_no,
      department: data[selectedIndex].department,
      position: data[selectedIndex].position,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}AdminVendorContact/Vendor-Contact-Update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          setIsEditContactPage(false);
          setSelectedIndex(-100);
          console.log(result, "vendor-contact-update patch api response");
          toast("Data updated successfully");
        } else {
          toast("Network error occurred");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const onClickContactEditButton = (e, index) => {
    e.preventDefault();
    // `test.${index}` === `test.${index}` ?
    // setContactEdit(true)
    setIsEditContactPage(true);
    setSelectedIndex(index);
    // : setIsEditContactPage(false)
    // console.log("btn clicked from ******** block");
  };
  //   const onClickEditButton = (key) => {
  //     // `test.${index}` === `test.${index}` ?
  //     setContactEdit(true)
  //     setIsEditContactPage(false)
  //     // : setIsEditContactPage(false)
  //     console.log("btn clicked from contacts block");
  //   };
  useEffect(() => {
    reset({ test: [] });
    vendorDetails.contactsList.map((e, index) => {
      append({
        person_name: e.person_name,
        person_email: e.person_email,
        contact_no: e.contact_no,
        department: e.department,
        position: e.position,
      });
    });
  }, [vendorDetails.contactsList]);

  return (
    <div className="contacts-main-div">
      <form onSubmit={handleSubmit(onSubmitContact)}>
        {fields.map((field, index) => (
          <fieldset className="contacts-field-set" key={field.id}>
            {!isEditContactPage && (
              <div
                onClick={(e) => {
                  onClickContactEditButton(e, index);
                }}
                // key={index}
                className="other-information-save-button"
              >
                <img
                  className="contacts-pencil"
                  //   onClick={onClickContactEditButton}

                  src={pencil}
                  alt=""
                ></img>
              </div>
            )}
            {isEditContactPage && selectedIndex === index && (
              <button
                className="other-information-save-button"
                disabled={!isEditContactPage}
                type="submit"
              >
                <img className="contacts-pencil" src={save} alt=""></img>
              </button>
            )}
            {/* {JSON.stringify(selectedIndex)}
            {JSON.stringify(index)}
            {JSON.stringify(!isEditContactPage && selectedIndex === index)} */}
            <input
              disabled={selectedIndex !== index}
              className={
                selectedIndex === index 
                  ?  "after-contact-click-edit" 
                 :   index % 2 === 0 ? "single-contact-value" : "even-contact-value"
              }
              {...register(`test.${index}.person_name`, {
                required: true,
                maxLength: 25,
              })}
            ></input>
            <input
              disabled={selectedIndex !== index}
              type="email"
              className={
                selectedIndex === index
                  ? "after-contact-click-edit"
                  :   index % 2 === 0 ? "single-contact-value" : "even-contact-value"
              }
              {...register(`test.${index}.person_email`, {
                required: true,
              })}
            ></input>
            <input
              disabled={selectedIndex !== index}
              type="number"
              className={
                selectedIndex === index
                  ? "after-contact-click-edit"
                  :   index % 2 === 0 ? "single-contact-value" : "even-contact-value"
              }
              {...register(`test.${index}.contact_no`, {
                required: true,
                minLength: 10,
              })}
            ></input>
            <input
              disabled={selectedIndex !== index}
              className={
                selectedIndex === index
                  ? "after-contact-click-edit"
                  :   index % 2 === 0 ? "single-contact-value" : "even-contact-value"
              }
              {...register(`test.${index}.department`, {
                required: true,
              })}
            ></input>
            <input
              disabled={selectedIndex !== index}
              className={
                selectedIndex === index
                  ? "after-contact-click-edit"
                  :   index % 2 === 0 ? "single-contact-value" : "even-contact-value"
              }
              {...register(`test.${index}.position`, {
                required: true,
              })}
            ></input>
          </fieldset>
        ))}
      </form>
      <div className="proposal-titles">
        <div className="details-expander-title center">
          {" "}
          {errors.person_name && (
            <span className="contact-error-msg">Please enter the value</span>
          )}{" "}
        </div>
        <div className="details-expander-title center ">
          {errors.person_email && (
            <span className="contact-error-msg">Please enter the value</span>
          )}
        </div>
        <div className="details-expander-title center">
          {errors.contact_no && (
            <span className="contact-error-msg">Please enter the value</span>
          )}{" "}
        </div>
        <div className="details-expander-title center">
          {errors.department && (
            <span className="contact-error-msg">Please enter the value</span>
          )}
        </div>
        <div className="details-expander-title center">
          {errors.position && (
            <span className="contact-error-msg">Please enter the value</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorContact;
