import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseUrl } from '../../../../Api/api';
import { toast } from 'react-toastify';


const MainEditProduct = () => {

        const dispatch = useDispatch();

        const [currentData, setCurrentData] = useState({});

        const { selectedSingleProductData } = useSelector((state) => state.mainProductList);
        console.log("selectedSingleProductData", selectedSingleProductData);

        const product_id = selectedSingleProductData?.id;

        useEffect(() => {

                axios.get(`${BaseUrl}product/Get-Product-By-Id?product_id=${product_id}`,
                        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
                        .then((res) => {
                                if (res.data.status === 200) {
                                        console.log(" product data based on id res", res);
                                        setCurrentData(res.data.data)
                                        // setClose(handleClose(false))
                                        // toast.success("Department Deleted Successfully")
                                        // window.location.reload();
                                        // window.scrollTo(0, 0);

                                }
                                else if (res.data.status === 401) {
                                        console.log("error res", res);
                                        // setClose(handleClose(false))
                                        // toast.error(res.data.message)
                                }

                        })
                        .catch((err) => {
                                console.log("err", err);
                                toast.error("Something went wrong")
                        })

        }, [])

        console.log("currentData product name", currentData?.productDetail?.product_name);

        return (
                <>
                        <div>MainEditProduct</div>
                        {JSON.stringify(selectedSingleProductData)}
                        {JSON.stringify(currentData)}
                </>

        )
}

export default MainEditProduct