import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const myReducer = createSlice({
    name: "conqtAdmin",
    initialState: {
        loading: false,
        campaign: {},
        campaignFile:[]
    },
    reducers: {
        updateCampaign: (state, action) => {
            state.campaign = { ...state.campaign, ...action.payload };
        },
        clearCampaign: (state, action) => {
            state.campaign = {};
        },
        updateCampaignFile: (state, action) => {
            state.campaignFile = action.payload;
          }
    },
    extraReducers: {
    },
})

export const {
    updateCampaign,
    clearCampaign,
    updateCampaignFile
} = myReducer.actions;

export default myReducer.reducer;

