import React, { useEffect, useState } from "react";
import DashBoardheader from "../../../../components/DashboardHeader";
import SideNavBar from "../../../../components/Sidebar";
import "./addSingleCustomer.css";
import TargetAddSingleCustomerBreadcrumbs from "../../../../components/TASCBreadCrumbs";
import { useNavigate } from "react-router-dom";
import BackArrowIcon from "../../../../images/BackArrow.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateAddSingleCustomer } from "../../../../Store/Reducer/AddSingleCustomerReducer";
import BiCircleIcon from "../../../../images/bi_info-circle.svg";
import { getAllCountryList } from "../../../../Store/Reducer/CountryListReducer";
import { BaseUrl } from "../../../Api/api";

const AddSingleCustomer = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [solutions, setSolutions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickTargetCustomer = () => {
    navigate("/target-customer-main-page");
  };
  const { addSingleCustomer } = useSelector((state) => state.addSingleCustomer);
  const { countryList, selectedCountryId } = useSelector(
    (state) => state.allCountryList
  );
  console.log("countryList", countryList);

  useEffect(() => {
    for (const key in addSingleCustomer) {
      if (Object.hasOwnProperty.call(addSingleCustomer, key)) {
        const element = addSingleCustomer[key];
        setValue(key, element);
      }
    }
  }, [addSingleCustomer]);

  const onSubmit = (data) => {
    dispatch(updateAddSingleCustomer(data));
    navigate("/target-customer-over-view");
    console.log("data", data);
    // console.log("updated", updateAddSingleCustomer(data))
  };

  // mobile number validation

  const validateMobileNumber = (value) => {
    const mobileNumberRegex = /^[0-9]{10}$/;
    const maxLength = 10;
    if (!value) {
      return 'Mobile number is required';
    }
    if (!mobileNumberRegex.test(value)) {
      return 'Invalid mobile number';
    }
    if (value.length > maxLength) {
      return `Mobile number must be ${maxLength} digits or less`;
    }
    return true;
  };

  console.log("Addsinglecustomer", addSingleCustomer);
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}vendor/Get-Solutions`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("Get Solutions", JSON.parse(result).data);
        setSolutions(JSON.parse(result).data);
      })
      .catch((error) => console.log("error", error));
  }, []);
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container" style={{ marginBottom: "100px" }}>
        <div className="container py-1">
          <TargetAddSingleCustomerBreadcrumbs />
        </div>
        <div className="container py-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className="target-add-customer-top-name">
              Add Single Customer
            </h5>
          </div>
          <div>
            <img
              onClick={handleClickTargetCustomer}
              src={BackArrowIcon}
              alt=""
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="container">
          <div className="target-add-single-customer px-3 py-4">
            <div style={{ paddingBottom: "50px" }}>
              <div>
                <h5 className="sub-h py-3">Overview</h5>
              </div>
              <div className="d-flex">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Name</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    {...register("customer_name", {
                      required: "Required Field",
                    })}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  />
                  <div>
                    <p className="required-field">
                      {errors.customer_name?.message}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">
                    Comany Name
                  </p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    {...register("company_name", {
                      required: "Required Field",
                    })}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  />
                  <div>
                    <p className="required-field">
                      {errors.company_name?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex my-4">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Phone No.</p>
                </div>
                <div className="col-4">
                  <input
                    type="number"
                    {...register("customer_phone_no", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      validate: validateMobileNumber,
                    })}
                    style={{
                      border: "1px solid #91959C",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  />
                  <div>
                    {errors.customer_phone_no?.type === 'required' && <p className="required-field"> Required Field</p>}
                    {errors.customer_phone_no?.type === 'minLength' && <p className="required-field">Mobile number must be at least 10 digits</p>}
                    {errors.customer_phone_no?.type === 'maxLength' && <p className="required-field">Mobile number must be no more than 10 digits</p>}
                  </div>
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Email ID</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    {...register("customer_email",
                      { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }
                    )}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  />
                  <div>
                    {errors.customer_email?.type === 'required' && <p className="required-field">Required Field</p>}
                    {errors.customer_email?.type === 'pattern' && <p className="required-field">Invalid email format (must be a valid Gmail address)</p>}
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">
                    Company Address
                  </p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    {...register("customer_address", {
                      required: "Required Field",
                    })}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  />
                  <div>
                    <p className="required-field">
                      {errors.customer_address?.message}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Country</p>
                </div>
                <div className="col-4">
                  {/* <input type="text"
                                        {...register('country', { required: "Required Field" })}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    /> */}

                  <select
                    name=""
                    id=""
                    type="text"
                    {...register("customer_country", {
                      required: " Required Field",
                    })}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "7px",
                      width: "300px",
                      background: "white",
                    }}
                  >
                    <option className="dropdown-country" value="select country">
                      {" "}
                      select country
                    </option>
                    {countryList &&
                      countryList.map((country, index) => {
                        return (
                          <option key={index} value={country.value}>
                            {country.country}
                          </option>
                        );
                      })}
                  </select>
                  <div>
                    <p className="required-field">
                      {errors.customer_country?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex my-4">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">City</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    {...register("customer_city", {
                      required: "Required Field",
                    })}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  />
                  <div>
                    <p className="required-field">
                      {errors.customer_city?.message}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Zip</p>
                </div>
                <div className="col-4">
                  <input
                    type="number"
                    {...register("customer_pincode", {
                      required: "Required Field",
                    })}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  />
                  <div>
                    <p className="required-field">
                      {errors.customer_pincode?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-2">
                  <p className="target-add-single-customer-lables">State</p>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    {...register("customer_state", {
                      required: "Required Field",
                    })}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  />
                  <div>
                    <p className="required-field">
                      {errors.customer_state?.message}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <p className="target-add-single-customer-lables">Category</p>
                </div>
                <div className="col-4">
                  <select
                    type="text"
                    {...register("customer_category", {
                      required: "Required Field",
                    })}
                    style={{
                      border: " 1px solid gray",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "300px",
                    }}
                  >
                    {solutions.map((solutions) => (
                      <option
                        key={solutions.id}
                        value={solutions.solutions_name}
                      >
                        {solutions.solutions_name}
                      </option>
                    ))}
                  </select>
                  <div>
                    <p className="required-field">
                      {errors.customer_category?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='' style={{ marginBottom: "100px" }}>
                            <div>
                                <h5 className='sub-h py-4'>Other Information</h5>
                            </div>
                            <div className='d-flex'>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>
                                        Employees <span><img src={BiCircleIcon} alt="" /></span>
                                    </p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        {...register('employess', { required: "Required Field" })}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                    <div>
                                        <p className='required-field'>{errors.employess?.message}</p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Website</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        {...register('website', { required: "Required Field" })}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                    <div>
                                        <p className='required-field'>{errors.website?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex my-4'>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Facebook Page</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        {...register('facebook', { required: "Required Field" })}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                    <div>
                                        <p className='required-field'>{errors.facebook?.message}</p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Twitter Page</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        {...register('twitterpage', { required: "Required Field" })}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                    <div>
                                        <p className='required-field'>{errors.twitterpage?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Linkedin ID</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"{...register('linkedinIcon', { required: "Required Field" })}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                    <div>
                                        <p className='required-field'>{errors.linkedinIcon?.message}</p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <p className='target-add-single-customer-lables'>Instagram</p>
                                </div>
                                <div className="col-4">
                                    <input type="text"
                                        {...register('instagram', { required: "Required Field" })}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    />
                                    <div>
                                        <p className='required-field'>{errors.instagram?.message}</p>
                                    </div>
                                </div>
                            </div>

                        </div> */}
          </div>
        </div>
        <div className="d-flex justify-content-end container mt-5">
          <button
            onClick={handleSubmit(onSubmit)}
            className="target-add-customer-bottom-save-btn px-4 py-1"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default AddSingleCustomer;
