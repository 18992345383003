import React, { useEffect, useState } from 'react'
import DashBoardheader from '../../components/DashboardHeader'
import SideNavBar from '../../components/Sidebar'
// import './target-customer.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import TargetCustomerTable from '../../../components/TargetCustomerTable';
import { useNavigate } from 'react-router-dom';
import { BaseUrl, dsUrl } from '../Api/api';
import axios from 'axios';
import ReviewsTable from '../../components/ReviewsTable';


const AdminReviews = () => {

        const [reviewsList, setReviewsList] = useState([])
        const [searchList, setSearchList] = useState([])

        const navigate = useNavigate()
        // const handleClickAddSingleCustomer = () => {
        //         navigate('/target-add-single-customer')
        // }

        // const handleClickAddMultipleCustomer = () => {
        //         navigate('/target-add-multiple-customer')
        // }

        useEffect(() => {
                const userToken = localStorage.getItem("token")
                axios
                        .get(`${BaseUrl}admin/Get-All-Products-Review`,
                                { headers: { Authorization: `Bearer ${userToken}` } }
                        )
                        .then((response) => {
                                console.log("reviewsList ==>", response.data)
                                if (response.data.status === 200) {
                                        setReviewsList(response.data.data)
                                        setSearchList(response.data.data)
                                } else if (response.data.status === 401) {
                                        console.log("error")
                                }
                        })
                        .catch((err) => {
                                console.log("error", err)
                        })
        }, [])

        const handleSearch = (e) => {
                const filtered = reviewsList.filter(l => {
                        return l.product_name && l.product_name.toLowerCase().includes(e.target.value)
                                || l.review_title && l.review_title.toLowerCase().includes(e.target.value)


                })
                setSearchList(filtered)
        }

        return (
                <>
                        <DashBoardheader />
                        <SideNavBar />
                        <div className="main-body-container">
                                <div className="container">
                                        <div className='mt-3'>
                                                <div className="">
                                                        <h1 className="customer-top-name">Reviews</h1>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                        <div className="customer-top-search d-flex">
                                                                <div>
                                                                        <input type="text"
                                                                                placeholder='search'
                                                                                style={{
                                                                                        border: "1px solid #B5B7BC",
                                                                                        padding: '5px',
                                                                                        borderRadius: "8px",
                                                                                        marginRight: "20px",
                                                                                        width: "250px"
                                                                                }}
                                                                                onChange={handleSearch}
                                                                        />
                                                                </div>

                                                        </div>
                                                </div>

                                        </div>
                                </div>
                                {/* {JSON.stringify(reviewsList)} */}
                                <div className="container mt-3">
                                        <ReviewsTable reviewsList={searchList} />
                                </div>

                        </div>
                </>
        )
}

export default AdminReviews