import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DashBoardheader from '../../../../components/DashboardHeader';
import SideNavBar from '../../../../components/Sidebar';
import TargetCustomerContactBreadCrumbs from '../../../../components/TCCBreadcrumbs';
import './editcustomercontact.css';
import axios from 'axios';
import { BaseUrl } from '../../../Api/api';
import { dsUrl } from '../../../Api/api';
import { Navigate, useNavigate } from 'react-router-dom';

const EditCustomerContact = () => {

    const { register, handleSubmit, setValue, getValues, watch, formState: { errors } } = useForm();

    const [countries, setCountries] = useState([])
    const navigate = useNavigate()

    const { targetCustomerContact } = useSelector((state) => state.targetCustomerContact);

    console.log("@@@@===>", targetCustomerContact)



    useEffect(() => {
        Object.keys(targetCustomerContact)?.map(key => {
            setValue(key, targetCustomerContact[key])
        })
    }, [targetCustomerContact])



    useEffect(() => {
        const userToken = localStorage.getItem("token")
        axios
            .get(`${BaseUrl}common/Get-All-Country`,
                { headers: { Authorization: `Bearer ${userToken}` } }
            )
            .then((response) => {
                console.log("countryList ========>", response.data)
                setCountries(response.data.data.country_list)

            })
    }, [])

    const updateContact = () => {

        const userToken = localStorage.getItem("token")

        const data = getValues();
        data['target_customer_contact_id'] = targetCustomerContact.contact_customer_id
        axios
          .post(
            `${BaseUrl}AdminTargetCustomer/Update-Admin-Target-Customer-contact`,
            data,
            { headers: { Authorization: `Bearer ${userToken}` } }
          )
          .then((response) => {
            console.log("response", response);
            navigate("/target-customer-contact-main-page");
            return response.data;
          })
          .catch((error) => {
            console.log();
          });
    }
    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className="main-body-container">
                <div className="container">
                    <TargetCustomerContactBreadCrumbs />
                </div>
                {/* {JSON.stringify(countries)} */}
                <div className="container py-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="target-add-customer-top-name">
                            Add Contact
                        </h5>
                    </div>
                    <div>
                        <img
                            // onClick={handleClickcustomercontact}
                            // src={BackArrowIcon}
                            alt=""
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </div>
                <div className="container py-3" style={{ marginBottom: "30px" }}>
                    {/* <div className='d-flex'>
                        <div className='customer-list'>Customer List</div>
                        <div className='px-2'>
                            <select
                                type="text"

                                {...register("target_customer_id", {
                                    required: "Required Field",
                                })}
                                style={{
                                    border: " 1px solid gray",
                                    borderRadius: "8px",
                                    padding: "5px",
                                    width: "300px",
                                    backgroundColor: "white"
                                }}
                            >
                                {customList?.map(customer => (
                                    <option value={customer?.id} className="">{customer?.company_name}</option>
                                ))}
                            </select>
                            <div>
                                <p className="required-field">
                                    {errors.company_name?.message}
                                </p>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="container">
                    <div className='target-add-customer-contact px-3 py-4'
                    >
                        <div className="d-flex">
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Name</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    {...register("contact_p_name", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid #0900BC",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_name?.message}
                                    </p>
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Email</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="email"
                                    {...register("contact_p_email", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid #0900BC",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_email?.message}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex my-4">
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Phone No.</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="number"
                                    {...register("contact_p_phone", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid #0900BC",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_phone?.message}
                                    </p>
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Department</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    {...register("contact_p_department", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid #0900BC",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_department?.message}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{ paddingBottom: "100px" }}>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Position</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    {...register("contact_p_role", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid #0900BC",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_role?.message}
                                    </p>
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Country</p>
                            </div>
                            <div className="col-4">
                                {/* <input
                                    type="text"
                                    {...register("contact_p_country", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                /> */}
                                <select
                                    name=""
                                    id=""
                                    type="text"
                                    placeholder='select Country'
                                    {...register("contact_p_country", {
                                        required: " Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid #0900BC",
                                        borderRadius: "8px",
                                        padding: "7px",
                                        width: "300px",
                                        background: "white",
                                    }}
                                >
                                    <option className="dropdown-country">
                                    </option>
                                    {countries?.map(con => (
                                        <option value={con?.country} className="">{con?.country}</option>
                                    ))}
                                </select>
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_country?.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='d-flex align-items-center justify-content-end py-3'
                    // style={{ paddingRight: "120px" }}
                    >
                        <button
                            onClick={updateContact}
                            className='customer-contact-add-btn px-4 py-1'>Done</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCustomerContact