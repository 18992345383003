import React, { useState, useEffect } from 'react'
import DashBoardheader from '../../../components/DashboardHeader'
import SideNavBar from '../../../components/Sidebar'
import './customercontact.css'
import TableCustomerContactTable from '../../../components/TargetCustomerContactTable'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BaseUrl, dsUrl } from "../../Api/api";

const CustomerContact = () => {
  const [customerContactList, setCustomerContactList] = useState([]);
  const [searchCustomerContactList, setSearchCustomerContactList] = useState(
    []
  );

  const navigate = useNavigate();
  const handleClickAddCustomerContact = () => {
    navigate("/target-add-customer-contact");
  };

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    axios
      .get(`${BaseUrl}AdminTargetCustomer/Get-Target-Customer-Contact-List`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        console.log("customecontactList ==>", response.data);
        setCustomerContactList(response.data.data);
        setSearchCustomerContactList(response.data.data);
      });
  }, []);

  const handleSearch = (e) => {
    const filtered = customerContactList.filter((l) => {
      return (
        (l.contact_p_name &&
          l.contact_p_name.toLowerCase().includes(e.target.value)) ||
        (l.contact_p_email &&
          l.contact_p_email.toLowerCase().includes(e.target.value)) ||
        (l.contact_p_department &&
          l.contact_p_department.toLowerCase().includes(e.target.value)) ||
        (l.contact_p_role &&
          l.contact_p_role.toLowerCase().includes(e.target.value)) ||
        (l.contact_p_phone &&
          l.contact_p_phone.toLowerCase().includes(e.target.value))
      );
    });
    setSearchCustomerContactList(filtered);
  };

  console.log("====>", customerContactList);
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container">
        <div className="container">
          <div className="">
            <div className="mt-3">
              <div className="">
                <h1 className="customer-top-name">Customer Contact</h1>
              </div>
              <div className="d-flex justify-content-end py-2">
                <div className="customer-top-search d-flex">
                  <div>
                    <input
                      type="text"
                      placeholder="search"
                      style={{
                        border: "1px solid #B5B7BC",
                        padding: "5px",
                        borderRadius: "8px",
                        marginRight: "20px",
                      }}
                      onChange={handleSearch}
                    />
                  </div>
                  <div>
                    <button
                      onClick={handleClickAddCustomerContact}
                      class="target-top-add-btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Add Customer Contact
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <TableCustomerContactTable
              customerContactList={searchCustomerContactList}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerContact;