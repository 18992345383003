import DashBoardheader from "../../../../components/DashboardHeader";
import SideNavBar from "../../../../components/Sidebar";
import "./addmultiplevendor.css";
import UploadImageIcon from "../../../../images/uploadImageIcon.svg";
import DownloadSVGIcon from "../../../../images/downloadSVGIcon.svg";
import BackArrowIcon from "../../../../images/BackArrow.svg";
import DownloadIcon from "../../../../images/MicrosoftTeams-image.png"
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    updateUploadedFile, clearUplaodedFile
  } from "../../../../Store/Reducer/SaveFileInBlob";
import { useDispatch, useSelector } from "react-redux";
import TargetVendorBreadCrumbs from "../TargetVendorBreadCrumbs";
import { useForm } from "react-hook-form";
import { BaseUrl } from "../../../Api/api";

const AddMultipleVendor = (props) => {

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});


    const dispatch = useDispatch();
  
  const hiddenFileInput = React.useRef(null);
  const [disable, setDisable] = useState(false);
  const [inputFile, setInputFile] = useState(null);
  const [downloadFileUrl, setDownloadFileUrl] = useState(null);
  const [solutions, setSolutions] = useState([]);
  const sampleFileUrl = "https://connect-internal.s3.ap-southeast-1.amazonaws.com/1672915585713-SampleDownload.csv"

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}vendor/Get-Solutions`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("Get Solutions", JSON.parse(result).data);
        setSolutions(JSON.parse(result).data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const onClickSave = (event) => {
    navigate('/target-overview-multiple-vendor')
  }
  const handleClearInput = (event) => {
    setInputFile(null);
    setDisable(false)
    downloadFileUrl.parentNode.removeChild(downloadFileUrl);
    dispatch(clearUplaodedFile());
        
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const OnclickDownloadFile = (event) => {
    downloadFileUrl.click();
  }

  const OnclickDownloadSampleFile = (event) => {
    const link = document.createElement('a');
      
      link.href = sampleFileUrl;
      link.setAttribute(
        'download',
        'Sample',
      );
      document.body.appendChild(link);
      link.click();
  }
  const { uploadfile } =
  useSelector((state) => state.uploadFile);

  const handleChange = (event) => {
    setDisable(true)
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded.name);
    setInputFile(fileUploaded);
    dispatch(updateUploadedFile(fileUploaded))
    const url = window.URL.createObjectURL(
        new Blob([fileUploaded]),
      );
      const link = document.createElement('a');
      
      link.href = url;
      link.setAttribute(
        'download',
        `${fileUploaded.name}`,
      );
      document.body.appendChild(link);
      setDownloadFileUrl(link)
     
  };


  console.log(uploadfile);
  const navigate = useNavigate();
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container">
      <div className="container py-2 mt-2">
          <TargetVendorBreadCrumbs />
        </div> 
        <div className="container py-4 d-flex justify-content-between align-items-center">
          <div>
            <h5 className="target-add-customer-top-name">
              Add Multiple Customer
            </h5>
          </div>
        </div>
        <div className="container">
          <div className="upload-target-amc-container">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {/* <button
                  class="btn btn-light dropdown-toggle"
                 
                  
                >
                  Select Catagory
                </button> */}
                <select
                    className="btn btn-light dropdown-toggle"
                    style={{
                      border: "1px solid gray",
                      width: "200px",
                      height:"auto",
                    }}
                    type="button"
                    aria-expanded="false"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    placeholder="Select Category"
                    {...register("category", {
                      required: "Required Field",
                    })}
                  >
                    {solutions.map((solutions) => (
                      <option
                        key={solutions.id}
                        value={solutions.solutions_name}
                      >
                        {solutions.solutions_name}
                      </option>
                    ))}
                  </select>
              </div>
              <div className="d-flex">
                <div className="px-4">
                  <p>List Name</p>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter List Name"
                    style={{
                      border: " 1px solid #B5B7BC",
                      borderRadius: "8px",
                      padding: "5px",
                      width: "400px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="target-upload-amc my-5">
              {inputFile == null ? (
                <>
                  {" "}
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "0",
                      height: "maxContent",
                      width: "maxContent",
                    }}
                    onClick={handleClick}
                  >
                    <img
                      src={UploadImageIcon}
                      alt=""
                      style={{ width: "100px" }}
                    />
                  </button>
                  <input
                  accept=".csv,text/csv,.xls"
                    className="target-upload-amc my-5"
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    onChange={handleChange}
                    type="file"
                    name="file"
                  />
                </>
              ) : (
                <>
                  {inputFile.name}{" "}
                  <button
                    onClick={handleClearInput}
                    className="close-button-input-file"
                  >
                    {" "}
                    X
                  </button>{" "}
                </>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {inputFile == null ? (
                  <><button onClick={OnclickDownloadSampleFile} style={{
                    backgroundColor: "transparent",
                    border: "0",
                    height: "maxContent",
                    width: "maxContent",
                  }}>
                   <img
                    src={DownloadIcon}
                    alt=""
                    style={{width:"40px"}}
                  />
                Download Sample
                  </button></>
                ) : (
                  <>

                  <button onClick={OnclickDownloadFile} style={{
                      backgroundColor: "transparent",
                      border: "0",
                      height: "maxContent",
                      width: "maxContent",
                    }}>
                     <img
                      src={DownloadIcon}
                      alt=""
                      style={{width:"40px"}}
                    />
                  
                    {inputFile.name}
                    </button>
                  </>
                )}
              </div>
              <div>
                {disable?<button onClick={onClickSave} className="save-button-multiple px-4 py-1">
                  Save
                </button>:<button disabled className="save-button-multiple px-4 py-1">
                  Save
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMultipleVendor;
