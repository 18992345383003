import React, { useEffect, useState } from 'react'
import './campaignDetails.css'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import BreadCrumbs from '../../../../components/BreadCrumbs'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateCampaign } from '../../../../Store/Reducer/CampaignReducer'


const CampaignDetails = () => {
    const { register, getValues, handleSubmit, setValue, formState: { errors } } = useForm({})
    const dispatch = useDispatch();
    const { campaign } = useSelector(state => state.campaign)

    useEffect(() => {
        for (const key in campaign) {
            if (Object.hasOwnProperty.call(campaign, key)) {
                const element = campaign[key];
                setValue(key, element)
            }
        }
    }, [campaign])

    const [campaignName, setCampaignName] = useState('')
    const navigate = useNavigate()

    const handleClickContentPage = () => {
        navigate('/campaign-content')
    }

    const handleChange = (e) => {
        setCampaignName(e.target.value)
        console.log("====", campaignName)
    }

    const onSubmit = (data) => {
        dispatch(updateCampaign(data))
        navigate('/campaign-content')
    }
    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className='main-body-container mb-5'>
                <div className="container py-2 mt-2">
                    <BreadCrumbs />
                </div>
                <div className='container py-4 d-flex justify-content-between align-items-center'>
                    <h2 className='campaign-details-top-name'>Campaign Details</h2>
                    {/* <img src={BackArrow} alt="" /> */}
                </div>
                {/* {JSON.stringify(campaign, null, 2)} */}

                <div className='container'>
                    <div className='campaign-details-top-card py-5'>
                        <div className='container d-flex flex-column '>
                            <div className='select-sender-type'>
                                select sender type
                            </div>
                            <div className='px-2'>
                                <form action="" >
                                    <div className='d-flex flex-column'>
                                        <div className='py-2'>
                                            <input type="radio" id="vendor" name="campaign_type" value="Vendor"
                                                {...register('campaign_type', { required: 'Required Field' })}
                                            />
                                            <label htmlFor="vendor" className='sender-type px-2'>Vendor</label>
                                        </div>
                                        <div>
                                            <input type="radio" id="customer" name="camapign_type" value="Customer"
                                                {...register('campaign_type', { required: 'Required Field' })}
                                            />
                                            <label htmlFor="customer" className='sender-type px-2'>Customer</label>
                                        </div>
                                        <div>
                                            <p className='required-field'>{errors.campaign_type?.message}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='container mt-2 py-2'>
                            <div>
                                <p className='campaign-top-name'>Campaign Name</p>
                            </div>
                            <div className='campaign-software-name mt-2'>
                                {/* <p className='campaign-top-name px-3'>
                            ERP Software
                        </p> */}
                                <input name='campaign_name' {...register('campaign_name', { required: 'Required Filed' })} type="text" style={{
                                    width: "100%",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderRadius: "5px"
                                }} />
                            </div>
                            <div>
                                <p className='required-field'>{errors.campaign_name?.message}</p>
                            </div>
                        </div>

                        <div className=' container py-1 '>
                            <div>
                                <p className='campaign-top-name'>Campaign Description</p>
                            </div>
                            <div className='campaign-software-content mt-2 pt-2 pb-2'>
                                <textarea name="campaign_description" {...register('campaign_description', { required: 'Required Field' })} style={{
                                    width: "100%", height: "150px",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderRadius: "5px"
                                }} />

                            </div>
                            <div>
                                <p className='required-field'>{errors.campaign_description?.message}</p>
                            </div>
                            <div className='d-flex justify-content-end mt-5'>
                                <button className='next-btn-campaign px-5 py-2' onClick={handleSubmit(onSubmit)}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default CampaignDetails