import React, { useState, useEffect } from "react";
import "../AddProducts.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIndustryListTableData } from "../../../../../Store/Reducer/IndustryList";
import { getDepartmentListTableData } from "../../../../../Store/Reducer/DepartmentList";
import { getCategoryListTableData } from "../../../../../Store/Reducer/CategoryList";
import Multiselect from "multiselect-react-dropdown";

const AddFilterBySection = ({
  formValues,
  setFormValues,
  handleChange,
  formErrors,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");
  const {
    industryList,
    loading: industryLoading,
    selectedIndustryId,
    selectedSingleIndustryData,
  } = useSelector((state) => state.industryList);

  const {
    categoryList,
    loading: categoryLoading,
    selectedCategoryId,
  } = useSelector((state) => state.categoryList);

  const {
    departmentList,
    loading: departmentLoading,
    selectedDepartmentId,
  } = useSelector((state) => state.departmentList);
  const userToken = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getIndustryListTableData());
    dispatch(getDepartmentListTableData());
    dispatch(getCategoryListTableData());
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  }, []);

  const onChangeFunc = (e) => {
    handleChange(e);
    setSelectedCategory(e.target.value);
  };

  const setSubCategory = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      ["category_name"]: e.target.selectedOptions[0].dataset.category,
    }));
  };
  const setIndustry = () => {};
  const setDepartment = () => {};

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  const onSelectDepartment = (selectedList, selectedItem) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["department_id"]: selectedList.map((item) => item.id),
      ["department_name"]: selectedList.map((item) => item.department_name),
    }));
  };
  const onRemoveDepartment = (selectedList, removedItem) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["department_id"]: selectedList.map((item) => item.id),
      ["department_name"]: selectedList.map((item) => item.department_name),
    }));
  };

  const onSelectIndustry = (selectedList, selectedItem) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["industry_id"]: selectedList.map((item) => item.id),
      ["industry_name"]: selectedList.map((item) => item.industry_name),
    }));
  };
  const onRemoveIndustry = (selectedList, removedItem) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["industry_id"]: selectedList.map((item) => item.id),
      ["industry_name"]: selectedList.map((item) => item.industry_name),
    }));
  };

  return (
    <>
      <div className="addnew-containerr">
        <div className="addnew-product-section-div">
          <div className="addnew-text-underline">
            <h1 className="addnew-product-section-title">Filter By</h1>
          </div>
        </div>
        <div className="AddFilterBySectionDiv">
          <div className="select-pricing-model-filterby">
            <label
              htmlFor="parent_category_id"
              className="addnew-product-Input-label"
            >
              Category
            </label>
            <select
              onChange={onChangeFunc}
              name="parent_category_id"
              className="addnew-product-Input"
              id=""
            >
              <option value={0} hidden>
                {" "}
                Select{" "}
              </option>
              {categoryList.map((item) => {
                return (
                  <option value={item.category_id}>{item.category_name}</option>
                );
              })}
            </select>
            <p style={{ margin: "0 0 1rem 0" }} className="err-message">{formErrors.parent_category_id}</p>
          </div>

          {selectedCategory.length !== 0 ? (
            <div className="select-pricing-model-filterby">
              <label
                htmlFor="category_id"
                className="addnew-product-Input-label"
              >
                Sub Category
              </label>
              <select
                onChange={setSubCategory}
                name="category_id"
                className="addnew-product-Input"
                id=""
              >
                <option value={0} hidden>
                  {" "}
                  Select{" "}
                </option>
                {categoryList.map((item) => {
                  return item.sub_categories.map((key) => {
                    return (
                      <option
                        data-category={key.category_name}
                        value={key.category_id}
                      >
                        {key.category_name}
                      </option>
                    );
                  });
                })}
              </select>
            <p style={{ margin: "0 0 1rem 0" }} className="err-message">{formErrors.category_name}</p>
            </div>
          ) : (
            <></>
          )}

          <div className="select-pricing-model-filterby">
            <label htmlFor="industry_id" className="addnew-product-Input-label">
              Industry
            </label>
            <Multiselect
              options={industryList}
              selectedValues={selectedIndustry}
              onSelect={onSelectIndustry}
              onRemove={onRemoveIndustry}
              displayValue="industry_name"
            />
            <p style={{ margin: "0 0 1rem 0" }} className="err-message">{formErrors.industry_name}</p>
          </div>

          <div className="select-pricing-model-filterby">
            <label
              htmlFor="department_id"
              className="addnew-product-Input-label"
            >
              Department
            </label>
            <Multiselect
              options={departmentList}
              selectedValues={selectedDepartment}
              onSelect={onSelectDepartment}
              onRemove={onRemoveDepartment}
              displayValue="department_name"
            />
            <p style={{ margin: "0 0 1rem 0" }} className="err-message">{formErrors.department_name}</p>
          </div>
        </div>
      
      </div>
    </>
  );
};

export default AddFilterBySection;
