import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import FilterComponent from "../DashboardHome/Vendor/VendorFilter/index";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import ColumnsIcon from "../../images/columns.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updatedData } from "../../Store/Reducer/Reducer";
import {
  getProposalTableData,
  updateSelectProposalId,
  updateSelectMilestoneId,
  updateSelectProposalTableCheckbox
} from "../../Store/Reducer/ProposalReducer";
import { transformProposalTableDataResponse } from "../../utils/util";

const Table = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [apiData, setData] = useState([]);
  const { proposalList, loading,selectedProposalTableCheckbox } = useSelector((state) => state.proposal);
  // console.log(proposalList, "data from proposal use Selector");
  //const [loading, setLoading] = useState(false);
  const [isShowTable, showTableUpdate] = useState(true);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});
  const [isColumns, isSetColumns] = useState({});
  const [checkBox, showCheckBox] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [contactView, setContactView] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState([

    {
      id: "Date",
      name: "Date",
      selector: (result) => result.created_at,
      // sortable: true,
    },
    {
      id: "Project Name",
      name: "Project Name",
      selector: (result) => result.project_name,
      sortable: true,
    },
    {
      id: "Vendor",
      name: "Vendor",
      selector: (result) => result.vendor_name,
      sortable: true,
    },

    {
      id: "Project Category",
      name: "Project Category",
      selector: (result) => result.solution_name,
      sortable: true,
    },
    {
      id: "Project Price",
      name: <div>Project Price</div>,
      selector: (result) => result.price,
      sortable: true,
    },
    {
      id: "Proposal Status",
      name: "Status",
      selector: (result) => result.proposal_status,
      sortable: true,
      cell: (result) => (
        <div
          className={
            result.proposal_status === "Accepted"
              ? "table-status-Delivered"
              : result.proposal_status === "In Review"
              ? "table-status-Delivering"
              : "table-status-Delayed"
          }
        >
          {result.proposal_status}
        </div>
      ),
    },
  ]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const userToken = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getProposalTableData());
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  }, []);
  console.log(proposalList)

  const proposalTableData = proposalList;

  const result = transformProposalTableDataResponse(proposalTableData);
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#0900bc",
        color: "white",
        fontSize: "14px",
        width: "100%",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "13.5px",
        color: "#103a5d",
      },
    },
    cells: {
      style: {
        fontSize: "13.5px"
      },
    },
  };
  const onClickContact = () => {
    setContactView(!contactView);
  };

  const onClickView = () => {
    navigate("/contacts");
  };
  const onClickCreate = () => {
    navigate("/create-contacts");
  };
  const onClickApply = () => {
    let select = allColumns.filter((value) => {
      return isSelected.includes(value.id);
    });
    setSelectedHeaders(select);
    dispatch(updateSelectProposalTableCheckbox(isSelected));
  };
  const onRowClicked = (row) => {
    navigate("/proposals-details");
    showTableUpdate(false);
    dispatch(updateSelectProposalId(row.proposal_id));
    dispatch(updateSelectMilestoneId(row.project_id));
    console.log(row)
    console.log(row.project_id,"row row.project_id  Announcement id proposalList");
    console.log(row.proposal_id,"row row.proposal_id proposalList")
  };

  const onClickCol = () => {
    showCheckBox(!checkBox);
  };
  useEffect(() => {
    let select = allColumns.filter((value) => {
      return (
        selectedProposalTableCheckbox&&
        selectedProposalTableCheckbox.includes(value.id)
      );
    });
    setSelectedHeaders(select);
  }, []);
  const allColumns = [
    {
      id: "Date",
      name: "Date",
      selector: (result) => result.created_at,
      // sortable: true,
    },
    {
      id: "Vendor",
      name: "Vendor",
      selector: (result) => result.vendor_name,
      sortable: true,
    },
    {
      id: "Project Name",
      name: "Project Name",
      selector: (result) => result.project_name,
      sortable: true,
    },
    {
      id: "Project Category",
      name: "Project Category",
      selector: (result) => result.solution_name,
      sortable: true,
    },
    {
      id: "Project Price",
      name: <div>Project Price</div>,
      selector: (result) => result.price,
      sortable: true,
    },

    {
      id: "Country",
      name: "Country",
      selector: (result) => result.country,
      sortable: true,
    },

    {
      id: "Proposal Status",
      name: "Status",
      selector: (result) => result.proposal_status,
      sortable: true,
      cell: (result) => (
        <div
          className={
            result.proposal_status === "Accepted"
              ? "table-status-Delivered"
              : result.proposal_status === "In Review"
              ? "table-status-Delivering"
              : "table-status-Delayed"
          }
        >
          {result.proposal_status}
        </div>
      ),
    },
    {
      id: "Phone Number",
      name: <div>Phone Number</div>,
      selector: (result) => result.vendor_phone_no,
      sortable: true,
    },
    {
      id: "Time Line",
      name: <div>Time Line</div>,
      selector: (result) => result.timeline,
      sortable: true,
    },
  ];

  const results =
    result &&
    result.filter(
      (item) =>
        (item.project_name &&
          item.project_name.toLowerCase().includes(filterText.toLowerCase())) ||
        item.solution_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.country.toLowerCase().includes(filterText.toLowerCase()) ||
        item.vendor_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.vendor_phone_no.toLowerCase().includes(filterText.toLowerCase()) ||
        item.timeline.toLowerCase().includes(filterText.toLowerCase()) ||
        item.proposal_status.toLowerCase().includes(filterText.toLowerCase()) ||
        item.price.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className="table-filter">
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            placeholder={"Search"}
          />
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="pt-3">
      {isShowTable && (
        <>
          <div
            className={checkBox ? "custom-columns" : "custom-columns-before"}
          >
            <img
              className="columns-icon"
              onClick={onClickCol}
              src={ColumnsIcon}
              alt=""
            />{" "}
            &nbsp;
            {checkBox && (
              <>
                <div className="dropdown-multi">
                  <DropdownMultiselect
                    handleOnChange={(value) => {
                      // console.log(value, "handleOnChange");
                      setIsSelected(value);
                    }}
                    options={[
                      "Country",
                      "Phone Number",
                      "Project Name",
                      "Project Category",
                      "Project Price",
                      "Proposal Status",
                      "Time Line",
                      "Vendor",
                      "Date",
                    ]}
                    selected={selectedProposalTableCheckbox}
                    placeholder="select"
                  />
                </div>
                <button className="apply-button" onClick={onClickApply}>
                  Apply
                </button>
              </>
            )}
          </div>
        </>
      )}
      {loading && (
        <center>
          <div
            className="spinner-border text-primary loader"
            role="status"
          ></div>
        </center>
      )}
      {isShowTable && (
        <>
          <DataTable
            columns={selectedHeaders}
            data={results}
            customStyles={customStyles}
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onRowClicked={onRowClicked}
            // highlightOnHover
            pagination
            // striped={true}
          />
        </>
      )}
    </div>
  );
};

export default Table;
