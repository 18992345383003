import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getCallExpertTableData = createAsyncThunk("callExpertList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}expert/Get-Experts-List`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log("result",result.data)
        return result.data
    })
    .catch((error) => console.log("error", error));
});



export const myReducer = createSlice({
  name: "callExpertList",
  initialState: {
    loading: false,
    selectedCallExpertId: "",
    callExpertList: [],
    selectedSingleExpertData:[],
    selectedCallExpertTableCheckbox: [
      "Date",
      "Client Name",
      "Company Email",
      "Phone Number",
      "Status",
      "Solution",
      "Industry",
      "Company Size",
      "Designation",
      "Client Query",
      "Feedback",
      "Action"
      ],
    

  },
  reducers: {
    updateSelectCallExpertList: (state, action) => {
      state.selectedCountryId = action.payload;
    },
    UpdateSelectedCallExpertId: (state, action) => {
      state.selectedCallExpertId = action.payload;
    },
    updateSelectedSingleExpertData : (state, action) => {
       state.selectedSingleExpertData = action.payload;
    },
    updateSelectedCallExpertTableCheckbox: (state, action) => {
        state.selectedCallExpertTableCheckbox = action.payload;
      },
    
  },
  extraReducers: {
    [getCallExpertTableData.pending]: (state) => {
      state.loading = true;
    },
    [getCallExpertTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.callExpertList = payload;

      // console.log(payload,"payload from reducer") 
    },
   

  },


})

export const {
    updateSelectCallExpertList,
    UpdateSelectedCallExpertId,
    updateSelectedCallExpertTableCheckbox,
    updateSelectedSingleExpertData
} = myReducer.actions;

export default myReducer.reducer;
