import React from 'react'
import './dashboardheader.css';
import  bellIcon from  '../../images/bell-icon.png';
// import Eng_flag from '../../assets/images/emojione_flag-for-united-states.svg'

const DashBoardheader = () => {
    return (

        <div className="dashboard-header py-2">
            <div className='d-flex align-items-center px-2'>
                {/* <img className='bell-icon' src={bellIcon} alt=""/> */}
                <p className='dashboard-vendor-circle'>C</p>
                <div className='px-2'>
                    <h5 className='dashboard-vendor-name'>Admin Name</h5>
                    <p className='dashboard-vendor-sub-name'>CONQT</p>
                </div>
            </div>
            <div className="dropdown">
                {/* <span className=" dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="" alt="" />
                </span> */}
                {/* <div className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                    <div style={{ width: '150px' }}>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item" >English (US)</li>
                          
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default DashBoardheader