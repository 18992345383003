import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const myReducer = createSlice({
    name: "conqtAdmin",
    initialState: {
        loading: false,
        addSingleVendor: {}
    },
    reducers: {
        updateAddSingleTargetVendor: (state, action) => {
            state.addSingleVendor = { ...state.addSingleVendor, ...action.payload };
        },
        clearAddSingleTargetVendor: (state, action) => {
            state.addSingleVendor = {};
        }
    },
    extraReducers: {
    },
})

export const {
    updateAddSingleTargetVendor,
    clearAddSingleTargetVendor
} = myReducer.actions;

export default myReducer.reducer;