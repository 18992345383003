import React, { useState, useEffect } from "react";
import VendorTable from "./VendorTable";
import TuneIcon from "@mui/icons-material/Tune";
import './target-vendor.css'
import SideNavBar from "../../../components/Sidebar";
import DashBoardheader from "../../../components/DashboardHeader";
import { useNavigate } from "react-router-dom";
import StatCard from "../../../components/Dashboard/StatCard/index.jsx";
import PersonIcon from "../../../images/material-symbols_person-add.png"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const VendorFeature = (props) => {
  const navigate = useNavigate();
  const [type, setType] = useState("all");
  const [enableFilter, setEnableFilter] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [apiData, setData] = useState([]);


  const toggleFilter = () => {
    setEnableFilter(!enableFilter);
  };
 

 
  
  return (
    <>
            <DashBoardheader />
            <SideNavBar />
            <div className="main-body-container">
                <div className="container">
                    <div className='mt-3'>
                        <div className="">
                            <h1 className="customer-top-name">Vendor</h1>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <div className="customer-top-search d-flex">
                                {/* <div>
                                    <input type="text"
                                        placeholder='search'
                                        style={{
                                            border: "1px solid #B5B7BC",
                                            padding: '5px',
                                            borderRadius: "8px",
                                            marginRight: "20px"
                                        }}
                                    />
                                </div> */}
                               
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container mt-3">
                    
                  <VendorTable
                    DelayClicked={() => {
                   
                    }}
                   isShowHeader={(val)=>{
                    setShowHeader(val)
                   }}
                  />
                </div>

            </div>
        </>
     
  );
};

export default VendorFeature;
