import React, { useEffect, useState } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import "./proposal.css";
import { useForm } from "react-hook-form";
import { BaseUrl } from "../Api/api";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import {
  getSingleProposalDetails,
  getProposalTableData,
  updateSinglePageMilestoneId,
} from "../../Store/Reducer/ProposalReducer";
import { useDispatch, useSelector } from "react-redux";
// import FilterComponent from "../DashboardHome/Vendor/VendorFilter/index";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { transformMilestoneTableDataResponse } from "../../utils/util";
import ColumnsIcon from "../../images/columns.png";
import AttachmentIcon from "../../images/Attachment_icon.png";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { CheckingValue } from "../../utils/util";
const ProposalSingleDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});
  const {
    proposalList,
    loading,
    proposalDetails,
    milestoneList,
    selectedProposalId,
    selectedMilestoneId,
  } = useSelector((state) => state.proposal);
  const userToken = localStorage.getItem("token");
  const [filterText, setFilterText] = useState("");
  const [isShowTable, showTableUpdate] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [checkBox, showCheckBox] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState([
    {
      id: "S.No",
      name: "S.No",
      selector: (result) => result.count,
      sortable: true,
    },
    {
      id: "Description",
      name: "Description",
      selector: (result) => result.milestone_description,
      sortable: true,
    },
    {
      id: "Amount",
      name: "Amount",
      selector: (result) => result.milestone_amount,
      sortable: true,
    },
    {
      id: "Percent",
      name: "Percent",
      selector: (result) => result.milestone_percent,
      sortable: true,
    },
    {
      id: "Status",
      name: "Status",
      selector: (result) => result.milestone_start_close_status,
      sortable: true,
    },
    {
      id: "Date",
      name: "Date",
      selector: (result) => result.milstone_timeline,
      sortable: true,
    },
  ]);

  
  //  console.log(proposalDetails, " ************data from proposal proposalDetails ");
  // console.log(milestoneList, " milestoneList use Selector");
  //  console.log(selectedMilestoneId, "selectedMilestoneId in details page");
  //  console.log(selectedProposalId, "selectedProposalId, in details page");
  // const projectDescription = parse(proposalDetails.project_description);
  // console.log(projectDescription, "projectDescription**********");
  console.log(selectedProposalId)
  console.log(selectedMilestoneId)
  useEffect(() => {
    dispatch(
      getSingleProposalDetails({
        proposal_id: selectedProposalId,
        announcement_id: selectedMilestoneId,
      })
    );
    // dispatch(getSingleProposalDetails(selectedMilestoneId));
  }, [selectedProposalId]);
  console.log(proposalDetails)
  useEffect(() => {
    if (proposalDetails) {
      Object.entries(proposalDetails).forEach(([name, value]) => {
        setValue(name, value);
      });
    }
  }, []);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        {/* <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder={""}
        /> */}
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const milestoneTableData = milestoneList;
  const result = transformMilestoneTableDataResponse(
    milestoneTableData,
    proposalDetails
  );
  const onRowClicked = (row) => {
    navigate("/milestone-details");
    showTableUpdate(false);
    dispatch(
      updateSinglePageMilestoneId({
        id: row.milestone_id,
        proposal_status: proposalDetails.proposal_status,
      })
    );

    console.log(proposalDetails.proposal_status, "proposal_status");
    // console.log(row.milestone_id, "milestone-details row list id");
  };
  const results =
    result &&
    result.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#0900bc",
        color: "white",
        fontSize: "14px",
        width: "100%",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "13.5px",
        color: "#103a5d",
      },
    },
    cells: {
      style: {
        fontSize: "13.5px",
      },
    },
  };
  const onClickApply = () => {
    let select = allColumns.filter((value) => {
      return isSelected.includes(value.id);
    });
    setSelectedHeaders(select);
  };
  const onClickCol = () => {
    showCheckBox(!checkBox);
  };
  const allColumns = [
    {
      id: "S.No",
      name: "S.No",
      selector: (result) => result.count,

      // sortable: true,
    },
    {
      id: "Description",
      name: "Description",
      selector: (result) => result.milestone_description,
      sortable: true,
    },
    {
      id: "Amount",
      name: "Amount",
      selector: (result) => result.milestone_amount,
      // sortable: true,
    },
    {
      id: "Percent",
      name: "Percent",
      selector: (result) => result.milestone_percent,
      sortable: true,
    },
    {
      id: "Status",
      name: "Status",
      selector: (result) => result.milestone_start_close_status,
      sortable: true,
    },
    {
      id: "Date",
      name: "Date",
      selector: (result) => result.milstone_timeline,
      sortable: true,
    },
    {
      id: "Project Name",
      name: "Project Name",
      selector: (result) => result.project_name,
      sortable: true,
    },
    {
      id: "Category",
      name: "Category",
      selector: (result) => result.solution_name,
      sortable: true,
    },
    {
      id: "Created Date",
      name: "Created Date",
      selector: (result) => result.created_at,
      sortable: true,
    },
  ];

  return (
    <div>
      <DashBoardheader />
      <SideNavBar />
      <div className=" container vendor-follow">
        <div className="vendor-follow-main-div">
          <h1 className="vendor-follow-header">
            {proposalDetails && proposalDetails.solution_name}
          </h1>
          {loading && (
            <center>
              <div
                className="spinner-border text-primary loader"
                role="status"
              ></div>
            </center>
          )}
          <div className="description-and-show-more">
            <h1 className="proposal-description">
              {proposalDetails &&
                proposalDetails.project_description &&
                (showMore
                  ? parse(proposalDetails.project_description)
                  : parse(
                      proposalDetails.project_description.substring(0, 50)
                    ))}
            </h1>
            <button
              className="show-more-button"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show Less" : "Show More"}
            </button>
          </div>
          <div className="proposal-input-label-main-div">
            <div className="d-flex col-12 pb-3">
              <div className="col-2">
                <p className="proposal-label">Assignee</p>
              </div>
              <div className="col-4">
                <div className="proposal-input">
                  {proposalDetails && proposalDetails.first_name
                    ? proposalDetails.first_name +
                      " " +
                      proposalDetails.last_name
                    : "Not Available"}
                </div>
                {/* <input
                  disabled
                  className="proposal-input"
                  type="text"
                  {...register("first_name", {
                    required: true,
                    maxLength: 30,
                  })}
                ></input> */}
                {errors.person_name && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>

              <div className="col-2 ">
                <div className="proposal-right-label">Location</div>
              </div>
              <div className="col-4">
                <div className="proposal-right-input">
                  {proposalDetails && proposalDetails.city
                    ? proposalDetails.city
                    : "Not Available"}
                </div>
                {/* <input
                  disabled
                  className="proposal-right-input"
                  {...register("city", {
                    required: true,
                  })}
                ></input> */}
                {errors.person_email && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex col-12">
              <div className="col-2">
                <p className="proposal-label">Project Category</p>
              </div>
              <div className="col-4">
                <div className="proposal-input">
                  {proposalDetails && proposalDetails.solution_name
                    ? proposalDetails.solution_name
                    : "Not Available"}
                </div>
                {/* <input
                  className="proposal-input"
                  disabled
                  {...register("solution_name", {
                    required: true,
                    minLength: 10,
                  })}
                ></input> */}
                {errors.contact_no && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
              <div className="col-2 ">
                <div className="proposal-right-label ">
                  Project Timeline
                </div>
              </div>
              <div className="col-4">
              <div className="proposal-right-input">
                  {proposalDetails && proposalDetails.project_expiry_datetime
                    ? proposalDetails.project_expiry_datetime
                    : "Not Available"}
                </div>
                {/* <input
                  disabled
                  className="proposal-right-input"
                  type="text"
                  {...register("project_expiry_datetime", {
                    required: true,
                  })}
                ></input> */}
                {errors.department && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex col-12 py-3">
              <div className="col-2">
                <p className="proposal-label">Proposal</p>
              </div>
              <div className="col-4">
                <div className="proposal-input">
                  <a
                    href={proposalDetails && proposalDetails.attachment_url}
                    target="_blank"
                  >
                    {proposalDetails && proposalDetails.attachment_url ? (
                      <img src={AttachmentIcon} alt="" />
                    ) : (
                      "Not Available"
                    )}
                  </a>
                </div>
              </div>
              <div className="col-2">
                <p className="proposal-right-label">Status</p>
              </div>
              <div className="col-4">
                <div className="proposal-right-input">
                  {" "}
                  {proposalDetails &&
                    (proposalDetails.proposal_status === 0
                      ? "InReview"
                      : proposalDetails.proposal_status === 1
                      ? "Accepted"
                      : "Rejected")}
                </div>
              </div>
            </div>
            <div className="d-flex col-12 pb-3">
              <div className="col-2">
                <p className="proposal-label">Proposal Price</p>
              </div>
              <div className="col-4">
                <div className="proposal-input">
                  {proposalDetails &&
                    proposalDetails.price &&
                    CheckingValue(
                      proposalDetails.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    )}
                </div>
                {/* <input
                  className="proposal-input"
                  type="text"
                  disabled
                  {...register("price", {
                    required: true,
                  })}
                ></input> */}
                {errors.position && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
            </div>
            <div className="proposal-long-input-div pb-2">
              <div className="col-2">
                <p className="proposal-label">Proposal Resources</p>
              </div>
              <div className="">
                <div className="proposal-long-input">
                  {" "}
                  <h5 className="proposal-input-text">
                    {proposalDetails && proposalDetails.project_resources
                      ? parse(proposalDetails.project_resources)
                      : "Not Available"}
                  </h5>
                </div>
                {/* <textarea
                className="proposal-long-input"
                  disabled
                  value={parse(proposalDetails.project_resources)}
                  // {...register(parse("project_resources"), {
                    
                  // })}
                  style={{
                 
                    height: "100%",
                  }}
                /> */}
                {errors.position && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
            </div>
            <div className="proposal-long-input-div pb-2">
              <div className="col-md-2">
                <p className="proposal-label">
                  Proposal out of <br /> Scope
                </p>
              </div>
              <div className="">
                <div className="proposal-long-input">
                  {" "}
                  <h5 className="proposal-input-text">
                    {proposalDetails && proposalDetails.out_of_scope
                      ? parse(proposalDetails.out_of_scope)
                      : "Not Available"}
                  </h5>
                </div>
                {/* <input
                  className="proposal-long-input"
                  type="text"
                  disabled
                  {...register("out_of_scope", {
                    required: true,
                  })}
                ></input> */}
                {errors.position && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
            </div>
            <div className="proposal-long-input-div pb-2">
              <div className="col-md-2">
                <p className="proposal-label">
                  Proposal Terms & <br /> Condition
                </p>
              </div>
              <div className="">
                <div className="proposal-long-input">
                  {" "}
                  <h5 className="proposal-input-text" style={{flexDirection:"column",alignItems:"flex-start"}}>
                    {proposalDetails && proposalDetails.terms_conditions
                      ? parse(proposalDetails.terms_conditions)
                      : "Not Available"}
                    {/* {proposalDetails &&
                    proposalDetails.terms_conditions.length >= 0
                      ? parse(proposalDetails.terms_conditions)
                      : "Not Available"} */}
                  </h5>
                </div>
                {/* <input
                  className="proposal-long-input"
                  type="text"
                  disabled
                  {...register("terms_conditions", {
                    required: true,
                  })}
                ></input> */}
                {errors.position && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
            </div>
            <div className="proposal-long-input-div">
              <div className="col-md-2">
                <p className="proposal-label">Proposal Description</p>
              </div>
              <div className="">
                <div className="proposal-long-input">
                  {" "}
                  <h5 className="proposal-input-text" style={{display: "flex",alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    flexWrap: "nowrap"}}>
                    {proposalDetails && proposalDetails.perposal_description
                      ? parse(proposalDetails.perposal_description)
                      : "Not Available"}
                  </h5>
                </div>
                {/* <input
                  className="proposal-long-input"
                  type="text"
                  disabled
                  {...register("perposal_description", {
                    required: true,
                  })}
                ></input> */}
                {errors.position && (
                  <span className="add-contact-error-msg">
                    Please enter the value
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="vendor-follow-main-div">
        <div className="milestone-and-columns-icon">
          <h1 className="milestone-header">Milestone</h1>
          {isShowTable && (
            <>
              <div
                className={
                  checkBox ? "milestone-columns" : "milestone-columns-before"
                }
              >
                <img
                  className="columns-icon"
                  onClick={onClickCol}
                  src={ColumnsIcon}
                  alt=""
                />{" "}
                &nbsp;
                {checkBox && (
                  <>
                    <div className="dropdown-multi">
                      <DropdownMultiselect
                        handleOnChange={(value) => {
                          setIsSelected(value);
                        }}
                        options={[
                          "Amount",
                          "Category",
                          "Created Date",
                          "Date",
                          "Percent",
                          "Phone No",
                          "Project Name",
                          "Status",
                          "S.No",
                        ]}
                        selected={[
                          "S.No",
                          "Percent",
                          "Status",
                          "Phone No",
                          "Date",
                          "Amount",
                        ]}
                        placeholder="select"
                      />
                    </div>
                    <button className="apply-button" onClick={onClickApply}>
                      Apply
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <DataTable
          columns={selectedHeaders}
          data={result}
          // expandableRows
          customStyles={customStyles}
          //  expandableRowsComponent={ExpandedComponent}
          //  expandableRows
          paginationResetDefaultPage={resetPaginationToggle}
          // subHeader
          // subHeaderComponent={subHeaderComponentMemo}
          onRowClicked={onRowClicked}
          pagination
          paginationRowsPerPageOptions={[2, 4]}
        />
      </div>
    </div>
  );
};

export default ProposalSingleDetails;
