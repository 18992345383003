import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import deleteIcon from '../../images/Icon.svg';
import eyeIcon from '../../images/Icons.svg';
import { useNavigate } from 'react-router-dom';
// import FilterComponent from './AddsaingleCustomerFilter/FilterComponent';
import { useDispatch } from 'react-redux';
import apporved from '../../images/apporved.svg';
import apporvedIcon from '../../images/apporvedIcon.svg';
import rejected from '../../images/rejected.svg';
import rejectIcon from '../../images/rejectIcon.svg';
import moment from "moment";
import Rate from "rc-rate";
import StarRatings from 'react-star-ratings';
import ReviewApproval from '../Modal/ReviewApproval';
import { updateSingleReview } from '../../Store/Reducer/AdminReviewReducer';
import ReviewReject from '../Modal/ReviewReject';
import ReviewDelete from '../Modal/ReviewDelete';


const customStyles = {
        headCells: {
                style: {
                        backgroundColor: "#0900bc",
                        color: "white",
                        fontSize: "14px",
                        width: "100%",

                },
        },
        rows: {
                style: {
                        cursor: "pointer",
                        textTransform: "capitalize",
                        fontSize: "13.5px",
                        color: "black",
                        fontWeight: "500",
                        letterSpacing: "0.5px",
                        whiteSpace: "nowrap",

                },
        },
        cells: {
                style: {
                        fontSize: "13.5px",
                },
        },
};

const ReviewsTable = ({ reviewsList }) => {

        const navigate = useNavigate()
        const dispatch = useDispatch()



        // const [filterText, setFilterText] = useState('');
        // const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
        const [pending, setPending] = useState(true);


        const [reviewApproval, setReviewApproval] = useState(false);
        const [reviewReject, setReviewReject] = useState(false);
        const [reviewDelete, setReviewDelete] = useState(false);


        console.log("========>", reviewsList)
        const [columns, setColumns] = useState([])
     

        useEffect(() => {
                const timeout = setTimeout(() => {
                        setColumns([
                                {
                                        name: "Name",
                                        selector: row => row.product_name,
                                        sortable: true,
                                },
                                {
                                        name: "Review",
                                        selector: row => row.review_title,
                                        sortable: true,
                                },
                                {
                                        name: "Rating",
                                        selector: row =>
                                                <div className='d-flex align-items-center'>
                                                        <StarRatings
                                                                rating={row.overall_rating}
                                                                starRatedColor="#EAC52D"
                                                                numberOfStars={5}
                                                                starDimension="16px"
                                                                starSpacing="5px"
                                                        />
                                                </div>,
                                },
                                {
                                        name: "Added on",
                                        selector: row => moment(row.created_at).format("DD-MM-YYYY  h:mm A"),
                                        sortable: true,
                                },
                                {
                                        name: "Status",
                                        selector: (row) => (
                                                row.review_status === 1 ? (
                                                        <div>
                                                                <img src={apporved} alt="" style={{ width: "80px" }} />
                                                        </div>
                                                ) : row.review_status === 2 ? (
                                                        <div>
                                                                <img src={rejected} alt="" style={{ width: "80px" }} />
                                                        </div>
                                                ) : (
                                                        <div className="d-flex">
                                                                <div >
                                                                        <img src={apporvedIcon} alt="" style={{ width: "27px" }}
                                                                                onClick={() => {
                                                                                        reviewApprovalOpen(row);
                                                                                }}
                                                                        />
                                                                </div>
                                                                <div className="px-4">
                                                                        <img src={rejectIcon} alt="" style={{ width: "27px" }}
                                                                                onClick={() => { reviewRejectOpen(row); }
                                                                                }
                                                                        />
                                                                </div>
                                                        </div>
                                                )
                                        )
                                },
                                {
                                        name: "Action",
                                        selector: (row) => (
                                                <div>
                                                        <img src={eyeIcon} alt="" onClick={() => { handleSingleReview(row) }}></img>
                                                        <img src={deleteIcon} alt="" className='px-4' onClick={() => { reviewDeleteOpen(row) }}></img>
                                                </div>
                                        ),
                                },
                        ])
                        setPending(false);
                }, 2000);
                return () => clearTimeout(timeout);
        }, []);






        useEffect(() => {

        }, [reviewsList])

        const handleSingleReview = (row) => {
                dispatch(updateSingleReview(row.id))
                console.log("rowcustomerdata", dispatch(updateSingleReview(row.id)))
                dispatch(updateSingleReview(row))
                console.log("rowcustomerdata", dispatch(updateSingleReview(row)))
                navigate("/single-review")
        }
        const handleClose = () => {
                setReviewApproval(false);
        };

        const handleRejectClose = () => {
                setReviewReject(false);
        };

        const handleDeleteClose = () => {
                setReviewDelete(false);
        };

        const reviewApprovalOpen = (row) => {
                dispatch(updateSingleReview(row.id))
                console.log("rowcustomerdata", dispatch(updateSingleReview(row.id)))
                dispatch(updateSingleReview(row))
                console.log("rowcustomerdata", dispatch(updateSingleReview(row)))
                setReviewApproval(true);
        };

        const reviewRejectOpen = (row) => {
                dispatch(updateSingleReview(row.id))
                console.log("rowcustomerdata", dispatch(updateSingleReview(row.id)))
                dispatch(updateSingleReview(row))
                console.log("rowcustomerdata", dispatch(updateSingleReview(row)))
                setReviewReject(true);
        };

        const reviewDeleteOpen = (row) => {
                dispatch(updateSingleReview(row.id))
                console.log("rowcustomerdata", dispatch(updateSingleReview(row.id)))
                dispatch(updateSingleReview(row))
                console.log("rowcustomerdata", dispatch(updateSingleReview(row)))
                setReviewDelete(true);
        };


        return (
                <>
                        {/* {JSON.stringify(customerList)} */}

                        <ReviewApproval open={reviewApproval} handleClose={handleClose} />
                        <ReviewReject open={reviewReject} handleClose={handleRejectClose} />
                        <ReviewDelete open={reviewDelete} handleClose={handleDeleteClose} name={"Review"} />
                        <DataTable

                                columns={columns}
                                data={reviewsList}
                                customStyles={customStyles}
                                // onRowClicked={onRowClicked}
                                // paginationResetDefaultPage={resetPaginationToggle}
                                // subHeaderComponent={subHeaderComponentMemo}
                                pagination
                                progressPending={pending}
                        />
                </>
        )
}

export default ReviewsTable