import React, { useEffect, useState } from "react";
import DashBoardheader from "../../../../components/DashboardHeader";
import SideNavBar from "../../../../components/Sidebar";
import "./editTargetCustomer.css";
import TargetAddSingleCustomerBreadcrumbs from "../../../../components/TASCBreadCrumbs";
import { useNavigate } from "react-router-dom";
import BackArrowIcon from "../../../../images/BackArrow.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clearAddSingleCustomer, updateAddSingleCustomer } from "../../../../Store/Reducer/AddSingleCustomerReducer";
import BiCircleIcon from "../../../../images/bi_info-circle.svg";
import { getAllCountryList } from "../../../../Store/Reducer/CountryListReducer";
import { BaseUrl, dsUrl } from "../../../Api/api";
import axios from "axios";

const EditTargetCustomer = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const [solutions, setSolutions] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClickTargetCustomer = () => {
        navigate("/target-customer-main-page");
    };
    const { addSingleCustomer } = useSelector((state) => state.addSingleCustomer);
    const { countryList, selectedCountryId } = useSelector(
        (state) => state.allCountryList
    );
    console.log("countryList", countryList);

    useEffect(() => {
        for (const key in addSingleCustomer) {
            if (Object.hasOwnProperty.call(addSingleCustomer, key)) {
                const element = addSingleCustomer[key];
                setValue(key, element);
            }
        }
    }, [addSingleCustomer]);

    const onSubmit = (data) => {
        dispatch(updateAddSingleCustomer(data));
        navigate("/target-customer-over-view");
        console.log("data", data);
        // console.log("updated", updateAddSingleCustomer(data))
    };

    console.log("Addsinglecustomer", addSingleCustomer);
    useEffect(() => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        fetch(`${BaseUrl}vendor/Get-Solutions`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                // console.log("Get Solutions", JSON.parse(result).data);
                setSolutions(JSON.parse(result).data);
            })
            .catch((error) => console.log("error", error));
    }, []);

    const updateCustomer = () => {

        const userToken = localStorage.getItem("token")
        const data = getValues()
        data['target_customer_id'] = addSingleCustomer.id
        axios
          .post(
            `${BaseUrl}AdminTargetCustomer/Update-Admin-Target-Customer`,
            data,
            { headers: { Authorization: `Bearer ${userToken}` } }
          )
          .then((response) => {
            console.log("res===>", response);
            dispatch(clearAddSingleCustomer());
            navigate("/target-customer-main-page");
            return response.data;
          })
          .catch((err) => {
            console.log("error", err);
          });
    }
    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className="main-body-container" style={{ marginBottom: "100px" }}>
                <div className="container py-1">
                    <TargetAddSingleCustomerBreadcrumbs />
                </div>
                <div className="container py-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="target-add-customer-top-name">
                            Add Single Customer
                        </h5>
                    </div>
                    {/* <div>
                        <img
                            onClick={handleClickTargetCustomer}
                            src={BackArrowIcon}
                            alt=""
                            style={{ cursor: "pointer" }}
                        />
                    </div> */}
                </div>
                <div className="container">
                    <div className="target-add-single-customer px-3 py-4">
                        <div style={{ paddingBottom: "200px" }}>
                            <div>
                                <h5 className="sub-h py-3">Overview</h5>
                            </div>
                            <div className="d-flex">
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">Name</p>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        {...register("customer_name", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                        }}
                                    />
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_name?.message}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">Comany Name</p>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        {...register("company_name", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                        }}
                                    />
                                    <div>
                                        <p className="required-field">
                                            {errors.company_name?.message}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex my-4">
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">Phone No.</p>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        {...register("customer_phone_no", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                        }}
                                    />
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_phone_no?.message}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">Email ID</p>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        {...register("customer_email", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                        }}
                                    />
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_email?.message}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">
                                        Company Address
                                    </p>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        {...register("customer_address", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                        }}
                                    />
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_address?.message}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">Country</p>
                                </div>
                                <div className="col-4">
                                    {/* <input type="text"
                                        {...register('country', { required: "Required Field" })}
                                        style={{
                                            border: " 1px solid gray",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px"
                                        }}
                                    /> */}

                                    <select
                                        name=""
                                        id=""
                                        type="text"
                                        {...register("customer_country", {
                                            required: " Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "7px",
                                            width: "300px",
                                            background: "white",
                                        }}
                                    >
                                        <option className="dropdown-country" value="select country">
                                            {" "}
                                            select country
                                        </option>
                                        {countryList &&
                                            countryList.map((country, index) => {
                                                return (
                                                    <option key={index} value={country.value}>
                                                        {country.country}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_country?.message}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex my-4">
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">City</p>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        {...register("customer_city", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                        }}
                                    />
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_city?.message}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">Zip</p>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        {...register("customer_pincode", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                        }}
                                    />
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_pincode?.message}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">State</p>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        {...register("customer_state", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                        }}
                                    />
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_state?.message}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <p className="target-add-single-customer-lables">Category</p>
                                </div>
                                <div className="col-4">
                                    <select
                                        type="text"
                                        {...register("customer_category", {
                                            required: "Required Field",
                                        })}
                                        style={{
                                            border: " 1px solid #0900BC",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            width: "300px",
                                            background: "white"
                                        }}
                                    >
                                        {solutions.map((solutions) => (
                                            <option
                                                key={solutions.id}
                                                value={solutions.solutions_name}
                                            >
                                                {solutions.solutions_name}
                                            </option>
                                        ))}
                                    </select>
                                    <div>
                                        <p className="required-field">
                                            {errors.customer_category?.message}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end container mt-5">
                    <button
                        onClick={updateCustomer}
                        className="target-add-customer-bottom-save-btn px-4 py-1"
                    >
                        Done
                    </button>
                </div>
            </div>
        </>
    );
};

export default EditTargetCustomer;
