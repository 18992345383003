import { configureStore } from "@reduxjs/toolkit";
import conqtAdminReducer from "./Reducer/Reducer";
import customerReducer from "./Reducer/CustomerReducer";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import campaignReducer from './Reducer/CampaignReducer';
import countryListReducer from "./Reducer/CountryListReducer";
import proposalReducer from "./Reducer/ProposalReducer";
import ProjectList from "./Reducer/ProjectList";
import AddSingleCustomerReducer from "./Reducer/AddSingleCustomerReducer";
import TargetVendorDetails from "./Reducer/TargetVendorDetails";
import AddSingleTargetVendor from "./Reducer/AddSingleTargetVendor";
import TargetCustomerContactReducer from "./Reducer/TargetCustomerContactReducer";
import SaveFileInBlob from "./Reducer/SaveFileInBlob"
import TargetVendorContact from "./Reducer/TargetVendorContact";
import SaveMCFReducer from "./Reducer/SaveMCFReducer";
import CallExpertReducer from "./Reducer/CallExpertReducer";
import MainProductList from "./Reducer/MainProductList"
import IndustryList from "./Reducer/IndustryList";
import DepartmentList from "./Reducer/DepartmentList";
import CategoryList from "./Reducer/CategoryList";
import BrandList from "./Reducer/BrandList";
import AdminReviewReducer from "./Reducer/AdminReviewReducer";


const reducers = combineReducers({
  targetCustomerContact: TargetCustomerContactReducer,
  addSingleCustomer: AddSingleCustomerReducer,
  campaign: campaignReducer,
  conqtAdmin: conqtAdminReducer,
  customer: customerReducer,
  proposal: proposalReducer,
  allCountryList: countryListReducer,
  project: ProjectList,
  targetVendor: TargetVendorDetails,
  addTargetVendor: AddSingleTargetVendor,
  targetVendor: TargetVendorDetails,
  addTargetVendor: AddSingleTargetVendor,
  uploadFile: SaveFileInBlob,
  customerUploadfile: SaveMCFReducer,
  targetVendorContact: TargetVendorContact,
  callExpertData : CallExpertReducer,
  mainProductList : MainProductList,
  industryList : IndustryList,
  departmentList : DepartmentList,
  categoryList : CategoryList,
  brandList: BrandList,
  adminReview: AdminReviewReducer

});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  //  devTools:false,
  devTools: process.env.NODE_ENV !== 'production'

});

