import React from 'react'
import SideNavBar from '../../../../components/Sidebar'

const EmailSubscriberList = () => {
    return (
        <>
            <SideNavBar />
        </>
    )
}

export default EmailSubscriberList