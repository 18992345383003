import React, { useState, useEffect } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
// import "./popupDep.css";
import './editReject.css'
import { useForm } from "react-hook-form";
// import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import FeaturedIcon from '../../../images/Featuredicon.svg';
import { BaseUrl } from '../../../pages/Api/api';
import RejIcon from '../../../images/rejicon.svg';

const EditReject = ({ open, handleClose, handleRejectSuccess }) => {

        const dispatch = useDispatch();
        const navigate = useNavigate();

        const [close, setClose] = useState(handleClose);


        return (
                <>
                        <Modal open={open} handleClose={handleClose} size="xs" style={{ marginTop: "50px" }}>
                                <Modal.Body >
                                        <img src={RejIcon} alt="" />
                                        <div className='d-flex flex-column justify-content-center'>
                                                <h4 className='app-top-con py-4'>Reject Product</h4>
                                                <p className='app-des'>Are you sure you want to Reject this product? This action cannot be undone.</p>

                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-4 pt-4'>
                                                <button className='cencel-dep-btn' onClick={handleClose}>Cancel</button>
                                                <button className='rej-con-btn' onClick={handleRejectSuccess}>Confirm</button>

                                        </div>
                                </Modal.Body>
                        </Modal>
                </>
        )
}

export default EditReject