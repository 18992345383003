import React, { useState } from "react";
import plus from "../../images/plus.png";
import minus from "../../images/minus.png";

const Expander = (props) => {
  const [isExpander, setIsExpander] = useState(false);
  const onClickContact = () => {
    setIsExpander(!isExpander);
  };
  return (
    <>
      {/* {!isExpander && ( */}
      <div onClick={onClickContact} className="single-page-contact">
        <h5 className="header-plus"> {props.header} </h5>{" "}
        <h5 className="header-plus">
          <img src={!isExpander ? plus : minus} alt="" />{" "}
        </h5>
      </div>
      {/* )} */}

      <div>{isExpander && <>{props.children}</>}</div>
    </>
  );
};

export default Expander;
