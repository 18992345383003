import React, { useState } from "react";
import "../AddProducts.css";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import PackageField from "./PackageField/PackageField";
const PricingSection = ({ formValues, setFormValues, handleChange, formErrors }) => {
  
  const [checked, setChecked] = useState(false);
  
  const [packages, setPackages] = useState(Array.from({ length: 1 }, (_, m) => ({ id: m, value: false })));
  
  const handleChanged = (checked) => {
    setChecked(checked);
  };
 
  const AddPackages = () => {
    const newPackages = [...packages, { id: packages.length, value: false }];
    setPackages(newPackages);
  }
  const DeletePackages = () => {
    setPackages(packages.filter((item) => item.value !== true));
  }

  console.log(packages)
  return (
    <>
      <div className="addnew-containerr">
        <div className="addnew-product-section-div">
          <div className="addnew-text-underline">
            <h1 className="addnew-product-section-title">Pricing</h1>
          </div>
        </div>
        <div className="select-pricing-model-addProduct">
          <select
            style={{ width: "28%" }}
            name="is_price"
            className="addnew-product-Input"
            id="is_price"
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              {" "}
              Select{" "}
            </option>
            <option value={"Price"}>Fixed Pricing</option>
            <option value={"Package"}>Packages</option>
            <option value={"Quote"}>Request On Quote</option>
          </select>
          {formValues.is_price==="Package"?<div className="Package-section-ADEdit">
            <div className="Package-Section-Edit-Add">
            <button onClick={AddPackages} className="package-section-button-trans">
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 14V8H0V6H6V0H8V6H14V8H8V14H6Z" fill="#5C5C5C" />
              </svg>
              </button>  
            
            </div>
            <div className="Package-Section-Edit-Add">
              <button className="package-section-button-trans">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 16.0001H3.414L12.728 6.68608L11.314 5.27208L2 14.5861V16.0001ZM18 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L6.243 16.0001H18V18.0001ZM12.728 3.85808L14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808Z"
                  fill="#5C5C5C"
                />
              </svg>
              </button>
             
            </div>
            <div className="Package-Section-Delete">
              <button onClick={DeletePackages} className="package-section-button-trans">
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 18C2.45 18 1.979 17.804 1.587 17.412C1.195 17.02 0.999333 16.5493 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.804 17.021 14.412 17.413C14.02 17.805 13.5493 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                  fill="#5C5C5C"
                />
              </svg>
              </button>
            </div>
          </div>:<></>}
        </div>
        <p style={{ margin:"0 0 1rem 0"}} className="err-message">{formErrors.is_price}</p>
        {formValues.is_price === "Price" ? (
          <div className="select-pricing-model-container">
            <div className="select-pricing-model-colum">
              <div className="select-pricing-model-row1">
                <label
                  htmlFor="product_name"
                  className="addnew-product-Input-label"
                >
                  Select Package
                </label>
                <select
                  name="pricing_model"
                  className="addnew-product-Input"
                  id=""
                >
                  <option value={0} hidden>
                    {" "}
                    Select{" "}
                  </option>
                  <option value={1}>Monthly</option>
                  <option value={1}>Quarterly</option>
                  <option value={1}>Half Yearly</option>
                  <option value={1}>Yearly</option>
                </select>
                <p className="input-dynamiclength-addproduct">Select Package</p>
              </div>
              <div className="select-pricing-model-row2">
                <div className="Toggle-btn-with-label">
                  <Switch
                    onChange={handleChanged}
                    checked={checked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor={"#0900BC"}
                    height={20}
                    width={36}
                    borderRadius={12}
                    handleDiameter={14}
                  />
                  <label
                    style={{ marginLeft: "0.5rem" }}
                    htmlFor="product_name"
                    className="addnew-product-Input-label"
                  >
                    Include Tax
                  </label>
                </div>
                <p
                  style={{ marginLeft: "2.5rem" }}
                  className="input-dynamiclength-addproduct"
                >
                  Toggle on to include GST
                </p>
              </div>
              <div className="select-pricing-model-row3">
                <label
                  htmlFor="product_name"
                  className="addnew-product-Input-label"
                >
                  Discount
                </label>
                <input
                  id="discount"
                  className="addnew-product-Input"
                  placeholder="Input Percentage"
                  name="discount"
                  type="number"
                  onChange={handleChange}
                />
                <p
                  style={{ margin: "0" }}
                  className="input-dynamiclength-addproduct"
                >
                  Enter Discount in Percentage{" "}
                </p>
              </div>
            </div>
            <div className="select-pricing-model-colum">
              <div className="select-pricing-model-row1">
                <label
                  htmlFor="product_name"
                  className="addnew-product-Input-label"
                >
                  Currency
                </label>
                <select
                  name="pricing_model"
                  className="addnew-product-Input"
                  id=""
                >
                  <option value={1} hidden>
                    {" "}
                    Select{" "}
                  </option>
                  <option value={1}>USD - $</option>
                </select>
                <p className="input-dynamiclength-addproduct">
                  Enter available offer for this product
                </p>
              </div>
              {checked === true ? (
                <div className="select-pricing-model-row2">
                  <label
                    htmlFor="product_name"
                    className="addnew-product-Input-label"
                  >
                    Tax Percentage
                  </label>
                  <div className="GST-With-Input-Div">
                    <select className="addnew-product-GST-Input">
                      <option hidden>Select</option>
                      <option>GST</option>
                    </select>
                    <input
                      id="gst_percentage"
                      className="addnew-product-Input-withGST"
                      placeholder="Input Percentage"
                      name="gst_percentage"
                      type="number"
                      onChange={handleChange}
                    />
                  </div>

                  <p className="input-dynamiclength-addproduct">
                    Enter Tax percentage{" "}
                  </p>
                </div>
              ) : (
                <></>
              )}

              <div className="select-pricing-model-row3">
                <label
                  htmlFor="product_name"
                  className="addnew-product-Input-label"
                >
                  Offer
                </label>
                <input
                  id="product_name"
                  className="addnew-product-Input"
                  placeholder="Input text"
                  name="description"
                  type="text"
                  //   value={formValues.description}
                  //   onChange={handleChange}
                />
                <p
                  style={{ margin: "0" }}
                  className="input-dynamiclength-addproduct"
                >
                  Enter available offer for this product{" "}
                </p>
              </div>
            </div>
            <div className="select-pricing-model-colum">
              <div className="select-pricing-model-row1">
                <label
                  htmlFor="product_name"
                  className="addnew-product-Input-label"
                >
                  Product Price
                </label>
                <input
                  id="initial_price"
                  className="addnew-product-Input"
                  placeholder="Input Amount"
                  name="initial_price"
                  type="number"
                  onChange={handleChange}
                />
                <p className="input-dynamiclength-addproduct">
                  Enter product MRP{" "}
                </p>
              </div>
              {checked === true ? (
                <div className="select-pricing-model-row2">
                  <label
                    htmlFor="product_name"
                    className="addnew-product-Input-label"
                  >
                    Price Including Tax
                  </label>
                  <input
                    id="is_tax_include"
                    className="addnew-product-Input"
                    placeholder="Input Price"
                    name="is_tax_include"
                    type="number"
                    disabled
                    onChange={handleChange}
                    value={(
                      Number(formValues.initial_price) +
                      Number(
                        Number(formValues.initial_price) *
                          (Number(formValues.gst_percentage) / 100)
                      )
                    ).toFixed(2)}
                  />
                  <p className="input-dynamiclength-addproduct">
                    This is a hint text to help user{" "}
                  </p>
                </div>
              ) : (
                <></>
              )}
              <div className="select-pricing-model-row3">
                <label htmlFor="price" className="addnew-product-Input-label">
                  Total Price
                </label>

                <input
                  id="price"
                  className="addnew-product-Input"
                  placeholder="Input amount"
                  name="price"
                  type="number"
                  disabled
                  onChange={handleChange}
                  value={(
                    Number(formValues.initial_price) +
                    Number(
                      Number(formValues.initial_price) *
                        (Number(formValues.gst_percentage) / 100)
                    ) -
                    Number(
                      Number(formValues.initial_price) *
                        (Number(formValues.discount) / 100)
                    )
                  ).toFixed(2)}
                />
                <p
                  style={{ margin: "0" }}
                  className="input-dynamiclength-addproduct"
                >
                  Enter available offer for this product
                </p>
              </div>
            </div>
          </div>
        ) : (
          formValues.is_price ==="Package"?<>
            <PackageField packages={packages} setPackages={setPackages} />
          </>:<></>
        )}
      </div>
    </>
  );
};

export default PricingSection;
