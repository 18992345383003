import React, { useState } from "react";
import plus from "../../images/plus.png";
import minus from "../../images/minus.png";

const Expander = (props) => {
  const [isExpander, setIsExpander] = useState(false);
  const onClickContact = () => {
    setIsExpander(!isExpander);
  };
  return (
    <>
      {/* {!isExpander && ( */}
        <div onClick={onClickContact} className="single-page-contact">
         <h5 className="header-plus"> {props.header} </h5> <h5 className="header-plus"><img src={ !isExpander ?  plus: minus  }  alt=""/> </h5>
        </div>
      {/* )} */}
     
      <div className="expander-main">
        {isExpander && (
          <>
            <div className="single-page-dropdown-div">
              <div className="expander-title">{props.title1} </div>
              <div className="expander-value">
                {/* {selectedVendorDetails.phone_no} */}
                {props.value1}
              </div>
            </div>

            <div className="single-page-dropdown-div">
              <div className="expander-title">{props.title2} </div>
              <div className="expander-value">
                {/* {selectedVendorDetails.phone_no} */}
                {props.value2}
              </div>
            </div>

            <div className="single-page-dropdown-div">
              <div className="expander-title">{props.title3} </div>
              <div className="expander-value">
                {/* {selectedVendorDetails.solutions} */}
                {props.value3}
              </div>
            </div>

            <div className="single-page-dropdown-div">
              <div className="expander-title">{props.title4} </div>
              <div className="expander-value">
                {/* {selectedVendorDetails.activation_status} */}
                {props.value4}
              </div>
            </div>
            {props.title5 && (
              <>
                <div className="single-page-dropdown-div">
                  <div className="expander-title">{props.title5} </div>
                  <div className="expander-value">
                    {/* {selectedVendorDetails.activation_status} */}
                    {props.value5}
                  </div>
                </div>
              </>
            )}

            {props.title6 && (
              <>
                <div className="single-page-dropdown-div">
                  <div className="expander-title">{props.title6} </div>
                  <div className="expander-value">
                    {/* {selectedVendorDetails.activation_status} */}
                    {props.value6}
                  </div>
                </div>
              </>
            )}
            {props.title7 && (
              <>
                <div className="single-page-dropdown-div">
                  <div className="expander-title">{props.title7} </div>
                  <div className="expander-value">
                    {/* {selectedVendorDetails.activation_status} */}
                    {props.value7}
                  </div>
                </div>
              </>
            )}
            {props.title8 && (
              <>
                <div className="single-page-dropdown-div">
                  <div className="expander-title">{props.title8} </div>
                  <div className="expander-value">
                    {/* {selectedVendorDetails.activation_status} */}
                    {props.value8}
                  </div>
                </div>
              </>
            )}
            {props.title9 && (
              <>
                <div className="single-page-dropdown-div">
                  <div className="expander-title">{props.title9} </div>
                  <div className="expander-value">
                    {/* {selectedVendorDetails.activation_status} */}
                    {props.value9}
                  </div>
                </div>
              </>
            )}
            {props.title10 && (
              <>
                <div className="single-page-dropdown-div">
                  <div className="expander-title">{props.title10} </div>
                  <div className="expander-value">
                    {/* {selectedVendorDetails.activation_status} */}
                    {props.value10}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Expander;
