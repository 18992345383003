import React, { useState, useEffect } from 'react'
import './targetcustomercontacttable.css'
import DataTable from 'react-data-table-component'
import EditTableIcon from '../../images/TargetCustomeTableIcon.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateTargetCustomerContactId, setCurrentCustomer, updateTargetCustomerContact } from '../../Store/Reducer/TargetCustomerContactReducer';

const customStyles = {
    headCells: {
        style: {
            background: ' #0900BC',
            color: 'white',
            fontSize: '16px',
            fontWeight: 400
        },
    },
};

const TableCustomerContactTable = ({ customerContactList }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClickCustomerContact = () => {
        navigate('/target-add-customer-contact')
    }
    const [columns, setColumns] = useState([
        {
            name: "Name",
            selector: row => row.contact_p_name,
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.contact_p_email,
            sortable: true,
        },
        {
            name: "Phone Number",
            selector: row => row.contact_p_phone,
            sortable: true,
        },
        {
            name: "Department",
            selector: row => row.contact_p_department,
            sortable: true,
        },
        {
            name: "Position",
            selector: row => row.contact_p_role,
            sortable: true,
        },
        {
            cell: (row) => (
                <div className="add-contact-icon-table">
                    <img src={EditTableIcon} onClick={handleClickCustomerContact} style={{ width: "25px" }} alt=""></img>
                </div>
            ),
        },
    ])

    useEffect(() => {

    }, [customerContactList])

    const [data, setData] = useState([
        {
            id: 1,
            Name: "ABC",
            Email: "info@gmail.com",
            PhoneNumber: "+66 02022022",
            Department: "Development",
            Position: "Frontend"
        },
        {
            id: 2,
            Name: "DEF",
            Email: "info@gmail.com",
            PhoneNumber: "+66 02022022",
            Department: "Development",
            Position: "Frontend"
        },
        {
            id: 3,
            Name: "GHI",
            Email: "info@gmail.com",
            PhoneNumber: "+66 02022022",
            Department: "Development",
            Position: "Frontend"
        },
        {
            id: 4,
            Name: "JKL",
            Email: "info@gmail.com",
            PhoneNumber: "+66 02022022",
            Department: "Development",
            Position: "Frontend"
        },
        {
            id: 5,
            Name: "MNO",
            Email: "info@gmail.com",
            PhoneNumber: "+66 02022022",
            Department: "Development",
            Position: "Frontend"
        },
        {
            id: 6,
            Name: "CPQ",
            Email: "info@gmail.com",
            PhoneNumber: "+66 02022022",
            Department: "Development",
            Position: "Frontend"
        },
        {
            id: 7,
            Name: "BRS",
            Email: "info@gmail.com",
            PhoneNumber: "+66 02022022",
            Department: "Development",
            Position: "Frontend"
        },
    ])

    const onRowClicked = (row) => {
        // showTableUpdate(false);
        dispatch(updateTargetCustomerContactId(row.id));
        dispatch(updateTargetCustomerContact(row));
        navigate("/target-customer-contact-overview");
    };



    return (
        <>
            <DataTable

                columns={columns}
                data={customerContactList}
                customStyles={customStyles}
                onRowClicked={onRowClicked}
                pagination
            />
        </>
    )
}

export default TableCustomerContactTable