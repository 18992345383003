import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import DashBoardheader from "../../../../components/DashboardHeader";
import SideNavBar from "../../../../components/Sidebar";
import TextEditor from "../../../../components/TextEditor";
import "./campaignContent.css";
import AttachIcon from "../../../../images/attachIcon.svg";
import { useNavigate } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import CancleIcon from "../../../../images/cancleXicon.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateCampaign, updateCampaignFile } from "../../../../Store/Reducer/CampaignReducer";
import PlusIcon from "../../../../images/Plusmath.svg";
import BackArrow from "../../../../images/BackArrow.svg";

const ComapignContent = () => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { campaign, campaignFile } = useSelector((state) => state.campaign);
  console.log(campaignFile, "file from use selector")
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    readAs: "DataURL",
    accept: [".json", ".pdf", ".png", ".jpeg", "jpg"],
    multiple: true,
    limitFilesConfig: { max: 5 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
    // imageSizeRestrictions: {
    //     maxHeight: 900, // in pixels
    //     maxWidth: 1600,
    //     minHeight: 600,
    //     minWidth: 768,
    // },
  });

  useEffect(() => {
    for (const key in campaign) {
      if (Object.hasOwnProperty.call(campaign, key)) {
        const element = campaign[key];
        setValue(key, element);
      }
    }
  }, [campaign]);

  console.log("images", filesContent);

  const onSubmit = (data) => {
    dispatch(updateCampaign(data));
    dispatch(updateCampaignFile({ ...filesContent }))
    navigate("/campaign-recipients");
  };

  console.log("campaign", campaign);
  const handleClickDetails = () => {
    navigate("/campaign-details");
  };
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container">
        <div className="container py-2 mt-2">
          <BreadCrumbs />
        </div>
        <div className="container py-1 d-flex justify-content-between align-items-center">
          <h4 className="recipient-top-heading">Content</h4>
          <img
            onClick={handleClickDetails}
            src={BackArrow}
            alt=""
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className='container'>
          <div className='campaign-content-top-card py-4'>
            <div className='container'>
              <p className='sender-details-name py-3'>Sender Details</p>
              <div className="row">
                <div className='d-flex'>
                  <div className='col-12 col-lg-3' style={{ marginLeft: "4px" }}>
                    <p className='campaign-content-details-label'>Name</p>
                    <input type="text"
                      {...register('signature_name', { required: "Required Field" })}
                      style={{
                        border: "1px solid gray",
                        padding: "5px",
                        borderRadius: "4px"
                      }}
                    />
                    <div>
                      <p className='required-field'>{errors.signature_name?.message}</p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-3'>
                    <p className='campaign-content-details-label'>Email</p>
                    <input type="email"
                      {...register('signature_email',
                        { required: "Required Field", pattern: /^\S+@\S+$/i }
                      )}
                      style={{
                        border: "1px solid gray",
                        padding: "5px",
                        borderRadius: "4px"
                      }}
                    />
                    <div>
                      <p className='required-field'>{errors.signature_email?.message}</p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-3'>
                    <p className='campaign-content-details-label'>Phone No.</p>
                    <input type="Number"
                      {...register('signature_contact_no',
                        { required: "Required Field", minLength: 6, maxLength: 10 }
                      )}
                      style={{
                        border: "1px solid gray",
                        padding: "5px",
                        borderRadius: "4px"
                      }}
                    />
                    <div>
                      <p className='required-field'>{errors.signature_contact_no?.message}</p>
                      {/* {errors.signature_contact_no && errors.signature_contact_no.types && (
<p className='required-field'>{errors.signature_contact_no.types.required}</p>
)}
{errors.signature_contact_no && errors.signature_contact_no.types && (
<p className='required-field'>{errors.signature_contact_no.types.minLength}</p>
)} */}
                    </div>
                  </div>

                  <div className='col-12 col-lg-3'>
                    <p className='campaign-content-details-label'>Position</p>
                    <input type="text"
                      {...register('signature_position',
                        { required: "Required Filed" }
                      )}
                      style={{
                        border: "1px solid gray",
                        padding: "5px",
                        borderRadius: "4px"
                      }}
                    />
                    <div>
                      <p className='required-field'>{errors.signature_position?.message}</p>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div className='container campaign-content-main-page p-3'>
              <div className='pt-1' style={{ background: "white" }}>
                <TextEditor
                  style={{ height: "500px" }}
                  // {...register('textdesciption')}
                  {...getValues()}
                />
              </div>

              <div className='py-3'>
                <div className='container d-flex justify-content-between align-items-center mt-5'>
                  <div className=' d-flex align-items-center px-2 py-1 mt-3'>

                    <button className='attach-campaign-content-btn px-1 py-1' onClick={() => openFileSelector()}>
                      <span><img src={PlusIcon} alt="" /></span>
                    </button>
                    <br />
                    {filesContent.map((file, index) => (
                      <div className=" mx-2 attach-campaign-content-btn px-1 py-1 d-flex align-items-center"
                        // {...register('files')}
                        key={index}>
                        <h6 style={{
                          background: "white",

                        }}>{file.name} <span><img style={{ width: "13px" }} onClick={() => clear()} className='mx-1' src={CancleIcon} alt="" /></span></h6>
                        <br />
                      </div>
                    ))}
                  </div>

                  <div className='d-flex justify-content-end mt-5'>
                    <button className='next-btn-campaign px-5 py-2' onClick={handleSubmit(onSubmit)}>Next</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ComapignContent;
