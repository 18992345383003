import React, { useEffect } from 'react'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import TargetCustomerContactBreadCrumbs from '../../../../components/TCCBreadcrumbs'
import './customercontactoverview.css';
import BackArrowIcon from "../../../../images/BackArrow.svg";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearTargetCustomerContact } from '../../../../Store/Reducer/TargetCustomerContactReducer'
import axios from 'axios';
import { BaseUrl, dsUrl } from "../../../Api/api";
import EditIcon from "../../../../images/TargetCustomeTableIcon.svg";

const CustomerConatactOverview = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickEditPage = () => {
    navigate("/target-edit-customer-contact");
  };

  const { targetCustomerContact } = useSelector(
    (state) => state.targetCustomerContact
  );

  const handleClickAddCustomerContact = () => {
    navigate("/target-add-customer-contact");
  };

  useEffect(() => {}, []);

  const targetCustomerContactCreate = () => {
    const userToken = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const formdata = new FormData();
    console.log("sent to api data,", targetCustomerContact);

    const data = { ...targetCustomerContact };

    formdata.append("contact_p_name", data.contact_p_name);
    formdata.append("contact_p_email", data.contact_p_email);
    formdata.append("contact_p_phone", data.contact_p_phone);
    formdata.append("contact_p_department", data.contact_p_department);
    formdata.append("contact_p_role", data.contact_p_role);
    formdata.append("contact_p_country", data.contact_p_country);
    formdata.append("target_customer_id", 20);

    console.log("formdata==>", formdata);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formdata,
    };

    axios
      .post(
        `${BaseUrl}AdminTargetCustomer/Create-Admin-Target-Customer-contact`,
        targetCustomerContact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((result) => {
        console.log("result===>", result);
        dispatch(clearTargetCustomerContact());
        navigate("/target-customer-contact-main-page");
        return result.data;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container">
        <div className="container">
          <TargetCustomerContactBreadCrumbs />
        </div>
        {/* {JSON.stringify(targetCustomerContact)} */}
        <div className="container py-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className="target-add-customer-top-name">
              Add Contact Overview
            </h5>
          </div>
          <div>
            <img
              onClick={handleClickAddCustomerContact}
              src={BackArrowIcon}
              alt=""
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="container py-3 my-2">
          {/* <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <div className='customer-list'>Customer List</div>
                            <div className='px-2'>
                                <input
                                    type="text"

                                    value={targetCustomerContact?.company_name}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                            </div>
                        </div>
                        
                    </div> */}
          <div className="d-flex justify-content-end">
            <img
              onClick={handleClickEditPage}
              src={EditIcon}
              alt=""
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        <div className="container">
          <div className="target-add-customer-contact px-3 py-4">
            <div className="d-flex">
              <div className="col-2">
                <p className="target-add-single-customer-lables">Name</p>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  value={targetCustomerContact?.contact_p_name}
                  style={{
                    border: " 1px solid gray",
                    borderRadius: "8px",
                    padding: "5px",
                    width: "300px",
                    background: "#EBEBEB",
                  }}
                />
              </div>
              <div className="col-2">
                <p className="target-add-single-customer-lables">Email</p>
              </div>
              <div className="col-4">
                <input
                  type="email"
                  value={targetCustomerContact?.contact_p_email}
                  style={{
                    border: " 1px solid gray",
                    borderRadius: "8px",
                    padding: "5px",
                    width: "300px",
                    background: "#EBEBEB",
                  }}
                />
              </div>
            </div>

            <div className="d-flex my-4">
              <div className="col-2">
                <p className="target-add-single-customer-lables">Phone No.</p>
              </div>
              <div className="col-4">
                <input
                  type="number"
                  value={targetCustomerContact?.contact_p_phone}
                  style={{
                    border: " 1px solid gray",
                    borderRadius: "8px",
                    padding: "5px",
                    width: "300px",
                    background: "#EBEBEB",
                  }}
                />
              </div>
              <div className="col-2">
                <p className="target-add-single-customer-lables">Department</p>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  value={targetCustomerContact?.contact_p_department}
                  style={{
                    border: " 1px solid gray",
                    borderRadius: "8px",
                    padding: "5px",
                    width: "300px",
                    background: "#EBEBEB",
                  }}
                />
              </div>
            </div>

            <div className="d-flex" style={{ paddingBottom: "100px" }}>
              <div className="col-2">
                <p className="target-add-single-customer-lables">Position</p>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  value={targetCustomerContact?.contact_p_role}
                  style={{
                    border: " 1px solid gray",
                    borderRadius: "8px",
                    padding: "5px",
                    width: "300px",
                    background: "#EBEBEB",
                  }}
                />
              </div>
              <div className="col-2">
                <p className="target-add-single-customer-lables">Country</p>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  value={targetCustomerContact?.contact_p_country}
                  style={{
                    border: " 1px solid gray",
                    borderRadius: "8px",
                    padding: "5px",
                    width: "300px",
                    background: "#EBEBEB",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="d-flex align-items-center justify-content-end py-3">
            <button
              onClick={targetCustomerContactCreate}
              className="customer-contact-add-btn px-4 py-1"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerConatactOverview