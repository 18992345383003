import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getProposalTableData = createAsyncThunk("proposalList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}admin/Get-All-Proposal-List`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result.data, "store for proposal api data");
      return result.data;
    })
    .catch((error) => console.log("error", error));
});
export const getSingleProposalDetails = createAsyncThunk(
  "proposalDetails", (obj) => {
    const userToken = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Content-Type", "application/json");
  console.log(obj, "From Redux")
    var raw = JSON.stringify({
    proposal_id: obj.proposal_id,
 announcement_id: obj.announcement_id,
    //     proposal_id: 91,
    // announcement_id: 135,

    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      `${BaseUrl}admin/Get-Admin-All-Miletsonte-List`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result.data, "single proposal api  data");
        return result.data;
      })
      .catch((error) => console.log("error", error));
  }
);
export const getSingleMilestoneDetails = createAsyncThunk("milestoneDetails", (obj) => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
 console.log(obj," milestone id in the reducer")
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}admin/Get-Milestone-Detail-By-Id?milestone_id=${obj.id}&proposal_status=${obj.proposal_status}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
       console.log(result.data, "store for milestone api data");
      return result.data;
    })
    .catch((error) => console.log("error", error));
})
export const myReducer = createSlice({
  name: "conqtAdmin",
  initialState: {
    loading: false,
    selectedProposalId: "",
    proposalList: [],
    proposalDetails: {},
    milestoneList:[],
    selectedMilestoneId: "",
    milestoneDetails:{},
    milestoneSinglePageId: "",
    selectedProposalTableCheckbox:[
      "Project Name",
      "Project Price",
      "Project Category",
      "Vendor",
      "Proposal Status",
      "Date",
    ]
  },
  reducers: {
    updateSelectProposalId: (state, action) => {
      // console.log("*****in the store", action);
      // console.log(action.payload, "action.payload proposal in the reducer");
      state.selectedProposalId = action.payload;
      // state.selectedMilestoneId = action.payload;
    },
    updateSelectMilestoneId: (state, action) => {
      // console.log("*****in the store", action);
      console.log(action.payload, "action.payload milsetone in the reducer");
      state.selectedMilestoneId = action.payload;
    },
    updateSinglePageMilestoneId: (state, action) => {
      // console.log("*****in the store", action);
      console.log(action.payload, "milestoneSinglePageId in the reducer");
      state.milestoneSinglePageId = action.payload;
    },
    updateSelectProposalTableCheckbox: (state, action) => {
      state.selectedProposalTableCheckbox = action.payload;
    },
  },
  extraReducers: {
    [getProposalTableData.pending]: (state) => {
      state.loading = true;
    },
    [getProposalTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.proposalList = payload;

      // console.log(payload,"payload from reducer")
    },
    [getSingleProposalDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSingleProposalDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.proposalDetails = payload.proposalDetails;
      state.milestoneList=payload.milestoneList
      // console.log(payload, "payload from proposal reducer");
    },
    [getSingleMilestoneDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSingleMilestoneDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.milestoneDetails = payload;
  },
  },
});

export const {  updateSelectProposalId ,updateSelectMilestoneId , updateSinglePageMilestoneId,updateSelectProposalTableCheckbox} = myReducer.actions;

export default myReducer.reducer;
