import React from 'react'
import './uploadfileimport.css'
import UploadIcon from '../../images/Upload.svg'

const ImportContactUploadFile = () => {
    return (
        <>
            <div className='upload-file-import-body px-3 py-3'>
                <div className='upload-top'>
                    <span><img style={{ width: "27px" }} src={UploadIcon} alt="" /></span>
                    <p className='upload-top-name px-2 pt-1'>Upload a file </p>
                </div>
                <div>
                    <p className='upload-top-content px-2 pt-1'>
                        Upload a file
                        Import contact - demo Excel file <span style={{ color: "gray" }}> doc.xls</span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default ImportContactUploadFile