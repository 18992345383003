import React, { useState, useEffect } from "react";
import SideNavBar from "../../components/Sidebar";
import DashBoardheader from "../../components/DashboardHeader";
import Expander from "../../components/Expander/index";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import ContactArrow from "../../images/contact-drop-down.png";
import { parsePath, useNavigate } from "react-router-dom";
import {
  getSingleVendorDetails,
  updateSelectVendorName,
} from "../../Store/Reducer/Reducer";
import GlobalExpander from "../../components/GlobalExpander/index";
import { toast } from "react-toastify";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import "react-toastify/dist/ReactToastify.css";
import { BaseUrl } from "../Api/api";
import { getAllCountryList,updateSelectcountryList } from "../../Store/Reducer/CountryListReducer";
import pencil from "../../images/pencil-square.svg";
import save from "../../images/save.png";
import VendorContact from "./vendorContact";

const VendorDashboardDetails = () => {
  const { vendorList, loading, selectedVendorId, vendorDetails } = useSelector(
    (state) => state.conqtAdmin
  );

  const { countryList, selectedCountryId } = useSelector(
    (state) => state.allCountryList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    index,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  // console.log(vendorDetails.contactsList, "contactsList");
  const fieldArrayName = "test";
  // const { fields, append, update, remove } = useFieldArray({
  //   control,
  //   name: fieldArrayName,
  //   defaultValues: {
  //     [fieldArrayName]: [],
  //   },
  // });
  // console.log(fields, "fields from hook form use field array");
  // console.log(fieldArrayName, "fields array from contact list");
  const [contactView, setContactView] = useState(false);
  const [isEditPage, setIsEditPage] = useState(true);
  const [isEditContactPage, setIsEditContactPage] = useState(false);
  const [isEditOtherInformationPage, setIsEditOtherInformationPage] =
    useState(true);
  const [isSavePage, setIsSavePage] = useState(false);
  const [data, setData] = useState({});
  const [otherInformationData, setOtherInformationData] = useState({});
  const [solutions, setSolutions] = useState([]);
  

  const onSubmit = (data) => {
    // dispatch(updatedDataSecond(data));
    // navigate("/c");
    // console.log(data,"onsubmit clicked in single page");
    const userToken = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      company_name: data.company_name,
      // first_name: data.name,
      // last_name: "a",
      activation_status: data.activation_status,
      phone_no: data.phone_no,
      solutions: data.solutions,
      // contact_name: data.contact_name,
      // contact_email: data.contact_email,
      // contact_mobile: data.contact_mobile,
      // position: data.position,
      // department: data.department,
      country: data.country,
      state: data.state,
      address: data.address,
      city: data.city,
      zipcode: data.zipcode,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}admin/Vendor-Basic-Detail-Update?vendor_id=` +
        selectedVendorId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          setData(result);
          setIsEditPage(true);
          setIsEditContactPage(true);
          // toast("Data updated successfully");
        } else {
          toast("Network error occurred");
        }
      })
      .catch((error) => console.log("error", error));

    var raw = JSON.stringify({
      vendor_id: selectedVendorId,
      no_of_employee: data.no_of_employee,
      agency_website: data.agency_website,
      linkedin_url: data.linkedin_url,
      twitter_url: data.twitter_url,
      instagram_url: data.instagram_url,
      facebook_url: data.facebook_url,
    });
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${BaseUrl}admin/Vendor-Other-Information-Update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          setOtherInformationData(result);
          setIsEditOtherInformationPage(true);
          toast("Data updated successfully");
        } else {
          toast("Network error occurred");
        }
      })
      .catch((error) => console.log("error", error));

    // console.log("*****submit******", data);
  };
  console.log(errors, "error");

  const onClickView = () => {
    navigate("/vendor-view-contacts");
  };
  const onClickCreate = () => {
    navigate("/add-new-contacts");
    dispatch(updateSelectVendorName(vendorDetails.basicDetails.company_name));
  };
  const onClickContactDropdown = (e) => {
    e.stopPropagation();
    setContactView(!contactView);
  };
  const onClickEditButton = () => {
    setIsEditPage(false);
  };
  const onClickSaveButton = () => {
    setIsSavePage(true);
  };
  const onClickContactEditButton = () => {
    setIsEditContactPage(true);
    // console.log("btn clicked from contacts block");
  };

  const onClickEditOtherInfromationButton = () => {
    setIsEditOtherInformationPage(!isEditOtherInformationPage);
  };
 
  useEffect(() => {
    if (selectedVendorId) {
      dispatch(getSingleVendorDetails(selectedVendorId));

      // console.log(selectedVendorId, "use effect calling");
    }
  }, []);

  useEffect(() => {
    dispatch(getAllCountryList());
  }, []);
  useEffect(() => {
    if (vendorDetails && vendorDetails.basicDetails) {
      Object.entries(vendorDetails.basicDetails).forEach(([name, value]) => {
        setValue(name, value);
      });
      Object.entries(
        vendorDetails.otherInformation
          ? vendorDetails.otherInformation
          : vendorDetails
      ).forEach(([name, value]) => {
        setValue(name, value);
      });
      // (
      //   vendorDetails.contactsList
      //     ? vendorDetails.contactsList
      //     : vendorDetails
      // ).forEach(([name, value]) => {
      //   setValue(name, value);
      // });
    }
    // console.log("data changed from store");
  }, [vendorDetails]);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}vendor/Get-Solutions`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("Get Solutions", JSON.parse(result).data);
        setSolutions(JSON.parse(result).data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="container vendor-details-main-div">
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && (
            <center>
              <div
                className="spinner-border text-primary loader"
                role="status"
              ></div>
            </center>
          )}
          <div className="title-and-edit-save">
            <h1 className="vendor-single-page-title">
              {vendorDetails &&
                vendorDetails.basicDetails &&
                vendorDetails.basicDetails.company_name &&
                vendorDetails.basicDetails.company_name
                  .charAt(0)
                  .toUpperCase() +
                  vendorDetails.basicDetails.company_name.slice(1)}
            </h1>
          </div>
          <div className="single-page-overview">
            <h5 className="header-plus">Overview</h5>
            {isEditPage && (
              <img
                className="overview-pencil"
                onClick={onClickEditButton}
                src={pencil}
                alt=""
              ></img>
            )}
            {!isEditPage && (
              <button
                className="overview-save-button"
                disabled={isEditPage}
                type="submit"
              >
                <img className="overview-pencil" src={save} alt=""></img>
              </button>
            )}
          </div>

          <div className="overview-table">
            <div className="single-page-two-div">
              <div className="single-page-left-container">
                <div className="input-title">
                  <div className="single-page-title"> Name </div>
                  <input
                    disabled={isEditPage}
                    className={
                      isEditPage ? "single-page-value" : "after-click-edit"
                    }
                    {...register("company_name", {
                      required: true,
                      minLength:5,
                      maxLength: 50,
                    })}
                  ></input>
                </div>

                {errors.company_name && (
                  <span className="left-error-msg">Please enter the value</span>
                )}
              </div>

              <div className="single-page-email-div">
                <div className="single-page-title">Email </div>
                <input
                  disabled
                  className="single-page-value"
                  {...register("user_name", {})}
                ></input>
              </div>
            </div>

            <hr className="line"></hr>

            <div className="single-page-two-div">
              <div className="single-page-left-container">
                <div className="input-and-title">
                  <div className="single-page-title">Phone No.</div>
                  <input
                    disabled={isEditPage}
                    className={
                      isEditPage ? "single-page-value" : "after-click-edit"
                    }
                    {...register("phone_no", {
                      required: true,
                      maxLength: 15,
                    })}
                  ></input>
                </div>
                {errors.phone_no && (
                  <span className="left-error-msg">Please enter the value</span>
                )}
              </div>

              <div className="single-page-left-container">
                <div className="category-and-input">
                  <div className="single-page-title">Category </div>
                  <select
                    disabled={isEditPage}
                    className={
                      isEditPage ? "single-page-value" : "after-click-edit"
                    }
                    {...register("solutions", {
                      required: true,
                    })}
                  >
                    {solutions.map((solutions) => (
                      <option
                        key={solutions.id}
                        value={solutions.solutions_name}
                      >
                        {solutions.solutions_name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.solutions && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <hr className="line"></hr>

            <div className="single-page-two-div">
              <div className="single-page-left-container">
                <div className="status-input-and-title">
                  <div className="single-page-title">Status</div>
                  <div disabled className="single-page-value">
                    {" "}
                    {vendorDetails &&
                    vendorDetails.basicDetails &&
                    vendorDetails.basicDetails.activation_status === 0
                      ? "Unsubscribe"
                      : "Subscribe"}
                  </div>
                </div>
              </div>

              <div className="single-page-left-container">
                <div className="company-and-input">
                  <div className="single-page-title">Address</div>
                  <input
                    disabled={isEditPage}
                    className={
                      isEditPage ? "single-page-value" : "after-click-edit"
                    }
                    {...register("address", {
                      required: true,
                    })}
                  ></input>
                </div>
                {errors.address && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <hr className="line"></hr>
            <div className="single-page-two-div">
              <div className="single-page-left-container">
                <div className="country-and-input">
                  <div className="single-page-title">Country</div>
                  <select
                    disabled={isEditPage}
                    className={
                      isEditPage ? "single-page-value" : "after-click-edit"
                    }
                    {...register("country", {
                      required: true,
                    })}
                  >
                    <option className="dropdown-country" value="">
                      {" "}
                      select country
                    </option>
                    {countryList &&
                      countryList.map((country, index) => {
                        return (
                          <option key={index} value={country.value}>
                            {country.country}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {errors.country && (
                  <span className="left-error-msg">Please enter the value</span>
                )}
              </div>

              <div className="single-page-left-container">
                <div className="city-and-input">
                  <div className="single-page-title">City</div>
                  <input
                    disabled={isEditPage}
                    className={
                      isEditPage ? "single-page-value" : "after-click-edit"
                    }
                    {...register("city", {
                      required: true,
                    })}
                  ></input>
                </div>
                {errors.city && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
            <hr className="line"></hr>

            <div className="single-page-two-div">
              <div className="single-page-left-container">
                <div className="state-and-input">
                  <div className="single-page-title">State/Province</div>
                  <input
                    disabled={isEditPage}
                    className={
                      isEditPage ? "single-page-value" : "after-click-edit"
                    }
                    {...register("state", {
                      required: true,
                    })}
                  ></input>
                </div>
                {errors.state && (
                  <span className="left-error-msg">Please enter the value</span>
                )}
              </div>

              <div className="single-page-left-container">
                <div className="zip-and-input">
                  <div className="single-page-title">Zip</div>
                  <input
                    disabled={isEditPage}
                    type="Number"
                    className={
                      isEditPage ? "single-page-value" : "after-click-edit"
                    }
                    {...register("zipcode", {
                      required: true,
                    })}
                  ></input>
                </div>
                {errors.zipcode && (
                  <span className="error-msg">Please enter the value</span>
                )}
              </div>
            </div>
          </div>
          <GlobalExpander
            header={
              <div>
                {" "}
                Other Information{" "}
                {isEditOtherInformationPage && (
                  <img
                    className="overview-pencil"
                    onClick={onClickEditOtherInfromationButton}
                    src={pencil}
                    alt=""
                  ></img>
                )}
                {!isEditOtherInformationPage && (
                  <button
                    className="other-information-save-icon"
                    disabled={isEditOtherInformationPage}
                    type="submit"
                  >
                    <img className="overview-pencil" src={save} alt=""></img>
                  </button>
                )}{" "}
              </div>
            }
          >
            <div className="overview-table">
              {/* //div start */}
              <div className="single-page-two-div">
                <div className="single-page-club-div-left">
                  <div className="single-page-title"> Employees </div>
                  <input
                    type="number"
                    disabled={isEditOtherInformationPage}
                    className={
                      isEditOtherInformationPage
                        ? "single-page-value"
                        : "after-click-edit"
                    }
                    {...register("no_of_employee", {})}
                    min="0"
                    oninput="validity.valid||(value='')"
                  ></input>
                </div>

                <div className="single-page-club-div">
                  <div className="single-page-title">Website </div>
                  <input
                    type="link"
                    disabled={isEditOtherInformationPage}
                    className={
                      isEditOtherInformationPage
                        ? "single-page-value"
                        : "after-click-edit"
                    }
                    {...register("agency_website", {})}
                  ></input>
                </div>
              </div>
              <hr className="line"></hr>

              <div className="single-page-two-div">
                <div className="single-page-club-div-left">
                  <div className="single-page-title"> Facebook Page </div>
                  <input
                    type="link"
                    disabled={isEditOtherInformationPage}
                    className={
                      isEditOtherInformationPage
                        ? "single-page-value"
                        : "after-click-edit"
                    }
                    {...register("facebook_url", {})}
                  ></input>
                </div>

                <div className="single-page-club-div">
                  <div className="single-page-title">Twitter Page </div>
                  <input
                    type="link"
                    disabled={isEditOtherInformationPage}
                    className={
                      isEditOtherInformationPage
                        ? "single-page-value"
                        : "after-click-edit"
                    }
                    {...register("twitter_url", {})}
                  ></input>
                </div>
              </div>

              <hr className="line"></hr>

              <div className="single-page-two-div">
                <div className="single-page-club-div-left">
                  <div className="single-page-title"> Linkedin ID </div>
                  <input
                    type="link"
                    disabled={isEditOtherInformationPage}
                    className={
                      isEditOtherInformationPage
                        ? "single-page-value"
                        : "after-click-edit"
                    }
                    {...register("linkedin_url", {})}
                  ></input>
                </div>

                <div className="single-page-club-div">
                  <div className="single-page-title">Instagram </div>
                  <input
                    type="link"
                    disabled={isEditOtherInformationPage}
                    className={
                      isEditOtherInformationPage
                        ? "single-page-value"
                        : "after-click-edit"
                    }
                    {...register("instagram_url", {})}
                  ></input>
                </div>
              </div>

              {/* <hr className="line"></hr> */}
            </div>
          </GlobalExpander>
        </form>

        <GlobalExpander
          header={
            <div>
              Contact{" "}
              <img
                className="contact-arrow"
                onClick={onClickContactDropdown}
                src={ContactArrow}
                alt=""
              />{" "}
              {contactView && (
                <div className="create-view">
                  <h5 onClick={onClickCreate} className="contact-create">
                    Create
                  </h5>
                  <hr className="create-view-line"></hr>
                  <h5 className="contact-view" onClick={onClickView}>
                    View
                  </h5>
                </div>
              )}
            </div>
          }
        >
          <div className="expander-body-proposal-details">
            <>
              <div className="proposal-titles">
                <div className="details-expander-title center"> Name </div>
                <div className="details-expander-title center ">Email </div>
                <div className="details-expander-title center">Phone No </div>
                <div className="details-expander-title center">Department</div>
                <div className="details-expander-title center">Position </div>
              </div>
              <VendorContact />

              <div className="proposal-values"></div>

              <div className="proposal-titles">
                <div className="details-expander-title center">
                  {" "}
                  {errors.person_name && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}{" "}
                </div>
                <div className="details-expander-title center ">
                  {errors.person_email && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
                <div className="details-expander-title center">
                  {errors.contact_no && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}{" "}
                </div>
                <div className="details-expander-title center">
                  {errors.department && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
                <div className="details-expander-title center">
                  {errors.position && (
                    <span className="contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
              </div>
            </>
          </div>
        </GlobalExpander>

        <Expander
          header={"Bank Account Details"}
          title1={"Bank Name"}
          value1={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.bankDetails.bank_name ?? "Not available")
          }
          title2={"Contact Person"}
          value2={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.bankDetails.account_name ?? "Not available")
          }
          title3={"Account Number"}
          value3={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.bankDetails.bank_account_no ?? "Not available")
          }
          title4={"Account Type"}
          value4={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.bankDetails.account_type ?? "Not available")
          }
          title5={"IFSC code"}
          value5={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.bankDetails.ifsc_code ?? "Not available")
          }
          title6={"Bank Address"}
          value6={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.bankDetails.bank_address ?? "Not available")
          }
          title7={"Currency Code"}
          value7={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.basicDetails.currency_code ?? "Not available")
          }
          title8={"Currency"}
          value8={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.basicDetails.currency ?? "Not available")
          }
          title9={"Bank Branch"}
          value9={
            vendorDetails &&
            vendorDetails.bankDetails &&
            (vendorDetails.bankDetails.ifsc ?? "Not available")
              ? vendorDetails.bankDetails.ifsc ?? "Not available"
              : "Not available"
          }
        />

        <GlobalExpander header={"Proposal"}>
          {vendorDetails && vendorDetails.projectProposalList ? (
            <div className="expander-body-proposal-details">
              {vendorDetails &&
              vendorDetails.projectProposalList &&
              vendorDetails.projectProposalList[0] ? (
                <>
                  <div className="proposal-titles">
                    <div className="details-proposal-expander-title">
                      Project Name{" "}
                    </div>
                    <div className="details-proposal-customer-title">
                      Customer{" "}
                    </div>
                    <div className="details-proposal-customer-title">
                      Category{" "}
                    </div>
                    <div className="details-proposal-price-title">Price </div>
                  </div>

                  {vendorDetails &&
                    vendorDetails.projectProposalList &&
                    vendorDetails.projectProposalList
                      .slice(0, 5)
                      .map((proposalDetails, index) => {
                        return (
                          <div className={index % 2 === 0
                            ? "proposal-values"
                            : "proposal-even-values"}>
                            {(
                              <>
                                <div className="details-proposal-expander-value">
                                  {" "}
                                  {proposalDetails.project_name ??
                                    "Not available"}
                                </div>
                                <div className="details-proposal-customer-value">
                                  {proposalDetails.customer_name ??
                                    "Not available"}
                                </div>
                                <div className="details-proposal-customer-value">
                                  {proposalDetails.category ?? "Not available"}
                                </div>
                                <div className="details-proposal-price-value">
                                  {proposalDetails.price
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                    " " +
                                    vendorDetails.basicDetails.currency ??
                                    "Not available"}
                                </div>
                              </>
                            ) ?? "Not available"}
                          </div>
                        );
                      })}
                </>
              ) : (
                <center>
                  <h4>Data Not Available</h4>
                </center>
              )}
            </div>
          ) : (
            "Not available"
          )}
        </GlobalExpander>

        <GlobalExpander header={"Project"}>
          <div className="expander-body-proposal-details">
            {vendorDetails &&
            vendorDetails.projectProposalList &&
            vendorDetails.projectProposalList[0] ? (
              <>
                <div className="proposal-titles">
                  <div className="details-proposal-expander-title">
                    Project{" "}
                  </div>
                  <div className="details-proposal-category-title">
                    Category{" "}
                  </div>
                  <div className="details-proposal-milestone-title">
                    Milestones{" "}
                  </div>
                  <div className="details-proposal-customer-title">
                    Attachment{" "}
                  </div>
                  <div className="details-proposal-customer-title">Budget</div>
                </div>

                {vendorDetails &&
                  vendorDetails.projectProposalList &&
                  vendorDetails.projectProposalList
                    .slice(0, 5)
                    .map((projectDetails, index) => {
                      return (
                        <div className={index % 2 === 0
                          ? "proposal-values"
                          : "proposal-even-values"}>
                          {(
                            <>
                              <div className="details-proposal-expander-value">
                                {" "}
                                {projectDetails.project_name ?? "Not available"}
                              </div>
                              <div className="details-proposal-category-value">
                                {projectDetails.category ?? "Not available"}
                              </div>
                              <div className="details-proposal-milestone-value">
                                {projectDetails.milestone_count ??
                                  "Not available"}
                              </div>
                              <div className="details-proposal-customer-value">
                                <a
                                  href={projectDetails.attachment_url}
                                  target="_blank"
                                >
                                  {projectDetails.attachment_url
                                    ? "Click Here"
                                    : "Not Available"}
                                </a>
                              </div>
                              <div className="details-proposal-customer-value">
                                {projectDetails.attachment_ur ??
                                  "Not available"}
                              </div>
                            </>
                          ) ?? "Not available"}
                        </div>
                      );
                    })}
              </>
            ) : (
              <center>
                <h4>Data Not Available</h4>
              </center>
            )}
          </div>
        </GlobalExpander>

        <GlobalExpander header={"Subscription"}>
          <div className="expander-body-proposal-details">
            {vendorDetails &&
            vendorDetails.SubscriptionList &&
            vendorDetails.SubscriptionList[0] ? (
              <>
                <div className="proposal-titles">
                  <div className="details-expander-title center">Date </div>
                  <div className="details-expander-title center"> Package</div>
                  <div className="details-expander-title center">Amount </div>
                  <div className="details-expander-title center">Status </div>
                  <div className="details-expander-title center">
                    Current Balance
                  </div>
                </div>

                {vendorDetails &&
                  vendorDetails.SubscriptionList &&
                  vendorDetails.SubscriptionList.slice(0, 5).map(
                    (SubscriptionDetails, index) => {
                      return (
                        <div className={index % 2 === 0
                          ? "proposal-values"
                          : "proposal-even-values"}>
                          <>
                            <div className="details-subscription-value center">
                              {" "}
                              {SubscriptionDetails.subscription_date ??
                                "Not Available"}
                            </div>
                            <div className="details-subscription-value center">
                              {SubscriptionDetails.subscription_title ??
                                "Not Available"}
                            </div>
                            <div className="details-subscription-value center">
                              {" $ " + SubscriptionDetails.transaction_amount ??
                                "Not Available"}
                            </div>
                            <div className="details-subscription-value center">
                              {SubscriptionDetails.transaction_status ??
                                "Not Available"}
                            </div>
                            <div className="details-subscription-value center">
                              {SubscriptionDetails.subscription_point ??
                                "Not Available"}
                            </div>
                          </>
                        </div>
                      );
                    }
                  )}
              </>
            ) : (
              <center>
                <h4>Data Not Available</h4>
              </center>
            )}
          </div>
        </GlobalExpander>

        <GlobalExpander header={"Invoice"}>
          <div className="expander-body-invoice-details">
            {vendorDetails &&
            vendorDetails.InvoiceList &&
            vendorDetails.InvoiceList[0] ? (
              <>
                <div className="invoice-titles">
                  <div className="details-invoice-number-title">Invoice No. </div>
                  <div className="details-expander-invoice-title"> Project</div>
                  <div className="details-expander-invoice-title">
                    Description{" "}
                  </div>
                  <div className="details-amount-title">Amount </div>
                  <div className="details-invoice-date-title">Invoice Date</div>
                  <div className="details-attachment-title">Attachment</div>
                  <div className="details-expander-due-title">Due Date</div>
                </div>

                {vendorDetails &&
                  vendorDetails.InvoiceList &&
                  vendorDetails.InvoiceList.slice(0, 5).map(
                    (InvoiceDetails, index) => {
                      return (
                        <div
                          className={
                            index % 2 === 0
                              ? "invoice-values"
                              : "invoice-even-values"
                          }
                        >
                          <>
                            <div className="details-invoice-number-value">
                              {" "}
                              {InvoiceDetails.invoice_no ?? "Not Available"}
                            </div>
                            <div className="details-expander-invoice-value">
                              {InvoiceDetails.project_name.slice(0, 50) ??
                                "Not Available"}
                            </div>
                            <div className="details-expander-invoice-value">
                              {parse(
                                InvoiceDetails.project_description.slice(0, 50)
                              ) ?? "Not Available"}
                            </div>
                            <div className="details-amount-value">
                              {" $ " + InvoiceDetails.milestone_amount ??
                                "Not Available"}
                            </div>
                            <div className="details-invoice-date-value">
                              {InvoiceDetails.invoice_date ?? "Not Available"}
                            </div>
                            <div className="details-attachment-value">
                              <a
                                href={InvoiceDetails.invoice_url}
                                target="_blank"
                              >
                                {InvoiceDetails.invoice_url
                                  ? "Click Here"
                                  : "Not Available"}
                              </a>
                            </div>
                            <div className="details-expander-due-value">
                              {InvoiceDetails.due ?? "Not Available"}
                            </div>
                          </>
                        </div>
                      );
                    }
                  )}
              </>
            ) : (
              <center>
                <h4>Data Not Available</h4>
              </center>
            )}
          </div>
        </GlobalExpander>

        <GlobalExpander header={"Receipt"}>
          <div className="expander-body-invoice-details">
            {vendorDetails &&
            vendorDetails.paymentReceiptList &&
            vendorDetails.paymentReceiptList[0] ? (
              <>
                <div className="invoice-titles">
                  <div className="details-invoice-title">Receipt </div>
                  <div className="details-expander-invoice-title"> Project</div>
                  <div className="details-expander-invoice-title">
                    Description{" "}
                  </div>
                  <div className="details-amount-title">Amount </div>
                  <div className="details-expander-title">Receipt Date</div>
                  <div className="details-expander-title">Attachment</div>
                </div>

                {vendorDetails &&
                  vendorDetails.paymentReceiptList &&
                  vendorDetails.paymentReceiptList
                    .slice(0, 5)
                    .map((paymentDetails, index) => {
                      return (
                        <div
                          className={
                            index % 2 === 0
                              ? "invoice-values"
                              : "invoice-even-values"
                          }
                        >
                          <>
                            <div className="details-invoice-value">
                              {" "}
                              {paymentDetails.receipt_no ?? "Not Available"}
                            </div>
                            <div className="details-expander-invoice-value">
                              {paymentDetails.project_name ?? "Not Available"}
                            </div>
                            <div className="details-expander-invoice-value">
                              {parse(
                                paymentDetails.project_description.slice(0, 50)
                              ) ?? "Not Available"}
                            </div>
                            <div className="details-amount-value">
                              {" $ " + paymentDetails.amount ?? "Not Available"}
                            </div>
                            <div className="details-expander-value">
                              {paymentDetails.receipt_date ?? "Not Available"}
                            </div>
                            <div className="details-expander-value">
                              {paymentDetails.acc ?? "Not Available"}
                            </div>
                          </>
                        </div>
                      );
                    })}
              </>
            ) : (
              <center>
                <h4>Data Not Available</h4>
              </center>
            )}
          </div>
        </GlobalExpander>
      </div>
    </>
  );
};

export default VendorDashboardDetails;
