import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const myReducer = createSlice({
    name: "conqtAdmin",
    initialState: {
        loading: false,
        addSingleCustomer: {},
        singleCustomerID: "",
        currentSingleCustomer: {},
    },
    reducers: {
        setCurrentSingleCustomer: (state, action) => {
            state.currentSingleCustomer = action.payload
        },
        updateSingleCustomerID: (state, action) => {
            state.singleCustomerID = { ...state.singleCustomerID, ...action.payload }
        },
        updateAddSingleCustomer: (state, action) => {
            state.addSingleCustomer = { ...state.addSingleCustomer, ...action.payload };
        },
        clearAddSingleCustomer: (state, action) => {
            state.addSingleCustomer = {};
        }
    },
    extraReducers: {
    },
})

export const {
    updateAddSingleCustomer,
    clearAddSingleCustomer,
    setCurrentSingleCustomer,
    updateSingleCustomerID,
} = myReducer.actions;

export default myReducer.reducer;