import React, { useEffect, useState } from 'react'
import DashBoardheader from '../../../components/DashboardHeader'
import { useDispatch, useSelector } from "react-redux";
import SideNavBar from '../../../components/Sidebar'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TargetCallExpertTable from '../../../components/TargetCallExpertTable';
import { useNavigate } from 'react-router-dom';
import "./CallExpertPage.css"
import {
  getCallExpertTableData
} from "../../../Store/Reducer/CallExpertReducer";


const TargetCallExpert = () => {

    const [expertList, setExpertList] = useState([])
    const [searchList, setSearchList] = useState([])
    const [showHeader, setShowHeader] = useState(true);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const userToken = localStorage.getItem("token");
    useEffect(() => {
      dispatch(getCallExpertTableData());
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
  
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
    }, []);

    const {
      callExpertList,
      loading,
    } = useSelector((state) => state.callExpertData);
    const feedbackHistory = []
    callExpertList.map((item,index)=>{
      if(item.remarklist.length !== 0){
        item.remarklist.map(key=>{
              feedbackHistory.push(key)
          })
      }
    })
  
      feedbackHistory.sort((s1, s2) => {
        return s2.id - s1.id;
    });
     
    

    return (
        <>
        <DashBoardheader />
        <SideNavBar />
      
        <div className="vendor-follow">
          <div className="vendor-follow-main-div">
          {showHeader && (
            <div className="vendor-addicon">
            <h1 className="vendor-follow-header">Call Expert</h1>
            {/* <img className="addicon" onClick={onClickAddIcon} src={PersonIcon} alt=""></img> */}
            </div>
            )}
            <div className="containerr">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                   
                    <TargetCallExpertTable
                      DelayClicked={() => {
                     
                      }}
                     isShowHeader={(val)=>{
                      setShowHeader(val)
                     }}
                    />
                   
                  </div>
                  
                  <div >
                <div className='feedback-header'><h1 className="feedback-title">Feed Back History</h1></div>
                <div className='feed-back-history'>
                 {feedbackHistory.map((key,index)=>{
                  return (
                    <div className='feedback-history-section'>
   <div className='feedback-admin-profile'>
     <div className='d-flex align-items-center px-2'>
       <p className='dashboard-vendor-circle'>{key.adminuser_name[0]}</p>
       <div className='px-2'>
         <h5 className='dashboard-vendor-name'>{key.adminuser_name}</h5>
         <p className='dashboard-vendor-sub-name'>CONQT</p>
       </div>
     </div>
   </div>
   <div className='feedback-history-content'>
     <p className='feedack-remark'>{key.remark}</p>
     <p className='feedback-date-time'>{key.created_at}</p>
   </div>
 </div>
                  )
                  
                 })}
                 </div>
                  </div>
                  <div style={{margin:"0 0 2rem 0"}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default TargetCallExpert