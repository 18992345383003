import React, { useState, useEffect } from 'react'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import './addvendorcontact.css'
import TargetVendorBreadCrumbs from '../TargetVendorBreadCrumbs'
import { useForm } from 'react-hook-form'
import BackArrowIcon from "../../../../images/BackArrow.svg";
import { getAllCountryList } from "../../../../Store/Reducer/CountryListReducer";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateFormDetails } from '../../../../Store/Reducer/TargetVendorContact';
import axios from 'axios'
import { dsUrl } from '../../../Api/api';
import { BaseUrl } from '../../../Api/api'
import Vendor from '../../../DashboardHome/Vendor'
import { FilterList } from '@mui/icons-material'
import { toast } from 'react-toastify'


const TargetAddVendorContact = () => {

    const { register, handleSubmit, setValue, getValues, watch, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [filteredList, setFilteredList] = useState([])
    const [countries, setCountries] = useState([])
    const [select, setSelect] = useState([])

    const { targetVendorContactList, selectedTargetVendorContactId, loading } = useSelector((state) => state.targetVendorContact);
    console.log(targetVendorContactList, "data from use Selector");

    const { formDetails } = useSelector((state) => state.targetVendorContact);
    const { countryList, selectedCountryId } = useSelector(
        (state) => state.allCountryList
    );


    // useEffect(() => {
    //     for (const key in formDetails) {
    //         if (Object.hasOwnProperty.call(formDetails, key)) {
    //             const element = formDetails[key];
    //             setValue(key, element);
    //         }
    //     }
    // }, [formDetails]);


    // mobile number validation 
    const validateMobileNumber = (value) => {
        const mobileNumberRegex = /^[0-9]{10}$/;
        const maxLength = 10;
        if (!value) {
            return 'Mobile number is required';
        }
        if (!mobileNumberRegex.test(value)) {
            return 'Invalid mobile number';
        }
        if (value.length > maxLength) {
            return `Mobile number must be ${maxLength} digits or less`;
        }
        return true;
    };

    useEffect(() => {

        const userToken = localStorage.getItem("token")
        axios
            .get(`${BaseUrl}common/Get-All-Country`,
                { headers: { Authorization: `Bearer ${userToken}` } }
            )
            .then((response) => {

                setCountries(response.data.data.country_list)

            })
    }, [])

    const onSubmit = (data) => {
        // dispatch(updateFormDetails(data))

        // const data[target_vendor_id] = vendor?.target_vendor_id

        const selectedKey = data.target_vendor_id
       data.target_vendor_id = selectedKey

        console.log("enter data", data)
        const token = localStorage.getItem('token')
        axios.post(`${BaseUrl}AdminTargetVendor/Create-Admin-Target-Vendor-contact`, data,
        { headers: { Authorization: `Bearer ${token}` } }
        ). then((response) => {
            console.log("response",response)
            if(response.data.status === 200) {
                toast.success("Added successfully")
                navigate("/target-vendor-contact-main-page")
            }
            else if(response.data.status ===401){
                toast.error(response.data.message)
            }
        }).catch((error) => {
            console.log("error",error)
        });
        // navigate('/target-vendor-contact-overview')
    }
    

    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className="main-body-container">
                <div className="container py-2 mt-3">
                    <TargetVendorBreadCrumbs />
                </div>
                {/* {JSON.stringify(countries)} */}
                <div className="container py-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="target-add-customer-top-name">
                            Add Contact
                        </h5>
                    </div>
                    {/* <div>
                        <img
                            onClick={handleClickcustomercontact}
                            src={BackArrowIcon}
                            alt=""
                            style={{ cursor: "pointer" }}
                        />
                    </div> */}
                </div>
                <div className="container py-3" style={{ marginBottom: "30px" }}>
                    <div className='d-flex'>
                        <div className='customer-list'>Vendor List</div>
                        <div className='px-2'>
                            <select
                                type="text"
                                placeholder="Select"
                                {...register("target_vendor_id", {
                                    required: "Required Field",
                                })}
                                style={{
                                    border: " 1px solid gray",
                                    borderRadius: "8px",
                                    padding: "5px",
                                    width: "300px",
                                    backgroundColor: "white",
                                }}>
                                {targetVendorContactList?.map((vendor )=> (
                                    <option value={vendor?.id} className="">{vendor?.company_name}</option>
                                ))}
                            </select>
                            {/* {JSON.stringify(targetVendorContactList)} */}
                            <div>
                                <p className="required-field">
                                    {errors.target_vendor_id?.message}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='target-add-customer-contact px-3 py-4'>
                        <div className="d-flex">
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Name</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    placeholder='Enter Person Name'
                                    {...register("contact_p_name", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_name?.message}
                                    </p>
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Email</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="email"
                                    placeholder='Enter Email Address'
                                    {...register("contact_p_email",
                                        { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }
                                    )}

                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    {errors.contact_p_email?.type === 'required' && <p className="required-field">Required Field</p>}
                                    {errors.contact_p_email?.type === 'pattern' && <p className="required-field">Invalid email format (must be a valid Gmail address)</p>}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex my-4">
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Phone No.</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="number"
                                    placeholder='Enter Contact Number'
                                    {...register("contact_p_phone", {
                                        required: true,
                                        minLength: 10,
                                        maxLength: 10,
                                        validate: validateMobileNumber,
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    {errors.contact_p_phone?.type === 'required' && <p className="required-field"> Required Field</p>}
                                    {errors.contact_p_phone?.type === 'minLength' && <p className="required-field">Mobile number must be at least 10 digits</p>}
                                    {errors.contact_p_phone?.type === 'maxLength' && <p className="required-field">Mobile number must be no more than 10 digits</p>}
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Department</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    placeholder='Enter Department'
                                    {...register("contact_p_department", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_department?.message}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{ paddingBottom: "50px" }}>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Position</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    placeholder='Enter Contact Position'
                                    {...register("contact_p_role", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_role?.message}
                                    </p>
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Country</p>
                            </div>
                            <div className="col-4">
                                <select
                                    name=""
                                    id=""
                                    type="text"

                                    {...register("contact_p_country", {
                                        required: " Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "7px",
                                        width: "300px",
                                        background: "white",
                                    }}
                                >
                                    {countries?.map(con => (
                                        <option placeholder='select Country' value={con?.country} className="">{con?.country}</option>
                                    ))}
                                </select>
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_country?.message}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className='d-flex align-items-center justify-content-end'
                            // style={{ paddingRight: "120px" }}
                            >
                                <button
                                    onClick={handleSubmit(onSubmit)}
                                    className='customer-contact-add-btn px-4 py-1'>Add</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default TargetAddVendorContact