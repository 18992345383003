import React, { useState } from 'react'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import './allcontacts.css'
import AllContactsEmailModule from '../../../../components/AllContacts/AllContactsEmailModule'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { useNavigate } from 'react-router-dom'

const AllContacts = () => {

    const navigate = useNavigate()
    const [showFilter, setShowFilter] = useState(false)

    const toggleFiler = () => {
        setShowFilter(!showFilter)
    }

    const handleClickImportContact = () => {
        navigate('/import-contact')
    }
    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className='body-container'>
                <div className="container">
                    <div className='top-button py-3'>
                        <p className='top-button-all-contacts px-3 py-2'
                            onClick={handleClickImportContact}
                            style={{ cursor: "pointer" }}
                        >Import Contact</p>
                    </div>
                    <div>
                        <hr />
                    </div>
                </div>
                {/* <div className="container">
                    <div className='filter-email-category p-2' style={{ cursor: "pointer" }}>
                        <p className='filter-email-category-name' onClick={toggleFiler}>Filter By Email Category</p><span onClick={toggleFiler}> <ArrowDropDown /></span>
                    </div>
                    <div>
                        {showFilter && (
                            <div className='filer-email-names'>
                                <p className='filter-email-category-name'>ERP</p>
                                <p className='filter-email-category-name'>CRM</p>
                                <p className='filter-email-category-name'>SAP</p>
                                <p className='filter-email-category-name'>Software Development</p>
                                <p className='filter-email-category-name'>Server</p>
                                <p className='filter-email-category-name'>Cloud</p>
                                <p className='filter-email-category-name'>IOT</p>
                            </div>
                        )}
                    </div>
                </div> */}
                <div className="container">
                    <div className='allcontacts-table'>
                        <AllContactsEmailModule />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllContacts