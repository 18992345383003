import React, { useState, useEffect } from 'react'
import DashBoardheader from '../../../components/DashboardHeader'
import SideNavBar from '../../../components/Sidebar'
import './vendorcontact.css'
import TableVendorContactTable from '../../../components/TargetVendorContactTable'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BaseUrl, dsUrl } from '../../Api/api'
import { useDispatch, useSelector } from "react-redux";
import TargetVendorContact, {
    getTargetVendorContactData
} from "../../../Store/Reducer/TargetVendorContact";
import TargetVendorBreadCrumbs from './TargetVendorBreadCrumbs'

const VendorContact = () => {

    const [searchVendorContactList, setSearchVendorContactList] = useState([]);
    const [vendorContactList, setVendorContactList] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const handleClickAddCustomerContact = () => {
        navigate('/target-add-vendor-contact')
    }
    const { targetVendorContactList, loading } = useSelector((state) => state.targetVendorContact);
    console.log(targetVendorContactList, "data from use Selector");

    useEffect(() => {

        dispatch(getTargetVendorContactData());

    }, []);

    useEffect(() => {
        const userToken = localStorage.getItem("token");
        axios
            .get(`${BaseUrl}AdminTargetVendor/Get-Target-Vendor-Contact-List`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((response) => {
                console.log("customecontactList ==>", response.data);
                setVendorContactList(response.data.data);
                setSearchVendorContactList(response.data.data);
            });
    }, []);

    const handleSearch = (e) => {
        const filtered = vendorContactList.filter((l) => {
            return (
                (l.contact_p_name &&
                    l.contact_p_name.toLowerCase().includes(e.target.value)) ||
                (l.contact_p_email &&
                    l.contact_p_email.toLowerCase().includes(e.target.value)) ||
                (l.contact_p_department &&
                    l.contact_p_department.toLowerCase().includes(e.target.value)) ||
                (l.contact_p_role &&
                    l.contact_p_role.toLowerCase().includes(e.target.value))
            );
        });
        setSearchVendorContactList(filtered);
    };

    // console.log("====>", targetVendorContactList);

    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className="main-body-container">
                <div className="container py-2 mt-3">
                    <TargetVendorBreadCrumbs />
                </div>
                <div className="container py-2 mt-2">
                </div>
                <div className="container">
                    <div className="">
                        <div className='mt-3'>
                            <div className="">
                                <h1 className="customer-top-name">Vendor Contact</h1>
                            </div>
                            <div className='d-flex justify-content-end py-2'>
                                <div className="customer-top-search d-flex">
                                    <div>
                                        <input type="text"
                                            placeholder='search'
                                            style={{
                                                border: "1px solid #B5B7BC",
                                                padding: '5px',
                                                borderRadius: "8px",
                                                marginRight: "20px"
                                            }}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            onClick={handleClickAddCustomerContact}
                                            class="target-top-add-btn" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                            Add Vendor Contact
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <TableVendorContactTable vendorContactList={searchVendorContactList} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorContact