import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
// import './breadcrumbs.css'
import { useLocation, useNavigate } from 'react-router-dom'

const   TargetVendorBreadCrumbs = () => {

    const [enableOverView,setEnableOverView]=useState(false)
    const [enableMultipleOverView,setEnableMultipleOverView]=useState(false)
    const [enableEdit,setEnableEdit]=useState(false)
    const { pathname } = useLocation();
    const navigate = useNavigate();

useEffect(()=>{
    if(pathname==='/target-overview-vendor'){
        setEnableOverView(true)
    }
    else if(pathname==='/target-edit-vendor'){
        setEnableEdit(true)
    }
    else if(pathname==='/target-overview-multiple-vendor'){
        setEnableMultipleOverView(true)
    }
},[])
    const onClickVendor = () => {
        navigate('/target-vendor-main-page')
    }

    const onClickAddcontact = () => {
        if(pathname=='/target-add-single-vendor' || '/target-overview-vendor' || '/target-edit-vendor'){
            navigate('/target-add-single-vendor')
        }
       else if(pathname=='/target-add-multiple-vendor' || '/target-overview-multiple-vendor'){
        navigate('/target-add-multiple-vendor')
       }
    }

    const onClickSingleOverview = () => {
        navigate('/target-overview-vendor')
    }

    const onClickSingleOverviewEdit = () =>{
        navigate('/target-edit-vendor')
    }

   

    return (
        <>

            <nav>
                <div className='d-flex' style={{ listStyle: "none" }}>
                    <li>
                        <p className={`breadcrumbs-name ${pathname === '/target-vendor-main-page' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickVendor}
                            style={{ cursor: "pointer" }}
                        >
                            Vendor <span className='px-1'>{'>'}</span>
                        </p>
                    </li>
                   {pathname=='/target-add-single-vendor'?<li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/target-add-single-vendor' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickAddcontact}
                            style={{ cursor: "pointer" }}
                        >
                            Add New Vendor
                        </p>
                    </li>:
                    <li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/target-add-multiple-vendor' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickAddcontact}
                            style={{ cursor: "pointer" }}
                        >
                            Add New Vendor
                        </p>
                    </li>}

                   {enableOverView==true?<li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/target-overview-vendor' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickSingleOverview}
                            style={{ cursor: "pointer" }}
                        >
                            <span className='px-1'>{'>'}</span> Overview <span className='px-1'></span>
                        </p>
                    </li>
                    :enableEdit==true?
                    <>
                    <li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/target-overview-vendor' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickSingleOverview}
                            style={{ cursor: "pointer" }}
                        >
                            <span className='px-1'>{'>'}</span> Overview <span className='px-1'></span>
                        </p>
                    </li>
                    <li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/target-edit-vendor' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickSingleOverviewEdit}
                            style={{ cursor: "pointer" }}
                        >
                            <span className='px-1'>{'>'}</span>  Edit <span className='px-1'></span>
                        </p>
                    </li></>:enableMultipleOverView==true?  <li className='px-2'>
                        <p className={`breadcrumbs-name ${pathname === '/target-overview-multiple-vendor' ? 'breadcrumbs-lin-active' : ''}`}
                            onClick={onClickSingleOverview}
                            style={{ cursor: "pointer" }}
                        >
                            <span className='px-1'>{'>'}</span> Overview <span className='px-1'></span>
                        </p>
                    </li>:<></>}
                </div>

            </nav>
        </>
    )
}

export default TargetVendorBreadCrumbs