import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import FilterComponent from "../DashboardHome/Vendor/VendorFilter/index";
import Expander from "../../components/Expander/index";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import ColumnsIcon from "../../images/columns.png";
import Arrow from "../../images/arrow-left-circle.svg";
import ContactArrow from "../../images/contact-drop-down.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updatedData } from "../../Store/Reducer/Reducer";
import {
  getCustomerTableData,
  updateSelectCustomerId,
  updateSelectCustomerName,
  updateSelectCustomerTableCheckbox
} from "../../Store/Reducer/CustomerReducer";
import { transformCustomerTableDataResponse } from "../../utils/util";
import AddContact from "../../images/add-contact.png";

const Table = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [apiData, setData] = useState([]);
  const { customerList, loading , selectedCustomerTableCheckbox} = useSelector((state) => state.customer);
  // console.log(customerList, "data from customer use Selector");
  //const [loading, setLoading] = useState(false);
  const [isShowTable, showTableUpdate] = useState(true);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});
  const [isColumns, isSetColumns] = useState({});
  const [checkBox, showCheckBox] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [contactView, setContactView] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState([
    {
      id: "Customer",
      name: "Contact",
      selector: (result) => result.name,
      sortable: true,
    },
    {
      id: "Organization",
      name: "Organization Name",
      selector: (result) => result.company_name,
      sortable: true,
    },

    {
      id: "Email",
      name: "Email id",
      selector: (result) => result.user_name,
      sortable: true,
    },
    {
      id: "Phone No",
      name: <div>Phone Number</div>,
      selector: (result) => result.phone_number,
      sortable: true,
    },
    {id:"Add Contact",
    name: "Add Contact",
      cell: (result) => (
        <div className="add-contact-icon-table">
          <img
            src={AddContact}
            onClick={() => {
              onClickAddIcon(result.company_name);
            }}
            alt=""
          ></img>
        </div>
      ),
    },
  ]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const userToken = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getCustomerTableData());
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  }, []);

  const customerTableData = customerList;
  const result = transformCustomerTableDataResponse(customerTableData);
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#0900bc",
        color: "white",
        fontSize: "14px",
        width: "100%",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "13.5px",
        color: "black",
        height:"auto",
        fontWeight: "500",
        letterSpacing: "0.5px",
      },
    },
    cells: {
      style: {
        fontSize: "13.5px",
      },
    },
  };
  const onClickContact = () => {
    setContactView(!contactView);
  };

  const onClickView = () => {
    navigate("/contacts");
  };
  const onClickCreate = () => {
    navigate("/create-contacts");
  };
  const onClickApply = () => {
    let select = allColumns.filter((value) => {
      return isSelected.includes(value.id);
    });
    setSelectedHeaders(select);
    dispatch(updateSelectCustomerTableCheckbox(isSelected));
  };
  const onClickAddIcon = (row) => {
    navigate("/add-customer-new-contact");
    dispatch(updateSelectCustomerName(row));
    // console.log(row,"customer row list")
  };
  const onRowClicked = (row) => {
    navigate("/customer-details");
    showTableUpdate(false);
    dispatch(updateSelectCustomerId(row.id));
  };

  const onClickCol = () => {
    showCheckBox(!checkBox);
  };

  useEffect(() => {
    let select = allColumns.filter((value) => {
      return (
        selectedCustomerTableCheckbox &&
        selectedCustomerTableCheckbox.includes(value.id)
      );
    });
    setSelectedHeaders(select);
  }, []);
  const allColumns = [
    {
      id: "Joining Date",
      name: "Created Date",
      selector: (result) => result.created_at,

      // sortable: true,
    },
    {
      id: "No. Projects",
      name: "No. Projects",
      selector: (result) => result.announcement_count,
      sortable: true,
    },
    {
      id: "Updated At",
      name: "Updated Date",
      selector: (result) => result.updated_at,
      // sortable: true,
    },
    {
      id: "Organization",
      name: "Organization Name",
      selector: (result) => result.company_name,
      sortable: true,
    },
    {
      id: "Customer",
      name: "Contact",
      selector: (result) => result.name,
      sortable: true,
    },
    {
      id: "Email",
      name: "Email id",
      selector: (result) => result.user_name,
      sortable: true,
    },
    {
      id: "Phone No",
      name: <div>Phone Number</div>,
      selector: (result) => result.phone_number,
      sortable: true,
    },
    {
      id: "Subscription",
      name: <div>Subscription</div>,
      selector: (result) => result.subcription,
      sortable: true,
    },
    {
      id: "Country",
      name: "Country",
      selector: (result) => result.country,
      sortable: true,
    },
    {
      id: "City",
      name: "City",
      selector: (result) => result.city,
      sortable: true,
    },
    {
      id: "Zipcode",
      name: "Zipcode",
      selector: (result) => result.zipcode,
      sortable: true,
    },
    {
      id: "Address",
      name: "Address",
      selector: (result) => result.address,
      sortable: true,
    },

    {
      id: "Currency Code",
      name: "Currency Code",
      selector: (result) => result.currency_code,
      sortable: true,
    },
    {
      id: "Currency",
      name: "Currency",
      selector: (result) => result.currency,
      sortable: true,
    },
    {
      id: "Activation Status",
      name: "Status",
      selector: (result) => result.activation_status,
      sortable: true,
      cell: (result) => (
        <div
          className={
            result.activation_status === "Subscribe"
              ? "table-status-Delivered"
              : "table-status-Delayed"
          }
        >
          {result.activation_status}
        </div>
      ),
    },
    {id:"Add Contact",
    name: "Add Contact",
    cell: (result) => (
      <div className="add-contact-icon-table">
        <img
          src={AddContact}
          onClick={() => {
            onClickAddIcon(result.company_name);
          }}
          alt=""
        ></img>
      </div>
    ),
  },
  ];

  const results =
    result &&
    result.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())) ||
        item.country.toLowerCase().includes(filterText.toLowerCase()) ||
        item.updated_at.toLowerCase().includes(filterText.toLowerCase()) ||
        item.user_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.phone_number.toLowerCase().includes(filterText.toLowerCase()) ||
        item.company_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.address.toLowerCase().includes(filterText.toLowerCase()) ||
        item.created_at.toLowerCase().includes(filterText.toLowerCase()) ||
        item.zipcode.toLowerCase().includes(filterText.toLowerCase()) ||
        item.city.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className="table-filter">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder={"Search"}
        />
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="pt-3">
      {isShowTable && (
        <>
          <div
            className={checkBox ? "custom-columns" : "custom-columns-before"}
          >
            <img
              className="columns-icon"
              onClick={onClickCol}
              src={ColumnsIcon}
              alt=""
            />{" "}
            &nbsp;
            {checkBox && (
              <>
                <div className="dropdown-multi">
                  <DropdownMultiselect
                    handleOnChange={(value) => {
                      setIsSelected(value);
                    }}
                    options={[
                      "Address",
                      "Add Contact",
                      "City",
                      "Country",
                      "Currency",
                      "Currency Code",
                      "Customer",
                      "Email",
                      "Joining Date",
                      "No. Projects",
                      "Organization",
                      "Phone No",
                      "Zipcode",
                    ]}
                    selected={ selectedCustomerTableCheckbox}
                    placeholder="select"
                  />
                </div>
                <button className="apply-button" onClick={onClickApply}>
                  Apply
                </button>
              </>
            )}
          </div>
        </>
      )}
      {loading && (
        <center>
          <div
            className="spinner-border text-primary loader"
            role="status"
          ></div>
        </center>
      )}
      {isShowTable && (
        <>
          <DataTable
            columns={selectedHeaders}
            data={results}
            // expandableRows
            customStyles={customStyles}
            //  expandableRowsComponent={ExpandedComponent}
            //  expandableRows
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onRowClicked={onRowClicked}
            pagination
          />
        </>
      )}
    </div>
  );
};

export default Table;
