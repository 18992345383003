import React, { useState } from 'react'
import BreadCrumbs from '../../../../components/BreadCrumbs'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import './campaignrecipient.css'
import DropDownIcon from '../../../../images/downArrow.svg'
import { useNavigate } from 'react-router-dom'
import MultipleOptions from '../../../../components/MultipleOptions'
import Multiselect from 'multiselect-react-dropdown';
import CancelIcon from '../../../../images/cancleXicon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { updateCampaign } from '../../../../Store/Reducer/CampaignReducer'
import BackArrow from '../../../../images/BackArrow.svg'
import { useEffect } from 'react'
import axios from 'axios'
import { BaseUrl, dsUrl } from "../../../Api/api";

const CampaignRecipients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSubscriberList, setShowSubscriberList] = useState(false);
  const { campaign } = useSelector((state) => state.campaign);
  const [receipentOptions, setReceipentOptions] = useState([]);

  const handleToggleSubscriberList = () => {
    setShowSubscriberList(!showSubscriberList);
  };

  const handleClickContentPage = () => {
    navigate("/campaign-content");
  };

  const handleClickReview = () => {
    navigate("/campaign-review");
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [options, setOptions] = useState([
    "(ERP Software-250Members)",
    "(ERP Software-190Members)",
    "(ERP Software-200Members)",
    "(ERP Software-181Members)",
  ]);

  const removeOption = (option) => () => {
    dispatch(
      updateCampaign({
        recipients: campaign?.recipients.filter((o) => o != option),
      })
    );
  };

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    console.log("token", userToken);
    axios
      .post(
        `${BaseUrl}admin/Get-All-Category-for-Campaign`,
        {
          get_type: campaign?.campaign_type,
        },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((response) => {
        console.log("response", response);
        setReceipentOptions(
          response.data?.data?.map(
            (option) =>
              option.categroy_name + "-  " + option.total_contact_count
          )
        );
      })
      .catch((err) => {
        console.log("errorsss", err);
      });
  }, []);

  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      <div className="main-body-container">
        <div className="container py-2 mt-2">
          <BreadCrumbs />
        </div>
        {/* {JSON.stringify(receipentOptions)} */}
        <div className="container py-4 d-flex justify-content-between align-items-center">
          <h4 className="recipient-top-heading">Recipients</h4>
          <img
            onClick={handleClickContentPage}
            src={BackArrow}
            alt=""
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="container">
          <div
            className="main-page-recipient"
            style={{
              padding: "20px",
            }}
          >
            <div className="py-3">
              <h4 className="recipients-top-header">
                Select Recipients of your Campaign
              </h4>
            </div>
            <div style={{ width: "500px" }}>
              <Multiselect
               showArrow={true}
                isObject={false}
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSelect={(e) => {
                  setSelectedOptions(e);
                  console.log("selected", e);
                  dispatch(
                    updateCampaign({
                      recipients: e,
                    })
                  );
                }}
                options={receipentOptions}
                selectedValues={campaign?.recipients}
                showCheckbox
              />
            </div>
            <div className=" d-flex align-items-center pt-4">
              {/* <p className='recipient-selected'>Recipients Selected : </p> */}
              {campaign?.recipients?.map((option) => (
                <div className=" d-flex flex-column">
                  <p
                    className="recipient-options px-2 py-1 mx-1"
                    style={{ background: "white" }}
                  >
                    {option}
                    <span className="px-2" onClick={removeOption(option)}>
                      <img src={CancelIcon} style={{ width: "15px" }} alt="" />
                    </span>
                  </p>
                </div>
              ))}
            </div>
            {campaign?.recipients && campaign?.recipients.length > 0 ? (
              <div
                className="container next-btn-camapaign py-4"
                style={{ marginTop: "100px" }}
              >
                <button
                  className="next-btn-campaign-recipient px-5 py-2"
                  onClick={handleClickReview}
                >
                  Next
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignRecipients