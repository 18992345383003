import React, { useState, useEffect } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import cross from "../../../../images/cross.svg";
// import "./popupDep.css";
import { useForm } from "react-hook-form";
import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../../../images/delete.svg";
import { getDepartmentListTableData } from '../../../../Store/Reducer/DepartmentList';


const DeleteIndustry = ({ open, handleClose, name }) => {

        const [close, setClose] = useState(handleClose);
        const dispatch = useDispatch();

        // console.log("selectedSingleDepartmentData", selectedSingleDepartmentData);
        // const department_id = selectedSingleDepartmentData?.id;
        // console.log("department_id", department_id);

        const selectedSingleIndustryData = useSelector((state) => state.industryList.selectedSingleIndustryData);
        console.log("selectedSingleIndustryData", selectedSingleIndustryData);
        const industry_id = selectedSingleIndustryData?.id;

        useEffect(() => {
                dispatch(getDepartmentListTableData())
        }, [])

        const deleteIndustry = async () => {
                const data = {
                        industry_id: industry_id
                };

                axios.post(`${BaseUrl}ProductMaster/Delete-Industry`, data,
                        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
                        .then((res) => {
                                if (res.data.status === 200) {
                                        console.log("res", res);
                                        setClose(handleClose(false))
                                        // toast.success("Department Deleted Successfully")
                                        window.location.reload();
                                        window.scrollTo(0, 0);

                                }
                                else if (res.data.status === 401) {
                                        console.log("error res", res);
                                        setClose(handleClose(false))
                                        toast.error(res.data.message)
                                }
                        })
                        .catch((err) => {
                                console.log("err", err);
                                toast.error("Something went wrong")
                        })
        }

        return (
                <>
                        <Modal open={open} handleClose={handleClose} size="xs">
                                <Modal.Body >
                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <img src={Delete} alt="" />
                                                <h4 className='are-you-sure py-4'>Are you sure?</h4>
                                                <p className='sub-del-header'>You want to delete this {name}</p>

                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-4 pt-4'>
                                                <button className='del-dep-btn' onClick={deleteIndustry}>Confirm</button>
                                                <button className='cencel-dep-btn' onClick={handleClose}>Cancel</button>
                                        </div>
                                </Modal.Body>


                        </Modal>
                </>
        )
}
export default DeleteIndustry