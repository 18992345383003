import React, { useEffect, useState } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import cross from "../../../../images/cross.svg";
// import "./popupDep.css"
import { useForm } from "react-hook-form";
import { BaseUrl } from '../../../Api/api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from "react-drag-drop-files";
import { getCategoryListTableData } from '../../../../Store/Reducer/CategoryList';


const EditCategory = ({ open, handleClose }) => {

        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { register, handleSubmit, getValues, setValue, setError, formState: { errors } } = useForm();

        const { selectedSingleCategoryData } = useSelector((state) => state.categoryList);
        console.log("selectedSingleCategoryData", selectedSingleCategoryData);

        const category_id = selectedSingleCategoryData?.id;

        useEffect(() => {
                Object.keys(selectedSingleCategoryData)?.map(key => {
                        setValue(key, selectedSingleCategoryData[key])
                })
        }, [selectedSingleCategoryData])

        const onSubmit = () => {
                console.log("data", getValues());
                const data = getValues();
                data["category_id"] = category_id;
                axios.post(`${BaseUrl}product/Update-Product-Category`, data,
                        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
                )
                        .then(res => {
                                if (res.data.status === 200) {
                                        console.log("res", res);
                                        handleClose(false)
                                        // toast.success("Department Updated Successfully")
                                        window.location.reload();
                                        window.scrollTo(0, 0);
                                }
                        })
                        .catch(err => {
                                console.log("err", err);
                                toast.error("Something went wrong")
                        })

        }

        return (
                <>
                        {/* <AddDepartment open={show} handleAddClose={handleAddClose} name={"Department"}
                                data={data} handleClose={handleClose}
                        /> */}
                        <Modal open={open} handleClose={handleClose} >
                                <div className='d-flex align-items-center justify-content-end'>
                                        <img src={cross} alt="" onClick={handleClose} style={{ cursor: "pointer" }} />
                                </div>
                                <Modal.Body >
                                        <h3 className='top-header-dep'>Edit Category</h3>

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                {/* {JSON.stringify(selectedSingleCategoryData)} */}
                                                <div className='d-flex  flex-column'>
                                                        <label htmlFor="" className='dep-label py-1'>Parent Category</label>
                                                        <input type="text" className='dep-input' style={{ cursor: "not-allowed", backgroundColor: "#F2F2F2" }}
                                                                value={selectedSingleCategoryData?.parent_category}
                                                        />
                                                </div>
                                                <div className='d-flex flex-column'>
                                                        <div className='d-flex flex-column py-3'>
                                                                <label htmlFor="" className='dep-label py-1'>Name</label>
                                                                <input type="text" className='dep-input'
                                                                        {...register("category_name", { required: true, maxLength: 20 })}
                                                                />
                                                                {errors.category_name && <p className='dep-error'>This field is required</p>}
                                                        </div>
                                                        <label htmlFor="" className='dep-label py-1'>Description</label>
                                                        <input type="text" className='dep-input'
                                                                {...register("description", { required: true, maxLength: 250 })}
                                                        />
                                                        {errors.description && <p className='dep-error'>This field is required</p>}
                                                </div>
                                                {/* <div className='d-flex flex-column py-3'>
                                                        <label htmlFor="" className='dep-label py-1'>Upload Image</label>
                                                        
                                                        <FileUploader handleChange={handleFileChange} name="file" types={fileTypes}
                                                                children={<div className="file-upload-btn">
                                                                        <button className='upload-cat-image'>Select Image</button>
                                                                </div>}
                                                        />
                                                </div> */}

                                                <button className='dep-add-btn mt-5'>
                                                        <p className='dep-add-btn-name'>Add</p>
                                                </button>

                                        </form>
                                </Modal.Body>
                        </Modal>
                </>
        )
}

export default EditCategory