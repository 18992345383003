import { width } from "@mui/system";
import React, { useState, useEffect } from "react";
import "./ActionPopUp.css";
import {
    getCallExpertTableData,
    updateSelectedCallExpertTableCheckbox,
    UpdateSelectedCallExpertId
  } from "../../../../Store/Reducer/CallExpertReducer";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { BaseUrl, dsUrl } from "../../../Api/api";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const ActionPopup = ({ close }) => {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const initialValues = {
    feedback: "",
    status: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const { selectedSingleExpertData } = useSelector(
    (state) => state.callExpertData
  );

  let user_info = JSON.parse(localStorage.getItem("user_info"));
  console.log(user_info.name, "userinfo");

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });

    console.log(errors);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);
    setFormErrors(validate(formValues));
    const userToken = localStorage.getItem("token");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        expert_id: selectedSingleExpertData[0]["id"],
        remark: formValues.feedback,
        admin_name: user_info.name,
        status: formValues.status,
      });

      console.log(raw);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BaseUrl}expert/Add-Call-Expert-Remark`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            setFormValues({ ...formValues, name: "" });
            toast("Feedback Send Successfully");
            close(false);
          } else {
            // toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  console.log(formValues);

  return (
    <>
      <div className="popup-content-callExpert">
        <div className="popup-header">
          <div className="popup-close-btn-div">
            <button
              onClick={() => {
                close(false);
                console.log("clicked");
              }}
              className="popup-close-btn"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5013 13.9587L7.39714 19.0628C7.20616 19.2538 6.96311 19.3493 6.66797 19.3493C6.37283 19.3493 6.12977 19.2538 5.9388 19.0628C5.74783 18.8719 5.65234 18.6288 5.65234 18.3337C5.65234 18.0385 5.74783 17.7955 5.9388 17.6045L11.043 12.5003L5.9388 7.39616C5.74783 7.20519 5.65234 6.96213 5.65234 6.66699C5.65234 6.37185 5.74783 6.1288 5.9388 5.93783C6.12977 5.74685 6.37283 5.65137 6.66797 5.65137C6.96311 5.65137 7.20616 5.74685 7.39714 5.93783L12.5013 11.042L17.6055 5.93783C17.7964 5.74685 18.0395 5.65137 18.3346 5.65137C18.6298 5.65137 18.8728 5.74685 19.0638 5.93783C19.2548 6.1288 19.3503 6.37185 19.3503 6.66699C19.3503 6.96213 19.2548 7.20519 19.0638 7.39616L13.9596 12.5003L19.0638 17.6045C19.2548 17.7955 19.3503 18.0385 19.3503 18.3337C19.3503 18.6288 19.2548 18.8719 19.0638 19.0628C18.8728 19.2538 18.6298 19.3493 18.3346 19.3493C18.0395 19.3493 17.7964 19.2538 17.6055 19.0628L12.5013 13.9587Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
          <div className="expert-title-div">
            <h1 className="popup-title">Overview</h1>
          </div>
        </div>
        <div className="popup-body">
          <div className="d-flexed">
            <div
              className="expert-disabled-boxes"
              style={{ marginLeft: "4px", marginRight: "1rem" }}
            >
              <p className="expert-content-details-label">Client Name</p>
              <input
                type="text"
                disabled
                value={selectedSingleExpertData[0]["name"]}
                style={{
                  border: "1px solid #D9D9D9",
                  paddingLeft: "1rem",
                  borderRadius: "5px",
                  width: "95%",
                  background: "#F8F8F8",
                  height: "2.2rem",
                  color: "black",
                }}
              />
            </div>
            <div className="expert-disabled-boxes">
              <p className="expert-content-details-label">Phone No.</p>
              <input
                type="text"
                disabled
                value={selectedSingleExpertData[0]["phone"]}
                style={{
                  border: "1px solid #D9D9D9",
                  paddingLeft: "1rem",
                  borderRadius: "5px",
                  width: "100%",
                  background: "#F8F8F8",
                  color: "black",
                  height: "2.2rem",
                }}
              />
            </div>
          </div>
          <div className="expert-expander">
            <div className="expert-show-more">
              <p className="expert-show-more-text">
                {showMore ? "Show Less" : "Show More"}
              </p>{" "}
              {showMore ? (
                <button
                  onClick={() => setShowMore(false)}
                  className="expert-show-more-btn"
                >
                  <svg
                    width="14"
                    height="7"
                    viewBox="0 0 14 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 0.25L0.75 6.5H13.25L7 0.25Z" fill="black" />
                  </svg>
                </button>
              ) : (
                <button
                  onClick={() => setShowMore(true)}
                  className="expert-show-more-btn"
                >
                  <svg
                    width="14"
                    height="7"
                    viewBox="0 0 14 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 6.75L0.75 0.5H13.25L7 6.75Z" fill="black" />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {showMore ? (
            <div className="expert-after-expand">
              <div className="d-flexed-under-showmore">
                <div
                  className="expert-disabled-boxes"
                  style={{ marginLeft: "4px", marginRight: "1rem" }}
                >
                  <p className="expert-content-details-label">Date</p>
                  <input
                    type="text"
                    disabled
                    value={selectedSingleExpertData[0]["created_at"]}
                    style={{
                      border: "1px solid #D9D9D9",
                      paddingLeft: "1rem",
                      borderRadius: "5px",
                      width: "95%",
                      background: "#F8F8F8",
                      height: "2.2rem",
                      color: "black",
                    }}
                  />
                </div>
                <div className="expert-disabled-boxes">
                  <p className="expert-content-details-label">Company Email</p>
                  <input
                    type="text"
                    disabled
                    value={selectedSingleExpertData[0]["company_email"]}
                    style={{
                      border: "1px solid #D9D9D9",
                      paddingLeft: "1rem",
                      borderRadius: "5px",
                      width: "100%",
                      background: "#F8F8F8",
                      color: "black",
                      height: "2.2rem",
                    }}
                  />
                </div>
              </div>
              <div className="d-flexed-under-showmore">
                <div
                  className="expert-disabled-boxes"
                  style={{ marginLeft: "4px", marginRight: "1rem" }}
                >
                  <p className="expert-content-details-label">Category</p>
                  <input
                    type="text"
                    disabled
                    value={selectedSingleExpertData[0]["solution"]}
                    style={{
                      border: "1px solid #D9D9D9",
                      paddingLeft: "1rem",
                      borderRadius: "5px",
                      width: "95%",
                      background: "#F8F8F8",
                      height: "2.2rem",
                      color: "black",
                    }}
                  />
                </div>
                <div className="expert-disabled-boxes">
                  <p className="expert-content-details-label">Industry</p>
                  <input
                    type="text"
                    disabled
                    value={selectedSingleExpertData[0]["company"]}
                    style={{
                      border: "1px solid #D9D9D9",
                      paddingLeft: "1rem",
                      borderRadius: "5px",
                      width: "100%",
                      background: "#F8F8F8",
                      color: "black",
                      height: "2.2rem",
                    }}
                  />
                </div>
              </div>
              <div className="d-flexed-under-showmore">
                <div
                  className="expert-disabled-boxes"
                  style={{ marginLeft: "4px", marginRight: "1rem" }}
                >
                  <p className="expert-content-details-label">Company Size</p>
                  <input
                    type="text"
                    disabled
                    value={selectedSingleExpertData[0]["company_size"]}
                    style={{
                      border: "1px solid #D9D9D9",
                      paddingLeft: "1rem",
                      borderRadius: "5px",
                      width: "95%",
                      background: "#F8F8F8",
                      height: "2.2rem",
                      color: "black",
                    }}
                  />
                </div>
                <div className="expert-disabled-boxes">
                  <p className="expert-content-details-label">Designation</p>
                  <input
                    type="text"
                    disabled
                    value={selectedSingleExpertData[0]["designation"]}
                    style={{
                      border: "1px solid #D9D9D9",
                      paddingLeft: "1rem",
                      borderRadius: "5px",
                      width: "100%",
                      background: "#F8F8F8",
                      color: "black",
                      height: "2.2rem",
                    }}
                  />
                </div>
              </div>
              <div className="d-flexed-under-showmore">
                <div
                  className="expert-disabled-boxes"
                  style={{ marginLeft: "4px", marginRight: "1rem" }}
                >
                  <p className="expert-content-details-label">Client Query</p>
                  <input
                    type="text"
                    disabled
                    value={parse(selectedSingleExpertData[0]["query"])}
                    style={{
                      border: "1px solid #D9D9D9",
                      paddingLeft: "1rem",
                      borderRadius: "5px",
                      width: "95%",
                      background: "#F8F8F8",
                      minHeight: "2.2rem",
                      color: "black",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="expert-remark-section">
            <div className="d-flexed-under-showmore">
              <div
                className="expert-disabled-boxes"
                style={{ marginLeft: "4px", marginRight: "1rem" }}
              >
                <p className="expert-content-details-label">User</p>
                <input
                  type="text"
                  disabled
                  value={user_info.name}
                  style={{
                    border: "1px solid #D9D9D9",
                    paddingLeft: "1rem",
                    borderRadius: "5px",
                    width: "95%",
                    background: "#F8F8F8",
                    height: "2.2rem",
                    color: "black",
                  }}
                />
              </div>
              <div className="expert-disabled-boxes">
                <p className="expert-content-details-label">
                  Status<p className="err-message">{formErrors.status}</p>
                </p>
                <label className="expert-radio-btn">
                  <input
                    type="radio"
                    name="status"
                    value="1"
                    onChange={handleChange}
                  />
                  New Inquiry
                </label>
                <label className="expert-radio-btn">
                  <input
                    type="radio"
                    name="status"
                    value="2"
                    onChange={handleChange}
                  />
                  In Progress
                </label>
                <label className="expert-radio-btn">
                  <input
                    type="radio"
                    name="status"
                    value="0"
                    onChange={handleChange}
                  />
                  Completed
                </label>
              </div>
            </div>
            <div className="d-flexed-under-showmore2">
              <div
                className="expert-feedback-boxes"
                style={{ marginLeft: "4px", marginRight: "1rem" }}
              >
                <label
                  htmlFor="feedbackAreaId"
                  className="expert-content-details-label"
                >
                  Feedback
                </label>
                <textarea
                  id="feedbackAreaId"
                  placeholder="Write Something..."
                  name="feedback"
                  value={formValues.feedback}
                  onChange={handleChange}
                  style={{
                    border: "1px solid #D9D9D9",
                    paddingLeft: "1rem",
                    borderRadius: "5px",
                    width: "100%",
                    background: "#F8F8F8",
                    color: "black",
                    minHeight: "6rem",
                  }}
                />
              </div>
              <p style={{ margin: "0 0 0 1rem" }} className="err-message">
                {formErrors.feedback}
              </p>
            </div>
            <div className="expert-submit">
              <button className="expert-submit-btn" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionPopup;
