import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const myReducer = createSlice({
    name: "conqtAdmin",
    initialState: {
        loading: false,
        customerUploadfile: {}
    },
    reducers: {
        updateCustomerUploadfile: (state, action) => {
            state.customerUploadfile = action.payload;

        },
        clearCustomerUploadfile: (state, action) => {
            state.customerUploadfile = {};
            console.log("running")
        }
    },
    extraReducers: {
    },
})

export const {
    updateCustomerUploadfile,
    clearCustomerUploadfile
} = myReducer.actions;

export default myReducer.reducer;