import React, { useState, useEffect } from 'react'
import DashBoardheader from '../../../../components/DashboardHeader'
import SideNavBar from '../../../../components/Sidebar'
import './addcustomercontact.css'
import TargetCustomerContactBreadCrumbs from '../../../../components/TCCBreadcrumbs'
import { useForm } from 'react-hook-form'
import BackArrowIcon from "../../../../images/BackArrow.svg";
import { getAllCountryList } from "../../../../Store/Reducer/CountryListReducer";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateTargetCustomerContact } from '../../../../Store/Reducer/TargetCustomerContactReducer';
import axios from 'axios'
import { dsUrl } from '../../../Api/api';
import { BaseUrl } from '../../../Api/api'


const TargetAddCustomerContact = () => {

    const { register, handleSubmit, setValue, getValues, watch, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [customList, setCustomeList] = useState([])
    const [countries, setCountries] = useState([])

    const { targetCustomerContact } = useSelector((state) => state.targetCustomerContact);
    const { countryList, selectedCountryId } = useSelector(
        (state) => state.allCountryList
    );
    console.log("country======>", countryList)

    // mobile number validation

    const validateMobileNumber = (value) => {
        const mobileNumberRegex = /^[0-9]{10}$/;
        const maxLength = 10;
        if (!value) {
            return 'Mobile number is required';
        }
        if (!mobileNumberRegex.test(value)) {
            return 'Invalid mobile number';
        }
        if (value.length > maxLength) {
            return `Mobile number must be ${maxLength} digits or less`;
        }
        return true;
    };

    useEffect(() => {
        for (const key in targetCustomerContact) {
            if (Object.hasOwnProperty.call(targetCustomerContact, key)) {
                const element = targetCustomerContact[key];
                setValue(key, element);
            }
        }
    }, [targetCustomerContact]);

    useEffect(() => {
        const userToken = localStorage.getItem("token")
        axios
            .get(`${BaseUrl}AdminTargetCustomer/Get-Target-Customer-List`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((response) => {
                console.log("custom ========>", response.data);
                setCustomeList(response.data.data);
            });
    }, [])
    console.log("*******", customList)
    useEffect(() => {
        const userToken = localStorage.getItem("token")
        axios
            .get(`${BaseUrl}common/Get-All-Country`,
                { headers: { Authorization: `Bearer ${userToken}` } }
            )
            .then((response) => {
                console.log("countryList ========>", response.data)
                setCountries(response.data.data.country_list)

            })
    }, [])



    const onSubmit = (data) => {
        dispatch(updateTargetCustomerContact(data))
        console.log("enter data", data)
        console.log("redux data", updateTargetCustomerContact(data))
        navigate('/target-customer-contact-overview')
    }
    const handleClickcustomercontact = () => {
        navigate('/target-customer-contact-main-page')
    }
    return (
        <>
            <DashBoardheader />
            <SideNavBar />
            <div className="main-body-container">
                <div className="container">
                    <TargetCustomerContactBreadCrumbs />
                </div>
                {/* {JSON.stringify(countries)} */}
                <div className="container py-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="target-add-customer-top-name">
                            Add Contact
                        </h5>
                    </div>
                    <div>
                        <img
                            onClick={handleClickcustomercontact}
                            src={BackArrowIcon}
                            alt=""
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </div>
                <div className="container py-3" style={{ marginBottom: "30px" }}>
                    <div className='d-flex'>
                        <div className='customer-list'>Customer List</div>
                        <div className='px-2'>
                            <select
                                type="text"

                                {...register("target_customer_id", {
                                    required: "Required Field",
                                })}
                                style={{
                                    border: " 1px solid gray",
                                    borderRadius: "8px",
                                    padding: "5px",
                                    width: "300px",
                                    backgroundColor: "white"
                                }}
                            >
                                {customList?.map(customer => (
                                    <option value={customer?.id} className="">{customer?.company_name}</option>
                                ))}
                            </select>
                            <div>
                                <p className="required-field">
                                    {errors.company_name?.message}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='target-add-customer-contact px-3 py-4'>
                        <div className="d-flex">
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Name</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    {...register("contact_p_name", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_name?.message}
                                    </p>
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Email</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="email"
                                    {...register("contact_p_email", {
                                        required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    {errors.contact_p_email?.type === 'required' && <p className="required-field">Required Field</p>}
                                    {errors.contact_p_email?.type === 'pattern' && <p className="required-field">Invalid email format (must be a valid Gmail address)</p>}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex my-4">
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Phone No.</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="number"
                                    {...register("contact_p_phone", {
                                        required: true,
                                        minLength: 10,
                                        maxLength: 10,
                                        validate: validateMobileNumber,
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    {errors.contact_p_phone?.type === 'required' && <p className="required-field"> Required Field</p>}
                                    {errors.contact_p_phone?.type === 'minLength' && <p className="required-field">Mobile number must be at least 10 digits</p>}
                                    {errors.contact_p_phone?.type === 'maxLength' && <p className="required-field">Mobile number must be no more than 10 digits</p>}
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Department</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    {...register("contact_p_department", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_department?.message}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{ paddingBottom: "50px" }}>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Position</p>
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    {...register("contact_p_role", {
                                        required: "Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "5px",
                                        width: "300px",
                                    }}
                                />
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_role?.message}
                                    </p>
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="target-add-single-customer-lables">Country</p>
                            </div>
                            <div className="col-4">
                                <select
                                    name=""
                                    id=""
                                    type="text"
                                    placeholder='select Country'
                                    {...register("contact_p_country", {
                                        required: " Required Field",
                                    })}
                                    style={{
                                        border: " 1px solid gray",
                                        borderRadius: "8px",
                                        padding: "7px",
                                        width: "300px",
                                        background: "white",
                                    }}
                                >
                                    <option className="dropdown-country">
                                    </option>
                                    {countries?.map(con => (
                                        <option value={con?.country} className="">{con?.country}</option>
                                    ))}
                                </select>
                                <div>
                                    <p className="required-field">
                                        {errors.contact_p_country?.message}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className='d-flex align-items-center justify-content-end py-3'
                            // style={{ paddingRight: "120px" }}
                            >
                                <button
                                    onClick={handleSubmit(onSubmit)}
                                    className='customer-contact-add-btn px-4 py-1'>Add</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default TargetAddCustomerContact