import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getTargetVendorTableData = createAsyncThunk("targetvendorList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}AdminTargetVendor/Get-Target-Vendor-List`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result.data, "store api data");
      return result.data


    })
    .catch((error) => console.log("error", error));
});
export const getSingleTargetVendorDetails = createAsyncThunk("targetVendorDetails", (id) => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  console.log(id, "vendor id  in reducer")
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}admin/Vendor-Details-By-Id?vendor_id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      //  console.log(result.data, "store single vendor api  data");
      return result.data


    })
    .catch((error) => console.log("error", error));
});


export const myReducer = createSlice({
  name: "conqtAdmin",
  initialState: {
    loading: false,
    selectedTargetVendorId: "",
    targetVendorList: [],
    targetVendorDetails: {},
    selectedTargetVendorName: "",
  },
  reducers: {
    updateSelectTargetVendorId: (state, action) => {
      state.selectedTargetVendorId = action.payload;
    },
    updateSelectTargetVendorName :(state,action)=>{
      state.selectedTargetVendorName=action.payload;
    }  },
  extraReducers: {
    [getTargetVendorTableData.pending]: (state) => {
      state.loading = true;
    },
    [getTargetVendorTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.targetVendorList = payload;

      // console.log(payload,"payload from reducer") 
    },
    [getSingleTargetVendorDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSingleTargetVendorDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.targetVendorDetails = payload;
      // console.log(payload,"payload from vendor details reducer") 
    },
  },
})

export const {
  updateSelectTargetVendorId,
  updateSelectTargetVendorName 
} = myReducer.actions;

export default myReducer.reducer;
