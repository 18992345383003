// import React, { useState } from 'react'
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// const TextEditor = () => {

//     const [editorState, setEditorState] = useState()

//     const onEditorStateChange = (data) => {
//         console.log("====", data)
//         setEditorState(data);
//     };
//     // const `editorState = state
//     return (

//         <>
//             <Editor
//                 editorState={editorState}
//                 toolbarClassName="toolbarClassName"
//                 wrapperClassName="wrapperClassName"
//                 editorClassName="editorClassName"
//                 onEditorStateChange={onEditorStateChange}

//             />
//         </>
//     )
// }

// export default TextEditor

import React, { Component } from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useDispatch, useSelector } from 'react-redux';
import { updateCampaign } from '../../Store/Reducer/CampaignReducer';

// const editorConfiguration = {
//     toolbar: ['bold', 'italic']
// };

const TextEditor = ({ signature_name, signature_email, signature_contact_no, signature_position }) => {
    const dispatch = useDispatch();
    const { campaign } = useSelector(state => state.campaign)

    return (
        <div>
            {/* {signature_name} */}
            <CKEditor

                editor={Editor}
                // config={editorConfiguration}
                data={`${campaign.editorData || ""} \n Sincerely,<br>${signature_name} 
                <br>${signature_email}
                <br>${signature_contact_no}
                <br>${signature_position}
                `}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    dispatch(updateCampaign({
                        campaign_content: data
                    }))
                    console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                }}
            />
        </div>
    )
}

export default TextEditor