import React, { useState, useMemo, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import FilterInputComponent from '../FilterInputComp';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
const TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJfaWQiOjEsInVzZXJfbmFtZSI6ImFtaXQxMm5pZzk2QGdtYWlsLmNvbSIsIm5hbWUiOiJBbWl0IFlhZGF2IiwibG9naW5fdHlwZSI6IkFkbWluIn0sImlhdCI6MTY3MDM4ODEyOCwiZXhwIjoxNjcyOTgwMTI4fQ.7S5nFWWZtywOv0Xa55uAKaxx-uFhsmWRwB47hKxYasM.MJaJhACJgTEmPl5LXYCK4x2KF469EGcvvTxjbUxpeQ0`

const customStyles = {
    headCells: {
        style: {
            background: ' #0900BC',
            color: 'white',
            fontSize: '16px',
            fontFamily: 'Public Sans',
            fontStyle: 'normal',
            fontWeight: 600
        },
    },
};

const AllContactsEmailModule = () => {

    const [apiData, setApidata] = useState([])
    const navigate = useNavigate()


    const handleClickParticularUserDetails = ({ data = {
        EmailAddress: 'Intern1conqt@gmail.com',
        FirstName: 'Intern1',
        LastName: 'Techcurve',
        PhoneNo: '7789645632',
        EmailCategory: 'ERP',
        RegisterDate: "04-27-2022"

    } }) => {
        console.log('Welcome: ', data)
        localStorage.setItem('selectedUser', JSON.stringify(data))
        navigate('/particular-user-details')
    }

    const [columns, setColumns] = useState([
        {
            name: "Email Address",
            selector: row => row.EmailAddress,
        },
        {
            name: "First Name",
            selector: row => row.FirstName,
        },
        {
            name: "Last Name",
            selector: row => row.LastName,
        },
        {
            name: "Phone No.",
            selector: row => row.PhoneNo,
        },
        {
            name: "Email Category.",
            selector: row => row.EmailCategory,
        },
        {
            name: "Register Date.",
            selector: row => row.RegisterDate,
        },
    ])

    const [data, setData] = useState([
        {
            id: 1,
            EmailAddress: <div onClick={handleClickParticularUserDetails}>
                Intern1conqt@gmail.com
            </div>,
            FirstName: 'Intern1',
            LastName: 'Techcurve',
            PhoneNo: '7789645632',
            EmailCategory: 'ERP',
            RegisterDate: "04-27-2022"
        },
        {
            id: 2,
            EmailAddress: <div onClick={handleClickParticularUserDetails}>
                Intern1conqt@gmail.com
            </div>,
            FirstName: 'Intern1',
            LastName: 'Techcurve',
            PhoneNo: '7789645632',
            EmailCategory: 'ERP',
            RegisterDate: "04-27-2022"
        },
        {
            id: 3,
            EmailAddress: <div onClick={handleClickParticularUserDetails}>
                Intern1conqt@gmail.com
            </div>,
            FirstName: 'Intern1',
            LastName: 'Techcurve',
            PhoneNo: '7789645632',
            EmailCategory: 'ERP',
            RegisterDate: "04-27-2022"
        },
        {
            id: 4,
            EmailAddress: <div onClick={handleClickParticularUserDetails}>
                Intern1conqt@gmail.com
            </div>,
            FirstName: 'Intern1',
            LastName: 'Techcurve',
            PhoneNo: '7789645632',
            EmailCategory: 'VMS',
            RegisterDate: "04-27-2022"
        },
        {
            id: 5,
            EmailAddress: <div onClick={handleClickParticularUserDetails}>
                Intern1conqt@gmail.com
            </div>,
            FirstName: 'Intern1',
            LastName: 'Techcurve',
            PhoneNo: '7789645632',
            EmailCategory: 'CRM',
            RegisterDate: "04-27-2022"
        },
        {
            id: 6,
            EmailAddress: <div onClick={handleClickParticularUserDetails}>
                Intern1conqt@gmail.com
            </div>,
            FirstName: 'Intern1',
            LastName: 'Techcurve',
            PhoneNo: '7789645632',
            EmailCategory: 'ERP',
            RegisterDate: "04-27-2022"
        },
        {
            id: 7,
            EmailAddress: <div onClick={handleClickParticularUserDetails}>
                Intern1conqt@gmail.com
            </div>,
            FirstName: 'Intern1',
            LastName: 'Techcurve',
            PhoneNo: '7789645632',
            EmailCategory: 'ERP',
            RegisterDate: "04-27-2022"
        },
        {
            id: 8,
            EmailAddress: <div onClick={handleClickParticularUserDetails}>
                Intern1conqt@gmail.com
            </div>,
            FirstName: 'Intern1',
            LastName: 'Techcurve',
            PhoneNo: '7789645632',
            EmailCategory: 'Cloud',
            RegisterDate: "04-27-2022"
        },

    ])

    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const results = data.filter(
        (item) =>
            item.EmailCategory &&
            item.EmailCategory.toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterInputComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);



    return (
        <div className=''>
            <DataTable
                columns={columns}
                data={results}
                customStyles={customStyles}
                pagination
                // selectableRows
                paginationResetDefaultPage={resetPaginationToggle}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
            />
        </div>
    )
}

export default AllContactsEmailModule