const convertWordsFirstLetterToUpperCase = (wordsString) => {
  return wordsString
    .replace(/_/g, " ")
    .replace(/\b\w/g, (l) => l.toUpperCase());
};

const transformTargetVendorTableDataResponse = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        id: e.id,
        index: index,
        category: e.category,
        company_address: e.company_address,
        company_city: e.company_city,
        company_country: e.company_country,
        company_email: e.company_email,
        company_name: e.company_name,
        company_phone_no: e.company_phone_no,
        company_pincode: e.company_pincode,
        company_state: e.company_state,
        contact_count: e.contact_count,
        person_name: e.person_name ? e.person_name : "---",
        created_at: e.created_at,
        delete_flag: e.delete_flag,
        status: e.status,
        updated_at: e.updated_at,
      };
    });
  }
};

const transformTableDataResponse = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        id: e.id,
        index: index,
        vendor_id: e.vendor_id,
        available_point: e.available_point,
        country: e.country,
        city: e.city,
        company_name: e.company_name,
        created_at: e.created_at,
        updated_at: e.updated_at.split("T")[0],
        user_name: e.user_name,
        currency: e.currency,
        currency_code: e.currency_code + " " + e.symbol,
        symbol: e.symbol,
        address: e.address,
        zipcode: e.zipcode,
        solutions: e.solutions,
        phone_no: e.phone_no,
        subcription: e.subcription,
        name: e.first_name ? e.first_name + " " + e.last_name : "---",
        activation_status:
          e.activation_status === 1 ? "Subscribe" : "Unsubscribe",
        status: e.status,
        person_name: e.person_name,
      };
    });
  }
};
const transformCustomerTableDataResponse = (customerTableData) => {
  if (customerTableData && customerTableData.length > 0) {
    return customerTableData.map((e, index) => {
      return {
        customer_id: e.customer_id,
        id: e.id,
        index: index,
        created_at: new Date(e.created_at).toLocaleDateString("en-GB"),
        updated_at: e.updated_at,
        title: e.title ? e.title : "---",
        name: e.name ? e.name : "---",
        user_name: e.user_name ? e.user_name : "---",
        first_name: e.first_name ? e.first_name : "---",
        last_name: e.last_name ? e.last_name : "---",
        company_name: e.company_name ? e.company_name : "---",
        phone_number: e.phone_number ? e.phone_number : "---",
        country: e.country ? e.country : "---",
        currency: e.currency ? e.currency : "---",
        state: e.state ? e.state : "---",
        currency_code: e.currency_code ? e.currency_code : "---",
        symbol: e.symbol,
        city: e.city ? e.city : "---",
        zipcode: e.zipcode ? e.zipcode : "---",
        department_name: e.department_name ? e.department_name : "---",
        position: e.position ? e.position : "---",
        address: e.address ? e.address : "---",
        announcement_count: e.announcement_count,
        status: e.status,
      };
    });
  }
};

const transformProposalTableDataResponse = (proposalTableData) => {
  if (proposalTableData && proposalTableData.length > 0) {
    return proposalTableData.map((e) => {
      return {
        created_at: e.created_at && e.created_at.split(" ")[0],
        proposal_id: e.proposal_id,
        project_id: e.project_id,
        project_name: e.project_name,
        vendor_name: e.vendor_name,
        solution_name: e.solution_name,
        country: e.vendor_country,
        vendor_phone_no: e.vendor_phone_no,
        vendor_currency: e.vendor_currency,
        vendor_currency_code: e.vendor_currency_code,
        timeline: e.timeline ? e.timeline + " " + e.timeline_unit : "---",
        proposal_status:
          e.proposal_status === 0
            ? "In Review"
            : e.proposal_status === 1
              ? "Accepted"
              : "Rejected",
        price: e.price
          ? e.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " " +
          e.vendor_currency_code
          : "Not Available",
      };
    });
  }
};

const transformProjectTableDataResponse = (projectTableData) => {
  if (projectTableData && projectTableData.length > 0) {
    return projectTableData.map((e) => {
      return {
        project_id: e.id,
        // price: e.project_budget?`${e.project_budget} ${e.proposal_currecny}`:"---",
        // price: `${e.price.toLocaleString("en-US")} ${e.proposal_currecny}`,
        price: e.price
          ? e.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " " +
          e.proposal_currency_code
          : "---",
        created_at: e.created_at && e.created_at.split(" ")[0],
        project_description: e.project_description,
        solution_name: e.solution_name,
        company_name: e.company_name,
        attachment_name: e.attachment_name,
        customer_name:e.customer_name ? e.customer_name :"---",
        country: e.country,
        project_expiry_datetime: new Date(
          e.project_expiry_datetime
        ).toLocaleDateString("en-GB"),
        project_name: e.project_name,
       

      };
    });
  }
};

const transformMilestoneTableDataResponse = (
  milestoneTableData,
  proposalDetails
) => {
  if (milestoneTableData && milestoneTableData.length > 0) {
    return milestoneTableData.map((e) => {
      return {
        milestone_id: e.milestone_id,
        created_at: e.created_at && e.created_at.split(" ")[0],
        milestone_start_close_status:
          proposalDetails.proposal_status === 1
            ? e.milestone_start_close_status === 0
              ? "Not Started"
              : e.milestone_start_close_status === 1
                ? "In Progress"
                : e.milestone_start_close_status === 2
                  ? "Completed"
                  : "Not Available"
            : proposalDetails.proposal_status === 0
              ? "Not Started"
              : proposalDetails.proposal_status === 2
                ? "Rejected"
                : "Not Available",

        mark_as_complete_status: e.mark_as_complete_status,
        milestone_percent: e.milestone_percent
          ? e.milestone_percent + "%"
          : "Not Available",
        milestone_amount: e.milestone_amount
          ? e.milestone_amount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " " +
          e.vendor_currency
          : "Not Available",
        milestone_description: e.milestone_description
          ? e.milestone_description
          : "Not Available",
        vendor_currency: e.vendor_currency,
        count: e.count ? e.count : "Not Available",
        user_currency: e.user_currency,
        milstone_timeline: e.milstone_timeline
          ? e.milstone_timeline.split(" ")[0]
          : "Not Available",
        project_id: e.project_id,
        project_name: e.project_name ? e.project_name : "Not Available",
        solution_name: e.solution_name ? e.solution_name : "Not Available",
      };
    });
  }
};

const CheckingValue = (value) => {
  return value ? value : "Not Available";
};
const SingleProjectTableDataResponse = (SingleProjectTableData) => {
  if (SingleProjectTableData && SingleProjectTableData.length > 0) {
    return SingleProjectTableData.map((e) => {
      return {
        id: e.proposal_id,
        proposal_id: e.proposal_id,
        project_id: e.project_id,
        project_name: e.project_name,
        vendor_name: e.vendor_name,
        product_category: e.solution_name,
        // price: `${e.price?.toLocaleString("en-US")} ${e.proposal_currecny}`,
        price: e.price
          ? e.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " " +
          e.proposal_currecny
          : "---",
      };
    });
  }
};

const transformFileDataIntoTable = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        company_name: e.company_name,
        company_email: e.company_email,
        company_phone_no: e.company_phone_no,
        company_state: e.company_state,
        company_city: e.company_city,
        company_address: e.company_address,
        company_pincode: e.company_pincode,
        category: e.category,
        person_name: e.person_name
      };
    });
  }
};

const transformCustomerFileDataIntoTable = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        Customer: e.Customer,
        Organization: e.Organization,
        company_phone_no: e.company_phone_no,
        company_state: e.company_state,
        company_city: e.company_city,
        company_address: e.company_address,
        company_pincode: e.company_pincode,
        category: e.category,
        person_name: e.person_name,
        company_email: e.company_email
      };
    });
  }
};

const transformMainProductData = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        id: e.id,
        product_name: e.product_name ? e.product_name : "---",
        company_name: e.brand_name ? e.brand_name : "---",
        verified: e.is_verified,
        category: e.category_name ? e.category_name : "---",
        added_on: e.created_at ? new Date(e.created_at)?.toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }) : "---",
        product_status: e.product_status,
        price: e.price ? `${"$ " + e.price.toLocaleString("en-US")}` : "---",
        delete: e.delete_flag == 0 ? "Not Deleted" : "Deleted",
      };
    });
  }
}
const transformDepartmentData = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        id: e.id,
        department_name: e.department_name ? e.department_name : "---",
        description: e.description ? e.description : "---",
        status: e.status,
        parent_department: "---",
        added_on: e.created_at ? e.created_at : "---",
        delete: e.delete_flag == 0 ? "Not Deleted" : "Deleted",
      };
    });
  }
}
const transformCategoryListData = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        id: e.category_id,
        category_name: e.category_name ? e.category_name : "---",
        description: e.description ? e.description : "---",
        status: e.status,
        parent_category: e.parent_category ? e.parent_category : "---",
        added_on: e.created_at ? e.created_at : "---",
        delete: e.delete_flag == 0 ? "Not Deleted" : "Deleted",
      };
    });
  }
}
const transformIndustryListData = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        id: e.id,
        industry_name: e.industry_name ? e.industry_name : "---",
        description: e.description ? e.description : "---",
        status: e.status,
        parent_industry: "---",
        added_on: e.created_at ? e.created_at : "---",
        delete: e.delete_flag == 0 ? "Not Deleted" : "Deleted",
      };
    });
  }
}
const transformCallExpertData = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        id: e.id,
        company: e.company,
        company_email: e.company_email,
        company_size: e.company_size,
        created_at: e.created_at,
        designation: e.designation,
        company_name: e.company_name,
        name: e.name,
        is_delete: e.is_delete,
        phone: e.phone ? "+" + e.phone : "---",
        query: e.query ? e.query : "---",
        remark: e.remark ? e.remark : "---",
        solution: e.solution ? e.solution : "---",
        status: e.status == 0 ? "Completed" : e.status == 1 ? "New Inquiry" : e.status == 2 ? "In Progress" : "---",
        updated_at: e.updated_at,
      };
    });
  }
};

function calculatePercentage(numerator, denominator) {
  return Math.round((numerator / denominator) * 100);
}

module.exports = {
  convertWordsFirstLetterToUpperCase,
  transformCustomerTableDataResponse,
  transformTableDataResponse,
  transformProposalTableDataResponse,
  transformMilestoneTableDataResponse,
  CheckingValue,
  transformProjectTableDataResponse,
  SingleProjectTableDataResponse,
  transformTargetVendorTableDataResponse,
  transformFileDataIntoTable,
  transformCustomerFileDataIntoTable,
  transformCallExpertData,
  transformMainProductData,
  transformIndustryListData,
  transformDepartmentData,
  transformCategoryListData,
  calculatePercentage
};
