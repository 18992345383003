import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../pages/Api/api";

export const getProjectTableData = createAsyncThunk("projectList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `${BaseUrl}admin/Get-All-Announcement-List-Admin`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      console.log(result.data, "store for proposal api data");
      return result.data;
    })
    .catch((error) => console.log("error", error));
});
export const getSingleProjectDetails = createAsyncThunk(
  "projectDetails",
  (accId) => {
    const userToken = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `${BaseUrl}admin/Get-All-Proposal-List-Admin-AS-Per-Project?announcement_id=${accId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result.data, "single proposal api  data");
        return result.data;
      })
      .catch((error) => console.log("error", error));
  }
);

export const myReducer = createSlice({
  name: "conqtAdmin",
  initialState: {
    loading: false,
    selectedProjectId: "",
    projectList: [],
    projectDetails: {},
    selectedProposalId: "",
    selectedProjectTableCheckbox: [
      "Date",
      "Project Name",
      "Customer Name",
      "Project Description",
      "Project Category",
      "Project Due Date",
      "Location",
      "Attachment",
      "Project Price",
    ],
  },
  reducers: {
    updateSelectProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
    updateSelectProposalId: (state, action) => {
      // console.log("*****in the store", action);
      console.log(action.payload, "action.payload milsetone in the reducer");
      state.selectedProposalId = action.payload;
    },
    updateSelectProjectTableCheckbox: (state, action) => {
      state.selectedProjectTableCheckbox = action.payload;
    },
  },
  extraReducers: {
    [getProjectTableData.pending]: (state) => {
      state.loading = true;
    },
    [getProjectTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projectList = payload;
    },
    [getSingleProjectDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSingleProjectDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projectDetails = payload;
    },
  },
});

export const {
  updateSelectProjectId,
  updateSelectProposalId,
  updateSelectProjectTableCheckbox,
} = myReducer.actions;

export default myReducer.reducer;
