import React from "react";
import { useEffect } from "react";
import { useState } from "react";
// import './breadcrumbs.css'
import { useLocation, useNavigate } from "react-router-dom";

const TargetVendorBreadCrumbs = () => {
  const [overview, setOverview] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);
  const [VendorContact, setVendorContact] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/target-vendor-contact-main-page") {
      setVendorContact(true);
    } else if (pathname === "/target-add-vendor-contact") {
      setAddNewContact(true);
    } else if (pathname === "/target-vendor-contact-overview") {
      setOverview(true);
    }
  }, []);
  const target = () => {
    navigate("/target-vendor-main-page");
  };

  const vendorcontact = () => {
    navigate("/target-vendor-contact-main-page");
  };

  const addContact = () => {
    navigate("/target-add-vendor-contact");
  };

  const overviewpage = () => {
    navigate("/target-vendor-contact-overview");
  };

  return (
    <>
      <nav>
        <div className="d-flex" style={{ listStyle: "none" }}>
          {VendorContact == true ? (
            <>
              <li>
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-vendor-main-page"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={target}
                  style={{ cursor: "pointer" }}
                >
                  Target <span className="px-1">{">"}</span>
                </p>
              </li>
              <li className="px-2">
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-vendor-contact-main-page"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={vendorcontact}
                  style={{ cursor: "pointer" }}
                >
                  Vendor Contact
                </p>
              </li>
            </>
          ) : addNewContact == true ? (
            <>
              {" "}
              <li>
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-vendor-main-page"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={target}
                  style={{ cursor: "pointer" }}
                >
                  Target <span className="px-1">{">"}</span>
                </p>
              </li>
              <li className="px-2">
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-vendor-contact-main-page"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={vendorcontact}
                  style={{ cursor: "pointer" }}
                >
                  Vendor Contact <span className="px-1">{">"}</span>
                </p>
              </li>
              <li className="px-2">
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-add-vendor-contact"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={addContact}
                  style={{ cursor: "pointer" }}
                >
                  Add New Vendor
                </p>
              </li>
            </>
          ) : overview == true ? (
            <>
              {" "}
              <li>
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-vendor-main-page"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={target}
                  style={{ cursor: "pointer" }}
                >
                  Target <span className="px-1">{">"}</span>
                </p>
              </li>
              <li className="px-2">
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-vendor-contact-main-page"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={vendorcontact}
                  style={{ cursor: "pointer" }}
                >
                  Vendor Contact <span className="px-1">{">"}</span>
                </p>
              </li>
              <li className="px-2">
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-add-vendor-contact"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={addContact}
                  style={{ cursor: "pointer" }}
                >
                  Add New Vendor 
                </p>
              </li>
              <li className="px-2">
                <p
                  className={`breadcrumbs-name ${
                    pathname === "/target-vendor-contact-overview"
                      ? "breadcrumbs-lin-active"
                      : ""
                  }`}
                  onClick={overviewpage}
                  style={{ cursor: "pointer" }}
                >
                  <span className="px-1">{">"}</span> Overview{" "}
                  <span className="px-1"></span>
                </p>
              </li>
            </>
          ) : (
            <></>
          )}
        </div>
      </nav>
    </>
  );
};

export default TargetVendorBreadCrumbs;
