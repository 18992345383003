import React, { useEffect, useState } from "react";
import SideNavBar from "../../../../components/Sidebar";
import DashBoardheader from "../../../../components/DashboardHeader";
import { Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import VendorBreadCrumbs from "../../../../components/AddNewContactBreadCrumbs";
import {
  getTableData,
  updateSelectVendorId,
} from "../../../../Store/Reducer/Reducer";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountryList } from "../../../../Store/Reducer/CountryListReducer";
import { BaseUrl } from "../../../Api/api";
import { toast } from "react-toastify";
const CreateVendorContact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorList, loading, selectedVendorId ,selectedVendorName } = useSelector(
    (state) => state.conqtAdmin
  );

  const { countryList, selectedCountryId } = useSelector(
    (state) => state.allCountryList
  );
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});
  const onSubmit = (data) => {
    // console.log(data, "******onSubmit******");
    // navigate('/vendor-dashboard')
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      vendor_id: selectedVendorId,
      person_name: data.person_name,
      person_email: data.person_email,
      contact_no: data.contact_no,
      department: data.department,
      position: data.position,
      country: data.country,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}AdminVendorContact/Add-Vendor-Contact`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
           toast("New Contact Added Successfully");
           navigate("/vendor-view-contacts")
        } else {
          toast("Network error occurred");
        }})
      .catch((error) => console.log("error", error));
  };

  const userToken = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getTableData());
    dispatch(getAllCountryList());
  }, []);
 
  return (
    <>
      <DashBoardheader />
      <SideNavBar />
      {loading && (
            <center>
              <div
                className="spinner-border text-primary loader"
                role="status"
              ></div>
            </center>
          )}
      <div className="main-body-container mb-5">
        <div className="container py-2 mt-2">
          <VendorBreadCrumbs />
        </div>
        <div className="container py-4 d-flex justify-content-between align-items-center">
          <h2 className="campaign-details-top-name">New Contact</h2>
        </div>
        <div className="container py-4 d-flex  align-items-center">
          <label className="vendor-list-title">Vendor List</label>
          <select className="vendor-list-dropdown" value={selectedVendorName}>
           
            {vendorList &&
              vendorList.map((vendorList, index) => {
                return (
                  <option key={index} value={vendorList.value} >
                    {vendorList.company_name}
                  </option>
                );
              })}
          </select>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="container ">
            <div className="cc-overview px-4 py-5">
              <div className="d-flex col-md-12 pb-3">
                <div className="col-md-2">
                  <p className="label-add-contact">Name </p>
                </div>
                <div className="col-md-3">
                  <input
                    className="label-input-add-new-contact"
                    type="text"
                    {...register("person_name", {
                      required: true,
                      maxLength: 30,
                    })}
                  ></input>
                  {errors.person_name && (
                    <span className="add-contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>

                <div className="col-md-2 px-5">
                  <div className="label-right-add-contact">Email</div>
                </div>
                <div className="col-md-4">
                  <input
                    className="label-input-add-new-contact"
                    type="email"
                    {...register("person_email", {
                      required: true,
                    })}
                  ></input>
                  {errors.person_email && (
                    <span className="add-contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex col-md-12">
                <div className="col-md-2">
                  <p className="label-add-contact">Phone No </p>
                </div>
                <div className="col-md-3">
                  <input
                    className="label-input-add-new-contact"
                    type="number"
                    {...register("contact_no", {
                      required: true,
                      minLength: 10,
                    })}
                  ></input>
                  {errors.contact_no && (
                    <span className="add-contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
                <div className="col-md-2">
                  <div className="label-right-add-contact px-5">Department</div>
                </div>
                <div className="col-md-4">
                  <input
                    className="label-input-add-new-contact"
                    type="text"
                    {...register("department", {
                      required: true,
                    })}
                  ></input>
                  {errors.department && (
                    <span className="add-contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex col-md-12 py-3">
                <div className="col-md-2">
                  <p className="label-add-contact">Position</p>
                </div>
                <div className="col-md-3">
                  <input
                    className="label-input-add-new-contact"
                    type="text"
                    {...register("position", {
                      required: true,
                    })}
                  ></input>
                  {errors.position && (
                    <span className="add-contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
                <div className="col-md-2">
                  <p className="label-right-add-contact px-5">Country</p>
                </div>
                <div className="col-md-4">
                  <select
                    className="label-input-add-new-contact"
                    type="text"
                    {...register("country", {
                      required: true,
                    })}
                  >
                    <option className="dropdown-country" value="">
                      {" "}
                      select country
                    </option>
                    {countryList &&
                      countryList.map((country, index) => {
                        return (
                          <option key={index} value={country.value}>
                            {country.country}
                          </option>
                        );
                      })}
                  </select>
                  {errors.countryList && (
                    <span className="add-contact-error-msg">
                      Please enter the value
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-end mt-5">
                <button className="add-btn-add-contact px-5 py-2" type="submit">
                  Add
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateVendorContact;
